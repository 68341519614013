

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.logo {
  display: inline-block;
  height: @layout-header-height;
  padding: 0 0 0 24px;
  font-size: 20px;
  line-height: @layout-header-height;
  vertical-align: top;
  cursor: pointer;
  img {
    display: inline-block;
    vertical-align: middle;
  }
}

.menu {
  :global(.anticon) {
    margin-right: 8px;
  }
  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}

.trigger {
  height: @layout-header-height;
  padding: ~'calc((@{layout-header-height} - 20px) / 2)' 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  &:hover {
    background: @pro-header-hover-bg;
  }
}

.right {
  float: right;
  overflow: hidden;
}
/*.right {
  float: right;
  height: 100%;
  overflow: hidden;
  .action {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > i {
      color: @text-color;
      vertical-align: middle;
    }
    &:hover {
      background: @pro-header-hover-bg;
    }
    &:global(.opened) {
      background: @pro-header-hover-bg;
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  .account {
    .avatar {
      margin: ~'calc((@{layout-header-height} - 24px) / 2)' 0;
      margin-right: 8px;
      color: @primary-color;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
}*/

.dark {
  height: @layout-header-height;
  .action {
    color: rgba(255, 255, 255, 0.85);
    > i {
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover,
    &:global(.opened) {
      background: @primary-color;
    }
    :global(.ant-badge) {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

@media only screen and (max-width: @screen-md) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  i.trigger {
    padding: 22px 12px;
  }
  .logo {
    position: relative;
    padding-right: 12px;
    padding-left: 12px;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
    background: #fff;
    .account {
      .avatar {
        margin-right: 0;
      }
    }
  }
}
