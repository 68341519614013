@import url(/css/mp.css);
@import url(/css/mp_2.css);
@import url(/css/mp_3.css);
@import url(/css/mp_4.css);


@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.logo {
  display: inline-block;
  height: @layout-header-height;
  padding: 0 0 0 24px;
  font-size: 20px;
  line-height: @layout-header-height;
  vertical-align: top;
  cursor: pointer;
  img {
    display: inline-block;
    vertical-align: middle;
  }
}

.menu {
  :global(.anticon) {
    margin-right: 8px;
  }
  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}

.trigger {
  height: @layout-header-height;
  padding: ~'calc((@{layout-header-height} - 20px) / 2)' 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  &:hover {
    background: @pro-header-hover-bg;
  }
}

.right {
  float: right;
  overflow: hidden;
}
/*.right {
  float: right;
  height: 100%;
  overflow: hidden;
  .action {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > i {
      color: @text-color;
      vertical-align: middle;
    }
    &:hover {
      background: @pro-header-hover-bg;
    }
    &:global(.opened) {
      background: @pro-header-hover-bg;
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  .account {
    .avatar {
      margin: ~'calc((@{layout-header-height} - 24px) / 2)' 0;
      margin-right: 8px;
      color: @primary-color;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
}*/

.dark {
  height: @layout-header-height;
  .action {
    color: rgba(255, 255, 255, 0.85);
    > i {
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover,
    &:global(.opened) {
      background: @primary-color;
    }
    :global(.ant-badge) {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

@media only screen and (max-width: @screen-md) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  i.trigger {
    padding: 22px 12px;
  }
  .logo {
    position: relative;
    padding-right: 12px;
    padding-left: 12px;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
    background: #fff;
    .account {
      .avatar {
        margin-right: 0;
      }
    }
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/*
rgb(100, 52, 32)
rgb(255, 200, 40)
rgb(108, 84, 163)
rgb(0, 155, 100)
rgb(0, 160, 221)
rgb(241, 86, 47)
rgb(238, 31, 56)
rgb(112, 191, 86)
rgb(0, 180, 147)
rgb(237, 28, 36)
*/
.logo_extra {
  display: inline-block;
  /*height: @layout-header-height ;*/
  min-height: 75px;
  height: auto;
  line-height: 20px;
  padding: 0 0 0 0px;
  font-size: 14px;
  vertical-align: top;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px #f0f2f5 solid;
  color: #ffffff !important;
  color: #fff;
}
.logo_extra.superadmin {
  background-color: #00A0E9;
}
.logo_extra.guest {
  background-color: #413c69;
}
.logo_extra.owner {
  background-color: #745c97;
}
.logo_extra.bankadmin .menu_balance_wrapper {
  background-color: #4B515D;
}
.logo_extra.personal .menu_balance_wrapper {
  background-color: #413c69;
}
.logo_extra.business .menu_balance_wrapper {
  background-color: #745c97;
}
.logo_extra.foundation .menu_balance_wrapper {
  background-color: #b21f66;
}
.logo_extra button {
  font-size: 18px;
  padding: 0px 4px;
  color: rgba(255, 255, 255, 0.9);
}
.logo_extra button:hover {
  color: #ffffff;
}
.logo_extra .menu_account_name,
.logo_extra .menu_account_container .menu_account_type {
  color: #fff;
}
.logo_extra .menu_account_item_icon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 16px;
}
.menu_account_selected.superadmin {
  color: #fff;
}
.menu_account_selected.owner {
  color: #fff;
}
.menu_account_selected.guest {
  color: #fff;
}
.menu_account_selected.admin {
  color: #4B515D;
}
.menu_account_selected.personal {
  color: #413c69;
}
.menu_account_selected.business {
  color: #745c97;
}
.menu_account_selected.foundation {
  color: #b21f66;
}
.logo {
  display: inline-block;
  height: 64px;
  line-height: 64px;
  padding: 0 0 0 0px;
  font-size: 20px;
  vertical-align: top;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px #f0f2f5 solid;
  overflow: hidden;
}
.logo.logo_mobile {
  padding-top: 10px;
  /*height: 130px;
    line-height: 130px;*/
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column!important;
}
.logo.logo_mobile .accountSelector {
  height: 50px;
  line-height: 50px;
}
.logo.logo_mobile a {
  height: 60px;
}
.logo a {
  color: inherit;
  display: block;
  text-decoration: none;
  height: 100%;
}
.logo a:hover {
  text-decoration: none;
}
.logo a .img_container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logo a .img_container img {
  max-width: 100%;
  max-height: 100%;
  /*padding:4px;*/
  /*object-fit: fill;*/
  /*display:inline-block;*/
  /*width:auto;
          height:50px;*/
  /*width:32px;
          height:32px;*/
}
.logo a span {
  text-align: left;
  padding-left: 8px;
  flex: 3 1;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
}
.menu :global(.anticon) {
  margin-right: 8px;
}
.menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.trigger {
  height: 64px;
  padding: calc((64px - 20px) / 2) 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}
.trigger:hover {
  background: #fff;
}
/*
.right {
  float: right;
  height: 100%;
  overflow: hidden;
  .action {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > i {
      color: @text-color;
      vertical-align: middle;
    }
    &:hover {
      background: @pro-header-hover-bg;
    }
    &:global(.opened) {
      background: @pro-header-hover-bg;
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  .account {
    .avatar {
      margin: ~'calc((@{layout-header-height} - 24px) / 2)' 0;
      margin-right: 8px;
      color: @primary-color;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
}
*/
.dark {
  height: 64px;
}
.dark .action {
  color: rgba(255, 255, 255, 0.85);
}
.dark .action > i {
  color: rgba(255, 255, 255, 0.85);
}
.dark .action:hover,
.dark .action:global(.opened) {
  background: #1890FF;
}
.dark .action :global(.ant-badge) {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 768px) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  i.trigger {
    padding: 22px 12px;
  }
  .logo {
    position: relative;
    padding-right: 12px;
    padding-left: 12px;
  }
  .right {
    /*position: absolute;*/
    top: 0;
    right: 12px;
  }
  .right .account .avatar {
    margin-right: 0;
  }
}
.menu :global(.anticon) {
  margin-right: 8px;
}
.menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container > * {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 480px) {
  .container {
    width: 100% !important;
  }
  .container > * {
    border-radius: 0 !important;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu :global(.anticon) {
  margin-right: 8px;
}
.menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.dropDown {
  line-height: 64px;
  vertical-align: top;
  cursor: pointer;
}
.dropDown > i {
  font-size: 16px !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.dropDown > i svg {
  position: relative;
  top: -1px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.list {
  max-height: 400px;
  overflow: auto;
}
.list::-webkit-scrollbar {
  display: none;
}
.list .item {
  padding-right: 24px;
  padding-left: 24px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
}
.list .item .meta {
  width: 100%;
}
.list .item .avatar {
  margin-top: 4px;
  background: #fff;
}
.list .item .iconElement {
  font-size: 32px;
}
.list .item.read {
  opacity: 0.4;
}
.list .item:last-child {
  border-bottom: 0;
}
.list .item:hover {
  background: #e6f7ff;
}
.list .item .title {
  margin-bottom: 8px;
  font-weight: normal;
}
.list .item .description {
  font-size: 12px;
  line-height: 1.5;
}
.list .item .datetime {
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.5;
}
.list .item .extra {
  float: right;
  margin-top: -1.5px;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
}
.list .loadMore {
  padding: 8px 0;
  color: #1890FF;
  text-align: center;
  cursor: pointer;
}
.list .loadMore.loadedAll {
  color: rgba(0, 0, 0, 0.25);
  cursor: unset;
}
.notFound {
  padding: 73px 0 88px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.notFound img {
  display: inline-block;
  height: 76px;
  margin-bottom: 16px;
}
.bottomBar.antd-pro-components-notice-icon-notice-list-bottomBar {
  display: none;
}
.bottomBar {
  height: 46px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 46px;
  text-align: center;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  transition: all 0.3s;
}
.bottomBar div {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.bottomBar div:only-child {
  width: 100%;
}
.bottomBar div:not(:only-child):last-child {
  border-left: 1px solid #e8e8e8;
}
.bottomBar.bottom_bordered {
  border-bottom: 1px solid #e8e8e8;
  padding: 12px 0;
  font-size: 14px;
  height: 56px;
  line-height: 32px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-bar {
  margin-bottom: 0;
}
.antd-pro-components-header-dropdown-index-container > * {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 480px) {
  .antd-pro-components-header-dropdown-index-container {
    width: 100% !important;
  }
  .antd-pro-components-header-dropdown-index-container > * {
    border-radius: 0 !important;
  }
}
.antd-pro-components-notice-icon-index-popover {
  position: relative;
  width: 336px;
}
.move-up-appear,
.move-up-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-appear.move-up-appear-active,
.move-up-enter.move-up-enter-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-up-appear,
.move-up-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-appear,
.move-down-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-appear.move-down-appear-active,
.move-down-enter.move-down-enter-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-down-appear,
.move-down-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-appear,
.move-left-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-appear.move-left-appear-active,
.move-left-enter.move-left-enter-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-left-appear,
.move-left-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-appear,
.move-right-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-appear.move-right-appear-active,
.move-right-enter.move-right-enter-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-right-appear,
.move-right-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes loadingCircle {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
[ant-click-animating-without-extra-node=true],
[ant-click-animating=true] {
  position: relative;
}
.ant-click-animating-node,
[ant-click-animating-without-extra-node=true]:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: "";
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  to {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  to {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  to {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  to {
    opacity: 0;
  }
}
.slide-up-appear,
.slide-up-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-appear.slide-up-appear-active,
.slide-up-enter.slide-up-enter-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-up-appear,
.slide-up-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-appear,
.slide-down-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-appear.slide-down-appear-active,
.slide-down-enter.slide-down-enter-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-down-appear,
.slide-down-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-appear,
.slide-left-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-appear.slide-left-appear-active,
.slide-left-enter.slide-left-enter-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-left-appear,
.slide-left-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-appear,
.slide-right-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-appear.slide-right-appear-active,
.slide-right-enter.slide-right-enter-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-right-appear,
.slide-right-enter {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 0;
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 1;
  }
  to {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    opacity: 0;
  }
}
/*
.swing-appear,
.swing-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.swing-appear.swing-appear-active,
.swing-enter.swing-enter-active {
    animation-name: antSwingIn;
    animation-play-state: running
}

@keyframes antSwingIn {

    0%,
    to {
        transform: translateX(0)
    }

    20% {
        transform: translateX(-10px)
    }

    40% {
        transform: translateX(10px)
    }

    60% {
        transform: translateX(-5px)
    }

    80% {
        transform: translateX(5px)
    }
}

.zoom-appear,
.zoom-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-appear.zoom-appear-active,
.zoom-enter.zoom-enter-active {
    animation-name: antZoomIn;
    animation-play-state: running
}

.zoom-leave.zoom-leave-active {
    animation-name: antZoomOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-appear,
.zoom-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-big-appear,
.zoom-big-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-appear.zoom-big-appear-active,
.zoom-big-enter.zoom-big-enter-active {
    animation-name: antZoomBigIn;
    animation-play-state: running
}

.zoom-big-leave.zoom-big-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-big-appear,
.zoom-big-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-big-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-big-fast-appear,
.zoom-big-fast-enter {
    animation-duration: .1s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-fast-leave {
    animation-duration: .1s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-big-fast-appear.zoom-big-fast-appear-active,
.zoom-big-fast-enter.zoom-big-fast-enter-active {
    animation-name: antZoomBigIn;
    animation-play-state: running
}

.zoom-big-fast-leave.zoom-big-fast-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-big-fast-appear,
.zoom-big-fast-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-big-fast-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-up-appear,
.zoom-up-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-up-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-up-appear.zoom-up-appear-active,
.zoom-up-enter.zoom-up-enter-active {
    animation-name: antZoomUpIn;
    animation-play-state: running
}

.zoom-up-leave.zoom-up-leave-active {
    animation-name: antZoomUpOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-up-appear,
.zoom-up-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-up-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-down-appear,
.zoom-down-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-down-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-down-appear.zoom-down-appear-active,
.zoom-down-enter.zoom-down-enter-active {
    animation-name: antZoomDownIn;
    animation-play-state: running
}

.zoom-down-leave.zoom-down-leave-active {
    animation-name: antZoomDownOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-down-appear,
.zoom-down-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-down-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-left-appear,
.zoom-left-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-left-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-left-appear.zoom-left-appear-active,
.zoom-left-enter.zoom-left-enter-active {
    animation-name: antZoomLeftIn;
    animation-play-state: running
}

.zoom-left-leave.zoom-left-leave-active {
    animation-name: antZoomLeftOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-left-appear,
.zoom-left-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-left-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

.zoom-right-appear,
.zoom-right-enter {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-right-leave {
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.zoom-right-appear.zoom-right-appear-active,
.zoom-right-enter.zoom-right-enter-active {
    animation-name: antZoomRightIn;
    animation-play-state: running
}

.zoom-right-leave.zoom-right-leave-active {
    animation-name: antZoomRightOut;
    animation-play-state: running;
    pointer-events: none
}

.zoom-right-appear,
.zoom-right-enter {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(.08, .82, .17, 1)
}

.zoom-right-leave {
    animation-timing-function: cubic-bezier(.78, .14, .15, .86)
}

@keyframes antZoomIn {
    0% {
        transform: scale(.2);
        opacity: 0
    }

    to {
        transform: scale(1);
        opacity: 1
    }
}

@keyframes antZoomOut {
    0% {
        transform: scale(1)
    }

    to {
        transform: scale(.2);
        opacity: 0
    }
}

@keyframes antZoomBigIn {
    0% {
        transform: scale(.8);
        opacity: 0
    }

    to {
        transform: scale(1);
        opacity: 1
    }
}

@keyframes antZoomBigOut {
    0% {
        transform: scale(1)
    }

    to {
        transform: scale(.8);
        opacity: 0
    }
}

@keyframes antZoomUpIn {
    0% {
        transform: scale(.8);
        transform-origin: 50% 0;
        opacity: 0
    }

    to {
        transform: scale(1);
        transform-origin: 50% 0
    }
}

@keyframes antZoomUpOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 0
    }

    to {
        transform: scale(.8);
        transform-origin: 50% 0;
        opacity: 0
    }
}

@keyframes antZoomLeftIn {
    0% {
        transform: scale(.8);
        transform-origin: 0 50%;
        opacity: 0
    }

    to {
        transform: scale(1);
        transform-origin: 0 50%
    }
}

@keyframes antZoomLeftOut {
    0% {
        transform: scale(1);
        transform-origin: 0 50%
    }

    to {
        transform: scale(.8);
        transform-origin: 0 50%;
        opacity: 0
    }
}

@keyframes antZoomRightIn {
    0% {
        transform: scale(.8);
        transform-origin: 100% 50%;
        opacity: 0
    }

    to {
        transform: scale(1);
        transform-origin: 100% 50%
    }
}

@keyframes antZoomRightOut {
    0% {
        transform: scale(1);
        transform-origin: 100% 50%
    }

    to {
        transform: scale(.8);
        transform-origin: 100% 50%;
        opacity: 0
    }
}

@keyframes antZoomDownIn {
    0% {
        transform: scale(.8);
        transform-origin: 50% 100%;
        opacity: 0
    }

    to {
        transform: scale(1);
        transform-origin: 50% 100%
    }
}

@keyframes antZoomDownOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 100%
    }

    to {
        transform: scale(.8);
        transform-origin: 50% 100%;
        opacity: 0
    }
}

.ant-motion-collapse-legacy {
    overflow: hidden
}

.ant-motion-collapse-legacy-active {
    transition: height .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1) !important
}

.ant-motion-collapse {
    overflow: hidden;
    transition: height .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1) !important
}

.ant-notification {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: fixed;
    z-index: 1010;
    width: 384px;
    max-width: calc(100vw - 32px);
    margin: 0 24px 0 0
}

.ant-notification-bottomLeft,
.ant-notification-topLeft {
    margin-right: 0;
    margin-left: 24px
}

.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active {
    animation-name: NotificationLeftFadeIn
}

.ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer
}

.ant-notification-notice {
    position: relative;
    margin-bottom: 16px;
    padding: 16px 24px;
    overflow: hidden;
    line-height: 1.5;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15)
}

.ant-notification-notice-message {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .85);
    font-size: 16px;
    line-height: 24px
}

.ant-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(264px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none
}

.ant-notification-notice-message-single-line-auto-margin:before {
    display: block;
    content: ""
}

.ant-notification-notice-description {
    font-size: 14px
}

.ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px
}

.ant-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px
}

.anticon.ant-notification-notice-icon-success {
    color: #52c41a
}

.anticon.ant-notification-notice-icon-info {
    color: #1890ff
}

.anticon.ant-notification-notice-icon-warning {
    color: #faad14
}

.anticon.ant-notification-notice-icon-error {
    color: #f5222d
}

.ant-notification-notice-close {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(0, 0, 0, .45);
    outline: 0
}

.ant-notification-notice-close:hover {
    color: rgba(0, 0, 0, .67)
}

.ant-notification-notice-btn {
    float: right;
    margin-top: 16px
}

.ant-notification .notification-fade-effect {
    animation-duration: .24s;
    animation-timing-function: cubic-bezier(.645, .045, .355, 1);
    animation-fill-mode: both
}

.ant-notification-fade-appear,
.ant-notification-fade-enter {
    opacity: 0;
    animation-duration: .24s;
    animation-timing-function: cubic-bezier(.645, .045, .355, 1);
    animation-fill-mode: both;
    animation-play-state: paused
}

.ant-notification-fade-leave {
    animation-duration: .24s;
    animation-timing-function: cubic-bezier(.645, .045, .355, 1);
    animation-fill-mode: both;
    animation-duration: .2s;
    animation-play-state: paused
}

.ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-fade-enter.ant-notification-fade-enter-active {
    animation-name: NotificationFadeIn;
    animation-play-state: running
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
    animation-name: NotificationFadeOut;
    animation-play-state: running
}

@keyframes NotificationFadeIn {
    0% {
        left: 384px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

@keyframes NotificationLeftFadeIn {
    0% {
        right: 384px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

@keyframes NotificationFadeOut {
    0% {
        max-height: 150px;
        margin-bottom: 16px;
        padding-top: 16px 24px;
        padding-bottom: 16px 24px;
        opacity: 1
    }

    to {
        max-height: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0
    }
}

.ant-btn {
    line-height: 1.5;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    color: rgba(0, 0, 0, .65);
    background-color: #fff;
    border: 1px solid #d9d9d9
}

.ant-btn>.anticon {
    line-height: 1
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
    outline: 0
}

.ant-btn:not([disabled]):hover {
    text-decoration: none
}

.ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none
}

.ant-btn.disabled,
.ant-btn[disabled] {
    cursor: not-allowed
}

.ant-btn.disabled>*,
.ant-btn[disabled]>* {
    pointer-events: none
}

.ant-btn-lg {
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    border-radius: 4px
}

.ant-btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px
}

.ant-btn>a:only-child {
    color: currentColor
}

.ant-btn>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn:focus,
.ant-btn:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff
}

.ant-btn:focus>a:only-child,
.ant-btn:hover>a:only-child {
    color: currentColor
}

.ant-btn:focus>a:only-child:after,
.ant-btn:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn.active,
.ant-btn:active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9
}

.ant-btn.active>a:only-child,
.ant-btn:active>a:only-child {
    color: currentColor
}

.ant-btn.active>a:only-child:after,
.ant-btn:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-disabled,
.ant-btn-disabled.active,
.ant-btn-disabled:active,
.ant-btn-disabled:focus,
.ant-btn-disabled:hover,
.ant-btn.disabled,
.ant-btn.disabled.active,
.ant-btn.disabled:active,
.ant-btn.disabled:focus,
.ant-btn.disabled:hover,
.ant-btn[disabled],
.ant-btn[disabled].active,
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-disabled.active>a:only-child,
.ant-btn-disabled:active>a:only-child,
.ant-btn-disabled:focus>a:only-child,
.ant-btn-disabled:hover>a:only-child,
.ant-btn-disabled>a:only-child,
.ant-btn.disabled.active>a:only-child,
.ant-btn.disabled:active>a:only-child,
.ant-btn.disabled:focus>a:only-child,
.ant-btn.disabled:hover>a:only-child,
.ant-btn.disabled>a:only-child,
.ant-btn[disabled].active>a:only-child,
.ant-btn[disabled]:active>a:only-child,
.ant-btn[disabled]:focus>a:only-child,
.ant-btn[disabled]:hover>a:only-child,
.ant-btn[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-disabled.active>a:only-child:after,
.ant-btn-disabled:active>a:only-child:after,
.ant-btn-disabled:focus>a:only-child:after,
.ant-btn-disabled:hover>a:only-child:after,
.ant-btn-disabled>a:only-child:after,
.ant-btn.disabled.active>a:only-child:after,
.ant-btn.disabled:active>a:only-child:after,
.ant-btn.disabled:focus>a:only-child:after,
.ant-btn.disabled:hover>a:only-child:after,
.ant-btn.disabled>a:only-child:after,
.ant-btn[disabled].active>a:only-child:after,
.ant-btn[disabled]:active>a:only-child:after,
.ant-btn[disabled]:focus>a:only-child:after,
.ant-btn[disabled]:hover>a:only-child:after,
.ant-btn[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn.active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
    text-decoration: none;
    background: #fff
}

.ant-btn>i,
.ant-btn>span {
    display: inline-block;
    transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);
    pointer-events: none
}

.ant-btn-primary {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045)
}

.ant-btn-primary>a:only-child {
    color: currentColor
}

.ant-btn-primary>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff
}

.ant-btn-primary:focus>a:only-child,
.ant-btn-primary:hover>a:only-child {
    color: currentColor
}

.ant-btn-primary:focus>a:only-child:after,
.ant-btn-primary:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-primary.active,
.ant-btn-primary:active {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9
}

.ant-btn-primary.active>a:only-child,
.ant-btn-primary:active>a:only-child {
    color: currentColor
}

.ant-btn-primary.active>a:only-child:after,
.ant-btn-primary:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-primary-disabled,
.ant-btn-primary-disabled.active,
.ant-btn-primary-disabled:active,
.ant-btn-primary-disabled:focus,
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled,
.ant-btn-primary.disabled.active,
.ant-btn-primary.disabled:active,
.ant-btn-primary.disabled:focus,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled],
.ant-btn-primary[disabled].active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-primary-disabled.active>a:only-child,
.ant-btn-primary-disabled:active>a:only-child,
.ant-btn-primary-disabled:focus>a:only-child,
.ant-btn-primary-disabled:hover>a:only-child,
.ant-btn-primary-disabled>a:only-child,
.ant-btn-primary.disabled.active>a:only-child,
.ant-btn-primary.disabled:active>a:only-child,
.ant-btn-primary.disabled:focus>a:only-child,
.ant-btn-primary.disabled:hover>a:only-child,
.ant-btn-primary.disabled>a:only-child,
.ant-btn-primary[disabled].active>a:only-child,
.ant-btn-primary[disabled]:active>a:only-child,
.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-primary[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-primary-disabled.active>a:only-child:after,
.ant-btn-primary-disabled:active>a:only-child:after,
.ant-btn-primary-disabled:focus>a:only-child:after,
.ant-btn-primary-disabled:hover>a:only-child:after,
.ant-btn-primary-disabled>a:only-child:after,
.ant-btn-primary.disabled.active>a:only-child:after,
.ant-btn-primary.disabled:active>a:only-child:after,
.ant-btn-primary.disabled:focus>a:only-child:after,
.ant-btn-primary.disabled:hover>a:only-child:after,
.ant-btn-primary.disabled>a:only-child:after,
.ant-btn-primary[disabled].active>a:only-child:after,
.ant-btn-primary[disabled]:active>a:only-child:after,
.ant-btn-primary[disabled]:focus>a:only-child:after,
.ant-btn-primary[disabled]:hover>a:only-child:after,
.ant-btn-primary[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9
}

.ant-btn-group .ant-btn-primary+.ant-btn-primary,
.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
    border-left-color: #40a9ff
}

.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled],
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
    border-left-color: #d9d9d9
}

.ant-btn-ghost {
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border-color: #d9d9d9
}

.ant-btn-ghost>a:only-child {
    color: currentColor
}

.ant-btn-ghost>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-ghost:focus,
.ant-btn-ghost:hover {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff
}

.ant-btn-ghost:focus>a:only-child,
.ant-btn-ghost:hover>a:only-child {
    color: currentColor
}

.ant-btn-ghost:focus>a:only-child:after,
.ant-btn-ghost:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-ghost.active,
.ant-btn-ghost:active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9
}

.ant-btn-ghost.active>a:only-child,
.ant-btn-ghost:active>a:only-child {
    color: currentColor
}

.ant-btn-ghost.active>a:only-child:after,
.ant-btn-ghost:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-ghost-disabled,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled].active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-ghost-disabled.active>a:only-child,
.ant-btn-ghost-disabled:active>a:only-child,
.ant-btn-ghost-disabled:focus>a:only-child,
.ant-btn-ghost-disabled:hover>a:only-child,
.ant-btn-ghost-disabled>a:only-child,
.ant-btn-ghost.disabled.active>a:only-child,
.ant-btn-ghost.disabled:active>a:only-child,
.ant-btn-ghost.disabled:focus>a:only-child,
.ant-btn-ghost.disabled:hover>a:only-child,
.ant-btn-ghost.disabled>a:only-child,
.ant-btn-ghost[disabled].active>a:only-child,
.ant-btn-ghost[disabled]:active>a:only-child,
.ant-btn-ghost[disabled]:focus>a:only-child,
.ant-btn-ghost[disabled]:hover>a:only-child,
.ant-btn-ghost[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-ghost-disabled.active>a:only-child:after,
.ant-btn-ghost-disabled:active>a:only-child:after,
.ant-btn-ghost-disabled:focus>a:only-child:after,
.ant-btn-ghost-disabled:hover>a:only-child:after,
.ant-btn-ghost-disabled>a:only-child:after,
.ant-btn-ghost.disabled.active>a:only-child:after,
.ant-btn-ghost.disabled:active>a:only-child:after,
.ant-btn-ghost.disabled:focus>a:only-child:after,
.ant-btn-ghost.disabled:hover>a:only-child:after,
.ant-btn-ghost.disabled>a:only-child:after,
.ant-btn-ghost[disabled].active>a:only-child:after,
.ant-btn-ghost[disabled]:active>a:only-child:after,
.ant-btn-ghost[disabled]:focus>a:only-child:after,
.ant-btn-ghost[disabled]:hover>a:only-child:after,
.ant-btn-ghost[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-dashed {
    color: rgba(0, 0, 0, .65);
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: dashed
}

.ant-btn-dashed>a:only-child {
    color: currentColor
}

.ant-btn-dashed>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-dashed:focus,
.ant-btn-dashed:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff
}

.ant-btn-dashed:focus>a:only-child,
.ant-btn-dashed:hover>a:only-child {
    color: currentColor
}

.ant-btn-dashed:focus>a:only-child:after,
.ant-btn-dashed:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-dashed.active,
.ant-btn-dashed:active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9
}

.ant-btn-dashed.active>a:only-child,
.ant-btn-dashed:active>a:only-child {
    color: currentColor
}

.ant-btn-dashed.active>a:only-child:after,
.ant-btn-dashed:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-dashed-disabled,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled].active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-dashed-disabled.active>a:only-child,
.ant-btn-dashed-disabled:active>a:only-child,
.ant-btn-dashed-disabled:focus>a:only-child,
.ant-btn-dashed-disabled:hover>a:only-child,
.ant-btn-dashed-disabled>a:only-child,
.ant-btn-dashed.disabled.active>a:only-child,
.ant-btn-dashed.disabled:active>a:only-child,
.ant-btn-dashed.disabled:focus>a:only-child,
.ant-btn-dashed.disabled:hover>a:only-child,
.ant-btn-dashed.disabled>a:only-child,
.ant-btn-dashed[disabled].active>a:only-child,
.ant-btn-dashed[disabled]:active>a:only-child,
.ant-btn-dashed[disabled]:focus>a:only-child,
.ant-btn-dashed[disabled]:hover>a:only-child,
.ant-btn-dashed[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-dashed-disabled.active>a:only-child:after,
.ant-btn-dashed-disabled:active>a:only-child:after,
.ant-btn-dashed-disabled:focus>a:only-child:after,
.ant-btn-dashed-disabled:hover>a:only-child:after,
.ant-btn-dashed-disabled>a:only-child:after,
.ant-btn-dashed.disabled.active>a:only-child:after,
.ant-btn-dashed.disabled:active>a:only-child:after,
.ant-btn-dashed.disabled:focus>a:only-child:after,
.ant-btn-dashed.disabled:hover>a:only-child:after,
.ant-btn-dashed.disabled>a:only-child:after,
.ant-btn-dashed[disabled].active>a:only-child:after,
.ant-btn-dashed[disabled]:active>a:only-child:after,
.ant-btn-dashed[disabled]:focus>a:only-child:after,
.ant-btn-dashed[disabled]:hover>a:only-child:after,
.ant-btn-dashed[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045)
}

.ant-btn-danger>a:only-child {
    color: currentColor
}

.ant-btn-danger>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-danger:focus,
.ant-btn-danger:hover {
    color: #fff;
    background-color: #ff7875;
    border-color: #ff7875
}

.ant-btn-danger:focus>a:only-child,
.ant-btn-danger:hover>a:only-child {
    color: currentColor
}

.ant-btn-danger:focus>a:only-child:after,
.ant-btn-danger:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-danger.active,
.ant-btn-danger:active {
    color: #fff;
    background-color: #d9363e;
    border-color: #d9363e
}

.ant-btn-danger.active>a:only-child,
.ant-btn-danger:active>a:only-child {
    color: currentColor
}

.ant-btn-danger.active>a:only-child:after,
.ant-btn-danger:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-danger-disabled,
.ant-btn-danger-disabled.active,
.ant-btn-danger-disabled:active,
.ant-btn-danger-disabled:focus,
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled,
.ant-btn-danger.disabled.active,
.ant-btn-danger.disabled:active,
.ant-btn-danger.disabled:focus,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled],
.ant-btn-danger[disabled].active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-danger-disabled.active>a:only-child,
.ant-btn-danger-disabled:active>a:only-child,
.ant-btn-danger-disabled:focus>a:only-child,
.ant-btn-danger-disabled:hover>a:only-child,
.ant-btn-danger-disabled>a:only-child,
.ant-btn-danger.disabled.active>a:only-child,
.ant-btn-danger.disabled:active>a:only-child,
.ant-btn-danger.disabled:focus>a:only-child,
.ant-btn-danger.disabled:hover>a:only-child,
.ant-btn-danger.disabled>a:only-child,
.ant-btn-danger[disabled].active>a:only-child,
.ant-btn-danger[disabled]:active>a:only-child,
.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-danger[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-danger-disabled.active>a:only-child:after,
.ant-btn-danger-disabled:active>a:only-child:after,
.ant-btn-danger-disabled:focus>a:only-child:after,
.ant-btn-danger-disabled:hover>a:only-child:after,
.ant-btn-danger-disabled>a:only-child:after,
.ant-btn-danger.disabled.active>a:only-child:after,
.ant-btn-danger.disabled:active>a:only-child:after,
.ant-btn-danger.disabled:focus>a:only-child:after,
.ant-btn-danger.disabled:hover>a:only-child:after,
.ant-btn-danger.disabled>a:only-child:after,
.ant-btn-danger[disabled].active>a:only-child:after,
.ant-btn-danger[disabled]:active>a:only-child:after,
.ant-btn-danger[disabled]:focus>a:only-child:after,
.ant-btn-danger[disabled]:hover>a:only-child:after,
.ant-btn-danger[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-link {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none
}

.ant-btn-link>a:only-child {
    color: currentColor
}

.ant-btn-link>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-link:focus,
.ant-btn-link:hover {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff
}

.ant-btn-link:focus>a:only-child,
.ant-btn-link:hover>a:only-child {
    color: currentColor
}

.ant-btn-link:focus>a:only-child:after,
.ant-btn-link:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-link.active,
.ant-btn-link:active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9
}

.ant-btn-link.active>a:only-child,
.ant-btn-link:active>a:only-child {
    color: currentColor
}

.ant-btn-link.active>a:only-child:after,
.ant-btn-link:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-link-disabled,
.ant-btn-link-disabled.active,
.ant-btn-link-disabled:active,
.ant-btn-link-disabled:focus,
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled,
.ant-btn-link.disabled.active,
.ant-btn-link.disabled:active,
.ant-btn-link.disabled:focus,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled],
.ant-btn-link[disabled].active,
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
    background-color: #f5f5f5;
    border-color: #d9d9d9
}

.ant-btn-link:active,
.ant-btn-link:focus,
.ant-btn-link:hover {
    border-color: transparent
}

.ant-btn-link-disabled,
.ant-btn-link-disabled.active,
.ant-btn-link-disabled:active,
.ant-btn-link-disabled:focus,
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled,
.ant-btn-link.disabled.active,
.ant-btn-link.disabled:active,
.ant-btn-link.disabled:focus,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled],
.ant-btn-link[disabled].active,
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-link-disabled.active>a:only-child,
.ant-btn-link-disabled:active>a:only-child,
.ant-btn-link-disabled:focus>a:only-child,
.ant-btn-link-disabled:hover>a:only-child,
.ant-btn-link-disabled>a:only-child,
.ant-btn-link.disabled.active>a:only-child,
.ant-btn-link.disabled:active>a:only-child,
.ant-btn-link.disabled:focus>a:only-child,
.ant-btn-link.disabled:hover>a:only-child,
.ant-btn-link.disabled>a:only-child,
.ant-btn-link[disabled].active>a:only-child,
.ant-btn-link[disabled]:active>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-link-disabled.active>a:only-child:after,
.ant-btn-link-disabled:active>a:only-child:after,
.ant-btn-link-disabled:focus>a:only-child:after,
.ant-btn-link-disabled:hover>a:only-child:after,
.ant-btn-link-disabled>a:only-child:after,
.ant-btn-link.disabled.active>a:only-child:after,
.ant-btn-link.disabled:active>a:only-child:after,
.ant-btn-link.disabled:focus>a:only-child:after,
.ant-btn-link.disabled:hover>a:only-child:after,
.ant-btn-link.disabled>a:only-child:after,
.ant-btn-link[disabled].active>a:only-child:after,
.ant-btn-link[disabled]:active>a:only-child:after,
.ant-btn-link[disabled]:focus>a:only-child:after,
.ant-btn-link[disabled]:hover>a:only-child:after,
.ant-btn-link[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-icon-only {
    width: 32px;
    height: 32px;
    padding: 0;
    font-size: 16px;
    border-radius: 4px
}

.ant-btn-icon-only.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 18px;
    border-radius: 4px
}

.ant-btn-icon-only.ant-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 14px;
    border-radius: 4px
}

.ant-btn-round {
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 32px
}

.ant-btn-round.ant-btn-lg {
    height: 40px;
    padding: 0 20px;
    font-size: 16px;
    border-radius: 40px
}

.ant-btn-round.ant-btn-sm {
    height: 24px;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 24px
}

.ant-btn-round.ant-btn-icon-only {
    width: auto
}

.ant-btn-circle,
.ant-btn-circle-outline {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%
}

.ant-btn-circle-outline.ant-btn-lg,
.ant-btn-circle.ant-btn-lg {
    min-width: 40px;
    border-radius: 50%
}

.ant-btn-circle-outline.ant-btn-sm,
.ant-btn-circle.ant-btn-sm {
    min-width: 24px;
    border-radius: 50%
}

.ant-btn:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: .35;
    transition: opacity .2s;
    content: "";
    pointer-events: none
}

.ant-btn .anticon {
    transition: margin-left .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-btn .anticon.anticon-minus>svg,
.ant-btn .anticon.anticon-plus>svg {
    shape-rendering: optimizeSpeed
}

.ant-btn.ant-btn-loading {
    position: relative;
    pointer-events: none
}

.ant-btn.ant-btn-loading:before {
    display: block
}

.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 29px
}

.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
    margin-left: -14px
}

.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 24px
}

.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
    margin-left: -17px
}

.ant-btn-group {
    position: relative;
    display: inline-block
}

.ant-btn-group>.ant-btn,
.ant-btn-group>span>.ant-btn {
    position: relative
}

.ant-btn-group>.ant-btn.active,
.ant-btn-group>.ant-btn:active,
.ant-btn-group>.ant-btn:focus,
.ant-btn-group>.ant-btn:hover,
.ant-btn-group>span>.ant-btn.active,
.ant-btn-group>span>.ant-btn:active,
.ant-btn-group>span>.ant-btn:focus,
.ant-btn-group>span>.ant-btn:hover {
    z-index: 2
}

.ant-btn-group>.ant-btn:disabled,
.ant-btn-group>span>.ant-btn:disabled {
    z-index: 0
}

.ant-btn-group>.ant-btn-icon-only {
    font-size: 14px
}

.ant-btn-group-lg>.ant-btn,
.ant-btn-group-lg>span>.ant-btn {
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    border-radius: 0;
    line-height: 38px
}

.ant-btn-group-lg>.ant-btn.ant-btn-icon-only {
    width: 40px;
    height: 40px;
    padding-right: 0;
    padding-left: 0
}

.ant-btn-group-sm>.ant-btn,
.ant-btn-group-sm>span>.ant-btn {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 0;
    line-height: 22px
}

.ant-btn-group-sm>.ant-btn>.anticon,
.ant-btn-group-sm>span>.ant-btn>.anticon {
    font-size: 14px
}

.ant-btn-group-sm>.ant-btn.ant-btn-icon-only {
    width: 24px;
    height: 24px;
    padding-right: 0;
    padding-left: 0
}

.ant-btn+.ant-btn-group,
.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-group .ant-btn+span,
.ant-btn-group span+.ant-btn,
.ant-btn-group+.ant-btn,
.ant-btn-group+.ant-btn-group,
.ant-btn-group>span+span {
    margin-left: -1px
}

.ant-btn-group .ant-btn-primary+.ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent
}

.ant-btn-group .ant-btn {
    border-radius: 0
}

.ant-btn-group>.ant-btn:first-child,
.ant-btn-group>span:first-child>.ant-btn {
    margin-left: 0
}

.ant-btn-group>.ant-btn:only-child {
    border-radius: 4px
}

.ant-btn-group>span:only-child>.ant-btn {
    border-radius: 4px
}

.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.ant-btn-group-sm>.ant-btn:only-child {
    border-radius: 4px
}

.ant-btn-group-sm>span:only-child>.ant-btn {
    border-radius: 4px
}

.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.ant-btn-group>.ant-btn-group {
    float: left
}

.ant-btn-group>.ant-btn-group:not(:first-child):not(:last-child)>.ant-btn {
    border-radius: 0
}

.ant-btn-group>.ant-btn-group:first-child:not(:last-child)>.ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.ant-btn-group>.ant-btn-group:last-child:not(:first-child)>.ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.ant-btn:active>span,
.ant-btn:focus>span {
    position: relative
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
    margin-left: 8px
}

.ant-btn-background-ghost {
    color: #fff;
    background: 0 0 !important;
    border-color: #fff
}

.ant-btn-background-ghost.ant-btn-primary {
    color: #1890ff;
    background-color: transparent;
    border-color: #1890ff;
    text-shadow: none
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff
}

.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-primary.active,
.ant-btn-background-ghost.ant-btn-primary:active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9
}

.ant-btn-background-ghost.ant-btn-primary.active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary:active>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-primary.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled].active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-background-ghost.ant-btn-primary-disabled.active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled>a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-primary-disabled.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-danger {
    color: #ff4d4f;
    background-color: transparent;
    border-color: #ff4d4f;
    text-shadow: none
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-danger:focus,
.ant-btn-background-ghost.ant-btn-danger:hover {
    color: #ff7875;
    background-color: transparent;
    border-color: #ff7875
}

.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-danger.active,
.ant-btn-background-ghost.ant-btn-danger:active {
    color: #d9363e;
    background-color: transparent;
    border-color: #d9363e
}

.ant-btn-background-ghost.ant-btn-danger.active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger:active>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-danger.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled].active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-background-ghost.ant-btn-danger-disabled.active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled>a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-danger-disabled.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-link {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    text-shadow: none;
    color: #fff
}

.ant-btn-background-ghost.ant-btn-link>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-link>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-link:focus,
.ant-btn-background-ghost.ant-btn-link:hover {
    color: #40a9ff;
    background-color: transparent;
    border-color: transparent
}

.ant-btn-background-ghost.ant-btn-link:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-link:hover>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-link:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-link.active,
.ant-btn-background-ghost.ant-btn-link:active {
    color: #096dd9;
    background-color: transparent;
    border-color: transparent
}

.ant-btn-background-ghost.ant-btn-link.active>a:only-child,
.ant-btn-background-ghost.ant-btn-link:active>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-link.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-background-ghost.ant-btn-link-disabled,
.ant-btn-background-ghost.ant-btn-link-disabled.active,
.ant-btn-background-ghost.ant-btn-link-disabled:active,
.ant-btn-background-ghost.ant-btn-link-disabled:focus,
.ant-btn-background-ghost.ant-btn-link-disabled:hover,
.ant-btn-background-ghost.ant-btn-link.disabled,
.ant-btn-background-ghost.ant-btn-link.disabled.active,
.ant-btn-background-ghost.ant-btn-link.disabled:active,
.ant-btn-background-ghost.ant-btn-link.disabled:focus,
.ant-btn-background-ghost.ant-btn-link.disabled:hover,
.ant-btn-background-ghost.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-link[disabled].active,
.ant-btn-background-ghost.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-btn-background-ghost.ant-btn-link-disabled.active>a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:active>a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled>a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled.active>a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:active>a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled>a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled].active>a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:active>a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]>a:only-child {
    color: currentColor
}

.ant-btn-background-ghost.ant-btn-link-disabled.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link-disabled:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link-disabled:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link-disabled:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link-disabled>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link.disabled.active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link.disabled:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link.disabled:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link.disabled:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link.disabled>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link[disabled].active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link[disabled]:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-link[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-btn-two-chinese-chars:first-letter {
    letter-spacing: .34em
}

.ant-btn-two-chinese-chars>:not(.anticon) {
    margin-right: -.34em;
    letter-spacing: .34em
}

.ant-btn-block {
    width: 100%
}

.ant-btn:empty {
    vertical-align: top
}

a.ant-btn {
    padding-top: .1px;
    line-height: 30px
}

a.ant-btn-lg {
    line-height: 38px
}

a.ant-btn-sm {
    line-height: 22px
}

.ant-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: fixed;
    top: 16px;
    left: 0;
    z-index: 1010;
    width: 100%;
    pointer-events: none
}

.ant-message-notice {
    padding: 8px;
    text-align: center
}

.ant-message-notice:first-child {
    margin-top: -8px
}

.ant-message-notice-content {
    display: inline-block;
    padding: 10px 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    pointer-events: all
}

.ant-message-success .anticon {
    color: #52c41a
}

.ant-message-error .anticon {
    color: #f5222d
}

.ant-message-warning .anticon {
    color: #faad14
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
    color: #1890ff
}

.ant-message .anticon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px
}

.ant-message-notice.move-up-leave.move-up-leave-active {
    overflow: hidden;
    animation-name: MessageMoveOut;
    animation-duration: .3s
}

@keyframes MessageMoveOut {
    0% {
        max-height: 150px;
        padding: 8px;
        opacity: 1
    }

    to {
        max-height: 0;
        padding: 0;
        opacity: 0
    }
}

.ant-result {
    padding: 48px 32px
}

.ant-result-success .ant-result-icon>.anticon {
    color: #52c41a
}

.ant-result-error .ant-result-icon>.anticon {
    color: #f5222d
}

.ant-result-info .ant-result-icon>.anticon {
    color: #1890ff
}

.ant-result-warning .ant-result-icon>.anticon {
    color: #faad14
}

.ant-result-image {
    width: 250px;
    height: 295px;
    margin: auto
}

.ant-result-icon {
    margin-bottom: 24px;
    text-align: center
}

.ant-result-icon>.anticon {
    font-size: 72px
}

.ant-result-title {
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 1.8;
    text-align: center
}

.ant-result-subtitle {
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 1.6;
    text-align: center
}

.ant-result-extra {
    margin-top: 32px;
    text-align: center
}

.ant-result-extra>* {
    margin-right: 8px
}

.ant-result-extra>:last-child {
    margin-right: 0
}

.ant-result-content {
    margin-top: 24px;
    padding: 24px 40px;
    background-color: #fafafa
}

.ant-spin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    display: none;
    color: #1890ff;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    transition: transform .3s cubic-bezier(.78, .14, .15, .86)
}

.ant-spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1
}

.ant-spin-nested-loading {
    position: relative
}

.ant-spin-nested-loading>div>.ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding-top: 5px;
    text-shadow: 0 1px 2px #fff
}

.ant-spin-nested-loading>div>.ant-spin.ant-spin-show-text .ant-spin-dot {
    margin-top: -20px
}

.ant-spin-nested-loading>div>.ant-spin-sm .ant-spin-dot {
    margin: -7px
}

.ant-spin-nested-loading>div>.ant-spin-sm .ant-spin-text {
    padding-top: 2px
}

.ant-spin-nested-loading>div>.ant-spin-sm.ant-spin-show-text .ant-spin-dot {
    margin-top: -17px
}

.ant-spin-nested-loading>div>.ant-spin-lg .ant-spin-dot {
    margin: -16px
}

.ant-spin-nested-loading>div>.ant-spin-lg .ant-spin-text {
    padding-top: 11px
}

.ant-spin-nested-loading>div>.ant-spin-lg.ant-spin-show-text .ant-spin-dot {
    margin-top: -26px
}

.ant-spin-container {
    position: relative;
    transition: opacity .3s
}

.ant-spin-container:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    transition: all .3s;
    content: "";
    pointer-events: none
}

.ant-spin-blur {
    clear: both;
    overflow: hidden;
    opacity: .5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
}

.ant-spin-blur:after {
    opacity: .4;
    pointer-events: auto
}

.ant-spin-tip {
    color: rgba(0, 0, 0, .45)
}

.ant-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em
}

.ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #1890ff;
    border-radius: 100%;
    transform: scale(.75);
    transform-origin: 50% 50%;
    opacity: .3;
    animation: antSpinMove 1s linear infinite alternate
}

.ant-spin-dot-item:first-child {
    top: 0;
    left: 0
}

.ant-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: .4s
}

.ant-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    animation-delay: .8s
}

.ant-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 1.2s
}

.ant-spin-dot-spin {
    transform: rotate(45deg);
    animation: antRotate 1.2s linear infinite
}

.ant-spin-sm .ant-spin-dot {
    font-size: 14px
}

.ant-spin-sm .ant-spin-dot i {
    width: 6px;
    height: 6px
}

.ant-spin-lg .ant-spin-dot {
    font-size: 32px
}

.ant-spin-lg .ant-spin-dot i {
    width: 14px;
    height: 14px
}

.ant-spin.ant-spin-show-text .ant-spin-text {
    display: block
}

@media (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .ant-spin-blur {
        background: #fff;
        opacity: .5
    }
}

@keyframes antSpinMove {
    to {
        opacity: 1
    }
}

@keyframes antRotate {
    to {
        transform: rotate(405deg)
    }
}

.ant-popover {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: 400;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text
}

.ant-popover:after {
    position: absolute;
    background: hsla(0, 0%, 100%, .01);
    content: ""
}

.ant-popover-hidden {
    display: none
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
    padding-bottom: 10px
}

.ant-popover-placement-right,
.ant-popover-placement-rightBottom,
.ant-popover-placement-rightTop {
    padding-left: 10px
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
    padding-top: 10px
}

.ant-popover-placement-left,
.ant-popover-placement-leftBottom,
.ant-popover-placement-leftTop {
    padding-right: 10px
}

.ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .ant-popover-inner {
        box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
    }
}

.ant-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8
}

.ant-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, .65)
}

.ant-popover-message {
    position: relative;
    padding: 4px 0 12px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px
}

.ant-popover-message>.anticon {
    position: absolute;
    top: 8px;
    color: #faad14;
    font-size: 14px
}

.ant-popover-message-title {
    padding-left: 22px
}

.ant-popover-buttons {
    margin-bottom: 4px;
    text-align: right
}

.ant-popover-buttons button {
    margin-left: 8px
}

.ant-popover-arrow {
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: 0 0;
    border-style: solid;
    border-width: 4.24264069px;
    transform: rotate(45deg)
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
    bottom: 6.2px;
    border-color: transparent #fff #fff transparent;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, .07)
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg)
}

.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow {
    left: 16px
}

.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
    right: 16px
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow {
    left: 6px;
    border-color: transparent transparent #fff #fff;
    box-shadow: -3px 3px 7px rgba(0, 0, 0, .07)
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg)
}

.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow {
    top: 12px
}

.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow {
    bottom: 12px
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    top: 6px;
    border-color: #fff transparent transparent #fff;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, .06)
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg)
}

.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow {
    left: 16px
}

.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    right: 16px
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow {
    right: 6px;
    border-color: #fff #fff transparent transparent;
    box-shadow: 3px -3px 7px rgba(0, 0, 0, .07)
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg)
}

.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow {
    top: 12px
}

.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow {
    bottom: 12px
}

.ant-typography {
    color: rgba(0, 0, 0, .65)
}

.ant-typography.ant-typography-secondary {
    color: rgba(0, 0, 0, .45)
}

.ant-typography.ant-typography-warning {
    color: #faad14
}

.ant-typography.ant-typography-danger {
    color: #f5222d
}

.ant-typography.ant-typography-disabled {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-typography p,
div.ant-typography {
    margin-bottom: 1em
}

.ant-typography h1,
h1.ant-typography {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 38px;
    line-height: 1.23
}

.ant-typography h2,
h2.ant-typography {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.35
}

.ant-typography h3,
h3.ant-typography {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35
}

.ant-typography h4,
h4.ant-typography {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4
}

.ant-typography+h1.ant-typography,
.ant-typography+h2.ant-typography,
.ant-typography+h3.ant-typography,
.ant-typography+h4.ant-typography {
    margin-top: 1.2em
}

.ant-typography div+h1,
.ant-typography div+h2,
.ant-typography div+h3,
.ant-typography div+h4,
.ant-typography h1+h1,
.ant-typography h1+h2,
.ant-typography h1+h3,
.ant-typography h1+h4,
.ant-typography h2+h1,
.ant-typography h2+h2,
.ant-typography h2+h3,
.ant-typography h2+h4,
.ant-typography h3+h1,
.ant-typography h3+h2,
.ant-typography h3+h3,
.ant-typography h3+h4,
.ant-typography h4+h1,
.ant-typography h4+h2,
.ant-typography h4+h3,
.ant-typography h4+h4,
.ant-typography li+h1,
.ant-typography li+h2,
.ant-typography li+h3,
.ant-typography li+h4,
.ant-typography p+h1,
.ant-typography p+h2,
.ant-typography p+h3,
.ant-typography p+h4,
.ant-typography ul+h1,
.ant-typography ul+h2,
.ant-typography ul+h3,
.ant-typography ul+h4 {
    margin-top: 1.2em
}

span.ant-typography-ellipsis {
    display: inline-block
}

.ant-typography a {
    color: #1890ff;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    transition: color .3s
}

.ant-typography a:focus,
.ant-typography a:hover {
    color: #40a9ff
}

.ant-typography a:active {
    color: #096dd9
}

.ant-typography a:active,
.ant-typography a:hover {
    text-decoration: none
}

.ant-typography a[disabled] {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed;
    pointer-events: none
}

.ant-typography code {
    margin: 0 .2em;
    padding: .2em .4em .1em;
    font-size: 85%;
    background: rgba(0, 0, 0, .06);
    border: 1px solid rgba(0, 0, 0, .06);
    border-radius: 3px
}

.ant-typography mark {
    padding: 0;
    background-color: #ffe58f
}

.ant-typography ins,
.ant-typography u {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
    text-decoration-skip-ink: auto
}

.ant-typography del,
.ant-typography s {
    text-decoration: line-through
}

.ant-typography strong {
    font-weight: 600
}

.ant-typography-copy,
.ant-typography-edit,
.ant-typography-expand {
    color: #1890ff;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    transition: color .3s;
    margin-left: 8px
}

.ant-typography-copy:focus,
.ant-typography-copy:hover,
.ant-typography-edit:focus,
.ant-typography-edit:hover,
.ant-typography-expand:focus,
.ant-typography-expand:hover {
    color: #40a9ff
}

.ant-typography-copy:active,
.ant-typography-edit:active,
.ant-typography-expand:active {
    color: #096dd9
}

.ant-typography-copy-success,
.ant-typography-copy-success:focus,
.ant-typography-copy-success:hover {
    color: #52c41a
}

.ant-typography-edit-content {
    position: relative
}

div.ant-typography-edit-content {
    left: -12px;
    margin-top: -5px;
    margin-bottom: calc(1em - 6px)
}

.ant-typography-edit-content-confirm {
    position: absolute;
    right: 10px;
    bottom: 8px;
    color: rgba(0, 0, 0, .45);
    pointer-events: none
}

.ant-typography ol,
.ant-typography ul {
    margin: 0 0 1em;
    padding: 0
}

.ant-typography ol li,
.ant-typography ul li {
    margin: 0 0 0 20px;
    padding: 0 0 0 4px
}

.ant-typography ul li {
    list-style-type: circle
}

.ant-typography ul li li {
    list-style-type: disc
}

.ant-typography ol li {
    list-style-type: decimal
}

.ant-typography-ellipsis-single-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-typography-ellipsis-multiple-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.ant-tooltip {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1060;
    display: block;
    max-width: 250px;
    visibility: visible
}

.ant-tooltip-hidden {
    display: none
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
    padding-bottom: 8px
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightBottom,
.ant-tooltip-placement-rightTop {
    padding-left: 8px
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
    padding-top: 8px
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftBottom,
.ant-tooltip-placement-leftTop {
    padding-right: 8px
}

.ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, .75);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-tooltip-arrow {
    position: absolute;
    display: block;
    width: 13.07106781px;
    height: 13.07106781px;
    overflow: hidden;
    background: 0 0;
    pointer-events: none
}

.ant-tooltip-arrow:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    background-color: rgba(0, 0, 0, .75);
    content: "";
    pointer-events: auto
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: -5.07106781px
}

.ant-tooltip-placement-top .ant-tooltip-arrow:before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow:before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow:before {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, .07);
    transform: translateY(-6.53553391px) rotate(45deg)
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%)
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 13px
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 13px
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    left: -5.07106781px
}

.ant-tooltip-placement-right .ant-tooltip-arrow:before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow:before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow:before {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, .07);
    transform: translateX(6.53553391px) rotate(45deg)
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%)
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 5px
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 5px
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    right: -5.07106781px
}

.ant-tooltip-placement-left .ant-tooltip-arrow:before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow:before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow:before {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, .07);
    transform: translateX(-6.53553391px) rotate(45deg)
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%)
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 5px
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 5px
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: -5.07106781px
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow:before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow:before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow:before {
    box-shadow: -3px -3px 7px rgba(0, 0, 0, .07);
    transform: translateY(6.53553391px) rotate(45deg)
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%)
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 13px
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 13px
}

.ant-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all .3s
}

.ant-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-input-disabled {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

.ant-input[disabled] {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5;
    vertical-align: bottom;
    transition: all .3s, height 0s
}

.ant-input-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px
}

.ant-input-sm {
    height: 24px;
    padding: 1px 7px
}

.ant-input-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0
}

.ant-input-group[class*=col-] {
    float: none;
    padding-right: 0;
    padding-left: 0
}

.ant-input-group>[class*=col-] {
    padding-right: 8px
}

.ant-input-group>[class*=col-]:last-child {
    padding-right: 0
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group>.ant-input {
    display: table-cell
}

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group>.ant-input:not(:first-child):not(:last-child) {
    border-radius: 0
}

.ant-input-group-addon,
.ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle
}

.ant-input-group-wrap>* {
    display: block !important
}

.ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit
}

.ant-input-group .ant-input:focus {
    z-index: 1;
    border-right-width: 1px
}

.ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px
}

.ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, .65);
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all .3s
}

.ant-input-group-addon .ant-select {
    margin: -5px -11px
}

.ant-input-group-addon .ant-select .ant-select-selection {
    margin: -1px;
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none
}

.ant-input-group-addon .ant-select-focused .ant-select-selection,
.ant-input-group-addon .ant-select-open .ant-select-selection {
    color: #1890ff
}

.ant-input-group-addon>i:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ""
}

.ant-input-group-addon:first-child,
.ant-input-group>.ant-input:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.ant-input-group-addon:first-child .ant-select .ant-select-selection,
.ant-input-group>.ant-input:first-child .ant-select .ant-select-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.ant-input-group>.ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.ant-input-group>.ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.ant-input-group-addon:first-child {
    border-right: 0
}

.ant-input-group-addon:last-child {
    border-left: 0
}

.ant-input-group-addon:last-child,
.ant-input-group>.ant-input:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.ant-input-group-addon:last-child .ant-select .ant-select-selection,
.ant-input-group>.ant-input:last-child .ant-select .ant-select-selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.ant-input-group-lg .ant-input,
.ant-input-group-lg>.ant-input-group-addon {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px
}

.ant-input-group-sm .ant-input,
.ant-input-group-sm>.ant-input-group-addon {
    height: 24px;
    padding: 1px 7px
}

.ant-input-group-lg .ant-select-selection--single {
    height: 40px
}

.ant-input-group-sm .ant-select-selection--single {
    height: 24px
}

.ant-input-group .ant-input-affix-wrapper {
    display: table-cell;
    float: left;
    width: 100%
}

.ant-input-group.ant-input-group-compact {
    display: block;
    zoom: 1
}

.ant-input-group.ant-input-group-compact:after,
.ant-input-group.ant-input-group-compact:before {
    display: table;
    content: ""
}

.ant-input-group.ant-input-group-compact:after {
    clear: both
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child):focus {
    z-index: 1
}

.ant-input-group.ant-input-group-compact>* {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0
}

.ant-input-group.ant-input-group-compact>:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px
}

.ant-input-group.ant-input-group-compact .ant-input {
    float: none
}

.ant-input-group.ant-input-group-compact>.ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact>.ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selection,
.ant-input-group.ant-input-group-compact>.ant-time-picker .ant-time-picker-input {
    border-right-width: 1px;
    border-radius: 0
}

.ant-input-group.ant-input-group-compact>.ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selection:hover,
.ant-input-group.ant-input-group-compact>.ant-time-picker .ant-time-picker-input:hover {
    z-index: 1
}

.ant-input-group.ant-input-group-compact>.ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selection:focus,
.ant-input-group.ant-input-group-compact>.ant-time-picker .ant-time-picker-input:focus {
    z-index: 1
}

.ant-input-group.ant-input-group-compact>.ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selection,
.ant-input-group.ant-input-group-compact>.ant-time-picker:first-child .ant-time-picker-input,
.ant-input-group.ant-input-group-compact>:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.ant-input-group.ant-input-group-compact>.ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selection,
.ant-input-group.ant-input-group-compact>.ant-time-picker:last-child .ant-time-picker-input,
.ant-input-group.ant-input-group-compact>:last-child {
    border-right-width: 1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input {
    vertical-align: top
}

.ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top
}

.ant-input-affix-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: start
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-input-affix-wrapper .ant-input {
    position: relative;
    text-align: inherit
}

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, .65);
    line-height: 0;
    transform: translateY(-50%)
}

.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
    line-height: 1.5
}

.ant-input-affix-wrapper .ant-input-disabled~.ant-input-suffix .anticon {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed
}

.ant-input-affix-wrapper .ant-input-prefix {
    left: 12px
}

.ant-input-affix-wrapper .ant-input-suffix {
    right: 12px
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 30px
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input:not(:last-child) {
    padding-right: 49px
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
    padding-right: 22px
}

.ant-input-affix-wrapper .ant-input {
    min-height: 100%
}

.ant-input-password-icon {
    color: rgba(0, 0, 0, .45);
    cursor: pointer;
    transition: all .3s
}

.ant-input-password-icon:hover {
    color: #333
}

.ant-input-clear-icon {
    color: rgba(0, 0, 0, .25);
    font-size: 12px;
    cursor: pointer;
    transition: color .3s;
    vertical-align: 0
}

.ant-input-clear-icon:hover {
    color: rgba(0, 0, 0, .45)
}

.ant-input-clear-icon:active {
    color: rgba(0, 0, 0, .65)
}

.ant-input-clear-icon+i {
    margin-left: 6px
}

.ant-input-textarea-clear-icon {
    color: rgba(0, 0, 0, .25);
    font-size: 12px;
    cursor: pointer;
    transition: color .3s;
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px 8px 0 0
}

.ant-input-textarea-clear-icon:hover {
    color: rgba(0, 0, 0, .45)
}

.ant-input-textarea-clear-icon:active {
    color: rgba(0, 0, 0, .65)
}

.ant-input-textarea-clear-icon+i {
    margin-left: 6px
}

.ant-input-search-icon {
    color: rgba(0, 0, 0, .45);
    cursor: pointer;
    transition: all .3s
}

.ant-input-search-icon:hover {
    color: rgba(0, 0, 0, .8)
}

.ant-input-search-enter-button input {
    border-right: 0
}

.ant-input-search-enter-button input+.ant-input-group-addon,
.ant-input-search-enter-button+.ant-input-group-addon {
    padding: 0;
    border: 0
}

.ant-input-search-enter-button input+.ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button+.ant-input-group-addon .ant-input-search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.antd-pro-components-copy-block-index-copy-block {
    position: fixed;
    right: 80px;
    bottom: 40px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    background: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    cursor: pointer
}

.antd-pro-components-copy-block-index-copy-block-view {
    position: relative
}

.antd-pro-components-copy-block-index-copy-block-view .antd-pro-components-copy-block-index-copy-block-code {
    display: inline-block;
    margin: 0 .2em;
    padding: .2em .4em .1em;
    font-size: 85%;
    border-radius: 3px
}

.ant-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #f0f2f5
}

.ant-layout,
.ant-layout * {
    box-sizing: border-box
}

.ant-layout.ant-layout-has-sider {
    flex-direction: row
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-x: hidden
}

.ant-layout-footer,
.ant-layout-header {
    flex: 0 0 auto
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
    background: #001529
}

.ant-layout-footer {
    padding: 24px 50px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    background: #f0f2f5
}

.ant-layout-content {
    flex: auto;
    min-height: 0
}

.ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #001529;
    transition: all .2s
}

.ant-layout-sider-children {
    height: 100%;
    margin-top: -.1px;
    padding-top: .1px
}

.ant-layout-sider-has-trigger {
    padding-bottom: 48px
}

.ant-layout-sider-right {
    order: 1
}

.ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #002140;
    cursor: pointer;
    transition: all .2s
}

.ant-layout-sider-zero-width>* {
    overflow: hidden
}

.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 64px;
    right: -36px;
    z-index: 1;
    width: 36px;
    height: 42px;
    color: #fff;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    background: #001529;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background .3s ease
}

.ant-layout-sider-zero-width-trigger:hover {
    background: #192c3e
}

.ant-layout-sider-zero-width-trigger-right {
    left: -36px;
    border-radius: 4px 0 0 4px
}

.ant-layout-sider-light {
    background: #fff
}

.ant-layout-sider-light .ant-layout-sider-trigger {
    color: rgba(0, 0, 0, .65);
    background: #fff
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: rgba(0, 0, 0, .65);
    background: #fff
}

.ant-pro-basicLayout {
    height: 100%
}

.ant-pro-basicLayout .ant-layout {
    transition: all .2s
}

.ant-pro-basicLayout .ant-layout-header {
    position: absolute
}

.ant-pro-basicLayout-content {
    position: relative;
    margin: 24px
}

.ant-pro-basicLayout-content.ant-pro-basicLayout-has-header {
    padding-top: 64px
}

.ant-pro-basicLayout .ant-pro-basicLayout-is-children,
.ant-pro-basicLayout .ant-pro-basicLayout-is-children>.ant-layout {
    height: calc(100vh - 64px)
}

.ant-pro-fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    transition: width .2s
}

.ant-pro-global-header {
    position: relative;
    height: 64px;
    padding: 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08)
}

.ant-pro-global-header-logo {
    display: inline-block;
    height: 64px;
    padding: 0 0 0 24px;
    font-size: 20px;
    line-height: 64px;
    vertical-align: top;
    cursor: pointer
}

.ant-pro-global-header-logo img {
    display: inline-block;
    width: 32px;
    vertical-align: middle
}

.ant-pro-global-header-menu .anticon {
    margin-right: 8px
}

.ant-pro-global-header-menu .ant-dropdown-menu-item {
    min-width: 160px
}

.ant-pro-global-header-trigger {
    height: 64px;
    padding: 19px 24px;
    font-size: 20px;
    cursor: pointer;
    transition: all .3s, padding 0s
}

.ant-pro-global-header-trigger:hover {
    background: #fff
}

.ant-pro-global-header .dark {
    height: 64px
}

.ant-pro-global-header .dark .action {
    color: hsla(0, 0%, 100%, .85)
}

.ant-pro-global-header .dark .action>i {
    color: hsla(0, 0%, 100%, .85)
}

.ant-pro-global-header .dark .action.opened,
.ant-pro-global-header .dark .action:hover {
    background: #1890ff
}

.ant-pro-global-header .dark .action .ant-badge {
    color: hsla(0, 0%, 100%, .85)
}

.ant-pro-sider-menu-logo {
    position: relative;
    height: 64px;
    padding-left: 24px;
    overflow: hidden;
    line-height: 64px;
    background: #001529;
    cursor: pointer;
    transition: all .3s
}

.ant-pro-sider-menu-logo img {
    display: inline-block;
    height: 32px;
    vertical-align: middle
}

.ant-pro-sider-menu-logo h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    vertical-align: middle
}

.ant-pro-sider-menu-sider {
    position: relative;
    z-index: 10;
    min-height: 100%;
    box-shadow: 2px 0 6px rgba(0, 21, 41, .35)
}

.ant-pro-sider-menu-sider.fix-sider-bar {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, .05)
}

.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-root {
    height: calc(100vh - 64px);
    overflow-y: auto
}

.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-inline {
    border-right: 0
}

.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-inline .ant-menu-item,
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-inline .ant-menu-submenu-title {
    width: 100%
}

.ant-pro-sider-menu-sider.light {
    background-color: #fff;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, .05)
}

.ant-pro-sider-menu-sider.light .ant-pro-sider-menu-logo {
    background-color: #fff
}

.ant-pro-sider-menu-sider.light .ant-pro-sider-menu-logo h1 {
    color: #1890ff
}

.ant-pro-sider-menu-sider.light .ant-menu-light {
    border-right-color: transparent
}

.ant-pro-sider-menu-icon {
    width: 14px;
    vertical-align: baseline
}

.ant-pro-sider-menu .top-nav-menu li.ant-menu-item {
    height: 64px;
    line-height: 64px
}

.ant-pro-sider-menu .drawer .drawer-content {
    background: #001529
}

.ant-pro-sider-menu .ant-menu-inline-collapsed>.ant-menu-item .sider-menu-item-img+span,
.ant-pro-sider-menu .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .sider-menu-item-img+span,
.ant-pro-sider-menu .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .sider-menu-item-img+span {
    display: inline-block;
    max-width: 0;
    opacity: 0
}

.ant-pro-sider-menu .ant-menu-item .sider-menu-item-img+span,
.ant-pro-sider-menu .ant-menu-submenu-title .sider-menu-item-img+span {
    opacity: 1;
    transition: opacity .3s cubic-bezier(.645, .045, .355, 1), width .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-pro-sider-menu .ant-drawer-body {
    padding: 0
}

.ant-menu {
    box-sizing: border-box;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    font-feature-settings: "tnum";
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    line-height: 0;
    list-style: none;
    background: #fff;
    outline: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    transition: background .3s, width .2s;
    zoom: 1
}

.ant-menu:after,
.ant-menu:before {
    display: table;
    content: ""
}

.ant-menu:after {
    clear: both
}

.ant-menu ol,
.ant-menu ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.ant-menu-hidden {
    display: none
}

.ant-menu-item-group-title {
    padding: 8px 16px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 1.5;
    transition: all .3s
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
    transition: border-color .3s cubic-bezier(.645, .045, .355, 1), background .3s cubic-bezier(.645, .045, .355, 1), padding .15s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu-submenu-selected {
    color: #1890ff
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background: #e6f7ff
}

.ant-menu-submenu .ant-menu-sub {
    cursor: auto;
    transition: background .3s cubic-bezier(.645, .045, .355, 1), padding .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu-item>a {
    display: block;
    color: rgba(0, 0, 0, .65)
}

.ant-menu-item>a:hover {
    color: #1890ff
}

.ant-menu-item>a:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: ""
}

.ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    line-height: 0;
    background-color: #e8e8e8
}

.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #1890ff
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px
}

.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent
}

.ant-menu-item-selected {
    color: #1890ff
}

.ant-menu-item-selected>a,
.ant-menu-item-selected>a:hover {
    color: #1890ff
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 1px solid #e8e8e8
}

.ant-menu-vertical-right {
    border-left: 1px solid #e8e8e8
}

.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
    min-width: 160px;
    padding: 0;
    border-right: 0;
    transform-origin: 0 0
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical.ant-menu-sub .ant-menu-item:after {
    border-right: 0
}

.ant-menu-vertical-left.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub>.ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub>.ant-menu-submenu,
.ant-menu-vertical.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical.ant-menu-sub>.ant-menu-submenu {
    transform-origin: 0 0
}

.ant-menu-horizontal.ant-menu-sub {
    min-width: 114px
}

.ant-menu-item,
.ant-menu-submenu-title {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: color .3s cubic-bezier(.645, .045, .355, 1), border-color .3s cubic-bezier(.645, .045, .355, 1), background .3s cubic-bezier(.645, .045, .355, 1), padding .15s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
    transition: font-size .15s cubic-bezier(.215, .61, .355, 1), margin .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu-item .anticon+span,
.ant-menu-submenu-title .anticon+span {
    opacity: 1;
    transition: opacity .3s cubic-bezier(.645, .045, .355, 1), width .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu>.ant-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    padding: 0;
    overflow: hidden;
    line-height: 0;
    background-color: #e8e8e8
}

.ant-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: #fff;
    border-radius: 4px
}

.ant-menu-submenu-popup .submenu-title-wrapper {
    padding-right: 20px
}

.ant-menu-submenu-popup:before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .0001;
    content: " "
}

.ant-menu-submenu>.ant-menu {
    background-color: #fff;
    border-radius: 4px
}

.ant-menu-submenu>.ant-menu-submenu-title:after {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    transition: transform .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background: #fff;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, .65), rgba(0, 0, 0, .65));
    border-radius: 2px;
    transition: background .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1);
    content: ""
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateY(-2px)
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    transform: rotate(-45deg) translateY(2px)
}

.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
    background: linear-gradient(90deg, #1890ff, #1890ff)
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    transform: rotate(-45deg) translateX(2px)
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    transform: rotate(45deg) translateX(-2px)
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
    transform: translateY(-2px)
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    transform: rotate(-45deg) translateX(-2px)
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateX(2px)
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #1890ff
}

.ant-menu-vertical .ant-menu-submenu-selected>a,
.ant-menu-vertical-left .ant-menu-submenu-selected>a,
.ant-menu-vertical-right .ant-menu-submenu-selected>a {
    color: #1890ff
}

.ant-menu-horizontal {
    line-height: 46px;
    white-space: nowrap;
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    box-shadow: none
}

.ant-menu-horizontal>.ant-menu-item,
.ant-menu-horizontal>.ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom;
    border-bottom: 2px solid transparent
}

.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-submenu-selected,
.ant-menu-horizontal>.ant-menu-submenu:hover {
    color: #1890ff;
    border-bottom: 2px solid #1890ff
}

.ant-menu-horizontal>.ant-menu-item>a {
    display: block;
    color: rgba(0, 0, 0, .65)
}

.ant-menu-horizontal>.ant-menu-item>a:hover {
    color: #1890ff
}

.ant-menu-horizontal>.ant-menu-item>a:before {
    bottom: -2px
}

.ant-menu-horizontal>.ant-menu-item-selected>a {
    color: #1890ff
}

.ant-menu-horizontal:after {
    display: block;
    clear: both;
    height: 0;
    content: " "
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item {
    position: relative
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
    transform: scaleY(.0001);
    opacity: 0;
    transition: transform .15s cubic-bezier(.215, .61, .355, 1), opacity .15s cubic-bezier(.215, .61, .355, 1);
    content: ""
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    font-size: 14px;
    line-height: 40px;
    text-overflow: ellipsis
}

.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu {
    padding-bottom: .02px
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child) {
    margin-bottom: 8px
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 40px;
    line-height: 40px
}

.ant-menu-inline {
    width: 100%
}

.ant-menu-inline .ant-menu-item-selected:after,
.ant-menu-inline .ant-menu-selected:after {
    transform: scaleY(1);
    opacity: 1;
    transition: transform .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1)
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px)
}

.ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px
}

.ant-menu-inline-collapsed {
    width: 80px
}

.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    left: 0;
    padding: 0 32px !important;
    text-overflow: clip
}

.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none
}

.ant-menu-inline-collapsed>.ant-menu-item .anticon,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
    margin: 0;
    font-size: 16px;
    line-height: 40px
}

.ant-menu-inline-collapsed>.ant-menu-item .anticon+span,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon+span,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span {
    display: inline-block;
    max-width: 0;
    opacity: 0
}

.ant-menu-inline-collapsed-tooltip {
    pointer-events: none
}

.ant-menu-inline-collapsed-tooltip .anticon {
    display: none
}

.ant-menu-inline-collapsed-tooltip a {
    color: hsla(0, 0%, 100%, .85)
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
    padding-right: 4px;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-menu-item-group-list {
    margin: 0;
    padding: 0
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px
}

.ant-menu-root.ant-menu-inline,
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right {
    box-shadow: none
}

.ant-menu-sub.ant-menu-inline {
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
    color: rgba(0, 0, 0, .25) !important;
    background: 0 0;
    border-color: transparent !important;
    cursor: not-allowed
}

.ant-menu-item-disabled>a,
.ant-menu-submenu-disabled>a {
    color: rgba(0, 0, 0, .25) !important;
    pointer-events: none
}

.ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-submenu-disabled>.ant-menu-submenu-title {
    color: rgba(0, 0, 0, .25) !important;
    cursor: not-allowed
}

.ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
    background: rgba(0, 0, 0, .25) !important
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    color: hsla(0, 0%, 100%, .65);
    background: #001529
}

.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: .45;
    transition: all .3s
}

.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    background: #fff
}

.ant-menu-dark.ant-menu-submenu-popup {
    background: 0 0
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, .45)
}

.ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
    top: 0;
    margin-top: 0;
    border-color: #001529;
    border-bottom: 0
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item>a:before {
    bottom: 0
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a {
    color: hsla(0, 0%, 100%, .65)
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
    border-right: 0
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0
}

.ant-menu-dark.ant-menu-inline .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after {
    border-right: 0
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent
}

.ant-menu-dark .ant-menu-item-active>a,
.ant-menu-dark .ant-menu-item:hover>a,
.ant-menu-dark .ant-menu-submenu-active>a,
.ant-menu-dark .ant-menu-submenu-open>a,
.ant-menu-dark .ant-menu-submenu-selected>a,
.ant-menu-dark .ant-menu-submenu-title:hover>a {
    color: #fff
}

.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    opacity: 1
}

.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
    background: #fff
}

.ant-menu-dark .ant-menu-item:hover {
    background-color: transparent
}

.ant-menu-dark .ant-menu-item-selected {
    color: #fff;
    border-right: 0
}

.ant-menu-dark .ant-menu-item-selected:after {
    border-right: 0
}

.ant-menu-dark .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-item-selected>a:hover {
    color: #fff
}

.ant-menu-dark .ant-menu-item-selected .anticon {
    color: #fff
}

.ant-menu-dark .ant-menu-item-selected .anticon+span {
    color: #fff
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-item-disabled>a,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-submenu-disabled>a {
    color: hsla(0, 0%, 100%, .35) !important;
    opacity: .8
}

.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title {
    color: hsla(0, 0%, 100%, .35) !important
}

.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
    background: hsla(0, 0%, 100%, .35) !important
}

.ant-pro-top-nav-header {
    position: relative;
    width: 100%;
    height: 64px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    transition: background .3s, width .2s
}

.ant-pro-top-nav-header .ant-menu-submenu.ant-menu-submenu-horizontal {
    height: 100%;
    line-height: 64px
}

.ant-pro-top-nav-header .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
    height: 100%
}

.ant-pro-top-nav-header.light {
    background-color: #fff
}

.ant-pro-top-nav-header.light h1 {
    color: #002140
}

.ant-pro-top-nav-header-main {
    display: flex;
    height: 64px;
    padding-left: 24px
}

.ant-pro-top-nav-header-main.wide {
    max-width: 1200px;
    margin: auto;
    padding-left: 0
}

.ant-pro-top-nav-header-main .left {
    display: flex;
    flex: 1 1
}

.ant-pro-top-nav-header-main .right {
    width: 324px
}

.ant-pro-top-nav-header-logo {
    position: relative;
    width: 165px;
    height: 64px;
    overflow: hidden;
    line-height: 64px;
    transition: all .3s
}

.ant-pro-top-nav-header-logo img {
    display: inline-block;
    height: 32px;
    vertical-align: middle
}

.ant-pro-top-nav-header-logo h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    vertical-align: top
}

.ant-pro-top-nav-header-menu .ant-menu.ant-menu-horizontal {
    height: 64px;
    line-height: 64px;
    border: none
}

.ant-pro-global-footer {
    margin: 48px 0 24px;
    padding: 0 16px;
    text-align: center
}

.ant-pro-global-footer-links {
    margin-bottom: 8px
}

.ant-pro-global-footer-links a {
    color: rgba(0, 0, 0, .45);
    transition: all .3s
}

.ant-pro-global-footer-links a:not(:last-child) {
    margin-right: 40px
}

.ant-pro-global-footer-links a:hover {
    color: rgba(0, 0, 0, .65)
}

.ant-pro-global-footer-copyright {
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

.ant-drawer {
    position: fixed;
    z-index: 1000;
    width: 0;
    height: 100%;
    transition: transform .3s cubic-bezier(.7, .3, .1, 1), height 0s ease .3s, width 0s ease .3s
}

.ant-drawer>* {
    transition: transform .3s cubic-bezier(.7, .3, .1, 1), box-shadow .3s cubic-bezier(.7, .3, .1, 1)
}

.ant-drawer-content-wrapper {
    position: absolute
}

.ant-drawer .ant-drawer-content {
    width: 100%;
    height: 100%
}

.ant-drawer-left,
.ant-drawer-right {
    top: 0;
    width: 0;
    height: 100%
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
    height: 100%
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
    width: 100%;
    transition: transform .3s cubic-bezier(.7, .3, .1, 1)
}

.ant-drawer-left.ant-drawer-open.no-mask,
.ant-drawer-right.ant-drawer-open.no-mask {
    width: 0
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 2px 0 8px rgba(0, 0, 0, .15)
}

.ant-drawer-right {
    right: 0
}

.ant-drawer-right .ant-drawer-content-wrapper {
    right: 0
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -2px 0 8px rgba(0, 0, 0, .15)
}

.ant-drawer-right.ant-drawer-open.no-mask {
    right: 1px;
    transform: translateX(1px)
}

.ant-drawer-bottom,
.ant-drawer-top {
    left: 0;
    width: 100%;
    height: 0%
}

.ant-drawer-bottom .ant-drawer-content-wrapper,
.ant-drawer-top .ant-drawer-content-wrapper {
    width: 100%
}

.ant-drawer-bottom.ant-drawer-open,
.ant-drawer-top.ant-drawer-open {
    height: 100%;
    transition: transform .3s cubic-bezier(.7, .3, .1, 1)
}

.ant-drawer-bottom.ant-drawer-open.no-mask,
.ant-drawer-top.ant-drawer-open.no-mask {
    height: 0%
}

.ant-drawer-top {
    top: 0
}

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-drawer-bottom {
    bottom: 0
}

.ant-drawer-bottom .ant-drawer-content-wrapper {
    bottom: 0
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 -2px 8px rgba(0, 0, 0, .15)
}

.ant-drawer-bottom.ant-drawer-open.no-mask {
    bottom: 1px;
    transform: translateY(1px)
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
    height: 100%;
    opacity: 1;
    transition: none;
    animation: antdDrawerFadeIn .3s cubic-bezier(.7, .3, .1, 1)
}

.ant-drawer-title {
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px
}

.ant-drawer-content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0
}

.ant-drawer-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    width: 56px;
    height: 56px;
    padding: 0;
    color: rgba(0, 0, 0, .45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color .3s;
    text-rendering: auto
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
    color: rgba(0, 0, 0, .75);
    text-decoration: none
}

.ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    color: rgba(0, 0, 0, .65);
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0
}

.ant-drawer-header-no-title {
    color: rgba(0, 0, 0, .65);
    background: #fff
}

.ant-drawer-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word
}

.ant-drawer-wrapper-body {
    height: 100%;
    overflow: auto
}

.ant-drawer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(0, 0, 0, .45);
    opacity: 0;
    transition: opacity .3s linear, height 0s ease .3s
}

.ant-drawer-open-content {
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15)
}

@keyframes antdDrawerFadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.ant-divider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    background: #e8e8e8
}

.ant-divider,
.ant-divider-vertical {
    position: relative;
    top: -.06em;
    display: inline-block;
    width: 1px;
    height: .9em;
    margin: 0 8px;
    vertical-align: middle
}

.ant-divider-horizontal {
    display: block;
    clear: both;
    width: 100%;
    min-width: 100%;
    height: 1px;
    margin: 24px 0
}

.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
    display: table;
    margin: 16px 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    background: 0 0
}

.ant-divider-horizontal.ant-divider-with-text-center:after,
.ant-divider-horizontal.ant-divider-with-text-center:before,
.ant-divider-horizontal.ant-divider-with-text-left:after,
.ant-divider-horizontal.ant-divider-with-text-left:before,
.ant-divider-horizontal.ant-divider-with-text-right:after,
.ant-divider-horizontal.ant-divider-with-text-right:before {
    position: relative;
    top: 50%;
    display: table-cell;
    width: 50%;
    border-top: 1px solid #e8e8e8;
    transform: translateY(50%);
    content: ""
}

.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
    display: inline-block;
    padding: 0 10px
}

.ant-divider-horizontal.ant-divider-with-text-left:before {
    top: 50%;
    width: 5%
}

.ant-divider-horizontal.ant-divider-with-text-left:after {
    top: 50%;
    width: 95%
}

.ant-divider-horizontal.ant-divider-with-text-right:before {
    top: 50%;
    width: 95%
}

.ant-divider-horizontal.ant-divider-with-text-right:after {
    top: 50%;
    width: 5%
}

.ant-divider-inner-text {
    display: inline-block;
    padding: 0 24px
}

.ant-divider-dashed {
    background: 0 0;
    border: dashed #e8e8e8;
    border-width: 1px 0 0
}

.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
    border-top: 0
}

.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed:after,
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed:before,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed:after,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed:before,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed:after,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed:before {
    border-style: dashed none none
}

.ant-divider-vertical.ant-divider-dashed {
    border-width: 0 0 0 1px
}

.ant-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative
}

.ant-list * {
    outline: 0
}

.ant-list-pagination {
    margin-top: 24px;
    text-align: right
}

.ant-list-more {
    margin-top: 12px;
    text-align: center
}

.ant-list-more button {
    padding-right: 32px;
    padding-left: 32px
}

.ant-list-spin {
    min-height: 40px;
    text-align: center
}

.ant-list-empty-text {
    padding: 16px;
    color: rgba(0, 0, 0, .25);
    font-size: 14px;
    text-align: center
}

.ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none
}

.ant-list-item {
    display: flex;
    align-items: center;
    padding: 12px 0
}

.ant-list-item-content {
    color: rgba(0, 0, 0, .65)
}

.ant-list-item-meta {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    font-size: 0
}

.ant-list-item-meta-avatar {
    margin-right: 16px
}

.ant-list-item-meta-content {
    flex: 1 0
}

h4 .ant-list-item-meta-title{
    font-weight: 500;
}

.ant-list-item-meta-title {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px
}

.ant-list-item-meta-title>a {
    color: rgba(0, 0, 0, .65);
    transition: all .3s
}

.ant-list-item-meta-title>a:hover {
    color: #1890ff
}

.ant-list-item-meta-description {
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.ant-list-item-action {
    flex: 0 0 auto;
    margin-left: 48px;
    padding: 0;
    font-size: 0;
    list-style: none
}

.ant-list-item-action>li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    cursor: pointer
}

.ant-list-item-action>li:first-child {
    padding-left: 0
}

.ant-list-item-action-split {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 14px;
    margin-top: -7px;
    background-color: #e8e8e8
}

.ant-list-header {
    background: 0 0
}

.ant-list-footer {
    background: 0 0
}

.ant-list-footer,
.ant-list-header {
    padding-top: 12px;
    padding-bottom: 12px
}

.ant-list-empty {
    padding: 16px 0;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
    text-align: center
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #e8e8e8
}

.ant-list-split .ant-list-item:last-child {
    border-bottom: none
}

.ant-list-split .ant-list-header {
    border-bottom: 1px solid #e8e8e8
}

.ant-list-loading .ant-list-spin-nested-loading {
    min-height: 32px
}

.ant-list-something-after-last-item .ant-spin-container>.ant-list-items>.ant-list-item:last-child {
    border-bottom: 1px solid #e8e8e8
}

.ant-list-lg .ant-list-item {
    padding-top: 16px;
    padding-bottom: 16px
}

.ant-list-sm .ant-list-item {
    padding-top: 8px;
    padding-bottom: 8px
}

.ant-list-vertical .ant-list-item {
    align-items: normal
}

.ant-list-vertical .ant-list-item-main {
    display: block;
    flex: 1 1
}

.ant-list-vertical .ant-list-item-extra {
    margin-left: 40px
}

.ant-list-vertical .ant-list-item-meta {
    margin-bottom: 16px
}

.ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 16px;
    line-height: 24px
}

.ant-list-vertical .ant-list-item-action {
    margin-top: 16px;
    margin-left: auto
}

.ant-list-vertical .ant-list-item-action>li {
    padding: 0 16px
}

.ant-list-vertical .ant-list-item-action>li:first-child {
    padding-left: 0
}

.ant-list-grid .ant-col>.ant-list-item {
    display: block;
    max-width: 100%;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none
}

.ant-list-item-no-flex {
    display: block
}

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
    float: right
}

.ant-list-bordered {
    border: 1px solid #d9d9d9;
    border-radius: 4px
}

.ant-list-bordered .ant-list-header {
    padding-right: 24px;
    padding-left: 24px
}

.ant-list-bordered .ant-list-footer {
    padding-right: 24px;
    padding-left: 24px
}

.ant-list-bordered .ant-list-item {
    padding-right: 24px;
    padding-left: 24px;
    border-bottom: 1px solid #e8e8e8
}

.ant-list-bordered .ant-list-pagination {
    margin: 16px 24px
}

.ant-list-bordered.ant-list-sm .ant-list-item {
    padding-right: 16px;
    padding-left: 16px
}

.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list-bordered.ant-list-sm .ant-list-header {
    padding: 8px 16px
}

.ant-list-bordered.ant-list-lg .ant-list-footer,
.ant-list-bordered.ant-list-lg .ant-list-header {
    padding: 16px 24px
}

@media screen and (max-width:768px) {
    .ant-list-item-action {
        margin-left: 24px
    }

    .ant-list-vertical .ant-list-item-extra {
        margin-left: 24px
    }
}

@media screen and (max-width:576px) {
    .ant-list-item {
        flex-wrap: wrap
    }

    .ant-list-item-action {
        margin-left: 12px
    }

    .ant-list-vertical .ant-list-item {
        flex-wrap: wrap-reverse
    }

    .ant-list-vertical .ant-list-item-main {
        min-width: 220px
    }

    .ant-list-vertical .ant-list-item-extra {
        margin: auto auto 16px
    }
}

.ant-empty {
    margin: 0 8px;
    font-size: 14px;
    line-height: 22px;
    text-align: center
}

.ant-empty-image {
    height: 100px;
    margin-bottom: 8px
}

.ant-empty-image img {
    height: 100%
}

.ant-empty-image svg {
    height: 100%;
    margin: auto
}

.ant-empty-description {
    margin: 0
}

.ant-empty-footer {
    margin-top: 16px
}

.ant-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, .25)
}

.ant-empty-normal .ant-empty-image {
    height: 40px
}

.ant-empty-small {
    margin: 8px 0;
    color: rgba(0, 0, 0, .25)
}

.ant-empty-small .ant-empty-image {
    height: 35px
}

.ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum"
}

.ant-pagination ol,
.ant-pagination ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.ant-pagination:after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: " "
}

.ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle
}

.ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: Arial;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgba(0, 0, 0, .65);
    transition: none
}

.ant-pagination-item a:hover {
    text-decoration: none
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: #1890ff;
    transition: all .3s
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
    color: #1890ff
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #1890ff
}

.ant-pagination-item-active a {
    color: #1890ff
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
    border-color: #40a9ff
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
    color: #40a9ff
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev {
    outline: 0
}

.ant-pagination-jump-next .ant-pagination-item-container,
.ant-pagination-jump-prev .ant-pagination-item-container {
    position: relative
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    display: inline-block;
    font-size: 12px;
    transform: scale(1) rotate(0);
    color: #1890ff;
    letter-spacing: -1px;
    opacity: 0;
    transition: all .2s
}

:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    font-size: 12px
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    color: rgba(0, 0, 0, .25);
    letter-spacing: 2px;
    text-align: center;
    text-indent: .13em;
    opacity: 1;
    transition: all .2s
}

.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
    opacity: 1
}

.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
    opacity: 0
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-prev {
    margin-right: 8px
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, .65);
    font-family: Arial;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s
}

.ant-pagination-next,
.ant-pagination-prev {
    outline: 0
}

.ant-pagination-next a,
.ant-pagination-prev a {
    color: rgba(0, 0, 0, .65);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-pagination-next:hover a,
.ant-pagination-prev:hover a {
    border-color: #40a9ff
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: 0;
    transition: all .3s
}

.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
    color: #1890ff;
    border-color: #1890ff
}

.ant-pagination-disabled,
.ant-pagination-disabled:focus,
.ant-pagination-disabled:hover {
    cursor: not-allowed
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled a,
.ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:hover a {
    color: rgba(0, 0, 0, .25);
    border-color: #d9d9d9;
    cursor: not-allowed
}

.ant-pagination-slash {
    margin: 0 10px 0 5px
}

.ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle
}

.ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
    margin-right: 8px
}

.ant-pagination-options-quick-jumper {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top
}

.ant-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all .3s;
    width: 50px;
    margin: 0 8px
}

.ant-pagination-options-quick-jumper input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-pagination-options-quick-jumper input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-pagination-options-quick-jumper input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-pagination-options-quick-jumper input-disabled {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

.ant-pagination-options-quick-jumper input[disabled] {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

textarea.ant-pagination-options-quick-jumper input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5;
    vertical-align: bottom;
    transition: all .3s, height 0s
}

.ant-pagination-options-quick-jumper input-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px
}

.ant-pagination-options-quick-jumper input-sm {
    height: 24px;
    padding: 1px 7px
}

.ant-pagination-simple .ant-pagination-next,
.ant-pagination-simple .ant-pagination-prev {
    height: 24px;
    line-height: 24px;
    vertical-align: top
}

.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
    height: 24px;
    border: 0
}

.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after,
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after {
    height: 24px;
    line-height: 24px
}

.ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px
}

.ant-pagination-simple .ant-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: 0;
    transition: border-color .3s
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #1890ff
}

.ant-pagination.mini .ant-pagination-simple-pager,
.ant-pagination.mini .ant-pagination-total-text {
    height: 24px;
    line-height: 24px
}

.ant-pagination.mini .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px
}

.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: 0 0;
    border-color: transparent
}

.ant-pagination.mini .ant-pagination-next,
.ant-pagination.mini .ant-pagination-prev {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px
}

.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link {
    background: 0 0;
    border-color: transparent
}

.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link:after,
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link:after {
    height: 24px;
    line-height: 24px
}

.ant-pagination.mini .ant-pagination-jump-next,
.ant-pagination.mini .ant-pagination-jump-prev {
    height: 24px;
    margin-right: 0;
    line-height: 24px
}

.ant-pagination.mini .ant-pagination-options {
    margin-left: 2px
}

.ant-pagination.mini .ant-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px
}

.ant-pagination.mini .ant-pagination-options-quick-jumper input {
    height: 24px;
    padding: 1px 7px;
    width: 44px
}

.ant-pagination.ant-pagination-disabled {
    cursor: not-allowed
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(0, 0, 0, .25);
    background: 0 0;
    border: none;
    cursor: not-allowed
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: #dbdbdb;
    border-color: transparent
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: #fff
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover {
    color: rgba(0, 0, 0, .45);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed
}

.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
    opacity: 0
}

.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
    opacity: 1
}

@media only screen and (max-width:992px) {

    .ant-pagination-item-after-jump-prev,
    .ant-pagination-item-before-jump-next {
        display: none
    }
}

@media only screen and (max-width:576px) {
    .ant-pagination-options {
        display: none
    }
}

.ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    outline: 0
}

.ant-select ol,
.ant-select ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.ant-select>ul>li>a {
    padding: 0;
    background-color: #fff
}

.ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    margin-top: -6px;
    color: rgba(0, 0, 0, .25);
    font-size: 12px;
    line-height: 1;
    transform-origin: 50% 50%
}

.ant-select-arrow>* {
    line-height: 1
}

.ant-select-arrow svg {
    display: inline-block
}

.ant-select-arrow:before {
    display: none
}

.ant-select-arrow .ant-select-arrow-icon {
    display: block
}

.ant-select-arrow .ant-select-arrow-icon svg {
    transition: transform .3s
}

.ant-select-selection {
    display: block;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1.02px solid #d9d9d9;
    border-radius: 4px;
    outline: 0;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-select-selection:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:active,
.ant-select-selection:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-select-selection__clear {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, .25);
    font-size: 12px;
    font-style: normal;
    line-height: 12px;
    text-align: center;
    text-transform: none;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    transition: color .3s ease, opacity .15s ease;
    text-rendering: auto
}

.ant-select-selection__clear:before {
    display: block
}

.ant-select-selection__clear:hover {
    color: rgba(0, 0, 0, .45)
}

.ant-select-selection:hover .ant-select-selection__clear {
    opacity: 1
}

.ant-select-selection-selected-value {
    float: left;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-select-no-arrow .ant-select-selection-selected-value {
    padding-right: 0
}

.ant-select-disabled {
    color: rgba(0, 0, 0, .25)
}

.ant-select-disabled .ant-select-selection {
    background: #f5f5f5;
    cursor: not-allowed
}

.ant-select-disabled .ant-select-selection:active,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:hover {
    border-color: #d9d9d9;
    box-shadow: none
}

.ant-select-disabled .ant-select-selection__clear {
    display: none;
    visibility: hidden;
    pointer-events: none
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    padding-right: 10px;
    color: rgba(0, 0, 0, .33);
    background: #f5f5f5
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
    display: none
}

.ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer
}

.ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 24px
}

.ant-select-no-arrow .ant-select-selection__rendered {
    margin-right: 11px
}

.ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 11px;
    margin-left: 11px;
    line-height: 30px
}

.ant-select-selection__rendered:after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: ".";
    pointer-events: none
}

.ant-select-lg {
    font-size: 16px
}

.ant-select-lg .ant-select-selection--single {
    height: 40px
}

.ant-select-lg .ant-select-selection__rendered {
    line-height: 38px
}

.ant-select-lg .ant-select-selection--multiple {
    min-height: 40px
}

.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 32px;
    line-height: 32px
}

.ant-select-lg .ant-select-selection--multiple .ant-select-arrow,
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear {
    top: 20px
}

.ant-select-sm .ant-select-selection--single {
    height: 24px
}

.ant-select-sm .ant-select-selection__rendered {
    margin-left: 7px;
    line-height: 22px
}

.ant-select-sm .ant-select-selection--multiple {
    min-height: 24px
}

.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 16px;
    line-height: 14px
}

.ant-select-sm .ant-select-selection--multiple .ant-select-arrow,
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear {
    top: 12px
}

.ant-select-sm .ant-select-arrow,
.ant-select-sm .ant-select-selection__clear {
    right: 8px
}

.ant-select-disabled .ant-select-selection__choice__remove {
    color: rgba(0, 0, 0, .25);
    cursor: default
}

.ant-select-disabled .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, .25)
}

.ant-select-search__field__wrap {
    position: relative;
    display: inline-block
}

.ant-select-search__field__placeholder,
.ant-select-selection__placeholder {
    position: absolute;
    top: 50%;
    right: 9px;
    left: 0;
    max-width: 100%;
    height: 20px;
    margin-top: -10px;
    overflow: hidden;
    color: #bfbfbf;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis
}

.ant-select-search__field__placeholder {
    left: 12px
}

.ant-select-search__field__mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    opacity: 0;
    pointer-events: none
}

.ant-select-search--inline {
    position: absolute;
    width: 100%;
    height: 100%
}

.ant-select-search--inline .ant-select-search__field__wrap {
    width: 100%;
    height: 100%
}

.ant-select-search--inline .ant-select-search__field {
    width: 100%;
    height: 100%;
    font-size: 100%;
    line-height: 1;
    background: 0 0;
    border-width: 0;
    border-radius: 4px;
    outline: 0
}

.ant-select-search--inline>i {
    float: right
}

.ant-select-selection--multiple {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1
}

.ant-select-selection--multiple:after,
.ant-select-selection--multiple:before {
    display: table;
    content: ""
}

.ant-select-selection--multiple:after {
    clear: both
}

.ant-select-selection--multiple .ant-select-search--inline {
    position: static;
    float: left;
    width: auto;
    max-width: 100%;
    padding: 0
}

.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    width: .75em;
    max-width: 100%
}

.ant-select-selection--multiple .ant-select-selection__rendered {
    height: auto;
    margin-bottom: -3px;
    margin-left: 5px
}

.ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-left: 6px
}

.ant-select-selection--multiple .ant-select-selection__rendered>ul>li,
.ant-select-selection--multiple>ul>li {
    height: 24px;
    margin-top: 3px;
    line-height: 22px
}

.ant-select-selection--multiple .ant-select-selection__choice {
    position: relative;
    float: left;
    max-width: 99%;
    margin-right: 4px;
    padding: 0 20px 0 10px;
    overflow: hidden;
    color: rgba(0, 0, 0, .65);
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    transition: padding .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-select-selection--multiple .ant-select-selection__choice__disabled {
    padding: 0 10px
}

.ant-select-selection--multiple .ant-select-selection__choice__content {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: margin .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    color: rgba(0, 0, 0, .45);
    font-weight: 700;
    line-height: inherit;
    cursor: pointer;
    transition: all .3s;
    display: inline-block;
    font-size: 12px;
    transform: scale(.83333333) rotate(0)
}

.ant-select-selection--multiple .ant-select-selection__choice__remove>* {
    line-height: 1
}

.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
    display: inline-block
}

.ant-select-selection--multiple .ant-select-selection__choice__remove:before {
    display: none
}

.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
    display: block
}

:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-size: 12px
}

.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, .75)
}

.ant-select-selection--multiple .ant-select-arrow,
.ant-select-selection--multiple .ant-select-selection__clear {
    top: 16px
}

.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-right: 20px
}

.ant-select-open .ant-select-arrow-icon svg {
    transform: rotate(180deg)
}

.ant-select-open .ant-select-selection {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-select-combobox .ant-select-arrow {
    display: none
}

.ant-select-combobox .ant-select-search--inline {
    float: none;
    width: 100%;
    height: 100%
}

.ant-select-combobox .ant-select-search__field__wrap {
    width: 100%;
    height: 100%
}

.ant-select-combobox .ant-select-search__field {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    box-shadow: none;
    transition: all .3s cubic-bezier(.645, .045, .355, 1), height 0s
}

.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 20px
}

.ant-select-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: normal;
    background-color: #fff;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpIn
}

.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownIn
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpOut
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownOut
}

.ant-select-dropdown-hidden {
    display: none
}

.ant-select-dropdown-menu {
    max-height: 250px;
    margin-bottom: 0;
    padding-left: 0;
    overflow: auto;
    list-style: none;
    outline: 0
}

.ant-select-dropdown-menu-item-group-list {
    margin: 0;
    padding: 0
}

.ant-select-dropdown-menu-item-group-list>.ant-select-dropdown-menu-item {
    padding-left: 20px
}

.ant-select-dropdown-menu-item-group-title {
    height: 32px;
    padding: 0 12px;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
    line-height: 32px
}

.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
    border-radius: 0
}

.ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, .65);
    font-weight: 400;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background .3s ease
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff
}

.ant-select-dropdown-menu-item:first-child {
    border-radius: 4px 4px 0 0
}

.ant-select-dropdown-menu-item:last-child {
    border-radius: 0 0 4px 4px
}

.ant-select-dropdown-menu-item-selected {
    color: rgba(0, 0, 0, .65);
    font-weight: 600;
    background-color: #fafafa
}

.ant-select-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed
}

.ant-select-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff
}

.ant-select-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #e8e8e8
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 32px
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    color: transparent;
    font-weight: 700;
    font-size: 12px;
    text-shadow: 0 .1px 0, .1px 0 0, 0 -.1px 0, -.1px 0;
    transform: translateY(-50%);
    transition: all .2s
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
    color: rgba(0, 0, 0, .87)
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
    display: none
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    display: inline-block;
    color: #1890ff
}

.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 12px
}

.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
    display: block
}

.ant-row {
    position: relative;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    zoom: 1;
    display: block;
    box-sizing: border-box
}

.ant-row:after,
.ant-row:before {
    display: table;
    content: ""
}

.ant-row:after {
    clear: both
}

.ant-row-flex {
    display: flex;
    flex-flow: row wrap
}

.ant-row-flex:after,
.ant-row-flex:before {
    display: flex
}

.ant-row-flex-start {
    justify-content: flex-start
}

.ant-row-flex-center {
    justify-content: center
}

.ant-row-flex-end {
    justify-content: flex-end
}

.ant-row-flex-space-between {
    justify-content: space-between
}

.ant-row-flex-space-around {
    justify-content: space-around
}

.ant-row-flex-top {
    align-items: flex-start
}

.ant-row-flex-middle {
    align-items: center
}

.ant-row-flex-bottom {
    align-items: flex-end
}

.ant-col {
    position: relative;
    min-height: 1px
}

.ant-col-1,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-2,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-lg-1,
.ant-col-lg-10,
.ant-col-lg-11,
.ant-col-lg-12,
.ant-col-lg-13,
.ant-col-lg-14,
.ant-col-lg-15,
.ant-col-lg-16,
.ant-col-lg-17,
.ant-col-lg-18,
.ant-col-lg-19,
.ant-col-lg-2,
.ant-col-lg-20,
.ant-col-lg-21,
.ant-col-lg-22,
.ant-col-lg-23,
.ant-col-lg-24,
.ant-col-lg-3,
.ant-col-lg-4,
.ant-col-lg-5,
.ant-col-lg-6,
.ant-col-lg-7,
.ant-col-lg-8,
.ant-col-lg-9,
.ant-col-md-1,
.ant-col-md-10,
.ant-col-md-11,
.ant-col-md-12,
.ant-col-md-13,
.ant-col-md-14,
.ant-col-md-15,
.ant-col-md-16,
.ant-col-md-17,
.ant-col-md-18,
.ant-col-md-19,
.ant-col-md-2,
.ant-col-md-20,
.ant-col-md-21,
.ant-col-md-22,
.ant-col-md-23,
.ant-col-md-24,
.ant-col-md-3,
.ant-col-md-4,
.ant-col-md-5,
.ant-col-md-6,
.ant-col-md-7,
.ant-col-md-8,
.ant-col-md-9,
.ant-col-sm-1,
.ant-col-sm-10,
.ant-col-sm-11,
.ant-col-sm-12,
.ant-col-sm-13,
.ant-col-sm-14,
.ant-col-sm-15,
.ant-col-sm-16,
.ant-col-sm-17,
.ant-col-sm-18,
.ant-col-sm-19,
.ant-col-sm-2,
.ant-col-sm-20,
.ant-col-sm-21,
.ant-col-sm-22,
.ant-col-sm-23,
.ant-col-sm-24,
.ant-col-sm-3,
.ant-col-sm-4,
.ant-col-sm-5,
.ant-col-sm-6,
.ant-col-sm-7,
.ant-col-sm-8,
.ant-col-sm-9,
.ant-col-xs-1,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-2,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9 {
    position: relative;
    padding-right: 0;
    padding-left: 0
}

.ant-col-1,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-2,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9 {
    flex: 0 0 auto;
    float: left
}

.ant-col-24 {
    display: block;
    box-sizing: border-box;
    width: 100%
}

.ant-col-push-24 {
    left: 100%
}

.ant-col-pull-24 {
    right: 100%
}

.ant-col-offset-24 {
    margin-left: 100%
}

.ant-col-order-24 {
    order: 24
}

.ant-col-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%
}

.ant-col-push-23 {
    left: 95.83333333%
}

.ant-col-pull-23 {
    right: 95.83333333%
}

.ant-col-offset-23 {
    margin-left: 95.83333333%
}

.ant-col-order-23 {
    order: 23
}

.ant-col-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%
}

.ant-col-push-22 {
    left: 91.66666667%
}

.ant-col-pull-22 {
    right: 91.66666667%
}

.ant-col-offset-22 {
    margin-left: 91.66666667%
}

.ant-col-order-22 {
    order: 22
}

.ant-col-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%
}

.ant-col-push-21 {
    left: 87.5%
}

.ant-col-pull-21 {
    right: 87.5%
}

.ant-col-offset-21 {
    margin-left: 87.5%
}

.ant-col-order-21 {
    order: 21
}

.ant-col-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%
}

.ant-col-push-20 {
    left: 83.33333333%
}

.ant-col-pull-20 {
    right: 83.33333333%
}

.ant-col-offset-20 {
    margin-left: 83.33333333%
}

.ant-col-order-20 {
    order: 20
}

.ant-col-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%
}

.ant-col-push-19 {
    left: 79.16666667%
}

.ant-col-pull-19 {
    right: 79.16666667%
}

.ant-col-offset-19 {
    margin-left: 79.16666667%
}

.ant-col-order-19 {
    order: 19
}

.ant-col-18 {
    display: block;
    box-sizing: border-box;
    width: 75%
}

.ant-col-push-18 {
    left: 75%
}

.ant-col-pull-18 {
    right: 75%
}

.ant-col-offset-18 {
    margin-left: 75%
}

.ant-col-order-18 {
    order: 18
}

.ant-col-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%
}

.ant-col-push-17 {
    left: 70.83333333%
}

.ant-col-pull-17 {
    right: 70.83333333%
}

.ant-col-offset-17 {
    margin-left: 70.83333333%
}

.ant-col-order-17 {
    order: 17
}

.ant-col-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%
}

.ant-col-push-16 {
    left: 66.66666667%
}

.ant-col-pull-16 {
    right: 66.66666667%
}

.ant-col-offset-16 {
    margin-left: 66.66666667%
}

.ant-col-order-16 {
    order: 16
}

.ant-col-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%
}

.ant-col-push-15 {
    left: 62.5%
}

.ant-col-pull-15 {
    right: 62.5%
}

.ant-col-offset-15 {
    margin-left: 62.5%
}

.ant-col-order-15 {
    order: 15
}

.ant-col-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%
}

.ant-col-push-14 {
    left: 58.33333333%
}

.ant-col-pull-14 {
    right: 58.33333333%
}

.ant-col-offset-14 {
    margin-left: 58.33333333%
}

.ant-col-order-14 {
    order: 14
}

.ant-col-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%
}

.ant-col-push-13 {
    left: 54.16666667%
}

.ant-col-pull-13 {
    right: 54.16666667%
}

.ant-col-offset-13 {
    margin-left: 54.16666667%
}

.ant-col-order-13 {
    order: 13
}

.ant-col-12 {
    display: block;
    box-sizing: border-box;
    width: 50%
}

.ant-col-push-12 {
    left: 50%
}

.ant-col-pull-12 {
    right: 50%
}

.ant-col-offset-12 {
    margin-left: 50%
}

.ant-col-order-12 {
    order: 12
}

.ant-col-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%
}

.ant-col-push-11 {
    left: 45.83333333%
}

.ant-col-pull-11 {
    right: 45.83333333%
}

.ant-col-offset-11 {
    margin-left: 45.83333333%
}

.ant-col-order-11 {
    order: 11
}

.ant-col-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%
}

.ant-col-push-10 {
    left: 41.66666667%
}

.ant-col-pull-10 {
    right: 41.66666667%
}

.ant-col-offset-10 {
    margin-left: 41.66666667%
}

.ant-col-order-10 {
    order: 10
}

.ant-col-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%
}

.ant-col-push-9 {
    left: 37.5%
}

.ant-col-pull-9 {
    right: 37.5%
}

.ant-col-offset-9 {
    margin-left: 37.5%
}

.ant-col-order-9 {
    order: 9
}

.ant-col-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%
}

.ant-col-push-8 {
    left: 33.33333333%
}

.ant-col-pull-8 {
    right: 33.33333333%
}

.ant-col-offset-8 {
    margin-left: 33.33333333%
}

.ant-col-order-8 {
    order: 8
}

.ant-col-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%
}

.ant-col-push-7 {
    left: 29.16666667%
}

.ant-col-pull-7 {
    right: 29.16666667%
}

.ant-col-offset-7 {
    margin-left: 29.16666667%
}

.ant-col-order-7 {
    order: 7
}

.ant-col-6 {
    display: block;
    box-sizing: border-box;
    width: 25%
}

.ant-col-push-6 {
    left: 25%
}

.ant-col-pull-6 {
    right: 25%
}

.ant-col-offset-6 {
    margin-left: 25%
}

.ant-col-order-6 {
    order: 6
}

.ant-col-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%
}

.ant-col-push-5 {
    left: 20.83333333%
}

.ant-col-pull-5 {
    right: 20.83333333%
}

.ant-col-offset-5 {
    margin-left: 20.83333333%
}

.ant-col-order-5 {
    order: 5
}

.ant-col-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%
}

.ant-col-push-4 {
    left: 16.66666667%
}

.ant-col-pull-4 {
    right: 16.66666667%
}

.ant-col-offset-4 {
    margin-left: 16.66666667%
}

.ant-col-order-4 {
    order: 4
}

.ant-col-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%
}

.ant-col-push-3 {
    left: 12.5%
}

.ant-col-pull-3 {
    right: 12.5%
}

.ant-col-offset-3 {
    margin-left: 12.5%
}

.ant-col-order-3 {
    order: 3
}

.ant-col-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%
}

.ant-col-push-2 {
    left: 8.33333333%
}

.ant-col-pull-2 {
    right: 8.33333333%
}

.ant-col-offset-2 {
    margin-left: 8.33333333%
}

.ant-col-order-2 {
    order: 2
}

.ant-col-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%
}

.ant-col-push-1 {
    left: 4.16666667%
}

.ant-col-pull-1 {
    right: 4.16666667%
}

.ant-col-offset-1 {
    margin-left: 4.16666667%
}

.ant-col-order-1 {
    order: 1
}

.ant-col-0 {
    display: none
}

.ant-col-offset-0 {
    margin-left: 0
}

.ant-col-order-0 {
    order: 0
}

.ant-col-xs-1,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-2,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9 {
    flex: 0 0 auto;
    float: left
}

.ant-col-xs-24 {
    display: block;
    box-sizing: border-box;
    width: 100%
}

.ant-col-xs-push-24 {
    left: 100%
}

.ant-col-xs-pull-24 {
    right: 100%
}

.ant-col-xs-offset-24 {
    margin-left: 100%
}

.ant-col-xs-order-24 {
    order: 24
}

.ant-col-xs-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%
}

.ant-col-xs-push-23 {
    left: 95.83333333%
}

.ant-col-xs-pull-23 {
    right: 95.83333333%
}

.ant-col-xs-offset-23 {
    margin-left: 95.83333333%
}

.ant-col-xs-order-23 {
    order: 23
}

.ant-col-xs-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%
}

.ant-col-xs-push-22 {
    left: 91.66666667%
}

.ant-col-xs-pull-22 {
    right: 91.66666667%
}

.ant-col-xs-offset-22 {
    margin-left: 91.66666667%
}

.ant-col-xs-order-22 {
    order: 22
}

.ant-col-xs-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%
}

.ant-col-xs-push-21 {
    left: 87.5%
}

.ant-col-xs-pull-21 {
    right: 87.5%
}

.ant-col-xs-offset-21 {
    margin-left: 87.5%
}

.ant-col-xs-order-21 {
    order: 21
}

.ant-col-xs-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%
}

.ant-col-xs-push-20 {
    left: 83.33333333%
}

.ant-col-xs-pull-20 {
    right: 83.33333333%
}

.ant-col-xs-offset-20 {
    margin-left: 83.33333333%
}

.ant-col-xs-order-20 {
    order: 20
}

.ant-col-xs-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%
}

.ant-col-xs-push-19 {
    left: 79.16666667%
}

.ant-col-xs-pull-19 {
    right: 79.16666667%
}

.ant-col-xs-offset-19 {
    margin-left: 79.16666667%
}

.ant-col-xs-order-19 {
    order: 19
}

.ant-col-xs-18 {
    display: block;
    box-sizing: border-box;
    width: 75%
}

.ant-col-xs-push-18 {
    left: 75%
}

.ant-col-xs-pull-18 {
    right: 75%
}

.ant-col-xs-offset-18 {
    margin-left: 75%
}

.ant-col-xs-order-18 {
    order: 18
}

.ant-col-xs-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%
}

.ant-col-xs-push-17 {
    left: 70.83333333%
}

.ant-col-xs-pull-17 {
    right: 70.83333333%
}

.ant-col-xs-offset-17 {
    margin-left: 70.83333333%
}

.ant-col-xs-order-17 {
    order: 17
}

.ant-col-xs-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%
}

.ant-col-xs-push-16 {
    left: 66.66666667%
}

.ant-col-xs-pull-16 {
    right: 66.66666667%
}

.ant-col-xs-offset-16 {
    margin-left: 66.66666667%
}

.ant-col-xs-order-16 {
    order: 16
}

.ant-col-xs-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%
}

.ant-col-xs-push-15 {
    left: 62.5%
}

.ant-col-xs-pull-15 {
    right: 62.5%
}

.ant-col-xs-offset-15 {
    margin-left: 62.5%
}

.ant-col-xs-order-15 {
    order: 15
}

.ant-col-xs-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%
}

.ant-col-xs-push-14 {
    left: 58.33333333%
}

.ant-col-xs-pull-14 {
    right: 58.33333333%
}

.ant-col-xs-offset-14 {
    margin-left: 58.33333333%
}

.ant-col-xs-order-14 {
    order: 14
}

.ant-col-xs-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%
}

.ant-col-xs-push-13 {
    left: 54.16666667%
}

.ant-col-xs-pull-13 {
    right: 54.16666667%
}

.ant-col-xs-offset-13 {
    margin-left: 54.16666667%
}

.ant-col-xs-order-13 {
    order: 13
}

.ant-col-xs-12 {
    display: block;
    box-sizing: border-box;
    width: 50%
}

.ant-col-xs-push-12 {
    left: 50%
}

.ant-col-xs-pull-12 {
    right: 50%
}

.ant-col-xs-offset-12 {
    margin-left: 50%
}

.ant-col-xs-order-12 {
    order: 12
}

.ant-col-xs-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%
}

.ant-col-xs-push-11 {
    left: 45.83333333%
}

.ant-col-xs-pull-11 {
    right: 45.83333333%
}

.ant-col-xs-offset-11 {
    margin-left: 45.83333333%
}

.ant-col-xs-order-11 {
    order: 11
}

.ant-col-xs-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%
}

.ant-col-xs-push-10 {
    left: 41.66666667%
}

.ant-col-xs-pull-10 {
    right: 41.66666667%
}

.ant-col-xs-offset-10 {
    margin-left: 41.66666667%
}

.ant-col-xs-order-10 {
    order: 10
}

.ant-col-xs-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%
}

.ant-col-xs-push-9 {
    left: 37.5%
}

.ant-col-xs-pull-9 {
    right: 37.5%
}

.ant-col-xs-offset-9 {
    margin-left: 37.5%
}

.ant-col-xs-order-9 {
    order: 9
}

.ant-col-xs-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%
}

.ant-col-xs-push-8 {
    left: 33.33333333%
}

.ant-col-xs-pull-8 {
    right: 33.33333333%
}

.ant-col-xs-offset-8 {
    margin-left: 33.33333333%
}

.ant-col-xs-order-8 {
    order: 8
}

.ant-col-xs-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%
}

.ant-col-xs-push-7 {
    left: 29.16666667%
}

.ant-col-xs-pull-7 {
    right: 29.16666667%
}

.ant-col-xs-offset-7 {
    margin-left: 29.16666667%
}

.ant-col-xs-order-7 {
    order: 7
}

.ant-col-xs-6 {
    display: block;
    box-sizing: border-box;
    width: 25%
}

.ant-col-xs-push-6 {
    left: 25%
}

.ant-col-xs-pull-6 {
    right: 25%
}

.ant-col-xs-offset-6 {
    margin-left: 25%
}

.ant-col-xs-order-6 {
    order: 6
}

.ant-col-xs-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%
}

.ant-col-xs-push-5 {
    left: 20.83333333%
}

.ant-col-xs-pull-5 {
    right: 20.83333333%
}

.ant-col-xs-offset-5 {
    margin-left: 20.83333333%
}

.ant-col-xs-order-5 {
    order: 5
}

.ant-col-xs-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%
}

.ant-col-xs-push-4 {
    left: 16.66666667%
}

.ant-col-xs-pull-4 {
    right: 16.66666667%
}

.ant-col-xs-offset-4 {
    margin-left: 16.66666667%
}

.ant-col-xs-order-4 {
    order: 4
}

.ant-col-xs-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%
}

.ant-col-xs-push-3 {
    left: 12.5%
}

.ant-col-xs-pull-3 {
    right: 12.5%
}

.ant-col-xs-offset-3 {
    margin-left: 12.5%
}

.ant-col-xs-order-3 {
    order: 3
}

.ant-col-xs-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%
}

.ant-col-xs-push-2 {
    left: 8.33333333%
}

.ant-col-xs-pull-2 {
    right: 8.33333333%
}

.ant-col-xs-offset-2 {
    margin-left: 8.33333333%
}

.ant-col-xs-order-2 {
    order: 2
}

.ant-col-xs-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%
}

.ant-col-xs-push-1 {
    left: 4.16666667%
}

.ant-col-xs-pull-1 {
    right: 4.16666667%
}

.ant-col-xs-offset-1 {
    margin-left: 4.16666667%
}

.ant-col-xs-order-1 {
    order: 1
}

.ant-col-xs-0 {
    display: none
}

.ant-col-push-0 {
    left: auto
}

.ant-col-pull-0 {
    right: auto
}

.ant-col-xs-push-0 {
    left: auto
}

.ant-col-xs-pull-0 {
    right: auto
}

.ant-col-xs-offset-0 {
    margin-left: 0
}

.ant-col-xs-order-0 {
    order: 0
}

@media (min-width:576px) {

    .ant-col-sm-1,
    .ant-col-sm-10,
    .ant-col-sm-11,
    .ant-col-sm-12,
    .ant-col-sm-13,
    .ant-col-sm-14,
    .ant-col-sm-15,
    .ant-col-sm-16,
    .ant-col-sm-17,
    .ant-col-sm-18,
    .ant-col-sm-19,
    .ant-col-sm-2,
    .ant-col-sm-20,
    .ant-col-sm-21,
    .ant-col-sm-22,
    .ant-col-sm-23,
    .ant-col-sm-24,
    .ant-col-sm-3,
    .ant-col-sm-4,
    .ant-col-sm-5,
    .ant-col-sm-6,
    .ant-col-sm-7,
    .ant-col-sm-8,
    .ant-col-sm-9 {
        flex: 0 0 auto;
        float: left
    }

    .ant-col-sm-24 {
        display: block;
        box-sizing: border-box;
        width: 100%
    }

    .ant-col-sm-push-24 {
        left: 100%
    }

    .ant-col-sm-pull-24 {
        right: 100%
    }

    .ant-col-sm-offset-24 {
        margin-left: 100%
    }

    .ant-col-sm-order-24 {
        order: 24
    }

    .ant-col-sm-23 {
        display: block;
        box-sizing: border-box;
        width: 95.83333333%
    }

    .ant-col-sm-push-23 {
        left: 95.83333333%
    }

    .ant-col-sm-pull-23 {
        right: 95.83333333%
    }

    .ant-col-sm-offset-23 {
        margin-left: 95.83333333%
    }

    .ant-col-sm-order-23 {
        order: 23
    }

    .ant-col-sm-22 {
        display: block;
        box-sizing: border-box;
        width: 91.66666667%
    }

    .ant-col-sm-push-22 {
        left: 91.66666667%
    }

    .ant-col-sm-pull-22 {
        right: 91.66666667%
    }

    .ant-col-sm-offset-22 {
        margin-left: 91.66666667%
    }

    .ant-col-sm-order-22 {
        order: 22
    }

    .ant-col-sm-21 {
        display: block;
        box-sizing: border-box;
        width: 87.5%
    }

    .ant-col-sm-push-21 {
        left: 87.5%
    }

    .ant-col-sm-pull-21 {
        right: 87.5%
    }

    .ant-col-sm-offset-21 {
        margin-left: 87.5%
    }

    .ant-col-sm-order-21 {
        order: 21
    }

    .ant-col-sm-20 {
        display: block;
        box-sizing: border-box;
        width: 83.33333333%
    }

    .ant-col-sm-push-20 {
        left: 83.33333333%
    }

    .ant-col-sm-pull-20 {
        right: 83.33333333%
    }

    .ant-col-sm-offset-20 {
        margin-left: 83.33333333%
    }

    .ant-col-sm-order-20 {
        order: 20
    }

    .ant-col-sm-19 {
        display: block;
        box-sizing: border-box;
        width: 79.16666667%
    }

    .ant-col-sm-push-19 {
        left: 79.16666667%
    }

    .ant-col-sm-pull-19 {
        right: 79.16666667%
    }

    .ant-col-sm-offset-19 {
        margin-left: 79.16666667%
    }

    .ant-col-sm-order-19 {
        order: 19
    }

    .ant-col-sm-18 {
        display: block;
        box-sizing: border-box;
        width: 75%
    }

    .ant-col-sm-push-18 {
        left: 75%
    }

    .ant-col-sm-pull-18 {
        right: 75%
    }

    .ant-col-sm-offset-18 {
        margin-left: 75%
    }

    .ant-col-sm-order-18 {
        order: 18
    }

    .ant-col-sm-17 {
        display: block;
        box-sizing: border-box;
        width: 70.83333333%
    }

    .ant-col-sm-push-17 {
        left: 70.83333333%
    }

    .ant-col-sm-pull-17 {
        right: 70.83333333%
    }

    .ant-col-sm-offset-17 {
        margin-left: 70.83333333%
    }

    .ant-col-sm-order-17 {
        order: 17
    }

    .ant-col-sm-16 {
        display: block;
        box-sizing: border-box;
        width: 66.66666667%
    }

    .ant-col-sm-push-16 {
        left: 66.66666667%
    }

    .ant-col-sm-pull-16 {
        right: 66.66666667%
    }

    .ant-col-sm-offset-16 {
        margin-left: 66.66666667%
    }

    .ant-col-sm-order-16 {
        order: 16
    }

    .ant-col-sm-15 {
        display: block;
        box-sizing: border-box;
        width: 62.5%
    }

    .ant-col-sm-push-15 {
        left: 62.5%
    }

    .ant-col-sm-pull-15 {
        right: 62.5%
    }

    .ant-col-sm-offset-15 {
        margin-left: 62.5%
    }

    .ant-col-sm-order-15 {
        order: 15
    }

    .ant-col-sm-14 {
        display: block;
        box-sizing: border-box;
        width: 58.33333333%
    }

    .ant-col-sm-push-14 {
        left: 58.33333333%
    }

    .ant-col-sm-pull-14 {
        right: 58.33333333%
    }

    .ant-col-sm-offset-14 {
        margin-left: 58.33333333%
    }

    .ant-col-sm-order-14 {
        order: 14
    }

    .ant-col-sm-13 {
        display: block;
        box-sizing: border-box;
        width: 54.16666667%
    }

    .ant-col-sm-push-13 {
        left: 54.16666667%
    }

    .ant-col-sm-pull-13 {
        right: 54.16666667%
    }

    .ant-col-sm-offset-13 {
        margin-left: 54.16666667%
    }

    .ant-col-sm-order-13 {
        order: 13
    }

    .ant-col-sm-12 {
        display: block;
        box-sizing: border-box;
        width: 50%
    }

    .ant-col-sm-push-12 {
        left: 50%
    }

    .ant-col-sm-pull-12 {
        right: 50%
    }

    .ant-col-sm-offset-12 {
        margin-left: 50%
    }

    .ant-col-sm-order-12 {
        order: 12
    }

    .ant-col-sm-11 {
        display: block;
        box-sizing: border-box;
        width: 45.83333333%
    }

    .ant-col-sm-push-11 {
        left: 45.83333333%
    }

    .ant-col-sm-pull-11 {
        right: 45.83333333%
    }

    .ant-col-sm-offset-11 {
        margin-left: 45.83333333%
    }

    .ant-col-sm-order-11 {
        order: 11
    }

    .ant-col-sm-10 {
        display: block;
        box-sizing: border-box;
        width: 41.66666667%
    }

    .ant-col-sm-push-10 {
        left: 41.66666667%
    }

    .ant-col-sm-pull-10 {
        right: 41.66666667%
    }

    .ant-col-sm-offset-10 {
        margin-left: 41.66666667%
    }

    .ant-col-sm-order-10 {
        order: 10
    }

    .ant-col-sm-9 {
        display: block;
        box-sizing: border-box;
        width: 37.5%
    }

    .ant-col-sm-push-9 {
        left: 37.5%
    }

    .ant-col-sm-pull-9 {
        right: 37.5%
    }

    .ant-col-sm-offset-9 {
        margin-left: 37.5%
    }

    .ant-col-sm-order-9 {
        order: 9
    }

    .ant-col-sm-8 {
        display: block;
        box-sizing: border-box;
        width: 33.33333333%
    }

    .ant-col-sm-push-8 {
        left: 33.33333333%
    }

    .ant-col-sm-pull-8 {
        right: 33.33333333%
    }

    .ant-col-sm-offset-8 {
        margin-left: 33.33333333%
    }

    .ant-col-sm-order-8 {
        order: 8
    }

    .ant-col-sm-7 {
        display: block;
        box-sizing: border-box;
        width: 29.16666667%
    }

    .ant-col-sm-push-7 {
        left: 29.16666667%
    }

    .ant-col-sm-pull-7 {
        right: 29.16666667%
    }

    .ant-col-sm-offset-7 {
        margin-left: 29.16666667%
    }

    .ant-col-sm-order-7 {
        order: 7
    }

    .ant-col-sm-6 {
        display: block;
        box-sizing: border-box;
        width: 25%
    }

    .ant-col-sm-push-6 {
        left: 25%
    }

    .ant-col-sm-pull-6 {
        right: 25%
    }

    .ant-col-sm-offset-6 {
        margin-left: 25%
    }

    .ant-col-sm-order-6 {
        order: 6
    }

    .ant-col-sm-5 {
        display: block;
        box-sizing: border-box;
        width: 20.83333333%
    }

    .ant-col-sm-push-5 {
        left: 20.83333333%
    }

    .ant-col-sm-pull-5 {
        right: 20.83333333%
    }

    .ant-col-sm-offset-5 {
        margin-left: 20.83333333%
    }

    .ant-col-sm-order-5 {
        order: 5
    }

    .ant-col-sm-4 {
        display: block;
        box-sizing: border-box;
        width: 16.66666667%
    }

    .ant-col-sm-push-4 {
        left: 16.66666667%
    }

    .ant-col-sm-pull-4 {
        right: 16.66666667%
    }

    .ant-col-sm-offset-4 {
        margin-left: 16.66666667%
    }

    .ant-col-sm-order-4 {
        order: 4
    }

    .ant-col-sm-3 {
        display: block;
        box-sizing: border-box;
        width: 12.5%
    }

    .ant-col-sm-push-3 {
        left: 12.5%
    }

    .ant-col-sm-pull-3 {
        right: 12.5%
    }

    .ant-col-sm-offset-3 {
        margin-left: 12.5%
    }

    .ant-col-sm-order-3 {
        order: 3
    }

    .ant-col-sm-2 {
        display: block;
        box-sizing: border-box;
        width: 8.33333333%
    }

    .ant-col-sm-push-2 {
        left: 8.33333333%
    }

    .ant-col-sm-pull-2 {
        right: 8.33333333%
    }

    .ant-col-sm-offset-2 {
        margin-left: 8.33333333%
    }

    .ant-col-sm-order-2 {
        order: 2
    }

    .ant-col-sm-1 {
        display: block;
        box-sizing: border-box;
        width: 4.16666667%
    }

    .ant-col-sm-push-1 {
        left: 4.16666667%
    }

    .ant-col-sm-pull-1 {
        right: 4.16666667%
    }

    .ant-col-sm-offset-1 {
        margin-left: 4.16666667%
    }

    .ant-col-sm-order-1 {
        order: 1
    }

    .ant-col-sm-0 {
        display: none
    }

    .ant-col-push-0 {
        left: auto
    }

    .ant-col-pull-0 {
        right: auto
    }

    .ant-col-sm-push-0 {
        left: auto
    }

    .ant-col-sm-pull-0 {
        right: auto
    }

    .ant-col-sm-offset-0 {
        margin-left: 0
    }

    .ant-col-sm-order-0 {
        order: 0
    }
}

@media (min-width:768px) {

    .ant-col-md-1,
    .ant-col-md-10,
    .ant-col-md-11,
    .ant-col-md-12,
    .ant-col-md-13,
    .ant-col-md-14,
    .ant-col-md-15,
    .ant-col-md-16,
    .ant-col-md-17,
    .ant-col-md-18,
    .ant-col-md-19,
    .ant-col-md-2,
    .ant-col-md-20,
    .ant-col-md-21,
    .ant-col-md-22,
    .ant-col-md-23,
    .ant-col-md-24,
    .ant-col-md-3,
    .ant-col-md-4,
    .ant-col-md-5,
    .ant-col-md-6,
    .ant-col-md-7,
    .ant-col-md-8,
    .ant-col-md-9 {
        flex: 0 0 auto;
        float: left
    }

    .ant-col-md-24 {
        display: block;
        box-sizing: border-box;
        width: 100%
    }

    .ant-col-md-push-24 {
        left: 100%
    }

    .ant-col-md-pull-24 {
        right: 100%
    }

    .ant-col-md-offset-24 {
        margin-left: 100%
    }

    .ant-col-md-order-24 {
        order: 24
    }

    .ant-col-md-23 {
        display: block;
        box-sizing: border-box;
        width: 95.83333333%
    }

    .ant-col-md-push-23 {
        left: 95.83333333%
    }

    .ant-col-md-pull-23 {
        right: 95.83333333%
    }

    .ant-col-md-offset-23 {
        margin-left: 95.83333333%
    }

    .ant-col-md-order-23 {
        order: 23
    }

    .ant-col-md-22 {
        display: block;
        box-sizing: border-box;
        width: 91.66666667%
    }

    .ant-col-md-push-22 {
        left: 91.66666667%
    }

    .ant-col-md-pull-22 {
        right: 91.66666667%
    }

    .ant-col-md-offset-22 {
        margin-left: 91.66666667%
    }

    .ant-col-md-order-22 {
        order: 22
    }

    .ant-col-md-21 {
        display: block;
        box-sizing: border-box;
        width: 87.5%
    }

    .ant-col-md-push-21 {
        left: 87.5%
    }

    .ant-col-md-pull-21 {
        right: 87.5%
    }

    .ant-col-md-offset-21 {
        margin-left: 87.5%
    }

    .ant-col-md-order-21 {
        order: 21
    }

    .ant-col-md-20 {
        display: block;
        box-sizing: border-box;
        width: 83.33333333%
    }

    .ant-col-md-push-20 {
        left: 83.33333333%
    }

    .ant-col-md-pull-20 {
        right: 83.33333333%
    }

    .ant-col-md-offset-20 {
        margin-left: 83.33333333%
    }

    .ant-col-md-order-20 {
        order: 20
    }

    .ant-col-md-19 {
        display: block;
        box-sizing: border-box;
        width: 79.16666667%
    }

    .ant-col-md-push-19 {
        left: 79.16666667%
    }

    .ant-col-md-pull-19 {
        right: 79.16666667%
    }

    .ant-col-md-offset-19 {
        margin-left: 79.16666667%
    }

    .ant-col-md-order-19 {
        order: 19
    }

    .ant-col-md-18 {
        display: block;
        box-sizing: border-box;
        width: 75%
    }

    .ant-col-md-push-18 {
        left: 75%
    }

    .ant-col-md-pull-18 {
        right: 75%
    }

    .ant-col-md-offset-18 {
        margin-left: 75%
    }

    .ant-col-md-order-18 {
        order: 18
    }

    .ant-col-md-17 {
        display: block;
        box-sizing: border-box;
        width: 70.83333333%
    }

    .ant-col-md-push-17 {
        left: 70.83333333%
    }

    .ant-col-md-pull-17 {
        right: 70.83333333%
    }

    .ant-col-md-offset-17 {
        margin-left: 70.83333333%
    }

    .ant-col-md-order-17 {
        order: 17
    }

    .ant-col-md-16 {
        display: block;
        box-sizing: border-box;
        width: 66.66666667%
    }

    .ant-col-md-push-16 {
        left: 66.66666667%
    }

    .ant-col-md-pull-16 {
        right: 66.66666667%
    }

    .ant-col-md-offset-16 {
        margin-left: 66.66666667%
    }

    .ant-col-md-order-16 {
        order: 16
    }

    .ant-col-md-15 {
        display: block;
        box-sizing: border-box;
        width: 62.5%
    }

    .ant-col-md-push-15 {
        left: 62.5%
    }

    .ant-col-md-pull-15 {
        right: 62.5%
    }

    .ant-col-md-offset-15 {
        margin-left: 62.5%
    }

    .ant-col-md-order-15 {
        order: 15
    }

    .ant-col-md-14 {
        display: block;
        box-sizing: border-box;
        width: 58.33333333%
    }

    .ant-col-md-push-14 {
        left: 58.33333333%
    }

    .ant-col-md-pull-14 {
        right: 58.33333333%
    }

    .ant-col-md-offset-14 {
        margin-left: 58.33333333%
    }

    .ant-col-md-order-14 {
        order: 14
    }

    .ant-col-md-13 {
        display: block;
        box-sizing: border-box;
        width: 54.16666667%
    }

    .ant-col-md-push-13 {
        left: 54.16666667%
    }

    .ant-col-md-pull-13 {
        right: 54.16666667%
    }

    .ant-col-md-offset-13 {
        margin-left: 54.16666667%
    }

    .ant-col-md-order-13 {
        order: 13
    }

    .ant-col-md-12 {
        display: block;
        box-sizing: border-box;
        width: 50%
    }

    .ant-col-md-push-12 {
        left: 50%
    }

    .ant-col-md-pull-12 {
        right: 50%
    }

    .ant-col-md-offset-12 {
        margin-left: 50%
    }

    .ant-col-md-order-12 {
        order: 12
    }

    .ant-col-md-11 {
        display: block;
        box-sizing: border-box;
        width: 45.83333333%
    }

    .ant-col-md-push-11 {
        left: 45.83333333%
    }

    .ant-col-md-pull-11 {
        right: 45.83333333%
    }

    .ant-col-md-offset-11 {
        margin-left: 45.83333333%
    }

    .ant-col-md-order-11 {
        order: 11
    }

    .ant-col-md-10 {
        display: block;
        box-sizing: border-box;
        width: 41.66666667%
    }

    .ant-col-md-push-10 {
        left: 41.66666667%
    }

    .ant-col-md-pull-10 {
        right: 41.66666667%
    }

    .ant-col-md-offset-10 {
        margin-left: 41.66666667%
    }

    .ant-col-md-order-10 {
        order: 10
    }

    .ant-col-md-9 {
        display: block;
        box-sizing: border-box;
        width: 37.5%
    }

    .ant-col-md-push-9 {
        left: 37.5%
    }

    .ant-col-md-pull-9 {
        right: 37.5%
    }

    .ant-col-md-offset-9 {
        margin-left: 37.5%
    }

    .ant-col-md-order-9 {
        order: 9
    }

    .ant-col-md-8 {
        display: block;
        box-sizing: border-box;
        width: 33.33333333%
    }

    .ant-col-md-push-8 {
        left: 33.33333333%
    }

    .ant-col-md-pull-8 {
        right: 33.33333333%
    }

    .ant-col-md-offset-8 {
        margin-left: 33.33333333%
    }

    .ant-col-md-order-8 {
        order: 8
    }

    .ant-col-md-7 {
        display: block;
        box-sizing: border-box;
        width: 29.16666667%
    }

    .ant-col-md-push-7 {
        left: 29.16666667%
    }

    .ant-col-md-pull-7 {
        right: 29.16666667%
    }

    .ant-col-md-offset-7 {
        margin-left: 29.16666667%
    }

    .ant-col-md-order-7 {
        order: 7
    }

    .ant-col-md-6 {
        display: block;
        box-sizing: border-box;
        width: 25%
    }

    .ant-col-md-push-6 {
        left: 25%
    }

    .ant-col-md-pull-6 {
        right: 25%
    }

    .ant-col-md-offset-6 {
        margin-left: 25%
    }

    .ant-col-md-order-6 {
        order: 6
    }

    .ant-col-md-5 {
        display: block;
        box-sizing: border-box;
        width: 20.83333333%
    }

    .ant-col-md-push-5 {
        left: 20.83333333%
    }

    .ant-col-md-pull-5 {
        right: 20.83333333%
    }

    .ant-col-md-offset-5 {
        margin-left: 20.83333333%
    }

    .ant-col-md-order-5 {
        order: 5
    }

    .ant-col-md-4 {
        display: block;
        box-sizing: border-box;
        width: 16.66666667%
    }

    .ant-col-md-push-4 {
        left: 16.66666667%
    }

    .ant-col-md-pull-4 {
        right: 16.66666667%
    }

    .ant-col-md-offset-4 {
        margin-left: 16.66666667%
    }

    .ant-col-md-order-4 {
        order: 4
    }

    .ant-col-md-3 {
        display: block;
        box-sizing: border-box;
        width: 12.5%
    }

    .ant-col-md-push-3 {
        left: 12.5%
    }

    .ant-col-md-pull-3 {
        right: 12.5%
    }

    .ant-col-md-offset-3 {
        margin-left: 12.5%
    }

    .ant-col-md-order-3 {
        order: 3
    }

    .ant-col-md-2 {
        display: block;
        box-sizing: border-box;
        width: 8.33333333%
    }

    .ant-col-md-push-2 {
        left: 8.33333333%
    }

    .ant-col-md-pull-2 {
        right: 8.33333333%
    }

    .ant-col-md-offset-2 {
        margin-left: 8.33333333%
    }

    .ant-col-md-order-2 {
        order: 2
    }

    .ant-col-md-1 {
        display: block;
        box-sizing: border-box;
        width: 4.16666667%
    }

    .ant-col-md-push-1 {
        left: 4.16666667%
    }

    .ant-col-md-pull-1 {
        right: 4.16666667%
    }

    .ant-col-md-offset-1 {
        margin-left: 4.16666667%
    }

    .ant-col-md-order-1 {
        order: 1
    }

    .ant-col-md-0 {
        display: none
    }

    .ant-col-push-0 {
        left: auto
    }

    .ant-col-pull-0 {
        right: auto
    }

    .ant-col-md-push-0 {
        left: auto
    }

    .ant-col-md-pull-0 {
        right: auto
    }

    .ant-col-md-offset-0 {
        margin-left: 0
    }

    .ant-col-md-order-0 {
        order: 0
    }
}

@media (min-width:992px) {

    .ant-col-lg-1,
    .ant-col-lg-10,
    .ant-col-lg-11,
    .ant-col-lg-12,
    .ant-col-lg-13,
    .ant-col-lg-14,
    .ant-col-lg-15,
    .ant-col-lg-16,
    .ant-col-lg-17,
    .ant-col-lg-18,
    .ant-col-lg-19,
    .ant-col-lg-2,
    .ant-col-lg-20,
    .ant-col-lg-21,
    .ant-col-lg-22,
    .ant-col-lg-23,
    .ant-col-lg-24,
    .ant-col-lg-3,
    .ant-col-lg-4,
    .ant-col-lg-5,
    .ant-col-lg-6,
    .ant-col-lg-7,
    .ant-col-lg-8,
    .ant-col-lg-9 {
        flex: 0 0 auto;
        float: left
    }

    .ant-col-lg-24 {
        display: block;
        box-sizing: border-box;
        width: 100%
    }

    .ant-col-lg-push-24 {
        left: 100%
    }

    .ant-col-lg-pull-24 {
        right: 100%
    }

    .ant-col-lg-offset-24 {
        margin-left: 100%
    }

    .ant-col-lg-order-24 {
        order: 24
    }

    .ant-col-lg-23 {
        display: block;
        box-sizing: border-box;
        width: 95.83333333%
    }

    .ant-col-lg-push-23 {
        left: 95.83333333%
    }

    .ant-col-lg-pull-23 {
        right: 95.83333333%
    }

    .ant-col-lg-offset-23 {
        margin-left: 95.83333333%
    }

    .ant-col-lg-order-23 {
        order: 23
    }

    .ant-col-lg-22 {
        display: block;
        box-sizing: border-box;
        width: 91.66666667%
    }

    .ant-col-lg-push-22 {
        left: 91.66666667%
    }

    .ant-col-lg-pull-22 {
        right: 91.66666667%
    }

    .ant-col-lg-offset-22 {
        margin-left: 91.66666667%
    }

    .ant-col-lg-order-22 {
        order: 22
    }

    .ant-col-lg-21 {
        display: block;
        box-sizing: border-box;
        width: 87.5%
    }

    .ant-col-lg-push-21 {
        left: 87.5%
    }

    .ant-col-lg-pull-21 {
        right: 87.5%
    }

    .ant-col-lg-offset-21 {
        margin-left: 87.5%
    }

    .ant-col-lg-order-21 {
        order: 21
    }

    .ant-col-lg-20 {
        display: block;
        box-sizing: border-box;
        width: 83.33333333%
    }

    .ant-col-lg-push-20 {
        left: 83.33333333%
    }

    .ant-col-lg-pull-20 {
        right: 83.33333333%
    }

    .ant-col-lg-offset-20 {
        margin-left: 83.33333333%
    }

    .ant-col-lg-order-20 {
        order: 20
    }

    .ant-col-lg-19 {
        display: block;
        box-sizing: border-box;
        width: 79.16666667%
    }

    .ant-col-lg-push-19 {
        left: 79.16666667%
    }

    .ant-col-lg-pull-19 {
        right: 79.16666667%
    }

    .ant-col-lg-offset-19 {
        margin-left: 79.16666667%
    }

    .ant-col-lg-order-19 {
        order: 19
    }

    .ant-col-lg-18 {
        display: block;
        box-sizing: border-box;
        width: 75%
    }

    .ant-col-lg-push-18 {
        left: 75%
    }

    .ant-col-lg-pull-18 {
        right: 75%
    }

    .ant-col-lg-offset-18 {
        margin-left: 75%
    }

    .ant-col-lg-order-18 {
        order: 18
    }

    .ant-col-lg-17 {
        display: block;
        box-sizing: border-box;
        width: 70.83333333%
    }

    .ant-col-lg-push-17 {
        left: 70.83333333%
    }

    .ant-col-lg-pull-17 {
        right: 70.83333333%
    }

    .ant-col-lg-offset-17 {
        margin-left: 70.83333333%
    }

    .ant-col-lg-order-17 {
        order: 17
    }

    .ant-col-lg-16 {
        display: block;
        box-sizing: border-box;
        width: 66.66666667%
    }

    .ant-col-lg-push-16 {
        left: 66.66666667%
    }

    .ant-col-lg-pull-16 {
        right: 66.66666667%
    }

    .ant-col-lg-offset-16 {
        margin-left: 66.66666667%
    }

    .ant-col-lg-order-16 {
        order: 16
    }

    .ant-col-lg-15 {
        display: block;
        box-sizing: border-box;
        width: 62.5%
    }

    .ant-col-lg-push-15 {
        left: 62.5%
    }

    .ant-col-lg-pull-15 {
        right: 62.5%
    }

    .ant-col-lg-offset-15 {
        margin-left: 62.5%
    }

    .ant-col-lg-order-15 {
        order: 15
    }

    .ant-col-lg-14 {
        display: block;
        box-sizing: border-box;
        width: 58.33333333%
    }

    .ant-col-lg-push-14 {
        left: 58.33333333%
    }

    .ant-col-lg-pull-14 {
        right: 58.33333333%
    }

    .ant-col-lg-offset-14 {
        margin-left: 58.33333333%
    }

    .ant-col-lg-order-14 {
        order: 14
    }

    .ant-col-lg-13 {
        display: block;
        box-sizing: border-box;
        width: 54.16666667%
    }

    .ant-col-lg-push-13 {
        left: 54.16666667%
    }

    .ant-col-lg-pull-13 {
        right: 54.16666667%
    }

    .ant-col-lg-offset-13 {
        margin-left: 54.16666667%
    }

    .ant-col-lg-order-13 {
        order: 13
    }

    .ant-col-lg-12 {
        display: block;
        box-sizing: border-box;
        width: 50%
    }

    .ant-col-lg-push-12 {
        left: 50%
    }

    .ant-col-lg-pull-12 {
        right: 50%
    }

    .ant-col-lg-offset-12 {
        margin-left: 50%
    }

    .ant-col-lg-order-12 {
        order: 12
    }

    .ant-col-lg-11 {
        display: block;
        box-sizing: border-box;
        width: 45.83333333%
    }

    .ant-col-lg-push-11 {
        left: 45.83333333%
    }

    .ant-col-lg-pull-11 {
        right: 45.83333333%
    }

    .ant-col-lg-offset-11 {
        margin-left: 45.83333333%
    }

    .ant-col-lg-order-11 {
        order: 11
    }

    .ant-col-lg-10 {
        display: block;
        box-sizing: border-box;
        width: 41.66666667%
    }

    .ant-col-lg-push-10 {
        left: 41.66666667%
    }

    .ant-col-lg-pull-10 {
        right: 41.66666667%
    }

    .ant-col-lg-offset-10 {
        margin-left: 41.66666667%
    }

    .ant-col-lg-order-10 {
        order: 10
    }

    .ant-col-lg-9 {
        display: block;
        box-sizing: border-box;
        width: 37.5%
    }

    .ant-col-lg-push-9 {
        left: 37.5%
    }

    .ant-col-lg-pull-9 {
        right: 37.5%
    }

    .ant-col-lg-offset-9 {
        margin-left: 37.5%
    }

    .ant-col-lg-order-9 {
        order: 9
    }

    .ant-col-lg-8 {
        display: block;
        box-sizing: border-box;
        width: 33.33333333%
    }

    .ant-col-lg-push-8 {
        left: 33.33333333%
    }

    .ant-col-lg-pull-8 {
        right: 33.33333333%
    }

    .ant-col-lg-offset-8 {
        margin-left: 33.33333333%
    }

    .ant-col-lg-order-8 {
        order: 8
    }

    .ant-col-lg-7 {
        display: block;
        box-sizing: border-box;
        width: 29.16666667%
    }

    .ant-col-lg-push-7 {
        left: 29.16666667%
    }

    .ant-col-lg-pull-7 {
        right: 29.16666667%
    }

    .ant-col-lg-offset-7 {
        margin-left: 29.16666667%
    }

    .ant-col-lg-order-7 {
        order: 7
    }

    .ant-col-lg-6 {
        display: block;
        box-sizing: border-box;
        width: 25%
    }

    .ant-col-lg-push-6 {
        left: 25%
    }

    .ant-col-lg-pull-6 {
        right: 25%
    }

    .ant-col-lg-offset-6 {
        margin-left: 25%
    }

    .ant-col-lg-order-6 {
        order: 6
    }

    .ant-col-lg-5 {
        display: block;
        box-sizing: border-box;
        width: 20.83333333%
    }

    .ant-col-lg-push-5 {
        left: 20.83333333%
    }

    .ant-col-lg-pull-5 {
        right: 20.83333333%
    }

    .ant-col-lg-offset-5 {
        margin-left: 20.83333333%
    }

    .ant-col-lg-order-5 {
        order: 5
    }

    .ant-col-lg-4 {
        display: block;
        box-sizing: border-box;
        width: 16.66666667%
    }

    .ant-col-lg-push-4 {
        left: 16.66666667%
    }

    .ant-col-lg-pull-4 {
        right: 16.66666667%
    }

    .ant-col-lg-offset-4 {
        margin-left: 16.66666667%
    }

    .ant-col-lg-order-4 {
        order: 4
    }

    .ant-col-lg-3 {
        display: block;
        box-sizing: border-box;
        width: 12.5%
    }

    .ant-col-lg-push-3 {
        left: 12.5%
    }

    .ant-col-lg-pull-3 {
        right: 12.5%
    }

    .ant-col-lg-offset-3 {
        margin-left: 12.5%
    }

    .ant-col-lg-order-3 {
        order: 3
    }

    .ant-col-lg-2 {
        display: block;
        box-sizing: border-box;
        width: 8.33333333%
    }

    .ant-col-lg-push-2 {
        left: 8.33333333%
    }

    .ant-col-lg-pull-2 {
        right: 8.33333333%
    }

    .ant-col-lg-offset-2 {
        margin-left: 8.33333333%
    }

    .ant-col-lg-order-2 {
        order: 2
    }

    .ant-col-lg-1 {
        display: block;
        box-sizing: border-box;
        width: 4.16666667%
    }

    .ant-col-lg-push-1 {
        left: 4.16666667%
    }

    .ant-col-lg-pull-1 {
        right: 4.16666667%
    }

    .ant-col-lg-offset-1 {
        margin-left: 4.16666667%
    }

    .ant-col-lg-order-1 {
        order: 1
    }

    .ant-col-lg-0 {
        display: none
    }

    .ant-col-push-0 {
        left: auto
    }

    .ant-col-pull-0 {
        right: auto
    }

    .ant-col-lg-push-0 {
        left: auto
    }

    .ant-col-lg-pull-0 {
        right: auto
    }

    .ant-col-lg-offset-0 {
        margin-left: 0
    }

    .ant-col-lg-order-0 {
        order: 0
    }
}

@media (min-width:1200px) {

    .ant-col-xl-1,
    .ant-col-xl-10,
    .ant-col-xl-11,
    .ant-col-xl-12,
    .ant-col-xl-13,
    .ant-col-xl-14,
    .ant-col-xl-15,
    .ant-col-xl-16,
    .ant-col-xl-17,
    .ant-col-xl-18,
    .ant-col-xl-19,
    .ant-col-xl-2,
    .ant-col-xl-20,
    .ant-col-xl-21,
    .ant-col-xl-22,
    .ant-col-xl-23,
    .ant-col-xl-24,
    .ant-col-xl-3,
    .ant-col-xl-4,
    .ant-col-xl-5,
    .ant-col-xl-6,
    .ant-col-xl-7,
    .ant-col-xl-8,
    .ant-col-xl-9 {
        flex: 0 0 auto;
        float: left
    }

    .ant-col-xl-24 {
        display: block;
        box-sizing: border-box;
        width: 100%
    }

    .ant-col-xl-push-24 {
        left: 100%
    }

    .ant-col-xl-pull-24 {
        right: 100%
    }

    .ant-col-xl-offset-24 {
        margin-left: 100%
    }

    .ant-col-xl-order-24 {
        order: 24
    }

    .ant-col-xl-23 {
        display: block;
        box-sizing: border-box;
        width: 95.83333333%
    }

    .ant-col-xl-push-23 {
        left: 95.83333333%
    }

    .ant-col-xl-pull-23 {
        right: 95.83333333%
    }

    .ant-col-xl-offset-23 {
        margin-left: 95.83333333%
    }

    .ant-col-xl-order-23 {
        order: 23
    }

    .ant-col-xl-22 {
        display: block;
        box-sizing: border-box;
        width: 91.66666667%
    }

    .ant-col-xl-push-22 {
        left: 91.66666667%
    }

    .ant-col-xl-pull-22 {
        right: 91.66666667%
    }

    .ant-col-xl-offset-22 {
        margin-left: 91.66666667%
    }

    .ant-col-xl-order-22 {
        order: 22
    }

    .ant-col-xl-21 {
        display: block;
        box-sizing: border-box;
        width: 87.5%
    }

    .ant-col-xl-push-21 {
        left: 87.5%
    }

    .ant-col-xl-pull-21 {
        right: 87.5%
    }

    .ant-col-xl-offset-21 {
        margin-left: 87.5%
    }

    .ant-col-xl-order-21 {
        order: 21
    }

    .ant-col-xl-20 {
        display: block;
        box-sizing: border-box;
        width: 83.33333333%
    }

    .ant-col-xl-push-20 {
        left: 83.33333333%
    }

    .ant-col-xl-pull-20 {
        right: 83.33333333%
    }

    .ant-col-xl-offset-20 {
        margin-left: 83.33333333%
    }

    .ant-col-xl-order-20 {
        order: 20
    }

    .ant-col-xl-19 {
        display: block;
        box-sizing: border-box;
        width: 79.16666667%
    }

    .ant-col-xl-push-19 {
        left: 79.16666667%
    }

    .ant-col-xl-pull-19 {
        right: 79.16666667%
    }

    .ant-col-xl-offset-19 {
        margin-left: 79.16666667%
    }

    .ant-col-xl-order-19 {
        order: 19
    }

    .ant-col-xl-18 {
        display: block;
        box-sizing: border-box;
        width: 75%
    }

    .ant-col-xl-push-18 {
        left: 75%
    }

    .ant-col-xl-pull-18 {
        right: 75%
    }

    .ant-col-xl-offset-18 {
        margin-left: 75%
    }

    .ant-col-xl-order-18 {
        order: 18
    }

    .ant-col-xl-17 {
        display: block;
        box-sizing: border-box;
        width: 70.83333333%
    }

    .ant-col-xl-push-17 {
        left: 70.83333333%
    }

    .ant-col-xl-pull-17 {
        right: 70.83333333%
    }

    .ant-col-xl-offset-17 {
        margin-left: 70.83333333%
    }

    .ant-col-xl-order-17 {
        order: 17
    }

    .ant-col-xl-16 {
        display: block;
        box-sizing: border-box;
        width: 66.66666667%
    }

    .ant-col-xl-push-16 {
        left: 66.66666667%
    }

    .ant-col-xl-pull-16 {
        right: 66.66666667%
    }

    .ant-col-xl-offset-16 {
        margin-left: 66.66666667%
    }

    .ant-col-xl-order-16 {
        order: 16
    }

    .ant-col-xl-15 {
        display: block;
        box-sizing: border-box;
        width: 62.5%
    }

    .ant-col-xl-push-15 {
        left: 62.5%
    }

    .ant-col-xl-pull-15 {
        right: 62.5%
    }

    .ant-col-xl-offset-15 {
        margin-left: 62.5%
    }

    .ant-col-xl-order-15 {
        order: 15
    }

    .ant-col-xl-14 {
        display: block;
        box-sizing: border-box;
        width: 58.33333333%
    }

    .ant-col-xl-push-14 {
        left: 58.33333333%
    }

    .ant-col-xl-pull-14 {
        right: 58.33333333%
    }

    .ant-col-xl-offset-14 {
        margin-left: 58.33333333%
    }

    .ant-col-xl-order-14 {
        order: 14
    }

    .ant-col-xl-13 {
        display: block;
        box-sizing: border-box;
        width: 54.16666667%
    }

    .ant-col-xl-push-13 {
        left: 54.16666667%
    }

    .ant-col-xl-pull-13 {
        right: 54.16666667%
    }

    .ant-col-xl-offset-13 {
        margin-left: 54.16666667%
    }

    .ant-col-xl-order-13 {
        order: 13
    }

    .ant-col-xl-12 {
        display: block;
        box-sizing: border-box;
        width: 50%
    }

    .ant-col-xl-push-12 {
        left: 50%
    }

    .ant-col-xl-pull-12 {
        right: 50%
    }

    .ant-col-xl-offset-12 {
        margin-left: 50%
    }

    .ant-col-xl-order-12 {
        order: 12
    }

    .ant-col-xl-11 {
        display: block;
        box-sizing: border-box;
        width: 45.83333333%
    }

    .ant-col-xl-push-11 {
        left: 45.83333333%
    }

    .ant-col-xl-pull-11 {
        right: 45.83333333%
    }

    .ant-col-xl-offset-11 {
        margin-left: 45.83333333%
    }

    .ant-col-xl-order-11 {
        order: 11
    }

    .ant-col-xl-10 {
        display: block;
        box-sizing: border-box;
        width: 41.66666667%
    }

    .ant-col-xl-push-10 {
        left: 41.66666667%
    }

    .ant-col-xl-pull-10 {
        right: 41.66666667%
    }

    .ant-col-xl-offset-10 {
        margin-left: 41.66666667%
    }

    .ant-col-xl-order-10 {
        order: 10
    }

    .ant-col-xl-9 {
        display: block;
        box-sizing: border-box;
        width: 37.5%
    }

    .ant-col-xl-push-9 {
        left: 37.5%
    }

    .ant-col-xl-pull-9 {
        right: 37.5%
    }

    .ant-col-xl-offset-9 {
        margin-left: 37.5%
    }

    .ant-col-xl-order-9 {
        order: 9
    }

    .ant-col-xl-8 {
        display: block;
        box-sizing: border-box;
        width: 33.33333333%
    }

    .ant-col-xl-push-8 {
        left: 33.33333333%
    }

    .ant-col-xl-pull-8 {
        right: 33.33333333%
    }

    .ant-col-xl-offset-8 {
        margin-left: 33.33333333%
    }

    .ant-col-xl-order-8 {
        order: 8
    }

    .ant-col-xl-7 {
        display: block;
        box-sizing: border-box;
        width: 29.16666667%
    }

    .ant-col-xl-push-7 {
        left: 29.16666667%
    }

    .ant-col-xl-pull-7 {
        right: 29.16666667%
    }

    .ant-col-xl-offset-7 {
        margin-left: 29.16666667%
    }

    .ant-col-xl-order-7 {
        order: 7
    }

    .ant-col-xl-6 {
        display: block;
        box-sizing: border-box;
        width: 25%
    }

    .ant-col-xl-push-6 {
        left: 25%
    }

    .ant-col-xl-pull-6 {
        right: 25%
    }

    .ant-col-xl-offset-6 {
        margin-left: 25%
    }

    .ant-col-xl-order-6 {
        order: 6
    }

    .ant-col-xl-5 {
        display: block;
        box-sizing: border-box;
        width: 20.83333333%
    }

    .ant-col-xl-push-5 {
        left: 20.83333333%
    }

    .ant-col-xl-pull-5 {
        right: 20.83333333%
    }

    .ant-col-xl-offset-5 {
        margin-left: 20.83333333%
    }

    .ant-col-xl-order-5 {
        order: 5
    }

    .ant-col-xl-4 {
        display: block;
        box-sizing: border-box;
        width: 16.66666667%
    }

    .ant-col-xl-push-4 {
        left: 16.66666667%
    }

    .ant-col-xl-pull-4 {
        right: 16.66666667%
    }

    .ant-col-xl-offset-4 {
        margin-left: 16.66666667%
    }

    .ant-col-xl-order-4 {
        order: 4
    }

    .ant-col-xl-3 {
        display: block;
        box-sizing: border-box;
        width: 12.5%
    }

    .ant-col-xl-push-3 {
        left: 12.5%
    }

    .ant-col-xl-pull-3 {
        right: 12.5%
    }

    .ant-col-xl-offset-3 {
        margin-left: 12.5%
    }

    .ant-col-xl-order-3 {
        order: 3
    }

    .ant-col-xl-2 {
        display: block;
        box-sizing: border-box;
        width: 8.33333333%
    }

    .ant-col-xl-push-2 {
        left: 8.33333333%
    }

    .ant-col-xl-pull-2 {
        right: 8.33333333%
    }

    .ant-col-xl-offset-2 {
        margin-left: 8.33333333%
    }

    .ant-col-xl-order-2 {
        order: 2
    }

    .ant-col-xl-1 {
        display: block;
        box-sizing: border-box;
        width: 4.16666667%
    }

    .ant-col-xl-push-1 {
        left: 4.16666667%
    }

    .ant-col-xl-pull-1 {
        right: 4.16666667%
    }

    .ant-col-xl-offset-1 {
        margin-left: 4.16666667%
    }

    .ant-col-xl-order-1 {
        order: 1
    }

    .ant-col-xl-0 {
        display: none
    }

    .ant-col-push-0 {
        left: auto
    }

    .ant-col-pull-0 {
        right: auto
    }

    .ant-col-xl-push-0 {
        left: auto
    }

    .ant-col-xl-pull-0 {
        right: auto
    }

    .ant-col-xl-offset-0 {
        margin-left: 0
    }

    .ant-col-xl-order-0 {
        order: 0
    }
}

@media (min-width:1600px) {

    .ant-col-xxl-1,
    .ant-col-xxl-10,
    .ant-col-xxl-11,
    .ant-col-xxl-12,
    .ant-col-xxl-13,
    .ant-col-xxl-14,
    .ant-col-xxl-15,
    .ant-col-xxl-16,
    .ant-col-xxl-17,
    .ant-col-xxl-18,
    .ant-col-xxl-19,
    .ant-col-xxl-2,
    .ant-col-xxl-20,
    .ant-col-xxl-21,
    .ant-col-xxl-22,
    .ant-col-xxl-23,
    .ant-col-xxl-24,
    .ant-col-xxl-3,
    .ant-col-xxl-4,
    .ant-col-xxl-5,
    .ant-col-xxl-6,
    .ant-col-xxl-7,
    .ant-col-xxl-8,
    .ant-col-xxl-9 {
        flex: 0 0 auto;
        float: left
    }

    .ant-col-xxl-24 {
        display: block;
        box-sizing: border-box;
        width: 100%
    }

    .ant-col-xxl-push-24 {
        left: 100%
    }

    .ant-col-xxl-pull-24 {
        right: 100%
    }

    .ant-col-xxl-offset-24 {
        margin-left: 100%
    }

    .ant-col-xxl-order-24 {
        order: 24
    }

    .ant-col-xxl-23 {
        display: block;
        box-sizing: border-box;
        width: 95.83333333%
    }

    .ant-col-xxl-push-23 {
        left: 95.83333333%
    }

    .ant-col-xxl-pull-23 {
        right: 95.83333333%
    }

    .ant-col-xxl-offset-23 {
        margin-left: 95.83333333%
    }

    .ant-col-xxl-order-23 {
        order: 23
    }

    .ant-col-xxl-22 {
        display: block;
        box-sizing: border-box;
        width: 91.66666667%
    }

    .ant-col-xxl-push-22 {
        left: 91.66666667%
    }

    .ant-col-xxl-pull-22 {
        right: 91.66666667%
    }

    .ant-col-xxl-offset-22 {
        margin-left: 91.66666667%
    }

    .ant-col-xxl-order-22 {
        order: 22
    }

    .ant-col-xxl-21 {
        display: block;
        box-sizing: border-box;
        width: 87.5%
    }

    .ant-col-xxl-push-21 {
        left: 87.5%
    }

    .ant-col-xxl-pull-21 {
        right: 87.5%
    }

    .ant-col-xxl-offset-21 {
        margin-left: 87.5%
    }

    .ant-col-xxl-order-21 {
        order: 21
    }

    .ant-col-xxl-20 {
        display: block;
        box-sizing: border-box;
        width: 83.33333333%
    }

    .ant-col-xxl-push-20 {
        left: 83.33333333%
    }

    .ant-col-xxl-pull-20 {
        right: 83.33333333%
    }

    .ant-col-xxl-offset-20 {
        margin-left: 83.33333333%
    }

    .ant-col-xxl-order-20 {
        order: 20
    }

    .ant-col-xxl-19 {
        display: block;
        box-sizing: border-box;
        width: 79.16666667%
    }

    .ant-col-xxl-push-19 {
        left: 79.16666667%
    }

    .ant-col-xxl-pull-19 {
        right: 79.16666667%
    }

    .ant-col-xxl-offset-19 {
        margin-left: 79.16666667%
    }

    .ant-col-xxl-order-19 {
        order: 19
    }

    .ant-col-xxl-18 {
        display: block;
        box-sizing: border-box;
        width: 75%
    }

    .ant-col-xxl-push-18 {
        left: 75%
    }

    .ant-col-xxl-pull-18 {
        right: 75%
    }

    .ant-col-xxl-offset-18 {
        margin-left: 75%
    }

    .ant-col-xxl-order-18 {
        order: 18
    }

    .ant-col-xxl-17 {
        display: block;
        box-sizing: border-box;
        width: 70.83333333%
    }

    .ant-col-xxl-push-17 {
        left: 70.83333333%
    }

    .ant-col-xxl-pull-17 {
        right: 70.83333333%
    }

    .ant-col-xxl-offset-17 {
        margin-left: 70.83333333%
    }

    .ant-col-xxl-order-17 {
        order: 17
    }

    .ant-col-xxl-16 {
        display: block;
        box-sizing: border-box;
        width: 66.66666667%
    }

    .ant-col-xxl-push-16 {
        left: 66.66666667%
    }

    .ant-col-xxl-pull-16 {
        right: 66.66666667%
    }

    .ant-col-xxl-offset-16 {
        margin-left: 66.66666667%
    }

    .ant-col-xxl-order-16 {
        order: 16
    }

    .ant-col-xxl-15 {
        display: block;
        box-sizing: border-box;
        width: 62.5%
    }

    .ant-col-xxl-push-15 {
        left: 62.5%
    }

    .ant-col-xxl-pull-15 {
        right: 62.5%
    }

    .ant-col-xxl-offset-15 {
        margin-left: 62.5%
    }

    .ant-col-xxl-order-15 {
        order: 15
    }

    .ant-col-xxl-14 {
        display: block;
        box-sizing: border-box;
        width: 58.33333333%
    }

    .ant-col-xxl-push-14 {
        left: 58.33333333%
    }

    .ant-col-xxl-pull-14 {
        right: 58.33333333%
    }

    .ant-col-xxl-offset-14 {
        margin-left: 58.33333333%
    }

    .ant-col-xxl-order-14 {
        order: 14
    }

    .ant-col-xxl-13 {
        display: block;
        box-sizing: border-box;
        width: 54.16666667%
    }

    .ant-col-xxl-push-13 {
        left: 54.16666667%
    }

    .ant-col-xxl-pull-13 {
        right: 54.16666667%
    }

    .ant-col-xxl-offset-13 {
        margin-left: 54.16666667%
    }

    .ant-col-xxl-order-13 {
        order: 13
    }

    .ant-col-xxl-12 {
        display: block;
        box-sizing: border-box;
        width: 50%
    }

    .ant-col-xxl-push-12 {
        left: 50%
    }

    .ant-col-xxl-pull-12 {
        right: 50%
    }

    .ant-col-xxl-offset-12 {
        margin-left: 50%
    }

    .ant-col-xxl-order-12 {
        order: 12
    }

    .ant-col-xxl-11 {
        display: block;
        box-sizing: border-box;
        width: 45.83333333%
    }

    .ant-col-xxl-push-11 {
        left: 45.83333333%
    }

    .ant-col-xxl-pull-11 {
        right: 45.83333333%
    }

    .ant-col-xxl-offset-11 {
        margin-left: 45.83333333%
    }

    .ant-col-xxl-order-11 {
        order: 11
    }

    .ant-col-xxl-10 {
        display: block;
        box-sizing: border-box;
        width: 41.66666667%
    }

    .ant-col-xxl-push-10 {
        left: 41.66666667%
    }

    .ant-col-xxl-pull-10 {
        right: 41.66666667%
    }

    .ant-col-xxl-offset-10 {
        margin-left: 41.66666667%
    }

    .ant-col-xxl-order-10 {
        order: 10
    }

    .ant-col-xxl-9 {
        display: block;
        box-sizing: border-box;
        width: 37.5%
    }

    .ant-col-xxl-push-9 {
        left: 37.5%
    }

    .ant-col-xxl-pull-9 {
        right: 37.5%
    }

    .ant-col-xxl-offset-9 {
        margin-left: 37.5%
    }

    .ant-col-xxl-order-9 {
        order: 9
    }

    .ant-col-xxl-8 {
        display: block;
        box-sizing: border-box;
        width: 33.33333333%
    }

    .ant-col-xxl-push-8 {
        left: 33.33333333%
    }

    .ant-col-xxl-pull-8 {
        right: 33.33333333%
    }

    .ant-col-xxl-offset-8 {
        margin-left: 33.33333333%
    }

    .ant-col-xxl-order-8 {
        order: 8
    }

    .ant-col-xxl-7 {
        display: block;
        box-sizing: border-box;
        width: 29.16666667%
    }

    .ant-col-xxl-push-7 {
        left: 29.16666667%
    }

    .ant-col-xxl-pull-7 {
        right: 29.16666667%
    }

    .ant-col-xxl-offset-7 {
        margin-left: 29.16666667%
    }

    .ant-col-xxl-order-7 {
        order: 7
    }

    .ant-col-xxl-6 {
        display: block;
        box-sizing: border-box;
        width: 25%
    }

    .ant-col-xxl-push-6 {
        left: 25%
    }

    .ant-col-xxl-pull-6 {
        right: 25%
    }

    .ant-col-xxl-offset-6 {
        margin-left: 25%
    }

    .ant-col-xxl-order-6 {
        order: 6
    }

    .ant-col-xxl-5 {
        display: block;
        box-sizing: border-box;
        width: 20.83333333%
    }

    .ant-col-xxl-push-5 {
        left: 20.83333333%
    }

    .ant-col-xxl-pull-5 {
        right: 20.83333333%
    }

    .ant-col-xxl-offset-5 {
        margin-left: 20.83333333%
    }

    .ant-col-xxl-order-5 {
        order: 5
    }

    .ant-col-xxl-4 {
        display: block;
        box-sizing: border-box;
        width: 16.66666667%
    }

    .ant-col-xxl-push-4 {
        left: 16.66666667%
    }

    .ant-col-xxl-pull-4 {
        right: 16.66666667%
    }

    .ant-col-xxl-offset-4 {
        margin-left: 16.66666667%
    }

    .ant-col-xxl-order-4 {
        order: 4
    }

    .ant-col-xxl-3 {
        display: block;
        box-sizing: border-box;
        width: 12.5%
    }

    .ant-col-xxl-push-3 {
        left: 12.5%
    }

    .ant-col-xxl-pull-3 {
        right: 12.5%
    }

    .ant-col-xxl-offset-3 {
        margin-left: 12.5%
    }

    .ant-col-xxl-order-3 {
        order: 3
    }

    .ant-col-xxl-2 {
        display: block;
        box-sizing: border-box;
        width: 8.33333333%
    }

    .ant-col-xxl-push-2 {
        left: 8.33333333%
    }

    .ant-col-xxl-pull-2 {
        right: 8.33333333%
    }

    .ant-col-xxl-offset-2 {
        margin-left: 8.33333333%
    }

    .ant-col-xxl-order-2 {
        order: 2
    }

    .ant-col-xxl-1 {
        display: block;
        box-sizing: border-box;
        width: 4.16666667%
    }

    .ant-col-xxl-push-1 {
        left: 4.16666667%
    }

    .ant-col-xxl-pull-1 {
        right: 4.16666667%
    }

    .ant-col-xxl-offset-1 {
        margin-left: 4.16666667%
    }

    .ant-col-xxl-order-1 {
        order: 1
    }

    .ant-col-xxl-0 {
        display: none
    }

    .ant-col-push-0 {
        left: auto
    }

    .ant-col-pull-0 {
        right: auto
    }

    .ant-col-xxl-push-0 {
        left: auto
    }

    .ant-col-xxl-pull-0 {
        right: auto
    }

    .ant-col-xxl-offset-0 {
        margin-left: 0
    }

    .ant-col-xxl-order-0 {
        order: 0
    }
}

.ant-switch {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 20px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, .25);
    border: 1px solid transparent;
    border-radius: 100px;
    cursor: pointer;
    transition: all .36s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-switch-inner {
    display: block;
    margin-right: 6px;
    margin-left: 24px;
    color: #fff;
    font-size: 12px
}

.ant-switch-loading-icon,
.ant-switch:after {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 18px;
    cursor: pointer;
    transition: all .36s cubic-bezier(.78, .14, .15, .86);
    content: " "
}

.ant-switch:after {
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, .2)
}

.ant-switch:not(.ant-switch-disabled):active:after,
.ant-switch:not(.ant-switch-disabled):active:before {
    width: 24px
}

.ant-switch-loading-icon {
    z-index: 1;
    display: none;
    font-size: 12px;
    background: 0 0
}

.ant-switch-loading-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.ant-switch-loading .ant-switch-loading-icon {
    display: inline-block;
    color: rgba(0, 0, 0, .65)
}

.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
    color: #1890ff
}

.ant-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-switch:focus:hover {
    box-shadow: none
}

.ant-switch-small {
    min-width: 28px;
    height: 16px;
    line-height: 14px
}

.ant-switch-small .ant-switch-inner {
    margin-right: 3px;
    margin-left: 18px;
    font-size: 12px
}

.ant-switch-small:after {
    width: 12px;
    height: 12px
}

.ant-switch-small:active:after,
.ant-switch-small:active:before {
    width: 16px
}

.ant-switch-small .ant-switch-loading-icon {
    width: 12px;
    height: 12px
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
    margin-right: 18px;
    margin-left: 3px
}

.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
    left: 100%;
    margin-left: -13px
}

.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
    font-weight: 700;
    transform: scale(.66667)
}

.ant-switch-checked {
    background-color: #1890ff
}

.ant-switch-checked .ant-switch-inner {
    margin-right: 24px;
    margin-left: 6px
}

.ant-switch-checked:after {
    left: 100%;
    margin-left: -1px;
    transform: translateX(-100%)
}

.ant-switch-checked .ant-switch-loading-icon {
    left: 100%;
    margin-left: -19px
}

.ant-switch-disabled,
.ant-switch-loading {
    cursor: not-allowed;
    opacity: .4
}

.ant-switch-disabled *,
.ant-switch-loading * {
    cursor: not-allowed
}

.ant-switch-disabled:after,
.ant-switch-disabled:before,
.ant-switch-loading:after,
.ant-switch-loading:before {
    cursor: not-allowed
}

@keyframes AntSwitchSmallLoadingCircle {
    0% {
        transform: rotate(0) scale(.66667);
        transform-origin: 50% 50%
    }

    to {
        transform: rotate(1turn) scale(.66667);
        transform-origin: 50% 50%
    }
}

.ant-pro-setting-drawer-content {
    position: relative;
    min-height: 100%;
    background: #fff
}

.ant-pro-setting-drawer-content .ant-list-item span {
    flex: 1 1
}

.ant-pro-setting-drawer-block-checbox {
    display: flex;
    background: #fff
}

.ant-pro-setting-drawer-block-checbox-item {
    position: relative;
    margin-right: 16px;
    border-radius: 4px;
    cursor: pointer
}

.ant-pro-setting-drawer-block-checbox-item img {
    width: 48px
}

.ant-pro-setting-drawer-block-checbox-selectIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-left: 24px;
    color: #1890ff;
    font-weight: 700;
    font-size: 14px
}

.ant-pro-setting-drawer-color_block {
    display: inline-block;
    width: 38px;
    height: 22px;
    margin: 4px 12px 4px 4px;
    vertical-align: middle;
    border-radius: 4px;
    cursor: pointer
}

.ant-pro-setting-drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px
}

.ant-pro-setting-drawer-handle {
    position: absolute;
    top: 240px;
    right: 300px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background: #1890ff;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto
}

.ant-pro-setting-drawer-production-hint {
    margin-top: 16px;
    font-size: 12px
}

.ant-pro-setting-drawer-content {
    position: relative;
    min-height: 100%;
    background: #fff
}

.ant-pro-setting-drawer-content .ant-list-item span {
    flex: 1 1
}

.ant-pro-setting-drawer-block-checbox {
    display: flex;
    background: #fff
}

.ant-pro-setting-drawer-block-checbox-item {
    position: relative;
    margin-right: 16px;
    border-radius: 4px;
    cursor: pointer
}

.ant-pro-setting-drawer-block-checbox-item img {
    width: 48px
}

.ant-pro-setting-drawer-block-checbox-selectIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-left: 24px;
    color: #1890ff;
    font-weight: 700;
    font-size: 14px
}

.ant-pro-setting-drawer-color_block {
    display: inline-block;
    width: 38px;
    height: 22px;
    margin: 4px 12px 4px 4px;
    vertical-align: middle;
    border-radius: 4px;
    cursor: pointer
}

.ant-pro-setting-drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px
}

.ant-pro-setting-drawer-handle {
    position: absolute;
    top: 240px;
    right: 300px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background: #1890ff;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto
}

.ant-pro-setting-drawer-production-hint {
    margin-top: 16px;
    font-size: 12px
}

.ant-pro-setting-drawer-content .theme-color {
    margin-top: 24px;
    overflow: hidden
}

.ant-pro-setting-drawer-content .theme-color .theme-color-title {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 22px
}

.ant-pro-setting-drawer-content .theme-color .theme-color-block {
    float: left;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 2px;
    cursor: pointer
}

.ant-pro-grid-content {
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: .3s
}

.ant-pro-grid-content.wide {
    max-width: 1200px;
    margin: 0 auto
}

.ant-page-header {
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    padding: 16px 24px;
    background-color: #fff
}

.ant-page-header-ghost {
    background-color: inherit
}

.ant-page-header.has-breadcrumb {
    padding-top: 12px
}

.ant-page-header.has-footer {
    padding-bottom: 0
}

.ant-page-header-back {
    float: left;
    margin: 8px 16px 8px 0;
    font-size: 16px;
    line-height: 1
}

.ant-page-header-back-button {
    color: #1890ff;
    text-decoration: none;
    outline: 0;
    transition: color .3s;
    color: #000;
    cursor: pointer
}

.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
    color: #40a9ff
}

.ant-page-header-back-button:active {
    color: #096dd9
}

.ant-page-header .ant-divider-vertical {
    height: 14px;
    margin: 0 12px;
    vertical-align: middle
}

.ant-breadcrumb+.ant-page-header-heading {
    margin-top: 8px
}

.ant-page-header-heading {
    width: 100%;
    overflow: hidden
}

.ant-page-header-heading-title {
    display: block;
    float: left;
    margin-bottom: 0;
    padding-right: 12px;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px
}

.ant-page-header-heading .ant-avatar {
    float: left;
    margin-right: 12px
}

.ant-page-header-heading-sub-title {
    float: left;
    margin: 5px 12px 5px 0;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.ant-page-header-heading-tags {
    float: left;
    margin: 4px 0
}

.ant-page-header-heading-extra {
    float: right
}

.ant-page-header-heading-extra>* {
    margin-left: 8px
}

.ant-page-header-heading-extra>:first-child {
    margin-left: 0
}

.ant-page-header-content {
    padding-top: 12px;
    overflow: hidden
}

.ant-page-header-footer {
    margin-top: 16px
}

.ant-page-header-footer .ant-tabs-bar {
    margin-bottom: 1px;
    border-bottom: 0
}

.ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
    padding: 8px;
    font-size: 16px
}

@media (max-width:576px) {
    .ant-page-header-heading-extra {
        display: block;
        float: unset;
        width: 100%;
        padding-top: 12px;
        overflow: hidden
    }
}

.ant-breadcrumb {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

.ant-breadcrumb .anticon {
    font-size: 14px
}

.ant-breadcrumb a {
    color: rgba(0, 0, 0, .45);
    transition: color .3s
}

.ant-breadcrumb a:hover {
    color: #40a9ff
}

.ant-breadcrumb>span:last-child {
    color: rgba(0, 0, 0, .65)
}

.ant-breadcrumb>span:last-child a {
    color: rgba(0, 0, 0, .65)
}

.ant-breadcrumb>span:last-child .ant-breadcrumb-separator {
    display: none
}

.ant-breadcrumb-separator {
    margin: 0 8px;
    color: rgba(0, 0, 0, .45)
}

.ant-breadcrumb-link>.anticon+span {
    margin-left: 4px
}

.ant-breadcrumb-overlay-link>.anticon {
    margin-left: 4px
}
*/
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown:before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: " ";
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  -webkit-transform: scale(0.83333333) rotate(0);
          transform: scale(0.83333333) rotate(0);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
.ant-dropdown-wrap .anticon-down:before {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateZ(0);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup li,
.ant-dropdown-menu-submenu-popup ul {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
  padding: 0;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #1890ff;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  -webkit-transform: scale(0.83333333) rotate(0);
          transform: scale(0.83333333) rotate(0);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-trigger > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  -webkit-transform: scale(0.83333333) rotate(0);
          transform: scale(0.83333333) rotate(0);
}
:root .ant-dropdown-link > .anticon.anticon-down,
:root .ant-dropdown-trigger > .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  -webkit-transform: scale(0.83333333) rotate(0);
          transform: scale(0.83333333) rotate(0);
}
:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title {
  color: hsla(0, 0%, 100%, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after {
  color: hsla(0, 0%, 100%, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover {
  color: #fff;
  background: 0 0;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}
/*
.ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%
}

.ant-avatar-image {
    background: 0 0
}

.ant-avatar-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center
}

.ant-avatar.ant-avatar-icon {
    font-size: 18px
}

.ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%
}

.ant-avatar-lg-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center
}

.ant-avatar-lg.ant-avatar-icon {
    font-size: 24px
}

.ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%
}

.ant-avatar-sm-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center
}

.ant-avatar-sm.ant-avatar-icon {
    font-size: 14px
}

.ant-avatar-square {
    border-radius: 4px
}

.ant-avatar>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 40px
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
    visibility: hidden
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: 40px;
    margin: 0 2px 0 0;
    padding: 0 16px;
    line-height: 38px;
    background: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    transition: all .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 40px;
    color: #1890ff;
    background: #fff;
    border-color: #e8e8e8;
    border-bottom: 1px solid #fff
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active:before {
    border-top: 2px solid transparent
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
    color: #1890ff;
    color: rgba(0, 0, 0, .25)
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
    padding: 0
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
    margin-bottom: 0
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
    width: 16px;
    height: 16px;
    height: 14px;
    margin-right: -5px;
    margin-left: 3px;
    overflow: hidden;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
    vertical-align: middle;
    transition: all .3s
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
    color: rgba(0, 0, 0, .85)
}

.ant-tabs.ant-tabs-card .ant-tabs-card-content>.ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content>.ant-tabs-tabpane {
    transition: none !important
}

.ant-tabs.ant-tabs-card .ant-tabs-card-content>.ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content>.ant-tabs-tabpane-inactive {
    overflow: hidden
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
    opacity: 1
}

.ant-tabs-extra-content {
    line-height: 45px
}

.ant-tabs-extra-content .ant-tabs-new-tab {
    position: relative;
    width: 20px;
    height: 20px;
    color: rgba(0, 0, 0, .65);
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: pointer;
    transition: all .3s
}

.ant-tabs-extra-content .ant-tabs-new-tab:hover {
    color: #1890ff;
    border-color: #1890ff
}

.ant-tabs-extra-content .ant-tabs-new-tab svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.ant-tabs.ant-tabs-large .ant-tabs-extra-content {
    line-height: 56px
}

.ant-tabs.ant-tabs-small .ant-tabs-extra-content {
    line-height: 37px
}

.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
    line-height: 40px
}

.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
    height: 100%
}

.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
    margin-bottom: 8px;
    border-bottom: 1px solid #e8e8e8
}

.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
    padding-bottom: 4px
}

.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
    margin-bottom: 8px
}

.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
    width: 90%
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
    margin-right: 0
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
    margin-right: 1px;
    border-right: 0;
    border-radius: 4px 0 0 4px
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
    margin-right: -1px;
    padding-right: 18px
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
    margin-left: 0
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
    margin-left: 1px;
    border-left: 0;
    border-radius: 0 4px 4px 0
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
    margin-left: -1px;
    padding-left: 18px
}

.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
    height: auto;
    border-top: 0;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px
}

.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
    padding-top: 1px;
    padding-bottom: 0;
    color: #1890ff
}

.ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    overflow: hidden;
    zoom: 1
}

.ant-tabs:after,
.ant-tabs:before {
    display: table;
    content: ""
}

.ant-tabs:after {
    clear: both
}

.ant-tabs-ink-bar {
    position: absolute;
    bottom: 1px;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    height: 2px;
    background-color: #1890ff;
    transform-origin: 0 0
}

.ant-tabs-bar {
    margin: 0 0 16px;
    border-bottom: 1px solid #e8e8e8;
    outline: 0;
    transition: padding .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-tabs-nav-container {
    position: relative;
    box-sizing: border-box;
    margin-bottom: -1px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap;
    transition: padding .3s cubic-bezier(.645, .045, .355, 1);
    zoom: 1
}

.ant-tabs-nav-container:after,
.ant-tabs-nav-container:before {
    display: table;
    content: ""
}

.ant-tabs-nav-container:after {
    clear: both
}

.ant-tabs-nav-container-scrolling {
    padding-right: 32px;
    padding-left: 32px
}

.ant-tabs-bottom .ant-tabs-bottom-bar {
    margin-top: 16px;
    margin-bottom: 0;
    border-top: 1px solid #e8e8e8;
    border-bottom: none
}

.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
    top: 1px;
    bottom: auto
}

.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
    margin-top: -1px;
    margin-bottom: 0
}

.ant-tabs-tab-next,
.ant-tabs-tab-prev {
    position: absolute;
    z-index: 2;
    width: 0;
    height: 100%;
    color: rgba(0, 0, 0, .45);
    text-align: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    opacity: 0;
    transition: width .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1), color .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
}

.ant-tabs-tab-next.ant-tabs-tab-arrow-show,
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
    width: 32px;
    height: 100%;
    opacity: 1;
    pointer-events: auto
}

.ant-tabs-tab-next:hover,
.ant-tabs-tab-prev:hover {
    color: rgba(0, 0, 0, .65)
}

.ant-tabs-tab-next-icon,
.ant-tabs-tab-prev-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: 700;
    font-style: normal;
    font-variant: normal;
    line-height: inherit;
    text-align: center;
    text-transform: none;
    transform: translate(-50%, -50%)
}

.ant-tabs-tab-next-icon-target,
.ant-tabs-tab-prev-icon-target {
    display: block;
    display: inline-block;
    font-size: 12px;
    transform: scale(.83333333) rotate(0)
}

:root .ant-tabs-tab-next-icon-target,
:root .ant-tabs-tab-prev-icon-target {
    font-size: 12px
}

.ant-tabs-tab-btn-disabled {
    cursor: not-allowed
}

.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
    color: rgba(0, 0, 0, .25)
}

.ant-tabs-tab-next {
    right: 2px
}

.ant-tabs-tab-prev {
    left: 0
}

:root .ant-tabs-tab-prev {
    -webkit-filter: none;
    filter: none
}

.ant-tabs-nav-wrap {
    margin-bottom: -1px;
    overflow: hidden
}

.ant-tabs-nav-scroll {
    overflow: hidden;
    white-space: nowrap
}

.ant-tabs-nav {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding-left: 0;
    list-style: none;
    transition: transform .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-tabs-nav:after,
.ant-tabs-nav:before {
    display: table;
    content: " "
}

.ant-tabs-nav:after {
    clear: both
}

.ant-tabs-nav .ant-tabs-tab {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 100%;
    margin: 0 32px 0 0;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    transition: color .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-tabs-nav .ant-tabs-tab:before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    border-top: 2px solid transparent;
    border-radius: 4px 4px 0 0;
    transition: all .3s;
    content: "";
    pointer-events: none
}

.ant-tabs-nav .ant-tabs-tab:last-child {
    margin-right: 0
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #40a9ff
}

.ant-tabs-nav .ant-tabs-tab:active {
    color: #096dd9
}

.ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 8px
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    font-weight: 500
}

.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
    font-size: 16px
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
    padding: 16px
}

.ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
    font-size: 14px
}

.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
    padding: 8px 16px
}

.ant-tabs-content:before {
    display: block;
    overflow: hidden;
    content: ""
}

.ant-tabs .ant-tabs-bottom-content,
.ant-tabs .ant-tabs-top-content {
    width: 100%
}

.ant-tabs .ant-tabs-bottom-content>.ant-tabs-tabpane,
.ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane {
    flex-shrink: 0;
    width: 100%;
    opacity: 1;
    transition: opacity .45s
}

.ant-tabs .ant-tabs-bottom-content>.ant-tabs-tabpane-inactive,
.ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane-inactive {
    height: 0;
    padding: 0 !important;
    overflow: hidden;
    opacity: 0;
    pointer-events: none
}

.ant-tabs .ant-tabs-bottom-content>.ant-tabs-tabpane-inactive input,
.ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane-inactive input {
    visibility: hidden
}

.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated {
    display: flex;
    flex-direction: row;
    transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);
    will-change: margin-left
}

.ant-tabs .ant-tabs-left-bar,
.ant-tabs .ant-tabs-right-bar {
    height: 100%;
    border-bottom: 0
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-arrow-show {
    width: 100%;
    height: 32px
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
    display: block;
    float: none;
    margin: 0 0 16px;
    padding: 8px 24px
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
    margin-bottom: 0
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
.ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
    text-align: center
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
    width: auto
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
    height: 100%
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
    margin-bottom: 0
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
    padding: 32px 0
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
    margin-bottom: 0
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
    width: 100%
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
    top: 0;
    bottom: auto;
    left: auto;
    width: 2px;
    height: auto
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 32px
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
    top: 0;
    width: 100%;
    height: 32px
}

.ant-tabs .ant-tabs-left-content,
.ant-tabs .ant-tabs-right-content {
    width: auto;
    margin-top: 0 !important;
    overflow: hidden
}

.ant-tabs .ant-tabs-left-bar {
    float: left;
    margin-right: -1px;
    margin-bottom: 0;
    border-right: 1px solid #e8e8e8
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: right
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
    margin-right: -1px
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
    margin-right: -1px
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    right: 1px
}

.ant-tabs .ant-tabs-left-content {
    padding-left: 24px;
    border-left: 1px solid #e8e8e8
}

.ant-tabs .ant-tabs-right-bar {
    float: right;
    margin-bottom: 0;
    margin-left: -1px;
    border-left: 1px solid #e8e8e8
}

.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
    margin-left: -1px
}

.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
    margin-left: -1px
}

.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
    left: 1px
}

.ant-tabs .ant-tabs-right-content {
    padding-right: 24px;
    border-right: 1px solid #e8e8e8
}

.ant-tabs-bottom .ant-tabs-ink-bar-animated,
.ant-tabs-top .ant-tabs-ink-bar-animated {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1), width .3s cubic-bezier(.645, .045, .355, 1), left .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1), height .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-tabs-no-animation>.ant-tabs-content>.ant-tabs-content-animated,
.no-flex>.ant-tabs-content>.ant-tabs-content-animated {
    margin-left: 0 !important;
    transform: none !important
}

.ant-tabs-no-animation>.ant-tabs-content>.ant-tabs-tabpane-inactive,
.no-flex>.ant-tabs-content>.ant-tabs-tabpane-inactive {
    height: 0;
    padding: 0 !important;
    overflow: hidden;
    opacity: 0;
    pointer-events: none
}

.ant-tabs-no-animation>.ant-tabs-content>.ant-tabs-tabpane-inactive input,
.no-flex>.ant-tabs-content>.ant-tabs-tabpane-inactive input {
    visibility: hidden
}

.ant-tabs-left-content>.ant-tabs-content-animated,
.ant-tabs-right-content>.ant-tabs-content-animated {
    margin-left: 0 !important;
    transform: none !important
}

.ant-tabs-left-content>.ant-tabs-tabpane-inactive,
.ant-tabs-right-content>.ant-tabs-tabpane-inactive {
    height: 0;
    padding: 0 !important;
    overflow: hidden;
    opacity: 0;
    pointer-events: none
}

.ant-tabs-left-content>.ant-tabs-tabpane-inactive input,
.ant-tabs-right-content>.ant-tabs-tabpane-inactive input {
    visibility: hidden
}

.ant-pro-page-header-wrap-children-content {
    margin: 24px 24px 0
}

.ant-pro-page-header-wrap-page-header-warp {
    background-color: #fff
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-detail {
    display: flex
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-row {
    display: flex;
    width: 100%
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-title-content {
    margin-bottom: 16px
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-content,
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-title {
    flex: auto
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent,
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-main {
    flex: 0 1 auto
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-main {
    width: 100%
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-title {
    margin-bottom: 16px
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-logo {
    margin-bottom: 16px
}

.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
    min-width: 242px;
    margin-left: 88px;
    text-align: right
}

@media screen and (max-width:1200px) {
    .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
        margin-left: 44px
    }
}

@media screen and (max-width:992px) {
    .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
        margin-left: 20px
    }
}

@media screen and (max-width:768px) {
    .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-row {
        display: block
    }

    .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-action,
    .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
        margin-left: 0;
        text-align: left
    }
}

@media screen and (max-width:576px) {
    .ant-pro-page-header-wrap-detail {
        display: block
    }

    .ant-pro-page-header-wrap-extraContent {
        margin-left: 0
    }
}

.antd-pro-components-header-dropdown-index-container>* {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

@media screen and (max-width:480px) {
    .antd-pro-components-header-dropdown-index-container {
        width: 100% !important
    }

    .antd-pro-components-header-dropdown-index-container>* {
        border-radius: 0 !important
    }
}

.antd-pro-components-select-lang-index-menu .anticon {
    margin-right: 8px
}

.antd-pro-components-select-lang-index-menu .ant-dropdown-menu-item {
    min-width: 160px
}

.antd-pro-components-select-lang-index-dropDown {
    line-height: 64px;
    vertical-align: top;
    cursor: pointer
}

.antd-pro-components-select-lang-index-dropDown>i {
    font-size: 16px !important;
    transform: none !important
}

.antd-pro-components-select-lang-index-dropDown>i svg {
    position: relative;
    top: -1px
}


.antd-pro-layouts-user-layout-lang {
    width: 100%;
    height: 40px;
    line-height: 44px;
    text-align: right
}

.antd-pro-layouts-user-layout-lang .ant-dropdown-trigger {
    margin-right: 24px
}


@media (min-width:768px) {
    .antd-pro-layouts-user-layout-container {
        background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
        background-repeat: no-repeat;
        background-position: center 110px;
        background-size: 100%
    }

    
}

.antd-pro-layouts-user-layout-top {
    text-align: center
}

.antd-pro-layouts-user-layout-header {
    height: 44px;
    line-height: 44px
}

.antd-pro-layouts-user-layout-header a {
    text-decoration: none
}

.antd-pro-layouts-user-layout-logo {
    height: 44px;
    margin-right: 16px;
    vertical-align: top
}

.antd-pro-layouts-user-layout-title {
    position: relative;
    top: 2px;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 33px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif
}

.antd-pro-layouts-user-layout-desc {
    margin-top: 12px;
    margin-bottom: 40px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

@keyframes antCheckboxEffect {
    0% {
        transform: scale(1);
        opacity: .5
    }

    to {
        transform: scale(1.6);
        opacity: 0
    }
}

.ant-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: -.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: 0;
    cursor: pointer
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #1890ff
}

.ant-checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    animation: antCheckboxEffect .36s ease-in-out;
    animation-fill-mode: backwards;
    content: ""
}

.ant-checkbox-wrapper:hover .ant-checkbox:after,
.ant-checkbox:hover:after {
    visibility: visible
}

.ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all .3s
}

.ant-checkbox-inner:after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
    content: " "
}

.ant-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
    content: " "
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff
}

.ant-checkbox-disabled {
    cursor: not-allowed
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: rgba(0, 0, 0, .25);
    animation-name: none
}

.ant-checkbox-disabled .ant-checkbox-input {
    cursor: not-allowed
}

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: #f5f5f5;
    border-collapse: separate;
    animation-name: none
}

.ant-checkbox-disabled+span {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed
}

.ant-checkbox-disabled:hover:after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled:after {
    visibility: hidden
}

.ant-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    line-height: unset;
    cursor: pointer
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: not-allowed
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 8px
}

.ant-checkbox+span {
    padding-right: 8px;
    padding-left: 8px
}

.ant-checkbox-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block
}

.ant-checkbox-group-item {
    display: inline-block;
    margin-right: 8px
}

.ant-checkbox-group-item:last-child {
    margin-right: 0
}

.ant-checkbox-group-item+.ant-checkbox-group-item {
    margin-left: 0
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: " "
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
    background-color: rgba(0, 0, 0, .25);
    border-color: rgba(0, 0, 0, .25)
}

.ant-alert {
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    padding: 8px 15px 8px 37px;
    word-wrap: break-word;
    border-radius: 4px
}

.ant-alert.ant-alert-no-icon {
    padding: 8px 15px
}

.ant-alert.ant-alert-closable {
    padding-right: 30px
}

.ant-alert-icon {
    position: absolute;
    top: 11.5px;
    left: 16px
}

.ant-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px
}

.ant-alert-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f
}

.ant-alert-success .ant-alert-icon {
    color: #52c41a
}

.ant-alert-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff
}

.ant-alert-info .ant-alert-icon {
    color: #1890ff
}

.ant-alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f
}

.ant-alert-warning .ant-alert-icon {
    color: #faad14
}

.ant-alert-error {
    background-color: #fff1f0;
    border: 1px solid #ffa39e
}

.ant-alert-error .ant-alert-icon {
    color: #f5222d
}

.ant-alert-close-icon {
    position: absolute;
    top: 8px;
    right: 16px;
    overflow: hidden;
    font-size: 12px;
    line-height: 22px;
    background-color: transparent;
    border: none;
    outline: 0;
    cursor: pointer
}

.ant-alert-close-icon .anticon-close {
    color: rgba(0, 0, 0, .45);
    transition: color .3s
}

.ant-alert-close-icon .anticon-close:hover {
    color: rgba(0, 0, 0, .75)
}

.ant-alert-close-text {
    color: rgba(0, 0, 0, .45);
    transition: color .3s
}

.ant-alert-close-text:hover {
    color: rgba(0, 0, 0, .75)
}

.ant-alert-with-description {
    position: relative;
    padding: 15px 15px 15px 64px;
    color: rgba(0, 0, 0, .65);
    line-height: 1.5;
    border-radius: 4px
}

.ant-alert-with-description.ant-alert-no-icon {
    padding: 15px
}

.ant-alert-with-description .ant-alert-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    font-size: 24px
}

.ant-alert-with-description .ant-alert-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
    cursor: pointer
}

.ant-alert-with-description .ant-alert-message {
    display: block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .85);
    font-size: 16px
}

.ant-alert-message {
    color: rgba(0, 0, 0, .85)
}

.ant-alert-with-description .ant-alert-description {
    display: block
}

.ant-alert.ant-alert-closing {
    height: 0 !important;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    transform-origin: 50% 0;
    transition: all .3s cubic-bezier(.78, .14, .15, .86)
}

.ant-alert-slide-up-leave {
    animation: antAlertSlideUpOut .3s cubic-bezier(.78, .14, .15, .86);
    animation-fill-mode: both
}

.ant-alert-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0
}

@keyframes antAlertSlideUpIn {
    0% {
        transform: scaleY(0);
        transform-origin: 0 0;
        opacity: 0
    }

    to {
        transform: scaleY(1);
        transform-origin: 0 0;
        opacity: 1
    }
}

@keyframes antAlertSlideUpOut {
    0% {
        transform: scaleY(1);
        transform-origin: 0 0;
        opacity: 1
    }

    to {
        transform: scaleY(0);
        transform-origin: 0 0;
        opacity: 0
    }
}

.ant-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum"
}

.ant-form legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9
}

.ant-form label {
    font-size: 14px
}

.ant-form input[type=search] {
    box-sizing: border-box
}

.ant-form input[type=checkbox],
.ant-form input[type=radio] {
    line-height: normal
}

.ant-form input[type=file] {
    display: block
}

.ant-form input[type=range] {
    display: block;
    width: 100%
}

.ant-form select[multiple],
.ant-form select[size] {
    height: auto
}

.ant-form input[type=checkbox]:focus,
.ant-form input[type=file]:focus,
.ant-form input[type=radio]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.ant-form output {
    display: block;
    padding-top: 15px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5
}

.ant-form-item-required:before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*"
}

.ant-form-hide-required-mark .ant-form-item-required:before {
    display: none
}

.ant-form-item-label>label {
    color: rgba(0, 0, 0, .85)
}

.ant-form-item-label>label:after {
    content: ":";
    position: relative;
    top: -.5px;
    margin: 0 8px 0 2px
}

.ant-form-item-label>label.ant-form-item-no-colon:after {
    content: " "
}

.ant-form-item {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    margin: 0 0 24px;
    vertical-align: top
}

.ant-form-item label {
    position: relative
}

.ant-form-item label>.anticon {
    font-size: 14px;
    vertical-align: top
}

.ant-form-item-control {
    position: relative;
    line-height: 40px;
    zoom: 1
}

.ant-form-item-control:after,
.ant-form-item-control:before {
    display: table;
    content: ""
}

.ant-form-item-control:after {
    clear: both
}

.ant-form-item-children {
    position: relative
}

.ant-form-item-with-help {
    margin-bottom: 5px
}

.ant-form-item-label {
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle
}

.ant-form-item-label-left {
    text-align: left
}

.ant-form-item .ant-switch {
    margin: 2px 0 4px
}

.ant-form-explain,
.ant-form-extra {
    clear: both;
    min-height: 22px;
    margin-top: -2px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 1.5;
    transition: color .3s cubic-bezier(.215, .61, .355, 1)
}

.ant-form-explain {
    margin-bottom: -1px
}

.ant-form-extra {
    padding-top: 4px
}

.ant-form-text {
    display: inline-block;
    padding-right: 8px
}

.ant-form-split {
    display: block;
    text-align: center
}

form .has-feedback .ant-input {
    padding-right: 24px
}

form .has-feedback .ant-input-password-icon {
    margin-right: 18px
}

form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection__clear,
form .has-feedback>.ant-select .ant-select-arrow,
form .has-feedback>.ant-select .ant-select-selection__clear {
    right: 28px
}

form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection-selected-value,
form .has-feedback>.ant-select .ant-select-selection-selected-value {
    padding-right: 42px
}

form .has-feedback .ant-cascader-picker-arrow {
    margin-right: 17px
}

form .has-feedback .ant-cascader-picker-clear {
    right: 28px
}

form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    right: 28px
}

form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-clear,
form .has-feedback .ant-time-picker-icon {
    right: 28px
}

form .ant-mentions,
form textarea.ant-input {
    height: auto;
    margin-bottom: 4px
}

form .ant-upload {
    background: 0 0
}

form input[type=checkbox],
form input[type=radio] {
    width: 14px;
    height: 14px
}

form .ant-checkbox-inline,
form .ant-radio-inline {
    display: inline-block;
    margin-left: 8px;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer
}

form .ant-checkbox-inline:first-child,
form .ant-radio-inline:first-child {
    margin-left: 0
}

form .ant-checkbox-vertical,
form .ant-radio-vertical {
    display: block
}

form .ant-checkbox-vertical+.ant-checkbox-vertical,
form .ant-radio-vertical+.ant-radio-vertical {
    margin-left: 0
}

form .ant-input-number+.ant-form-text {
    margin-left: 8px
}

form .ant-input-number-handler-wrap {
    z-index: 2
}

form .ant-cascader-picker,
form .ant-select {
    width: 100%
}

form .ant-input-group .ant-cascader-picker,
form .ant-input-group .ant-select {
    width: auto
}

form .ant-input-group-wrapper,
form :not(.ant-input-group-wrapper)>.ant-input-group {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align: left
}

.ant-col-24.ant-form-item-label label:after,
.ant-col-xl-24.ant-form-item-label label:after,
.ant-form-vertical .ant-form-item-label label:after {
    display: none
}

.ant-form-vertical .ant-form-item {
    padding-bottom: 8px
}

.ant-form-vertical .ant-form-item-control {
    line-height: 1.5
}

.ant-form-vertical .ant-form-explain {
    margin-top: 2px;
    margin-bottom: -5px
}

.ant-form-vertical .ant-form-extra {
    margin-top: 2px;
    margin-bottom: -4px
}

@media (max-width:575px) {

    .ant-form-item-control-wrapper,
    .ant-form-item-label {
        display: block;
        width: 100%
    }

    .ant-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5;
        white-space: normal;
        text-align: left
    }

    .ant-form-item-label label:after {
        display: none
    }

    .ant-col-xs-24.ant-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5;
        white-space: normal;
        text-align: left
    }

    .ant-col-xs-24.ant-form-item-label label:after {
        display: none
    }
}

@media (max-width:767px) {
    .ant-col-sm-24.ant-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5;
        white-space: normal;
        text-align: left
    }

    .ant-col-sm-24.ant-form-item-label label:after {
        display: none
    }
}

@media (max-width:991px) {
    .ant-col-md-24.ant-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5;
        white-space: normal;
        text-align: left
    }

    .ant-col-md-24.ant-form-item-label label:after {
        display: none
    }
}

@media (max-width:1199px) {
    .ant-col-lg-24.ant-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5;
        white-space: normal;
        text-align: left
    }

    .ant-col-lg-24.ant-form-item-label label:after {
        display: none
    }
}

@media (max-width:1599px) {
    .ant-col-xl-24.ant-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5;
        white-space: normal;
        text-align: left
    }

    .ant-col-xl-24.ant-form-item-label label:after {
        display: none
    }
}

.ant-form-inline .ant-form-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 0
}

.ant-form-inline .ant-form-item-with-help {
    margin-bottom: 24px
}

.ant-form-inline .ant-form-item>.ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item>.ant-form-item-label {
    display: inline-block;
    vertical-align: top
}

.ant-form-inline .ant-form-text {
    display: inline-block
}

.ant-form-inline .has-feedback {
    display: inline-block
}

.has-error.has-feedback .ant-form-item-children-icon,
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    visibility: visible;
    animation: zoomIn .3s cubic-bezier(.12, .4, .29, 1.46);
    pointer-events: none
}

.has-error.has-feedback .ant-form-item-children-icon svg,
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.has-success.has-feedback .ant-form-item-children-icon {
    color: #52c41a;
    animation-name: diffZoomIn1 !important
}

.has-warning .ant-form-explain,
.has-warning .ant-form-split {
    color: #faad14
}

.has-warning .ant-input,
.has-warning .ant-input:hover {
    background-color: #fff;
    border-color: #faad14
}

.has-warning .ant-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, .2)
}

.has-warning .ant-input:not([disabled]):hover {
    border-color: #faad14
}

.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, .2)
}

.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
    background-color: #fff;
    border-color: #faad14
}

.has-warning .ant-input-affix-wrapper .ant-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, .2)
}

.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #faad14
}

.has-warning .ant-input-prefix {
    color: #faad14
}

.has-warning .ant-input-group-addon {
    color: #faad14;
    background-color: #fff;
    border-color: #faad14
}

.has-warning .has-feedback {
    color: #faad14
}

.has-warning.has-feedback .ant-form-item-children-icon {
    color: #faad14;
    animation-name: diffZoomIn3 !important
}

.has-warning .ant-select-selection {
    border-color: #faad14
}

.has-warning .ant-select-selection:hover {
    border-color: #faad14
}

.has-warning .ant-select-focused .ant-select-selection,
.has-warning .ant-select-open .ant-select-selection {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, .2)
}

.has-warning .ant-calendar-picker-icon:after,
.has-warning .ant-cascader-picker-arrow,
.has-warning .ant-picker-icon:after,
.has-warning .ant-select-arrow,
.has-warning .ant-time-picker-icon:after {
    color: #faad14
}

.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
    border-color: #faad14
}

.has-warning .ant-input-number-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-time-picker-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, .2)
}

.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
    border-color: #faad14
}

.has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, .2)
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
    color: #f5222d
}

.has-error .ant-input,
.has-error .ant-input:hover {
    background-color: #fff;
    border-color: #f5222d
}

.has-error .ant-input:focus {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2)
}

.has-error .ant-input:not([disabled]):hover {
    border-color: #f5222d
}

.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2)
}

.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: #fff;
    border-color: #f5222d
}

.has-error .ant-input-affix-wrapper .ant-input:focus {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2)
}

.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #f5222d
}

.has-error .ant-input-prefix {
    color: #f5222d
}

.has-error .ant-input-group-addon {
    color: #f5222d;
    background-color: #fff;
    border-color: #f5222d
}

.has-error .has-feedback {
    color: #f5222d
}

.has-error.has-feedback .ant-form-item-children-icon {
    color: #f5222d;
    animation-name: diffZoomIn2 !important
}

.has-error .ant-select-selection {
    border-color: #f5222d
}

.has-error .ant-select-selection:hover {
    border-color: #f5222d
}

.has-error .ant-select-focused .ant-select-selection,
.has-error .ant-select-open .ant-select-selection {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2)
}

.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #f5222d
}

.has-error .ant-input-group-addon .ant-select-selection {
    border-color: transparent;
    box-shadow: none
}

.has-error .ant-calendar-picker-icon:after,
.has-error .ant-cascader-picker-arrow,
.has-error .ant-picker-icon:after,
.has-error .ant-select-arrow,
.has-error .ant-time-picker-icon:after {
    color: #f5222d
}

.has-error .ant-input-number,
.has-error .ant-time-picker-input {
    border-color: #f5222d
}

.has-error .ant-input-number-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input-focused,
.has-error .ant-time-picker-input:focus {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2)
}

.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
    border-color: #f5222d
}

.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
    border-color: #f5222d
}

.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus,
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2)
}

.has-error .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2)
}

.has-error .ant-transfer-list {
    border-color: #f5222d
}

.has-error .ant-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9
}

.has-error .ant-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.has-error .ant-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.is-validating.has-feedback .ant-form-item-children-icon {
    display: inline-block;
    color: #1890ff
}

.ant-advanced-search-form .ant-form-item {
    margin-bottom: 24px
}

.ant-advanced-search-form .ant-form-item-with-help {
    margin-bottom: 5px
}

.show-help-appear,
.show-help-enter {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.show-help-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.show-help-appear.show-help-appear-active,
.show-help-enter.show-help-enter-active {
    animation-name: antShowHelpIn;
    animation-play-state: running
}

.show-help-leave.show-help-leave-active {
    animation-name: antShowHelpOut;
    animation-play-state: running;
    pointer-events: none
}

.show-help-appear,
.show-help-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(.645, .045, .355, 1)
}

.show-help-leave {
    animation-timing-function: cubic-bezier(.645, .045, .355, 1)
}

@keyframes antShowHelpIn {
    0% {
        transform: translateY(-5px);
        opacity: 0
    }

    to {
        transform: translateY(0);
        opacity: 1
    }
}

@keyframes antShowHelpOut {
    to {
        transform: translateY(-5px);
        opacity: 0
    }
}

@keyframes diffZoomIn1 {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

@keyframes diffZoomIn2 {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

@keyframes diffZoomIn3 {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.antd-pro-pages-user-login-components-login-index-login .ant-tabs .ant-tabs-bar {
    margin-bottom: 24px;
    text-align: center;
    border-bottom: 0
}

.antd-pro-pages-user-login-components-login-index-login .ant-form-item {
    margin: 0 2px 24px
}

.antd-pro-pages-user-login-components-login-index-login .antd-pro-pages-user-login-components-login-index-getCaptcha {
    display: block;
    width: 100%
}

.antd-pro-pages-user-login-components-login-index-login .antd-pro-pages-user-login-components-login-index-icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, .2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color .3s
}

.antd-pro-pages-user-login-components-login-index-login .antd-pro-pages-user-login-components-login-index-icon:hover {
    color: #1890ff
}

.antd-pro-pages-user-login-components-login-index-login .antd-pro-pages-user-login-components-login-index-other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left
}

.antd-pro-pages-user-login-components-login-index-login .antd-pro-pages-user-login-components-login-index-other .antd-pro-pages-user-login-components-login-index-register {
    float: right
}

.antd-pro-pages-user-login-components-login-index-login .antd-pro-pages-user-login-components-login-index-prefixIcon {
    color: rgba(0, 0, 0, .25);
    font-size: 14px
}

.antd-pro-pages-user-login-components-login-index-login .antd-pro-pages-user-login-components-login-index-submit {
    width: 100%;
    margin-top: 24px
}

.antd-pro-pages-user-login-style-main {
    width: 368px;
    margin: 0 auto
}

@media screen and (max-width:576px) {
    .antd-pro-pages-user-login-style-main {
        width: 95%
    }
}

.antd-pro-pages-user-login-style-main .antd-pro-pages-user-login-style-icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, .2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color .3s
}

.antd-pro-pages-user-login-style-main .antd-pro-pages-user-login-style-icon:hover {
    color: #1890ff
}

.antd-pro-pages-user-login-style-main .antd-pro-pages-user-login-style-other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left
}

.antd-pro-pages-user-login-style-main .antd-pro-pages-user-login-style-other .antd-pro-pages-user-login-style-register {
    float: right
}

.antd-pro-pages-user-login-style-main .antd-pro-login-submit {
    width: 100%;
    margin-top: 24px
}

.antd-pro-pages-user-register-result-style-registerResult {
    width: 800px;
    min-height: 400px;
    margin: auto;
    padding: 80px;
    background: 0 0
}

.antd-pro-pages-user-register-result-style-registerResult .anticon {
    font-size: 64px
}

.antd-pro-pages-user-register-result-style-registerResult .antd-pro-pages-user-register-result-style-title {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px
}

.antd-pro-pages-user-register-result-style-registerResult .antd-pro-pages-user-register-result-style-actions {
    margin-top: 40px
}

.antd-pro-pages-user-register-result-style-registerResult .antd-pro-pages-user-register-result-style-actions a+a {
    margin-left: 8px
}

.ant-progress {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block
}

.ant-progress-line {
    position: relative;
    width: 100%;
    font-size: 14px
}

.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
    font-size: 12px
}

.ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0
}

.ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em + 8px)
}

.ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 100px
}

.ant-progress-circle-trail {
    stroke: #f5f5f5
}

.ant-progress-circle-path {
    animation: ant-progress-appear .3s
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #1890ff
}

.ant-progress-bg,
.ant-progress-success-bg {
    position: relative;
    background-color: #1890ff;
    border-radius: 100px;
    transition: all .4s cubic-bezier(.08, .82, .17, 1) 0s
}

.ant-progress-success-bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #52c41a
}

.ant-progress-text {
    display: inline-block;
    width: 2em;
    margin-left: 8px;
    color: rgba(0, 0, 0, .45);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal
}

.ant-progress-text .anticon {
    font-size: 14px
}

.ant-progress-status-active .ant-progress-bg:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 10px;
    opacity: 0;
    animation: ant-progress-active 2.4s cubic-bezier(.23, 1, .32, 1) infinite;
    content: ""
}

.ant-progress-status-exception .ant-progress-bg {
    background-color: #f5222d
}

.ant-progress-status-exception .ant-progress-text {
    color: #f5222d
}

.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #f5222d
}

.ant-progress-status-success .ant-progress-bg {
    background-color: #52c41a
}

.ant-progress-status-success .ant-progress-text {
    color: #52c41a
}

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #52c41a
}

.ant-progress-circle .ant-progress-inner {
    position: relative;
    line-height: 1;
    background-color: transparent
}

.ant-progress-circle .ant-progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    line-height: 1;
    white-space: normal;
    text-align: center;
    transform: translate(-50%, -50%)
}

.ant-progress-circle .ant-progress-text .anticon {
    font-size: 1.16666667em
}

.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: #f5222d
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #52c41a
}

@keyframes ant-progress-active {
    0% {
        width: 0;
        opacity: .1
    }

    20% {
        width: 0;
        opacity: .5
    }

    to {
        width: 100%;
        opacity: 0
    }
}

.antd-pro-pages-user-register-style-main {
    width: 368px;
    margin: 0 auto
}

.antd-pro-pages-user-register-style-main .ant-form-item {
    margin-bottom: 24px
}

.antd-pro-pages-user-register-style-main h3 {
    margin-bottom: 20px;
    font-size: 16px
}

.antd-pro-pages-user-register-style-main .antd-pro-pages-user-register-style-getCaptcha {
    display: block;
    width: 100%
}

.antd-pro-pages-user-register-style-main .antd-pro-pages-user-register-style-submit {
    width: 50%
}

.antd-pro-pages-user-register-style-main .antd-pro-pages-user-register-style-login {
    float: right;
    line-height: 40px
}

.antd-pro-pages-user-register-style-error,
.antd-pro-pages-user-register-style-success,
.antd-pro-pages-user-register-style-warning {
    transition: color .3s
}

.antd-pro-pages-user-register-style-success {
    color: #52c41a
}

.antd-pro-pages-user-register-style-warning {
    color: #faad14
}

.antd-pro-pages-user-register-style-error {
    color: #f5222d
}

.antd-pro-pages-user-register-style-progress-pass>.antd-pro-pages-user-register-style-progress .ant-progress-bg {
    background-color: #faad14
}

.antd-pro-components-global-header-index-menu .anticon {
    margin-right: 8px
}

.antd-pro-components-global-header-index-menu .ant-dropdown-menu-item {
    min-width: 160px
}

.antd-pro-components-global-header-index-right {
    float: right;
    height: 100%;
    margin-left: auto;
    overflow: hidden
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all .3s
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action>i {
    color: rgba(0, 0, 0, .65);
    vertical-align: middle
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action:hover {
    background: rgba(0, 0, 0, .025)
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action.opened {
    background: rgba(0, 0, 0, .025)
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search {
    padding: 0 12px
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search:hover {
    background: 0 0
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
    margin: 20px 8px 20px 0;
    color: #1890ff;
    vertical-align: top;
    background: hsla(0, 0%, 100%, .85)
}

.antd-pro-components-global-header-index-dark {
    height: 64px
}

.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action {
    color: hsla(0, 0%, 100%, .85)
}

.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action>i {
    color: hsla(0, 0%, 100%, .85)
}

.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action.opened,
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover {
    background: #1890ff
}

.ant-pro-global-header .antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action {
    color: rgba(0, 0, 0, .65)
}

.ant-pro-global-header .antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action>i {
    color: rgba(0, 0, 0, .65)
}

.ant-pro-global-header .antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover {
    color: hsla(0, 0%, 100%, .85)
}

.ant-pro-global-header .antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover>i {
    color: hsla(0, 0%, 100%, .85)
}

@media only screen and (max-width:768px) {
    .ant-divider-vertical {
        vertical-align: unset
    }

    .antd-pro-components-global-header-index-name {
        display: none
    }

    .antd-pro-components-global-header-index-right {
        position: absolute;
        top: 0;
        right: 12px
    }

    .antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
        margin-right: 0
    }
}

.ant-select-auto-complete {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum"
}

.ant-select-auto-complete.ant-select .ant-select-selection {
    border: 0;
    box-shadow: none
}

.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    height: 100%;
    margin-right: 0;
    margin-left: 0;
    line-height: 32px
}

.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-right: 12px;
    margin-left: 12px
}

.ant-select-auto-complete.ant-select .ant-select-selection--single {
    height: auto
}

.ant-select-auto-complete.ant-select .ant-select-search--inline {
    position: static;
    float: left
}

.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 0 !important
}

.ant-select-auto-complete.ant-select .ant-input {
    height: 32px;
    line-height: 1.5;
    background: 0 0;
    border-width: 1px
}

.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-select-auto-complete.ant-select .ant-input[disabled] {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
    background-color: transparent
}

.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
    line-height: 40px
}

.ant-select-auto-complete.ant-select-lg .ant-input {
    height: 40px;
    padding-top: 6px;
    padding-bottom: 6px
}

.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
    line-height: 24px
}

.ant-select-auto-complete.ant-select-sm .ant-input {
    height: 24px;
    padding-top: 1px;
    padding-bottom: 1px
}

.ant-input-group>.ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
    display: inline;
    float: none
}

.antd-pro-components-header-search-index-headerSearch .anticon-search {
    font-size: 16px;
    cursor: pointer
}

.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input {
    width: 0;
    background: 0 0;
    border-radius: 0;
    transition: width .3s, margin-left .3s
}

.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input .ant-select-selection {
    background: 0 0
}

.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input input {
    padding-right: 0;
    padding-left: 0;
    border: 0;
    box-shadow: none !important
}

.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:focus,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:hover {
    border-bottom: 1px solid #d9d9d9
}

.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input.antd-pro-components-header-search-index-show {
    width: 210px;
    margin-left: 8px
}

.ant-tag {
    box-sizing: border-box;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    height: auto;
    margin: 0 8px 0 0;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: default;
    opacity: 1;
    transition: all .3s cubic-bezier(.78, .14, .15, .86)
}

.ant-tag:hover {
    opacity: .85
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
    color: rgba(0, 0, 0, .65)
}

.ant-tag>a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px
}

.ant-tag .anticon-close {
    display: inline-block;
    font-size: 12px;
    transform: scale(.83333333) rotate(0);
    margin-left: 3px;
    color: rgba(0, 0, 0, .45);
    font-weight: 700;
    cursor: pointer;
    transition: all .3s cubic-bezier(.78, .14, .15, .86)
}

:root .ant-tag .anticon-close {
    font-size: 12px
}

.ant-tag .anticon-close:hover {
    color: rgba(0, 0, 0, .85)
}

.ant-tag-has-color {
    border-color: transparent
}

.ant-tag-has-color,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover,
.ant-tag-has-color a,
.ant-tag-has-color a:hover {
    color: #fff
}

.ant-tag-checkable {
    background-color: transparent;
    border-color: transparent
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #1890ff
}

.ant-tag-checkable-checked,
.ant-tag-checkable:active {
    color: #fff
}

.ant-tag-checkable-checked {
    background-color: #1890ff
}

.ant-tag-checkable:active {
    background-color: #096dd9
}

.ant-tag-hidden {
    display: none
}

.ant-tag-pink {
    color: #eb2f96;
    background: #fff0f6;
    border-color: #ffadd2
}

.ant-tag-pink-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96
}

.ant-tag-magenta {
    color: #eb2f96;
    background: #fff0f6;
    border-color: #ffadd2
}

.ant-tag-magenta-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96
}

.ant-tag-red {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e
}

.ant-tag-red-inverse {
    color: #fff;
    background: #f5222d;
    border-color: #f5222d
}

.ant-tag-volcano {
    color: #fa541c;
    background: #fff2e8;
    border-color: #ffbb96
}

.ant-tag-volcano-inverse {
    color: #fff;
    background: #fa541c;
    border-color: #fa541c
}

.ant-tag-orange {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591
}

.ant-tag-orange-inverse {
    color: #fff;
    background: #fa8c16;
    border-color: #fa8c16
}

.ant-tag-yellow {
    color: #fadb14;
    background: #feffe6;
    border-color: #fffb8f
}

.ant-tag-yellow-inverse {
    color: #fff;
    background: #fadb14;
    border-color: #fadb14
}

.ant-tag-gold {
    color: #faad14;
    background: #fffbe6;
    border-color: #ffe58f
}

.ant-tag-gold-inverse {
    color: #fff;
    background: #faad14;
    border-color: #faad14
}

.ant-tag-cyan {
    color: #13c2c2;
    background: #e6fffb;
    border-color: #87e8de
}

.ant-tag-cyan-inverse {
    color: #fff;
    background: #13c2c2;
    border-color: #13c2c2
}

.ant-tag-lime {
    color: #a0d911;
    background: #fcffe6;
    border-color: #eaff8f
}

.ant-tag-lime-inverse {
    color: #fff;
    background: #a0d911;
    border-color: #a0d911
}

.ant-tag-green {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f
}

.ant-tag-green-inverse {
    color: #fff;
    background: #52c41a;
    border-color: #52c41a
}

.ant-tag-blue {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff
}

.ant-tag-blue-inverse {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff
}

.ant-tag-geekblue {
    color: #2f54eb;
    background: #f0f5ff;
    border-color: #adc6ff
}

.ant-tag-geekblue-inverse {
    color: #fff;
    background: #2f54eb;
    border-color: #2f54eb
}

.ant-tag-purple {
    color: #722ed1;
    background: #f9f0ff;
    border-color: #d3adf7
}

.ant-tag-purple-inverse {
    color: #fff;
    background: #722ed1;
    border-color: #722ed1
}

.ant-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    color: unset;
    line-height: 1
}

.ant-badge-count {
    z-index: 10;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #f5222d;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff
}

.ant-badge-count a,
.ant-badge-count a:hover {
    color: #fff
}

.ant-badge-multiple-words {
    padding: 0 8px
}

.ant-badge-dot {
    z-index: 10;
    width: 6px;
    height: 6px;
    background: #f5222d;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff
}

.ant-badge .ant-scroll-number-custom-component,
.ant-badge-count,
.ant-badge-dot {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0
}

.ant-badge-status {
    line-height: inherit;
    vertical-align: baseline
}

.ant-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%
}

.ant-badge-status-success {
    background-color: #52c41a
}

.ant-badge-status-processing {
    position: relative;
    background-color: #1890ff
}

.ant-badge-status-processing:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 50%;
    animation: antStatusProcessing 1.2s ease-in-out infinite;
    content: ""
}

.ant-badge-status-default {
    background-color: #d9d9d9
}

.ant-badge-status-error {
    background-color: #f5222d
}

.ant-badge-status-warning {
    background-color: #faad14
}

.ant-badge-status-pink {
    background: #eb2f96
}

.ant-badge-status-magenta {
    background: #eb2f96
}

.ant-badge-status-red {
    background: #f5222d
}

.ant-badge-status-volcano {
    background: #fa541c
}

.ant-badge-status-orange {
    background: #fa8c16
}

.ant-badge-status-yellow {
    background: #fadb14
}

.ant-badge-status-gold {
    background: #faad14
}

.ant-badge-status-cyan {
    background: #13c2c2
}

.ant-badge-status-lime {
    background: #a0d911
}

.ant-badge-status-green {
    background: #52c41a
}

.ant-badge-status-blue {
    background: #1890ff
}

.ant-badge-status-geekblue {
    background: #2f54eb
}

.ant-badge-status-purple {
    background: #722ed1
}

.ant-badge-status-text {
    margin-left: 8px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px
}

.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
    animation: antZoomBadgeIn .3s cubic-bezier(.12, .4, .29, 1.46);
    animation-fill-mode: both
}

.ant-badge-zoom-leave {
    animation: antZoomBadgeOut .3s cubic-bezier(.71, -.46, .88, .6);
    animation-fill-mode: both
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: middle
}

.ant-badge-not-a-wrapper .ant-scroll-number {
    position: relative;
    top: auto;
    display: block
}

.ant-badge-not-a-wrapper .ant-badge-count {
    transform: none
}

@keyframes antStatusProcessing {
    0% {
        transform: scale(.8);
        opacity: .5
    }

    to {
        transform: scale(2.4);
        opacity: 0
    }
}

.ant-scroll-number {
    overflow: hidden
}

.ant-scroll-number-only {
    display: inline-block;
    height: 20px;
    transition: all .3s cubic-bezier(.645, .045, .355, 1)
}

.ant-scroll-number-only>p {
    height: 20px;
    margin: 0
}

.ant-scroll-number-symbol {
    vertical-align: top
}

@keyframes antZoomBadgeIn {
    0% {
        transform: scale(0) translate(50%, -50%);
        opacity: 0
    }

    to {
        transform: scale(1) translate(50%, -50%)
    }
}

@keyframes antZoomBadgeOut {
    0% {
        transform: scale(1) translate(50%, -50%)
    }

    to {
        transform: scale(0) translate(50%, -50%);
        opacity: 0
    }
}

.antd-pro-components-notice-icon-notice-list-list {
    max-height: 400px;
    overflow: auto
}

.antd-pro-components-notice-icon-notice-list-list::-webkit-scrollbar {
    display: none
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item {
    padding-right: 24px;
    padding-left: 24px;
    overflow: hidden;
    cursor: pointer;
    transition: all .3s
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-meta {
    width: 100%
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-avatar {
    margin-top: 4px;
    background: #fff
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-iconElement {
    font-size: 32px
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item.antd-pro-components-notice-icon-notice-list-read {
    opacity: .4
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:last-child {
    border-bottom: 0
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:hover {
    background: #e6f7ff
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-title {
    margin-bottom: 8px;
    font-weight: 400
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-description {
    font-size: 12px;
    line-height: 1.5
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-datetime {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.5
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-extra {
    float: right;
    margin-top: -1.5px;
    margin-right: 0;
    color: rgba(0, 0, 0, .45);
    font-weight: 400
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-loadMore {
    padding: 8px 0;
    color: #1890ff;
    text-align: center;
    cursor: pointer
}

.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-loadMore.antd-pro-components-notice-icon-notice-list-loadedAll {
    color: rgba(0, 0, 0, .25);
    cursor: unset
}

.antd-pro-components-notice-icon-notice-list-notFound {
    padding: 73px 0 88px;
    color: rgba(0, 0, 0, .45);
    text-align: center
}

.antd-pro-components-notice-icon-notice-list-notFound img {
    display: inline-block;
    height: 76px;
    margin-bottom: 16px
}

.antd-pro-components-notice-icon-notice-list-bottomBar {
    height: 06px;
    color: rgba(0, 0, 0, .65);
    line-height: 46px;
    text-align: center;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    transition: all .3s
}

.antd-pro-components-notice-icon-notice-list-bottomBar div {
    display: inline-block;
    width: 50%;
    cursor: pointer;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.antd-pro-components-notice-icon-notice-list-bottomBar div:hover {
    color: rgba(0, 0, 0, .85)
}

.antd-pro-components-notice-icon-notice-list-bottomBar div:only-child {
    width: 100%
}

.antd-pro-components-notice-icon-notice-list-bottomBar div:not(:only-child):last-child {
    border-left: 1px solid #e8e8e8
}

.antd-pro-components-notice-icon-index-popover {
    position: relative;
    width: 336px
}

.antd-pro-components-notice-icon-index-noticeButton {
    display: inline-block;
    cursor: pointer;
    transition: all .3s
}

.antd-pro-components-notice-icon-index-icon {
    padding: 4px;
    vertical-align: middle
}

.antd-pro-components-notice-icon-index-badge {
    font-size: 16px
}

.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
    text-align: center
}

.antd-pro-components-notice-icon-index-tabs .ant-tabs-bar {
    margin-bottom: 0
}

.antd-pro-pages-dashboard-analysis-style-iconGroup i {
    margin-left: 16px;
    color: rgba(0, 0, 0, .45);
    cursor: pointer;
    transition: color .32s
}

.antd-pro-pages-dashboard-analysis-style-iconGroup i:hover {
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-dashboard-analysis-style-rankingList {
    margin: 25px 0 0;
    padding: 0;
    list-style: none
}

.antd-pro-pages-dashboard-analysis-style-rankingList li {
    display: flex;
    align-items: center;
    margin-top: 16px;
    zoom: 1
}

.antd-pro-pages-dashboard-analysis-style-rankingList li:after,
.antd-pro-pages-dashboard-analysis-style-rankingList li:before {
    display: table;
    content: " "
}

.antd-pro-pages-dashboard-analysis-style-rankingList li:after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden
}

.antd-pro-pages-dashboard-analysis-style-rankingList li span {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-analysis-style-rankingList li .antd-pro-pages-dashboard-analysis-style-rankingItemNumber {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 1.5px;
    margin-right: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background-color: #fafafa;
    border-radius: 20px
}

.antd-pro-pages-dashboard-analysis-style-rankingList li .antd-pro-pages-dashboard-analysis-style-rankingItemNumber.antd-pro-pages-dashboard-analysis-style-active {
    color: #fff;
    background-color: #314659
}

.antd-pro-pages-dashboard-analysis-style-rankingList li .antd-pro-pages-dashboard-analysis-style-rankingItemTitle {
    flex: 1 1;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.antd-pro-pages-dashboard-analysis-style-salesExtra {
    display: inline-block;
    margin-right: 24px
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a {
    margin-left: 24px;
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a:hover {
    color: #1890ff
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a.antd-pro-pages-dashboard-analysis-style-currentDate {
    color: #1890ff
}

.antd-pro-pages-dashboard-analysis-style-salesCard .antd-pro-pages-dashboard-analysis-style-salesBar {
    padding: 0 0 32px 32px
}

.antd-pro-pages-dashboard-analysis-style-salesCard .antd-pro-pages-dashboard-analysis-style-salesRank {
    padding: 0 32px 32px 72px
}

.antd-pro-pages-dashboard-analysis-style-salesCard .ant-tabs-bar {
    padding-left: 16px
}

.antd-pro-pages-dashboard-analysis-style-salesCard .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
    padding-top: 16px;
    padding-bottom: 14px;
    line-height: 24px
}

.antd-pro-pages-dashboard-analysis-style-salesCard .ant-tabs-extra-content {
    padding-right: 24px;
    line-height: 55px
}

.antd-pro-pages-dashboard-analysis-style-salesCard .ant-card-head {
    position: relative
}

.antd-pro-pages-dashboard-analysis-style-salesCard .ant-card-head-title {
    align-items: normal
}

.antd-pro-pages-dashboard-analysis-style-salesCardExtra {
    height: inherit
}

.antd-pro-pages-dashboard-analysis-style-salesTypeRadio {
    position: absolute;
    right: 54px;
    bottom: 12px
}

.antd-pro-pages-dashboard-analysis-style-offlineCard .ant-tabs-ink-bar {
    bottom: auto
}

.antd-pro-pages-dashboard-analysis-style-offlineCard .ant-tabs-bar {
    border-bottom: none
}

.antd-pro-pages-dashboard-analysis-style-offlineCard .ant-tabs-nav-container-scrolling {
    padding-right: 40px;
    padding-left: 40px
}

.antd-pro-pages-dashboard-analysis-style-offlineCard .ant-tabs-tab-prev-icon:before {
    position: relative;
    left: 6px
}

.antd-pro-pages-dashboard-analysis-style-offlineCard .ant-tabs-tab-next-icon:before {
    position: relative;
    right: 6px
}

.antd-pro-pages-dashboard-analysis-style-offlineCard .ant-tabs-tab-active h4 {
    color: #1890ff
}

.antd-pro-pages-dashboard-analysis-style-trendText {
    margin-left: 8px;
    color: rgba(0, 0, 0, .85)
}

@media screen and (max-width:992px) {
    .antd-pro-pages-dashboard-analysis-style-salesExtra {
        display: none
    }

    .antd-pro-pages-dashboard-analysis-style-rankingList li span:first-child {
        margin-right: 8px
    }
}

@media screen and (max-width:768px) {
    .antd-pro-pages-dashboard-analysis-style-rankingTitle {
        margin-top: 16px
    }

    .antd-pro-pages-dashboard-analysis-style-salesCard .antd-pro-pages-dashboard-analysis-style-salesBar {
        padding: 16px
    }
}

@media screen and (max-width:576px) {
    .antd-pro-pages-dashboard-analysis-style-salesExtraWrap {
        display: none
    }

    .antd-pro-pages-dashboard-analysis-style-salesCard .ant-tabs-content {
        padding-top: 30px
    }
}

.antd-pro-pages-dashboard-analysis-components-charts-index-miniChart {
    position: relative;
    width: 100%
}

.antd-pro-pages-dashboard-analysis-components-charts-index-miniChart .antd-pro-pages-dashboard-analysis-components-charts-index-chartContent {
    position: absolute;
    bottom: -28px;
    width: 100%
}

.antd-pro-pages-dashboard-analysis-components-charts-index-miniChart .antd-pro-pages-dashboard-analysis-components-charts-index-chartContent>div {
    margin: 0 -5px;
    overflow: hidden
}

.antd-pro-pages-dashboard-analysis-components-charts-index-miniChart .antd-pro-pages-dashboard-analysis-components-charts-index-chartLoading {
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -7px
}

.ant-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    transition: all .3s
}

.ant-card-hoverable {
    cursor: pointer
}

.ant-card-hoverable:hover {
    border-color: rgba(0, 0, 0, .09);
    box-shadow: 0 2px 8px rgba(0, 0, 0, .09)
}

.ant-card-bordered {
    border: 1px solid #e8e8e8
}

.ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    background: 0 0;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
    zoom: 1
}

.ant-card-head:after,
.ant-card-head:before {
    display: table;
    content: ""
}

.ant-card-head:after {
    clear: both
}

.ant-card-head-wrapper {
    display: flex;
    align-items: center
}

.ant-card-head-title {
    display: inline-block;
    flex: 1 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-card-head .ant-tabs {
    clear: both;
    margin-bottom: -17px;
    color: rgba(0, 0, 0, .65);
    font-weight: 400;
    font-size: 14px
}

.ant-card-head .ant-tabs-bar {
    border-bottom: 1px solid #e8e8e8
}

.ant-card-extra {
    float: right;
    margin-left: auto;
    padding: 16px 0;
    color: rgba(0, 0, 0, .65);
    font-weight: 400;
    font-size: 14px
}

.ant-card-body {
    padding: 24px;
    zoom: 1
}

.ant-card-body:after,
.ant-card-body:before {
    display: table;
    content: ""
}

.ant-card-body:after {
    clear: both
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
    margin: -1px 0 0 -1px;
    padding: 0
}

.ant-card-grid {
    float: left;
    width: 33.33%;
    padding: 24px;
    border: 0;
    border-radius: 0;
    box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, inset 1px 0 0 0 #e8e8e8, inset 0 1px 0 0 #e8e8e8;
    transition: all .3s
}

.ant-card-grid-hoverable:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-card-contain-tabs>.ant-card-head .ant-card-head-title {
    min-height: 32px;
    padding-bottom: 0
}

.ant-card-contain-tabs>.ant-card-head .ant-card-extra {
    padding-bottom: 0
}

.ant-card-cover>* {
    display: block;
    width: 100%
}

.ant-card-cover img {
    border-radius: 2px 2px 0 0
}

.ant-card-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fafafa;
    border-top: 1px solid #e8e8e8;
    zoom: 1
}

.ant-card-actions:after,
.ant-card-actions:before {
    display: table;
    content: ""
}

.ant-card-actions:after {
    clear: both
}

.ant-card-actions>li {
    float: left;
    margin: 12px 0;
    color: rgba(0, 0, 0, .45);
    text-align: center
}

.ant-card-actions>li>span {
    position: relative;
    display: block;
    min-width: 32px;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer
}

.ant-card-actions>li>span:hover {
    color: #1890ff;
    transition: color .3s
}

.ant-card-actions>li>span a:not(.ant-btn),
.ant-card-actions>li>span>.anticon {
    display: inline-block;
    width: 100%;
    color: rgba(0, 0, 0, .45);
    line-height: 22px;
    transition: color .3s
}

.ant-card-actions>li>span a:not(.ant-btn):hover,
.ant-card-actions>li>span>.anticon:hover {
    color: #1890ff
}

.ant-card-actions>li>span>.anticon {
    font-size: 16px;
    line-height: 22px
}

.ant-card-actions>li:not(:last-child) {
    border-right: 1px solid #e8e8e8
}

.ant-card-type-inner .ant-card-head {
    padding: 0 24px;
    background: #fafafa
}

.ant-card-type-inner .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px
}

.ant-card-type-inner .ant-card-body {
    padding: 16px 24px
}

.ant-card-type-inner .ant-card-extra {
    padding: 13.5px 0
}

.ant-card-meta {
    margin: -4px 0;
    zoom: 1
}

.ant-card-meta:after,
.ant-card-meta:before {
    display: table;
    content: ""
}

.ant-card-meta:after {
    clear: both
}

.ant-card-meta-avatar {
    float: left;
    padding-right: 16px
}

.ant-card-meta-detail {
    overflow: hidden
}

.ant-card-meta-detail>div:not(:last-child) {
    margin-bottom: 8px
}

.ant-card-meta-title {
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-card-meta-description {
    color: rgba(0, 0, 0, .45)
}

.ant-card-loading {
    overflow: hidden
}

.ant-card-loading .ant-card-body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-card-loading-content p {
    margin: 0
}

.ant-card-loading-block {
    height: 14px;
    margin: 4px 0;
    background: linear-gradient(90deg, rgba(207, 216, 220, .2), rgba(207, 216, 220, .4), rgba(207, 216, 220, .2));
    background-size: 600% 600%;
    border-radius: 2px;
    animation: card-loading 1.4s ease infinite
}

@keyframes card-loading {

    0%,
    to {
        background-position: 0 50%
    }

    50% {
        background-position: 100% 50%
    }
}

.ant-card-small>.ant-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: 14px
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
    padding: 8px 0
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-extra {
    padding: 8px 0;
    font-size: 14px
}

.ant-card-small>.ant-card-body {
    padding: 12px
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard {
    position: relative
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartTop {
    position: relative;
    width: 100%;
    overflow: hidden
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartTopMargin {
    margin-bottom: 12px
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartTopHasMargin {
    margin-bottom: 20px
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-metaWrap {
    float: left
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-avatar {
    position: relative;
    top: 4px;
    float: left;
    margin-right: 20px
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-avatar img {
    border-radius: 100%
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-meta {
    height: 22px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-action {
    position: absolute;
    top: 4px;
    right: 0;
    line-height: 1;
    cursor: pointer
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-total {
    height: 38px;
    margin-top: 4px;
    margin-bottom: 0;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-content {
    position: relative;
    width: 100%;
    margin-bottom: 12px
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-contentFixed {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-footer {
    margin-top: 8px;
    padding-top: 9px;
    border-top: 1px solid #e8e8e8
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-footer>* {
    position: relative
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-footerMargin {
    margin-top: 20px
}

.antd-pro-pages-dashboard-analysis-components-charts-field-index-field {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.antd-pro-pages-dashboard-analysis-components-charts-field-index-field .antd-pro-pages-dashboard-analysis-components-charts-field-index-label,
.antd-pro-pages-dashboard-analysis-components-charts-field-index-field .antd-pro-pages-dashboard-analysis-components-charts-field-index-number {
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-analysis-components-charts-field-index-field .antd-pro-pages-dashboard-analysis-components-charts-field-index-number {
    margin-left: 8px;
    color: rgba(0, 0, 0, .85)
}

.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress {
    position: relative;
    width: 100%;
    padding: 5px 0
}

.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-progressWrap {
    position: relative;
    background-color: #f5f5f5
}

.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-progress {
    width: 0;
    height: 100%;
    background-color: #1890ff;
    border-radius: 1px 0 0 1px;
    transition: all .4s cubic-bezier(.08, .82, .17, 1) 0s
}

.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-target {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9;
    width: 20px
}

.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-target span {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 4px;
    border-radius: 100px
}

.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-target span:last-child {
    top: auto;
    bottom: 0
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie {
    position: relative
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-chart {
    position: relative
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie.antd-pro-pages-dashboard-analysis-components-charts-pie-index-hasLegend .antd-pro-pages-dashboard-analysis-components-charts-pie-index-chart {
    width: calc(100% - 240px)
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-legend {
    position: absolute;
    top: 50%;
    right: 0;
    min-width: 200px;
    margin: 0 20px;
    padding: 0;
    list-style: none;
    transform: translateY(-50%)
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-legend li {
    height: 22px;
    margin-bottom: 16px;
    line-height: 22px;
    cursor: pointer
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-legend li:last-child {
    margin-bottom: 0
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 8px
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-line {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin-right: 8px;
    background-color: #e8e8e8
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-legendTitle {
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-percent {
    color: rgba(0, 0, 0, .45)
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-value {
    position: absolute;
    right: 0
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-title {
    margin-bottom: 8px
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-total {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 62px;
    text-align: center;
    transform: translate(-50%, -50%)
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-total>h4 {
    height: 22px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .45);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-pie .antd-pro-pages-dashboard-analysis-components-charts-pie-index-total>p {
    display: block;
    height: 32px;
    color: rgba(0, 0, 0, .85);
    font-size: 1.2em;
    line-height: 32px;
    white-space: nowrap
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-legendBlock.antd-pro-pages-dashboard-analysis-components-charts-pie-index-hasLegend .antd-pro-pages-dashboard-analysis-components-charts-pie-index-chart {
    width: 100%;
    margin: 0 0 32px
}

.antd-pro-pages-dashboard-analysis-components-charts-pie-index-legendBlock .antd-pro-pages-dashboard-analysis-components-charts-pie-index-legend {
    position: relative;
    transform: none
}

.antd-pro-pages-dashboard-analysis-components-charts-tag-cloud-index-tagCloud {
    overflow: hidden
}

.antd-pro-pages-dashboard-analysis-components-charts-tag-cloud-index-tagCloud canvas {
    transform-origin: 0 0
}

.antd-pro-pages-dashboard-analysis-components-charts-timeline-chart-index-timelineChart {
    background: #fff
}

.antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-waterWave {
    position: relative;
    display: inline-block;
    transform-origin: left
}

.antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-text {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    text-align: center
}

.antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-text span {
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-text h4 {
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 32px
}

.antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-analysis-components-charts-water-wave-index-waterWaveCanvasWrapper {
    transform: scale(.5);
    transform-origin: 0 0
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem {
    display: inline-block;
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem .antd-pro-pages-dashboard-analysis-components-trend-index-down,
.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem .antd-pro-pages-dashboard-analysis-components-trend-index-up {
    position: relative;
    top: 1px;
    margin-left: 4px
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem .antd-pro-pages-dashboard-analysis-components-trend-index-down i,
.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem .antd-pro-pages-dashboard-analysis-components-trend-index-up i {
    font-size: 12px;
    transform: scale(.83)
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem .antd-pro-pages-dashboard-analysis-components-trend-index-up {
    color: #f5222d
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem .antd-pro-pages-dashboard-analysis-components-trend-index-down {
    top: -1px;
    color: #52c41a
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem.antd-pro-pages-dashboard-analysis-components-trend-index-trendItemGrey .antd-pro-pages-dashboard-analysis-components-trend-index-down,
.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem.antd-pro-pages-dashboard-analysis-components-trend-index-trendItemGrey .antd-pro-pages-dashboard-analysis-components-trend-index-up {
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem.antd-pro-pages-dashboard-analysis-components-trend-index-reverseColor .antd-pro-pages-dashboard-analysis-components-trend-index-up {
    color: #52c41a
}

.antd-pro-pages-dashboard-analysis-components-trend-index-trendItem.antd-pro-pages-dashboard-analysis-components-trend-index-reverseColor .antd-pro-pages-dashboard-analysis-components-trend-index-down {
    color: #f5222d
}

.ant-calendar-picker-container {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1050;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
}

.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight {
    animation-name: antSlideDownIn
}

.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight {
    animation-name: antSlideUpIn
}

.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
    animation-name: antSlideDownOut
}

.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
    animation-name: antSlideUpOut
}

.ant-calendar-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    outline: 0;
    cursor: text;
    transition: opacity .3s
}

.ant-calendar-picker-input {
    outline: 0
}

.ant-calendar-picker-input.ant-input {
    line-height: 1.5
}

.ant-calendar-picker-input.ant-input-sm {
    padding-top: 0;
    padding-bottom: 0
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #40a9ff
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    z-index: 1;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    font-size: 12px;
    line-height: 14px;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-calendar-picker-clear {
    z-index: 2;
    color: rgba(0, 0, 0, .25);
    font-size: 14px;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    pointer-events: none
}

.ant-calendar-picker-clear:hover {
    color: rgba(0, 0, 0, .45)
}

.ant-calendar-picker:hover .ant-calendar-picker-clear {
    opacity: 1;
    pointer-events: auto
}

.ant-calendar-picker-icon {
    display: inline-block;
    color: rgba(0, 0, 0, .25);
    font-size: 14px;
    line-height: 1
}

.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
    right: 8px
}

.ant-calendar {
    position: relative;
    width: 280px;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fff;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-calendar-input-wrap {
    height: 34px;
    padding: 6px 10px;
    border-bottom: 1px solid #e8e8e8
}

.ant-calendar-input {
    width: 100%;
    height: 22px;
    color: rgba(0, 0, 0, .65);
    background: #fff;
    border: 0;
    outline: 0;
    cursor: auto
}

.ant-calendar-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-calendar-input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-calendar-input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-calendar-input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-calendar-week-number {
    width: 286px
}

.ant-calendar-week-number-cell {
    text-align: center
}

.ant-calendar-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-calendar-header a:hover {
    color: #40a9ff
}

.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-month-select,
.ant-calendar-header .ant-calendar-year-select {
    display: inline-block;
    padding: 0 2px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    line-height: 40px
}

.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow {
    display: none
}

.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-next-year-btn,
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 0 5px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px
}

.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
    left: 7px;
    height: 100%
}

.ant-calendar-header .ant-calendar-prev-century-btn:after,
.ant-calendar-header .ant-calendar-prev-century-btn:before,
.ant-calendar-header .ant-calendar-prev-decade-btn:after,
.ant-calendar-header .ant-calendar-prev-decade-btn:before,
.ant-calendar-header .ant-calendar-prev-year-btn:after,
.ant-calendar-header .ant-calendar-prev-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-header .ant-calendar-prev-century-btn:hover:after,
.ant-calendar-header .ant-calendar-prev-century-btn:hover:before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover:after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover:before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover:after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-header .ant-calendar-prev-century-btn:after,
.ant-calendar-header .ant-calendar-prev-decade-btn:after,
.ant-calendar-header .ant-calendar-prev-year-btn:after {
    display: none
}

.ant-calendar-header .ant-calendar-prev-century-btn:after,
.ant-calendar-header .ant-calendar-prev-decade-btn:after,
.ant-calendar-header .ant-calendar-prev-year-btn:after {
    position: relative;
    left: -3px;
    display: inline-block
}

.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
    right: 7px;
    height: 100%
}

.ant-calendar-header .ant-calendar-next-century-btn:after,
.ant-calendar-header .ant-calendar-next-century-btn:before,
.ant-calendar-header .ant-calendar-next-decade-btn:after,
.ant-calendar-header .ant-calendar-next-decade-btn:before,
.ant-calendar-header .ant-calendar-next-year-btn:after,
.ant-calendar-header .ant-calendar-next-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-header .ant-calendar-next-century-btn:hover:after,
.ant-calendar-header .ant-calendar-next-century-btn:hover:before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover:after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover:before,
.ant-calendar-header .ant-calendar-next-year-btn:hover:after,
.ant-calendar-header .ant-calendar-next-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-header .ant-calendar-next-century-btn:after,
.ant-calendar-header .ant-calendar-next-decade-btn:after,
.ant-calendar-header .ant-calendar-next-year-btn:after {
    display: none
}

.ant-calendar-header .ant-calendar-next-century-btn:after,
.ant-calendar-header .ant-calendar-next-century-btn:before,
.ant-calendar-header .ant-calendar-next-decade-btn:after,
.ant-calendar-header .ant-calendar-next-decade-btn:before,
.ant-calendar-header .ant-calendar-next-year-btn:after,
.ant-calendar-header .ant-calendar-next-year-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-header .ant-calendar-next-century-btn:before,
.ant-calendar-header .ant-calendar-next-decade-btn:before,
.ant-calendar-header .ant-calendar-next-year-btn:before {
    position: relative;
    left: 3px
}

.ant-calendar-header .ant-calendar-next-century-btn:after,
.ant-calendar-header .ant-calendar-next-decade-btn:after,
.ant-calendar-header .ant-calendar-next-year-btn:after {
    display: inline-block
}

.ant-calendar-header .ant-calendar-prev-month-btn {
    left: 29px;
    height: 100%
}

.ant-calendar-header .ant-calendar-prev-month-btn:after,
.ant-calendar-header .ant-calendar-prev-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-header .ant-calendar-prev-month-btn:hover:after,
.ant-calendar-header .ant-calendar-prev-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-header .ant-calendar-prev-month-btn:after {
    display: none
}

.ant-calendar-header .ant-calendar-next-month-btn {
    right: 29px;
    height: 100%
}

.ant-calendar-header .ant-calendar-next-month-btn:after,
.ant-calendar-header .ant-calendar-next-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-header .ant-calendar-next-month-btn:hover:after,
.ant-calendar-header .ant-calendar-next-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-header .ant-calendar-next-month-btn:after {
    display: none
}

.ant-calendar-header .ant-calendar-next-month-btn:after,
.ant-calendar-header .ant-calendar-next-month-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-body {
    padding: 8px 12px
}

.ant-calendar table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse
}

.ant-calendar table,
.ant-calendar td,
.ant-calendar th {
    text-align: center;
    border: 0
}

.ant-calendar-calendar-table {
    margin-bottom: 0;
    border-spacing: 0
}

.ant-calendar-column-header {
    width: 33px;
    padding: 6px 0;
    line-height: 18px;
    text-align: center
}

.ant-calendar-column-header .ant-calendar-column-header-inner {
    display: block;
    font-weight: 400
}

.ant-calendar-week-number-header .ant-calendar-column-header-inner {
    display: none
}

.ant-calendar-cell {
    height: 30px;
    padding: 3px 0
}

.ant-calendar-date {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    line-height: 22px;
    text-align: center;
    background: 0 0;
    border: 1px solid transparent;
    border-radius: 2px;
    transition: background .3s ease
}

.ant-calendar-date-panel {
    position: relative;
    outline: 0
}

.ant-calendar-date:hover {
    background: #e6f7ff;
    cursor: pointer
}

.ant-calendar-date:active {
    color: #fff;
    background: #40a9ff
}

.ant-calendar-today .ant-calendar-date {
    color: #1890ff;
    font-weight: 700;
    border-color: #1890ff
}

.ant-calendar-selected-day .ant-calendar-date {
    background: #bae7ff
}

.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
    color: rgba(0, 0, 0, .25);
    background: 0 0;
    border-color: transparent
}

.ant-calendar-disabled-cell .ant-calendar-date {
    position: relative;
    width: auto;
    color: rgba(0, 0, 0, .25);
    background: #f5f5f5;
    border: 1px solid transparent;
    border-radius: 0;
    cursor: not-allowed
}

.ant-calendar-disabled-cell .ant-calendar-date:hover {
    background: #f5f5f5
}

.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date:before {
    position: absolute;
    top: -1px;
    left: 5px;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, .1);
    border-radius: 2px;
    content: ""
}

.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
    position: relative;
    padding-right: 5px;
    padding-left: 5px
}

.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
    position: absolute;
    top: -1px;
    left: 5px;
    width: 24px;
    height: 24px;
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: 2px;
    content: " "
}

.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.ant-calendar-footer {
    padding: 0 12px;
    line-height: 38px;
    border-top: 1px solid #e8e8e8
}

.ant-calendar-footer:empty {
    border-top: 0
}

.ant-calendar-footer-btn {
    display: block;
    text-align: center
}

.ant-calendar-footer-extra {
    text-align: left
}

.ant-calendar .ant-calendar-clear-btn,
.ant-calendar .ant-calendar-today-btn {
    display: inline-block;
    margin: 0 0 0 8px;
    text-align: center
}

.ant-calendar .ant-calendar-clear-btn-disabled,
.ant-calendar .ant-calendar-today-btn-disabled {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed
}

.ant-calendar .ant-calendar-clear-btn:only-child,
.ant-calendar .ant-calendar-today-btn:only-child {
    margin: 0
}

.ant-calendar .ant-calendar-clear-btn {
    position: absolute;
    top: 7px;
    right: 5px;
    display: none;
    width: 20px;
    height: 20px;
    margin: 0;
    overflow: hidden;
    line-height: 20px;
    text-align: center;
    text-indent: -76px
}

.ant-calendar .ant-calendar-clear-btn:after {
    display: inline-block;
    width: 20px;
    color: rgba(0, 0, 0, .25);
    font-size: 14px;
    line-height: 1;
    text-indent: 43px;
    transition: color .3s ease
}

.ant-calendar .ant-calendar-clear-btn:hover:after {
    color: rgba(0, 0, 0, .45)
}

.ant-calendar .ant-calendar-ok-btn {
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    color: #fff;
    background-color: #1890ff;
    border: 1px solid #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 22px
}

.ant-calendar .ant-calendar-ok-btn>.anticon {
    line-height: 1
}

.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
    outline: 0
}

.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
    text-decoration: none
}

.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none
}

.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
    cursor: not-allowed
}

.ant-calendar .ant-calendar-ok-btn.disabled>*,
.ant-calendar .ant-calendar-ok-btn[disabled]>* {
    pointer-events: none
}

.ant-calendar .ant-calendar-ok-btn-lg {
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    border-radius: 4px
}

.ant-calendar .ant-calendar-ok-btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px
}

.ant-calendar .ant-calendar-ok-btn>a:only-child {
    color: currentColor
}

.ant-calendar .ant-calendar-ok-btn>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-calendar .ant-calendar-ok-btn:focus,
.ant-calendar .ant-calendar-ok-btn:hover {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff
}

.ant-calendar .ant-calendar-ok-btn:focus>a:only-child,
.ant-calendar .ant-calendar-ok-btn:hover>a:only-child {
    color: currentColor
}

.ant-calendar .ant-calendar-ok-btn:focus>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn:hover>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-calendar .ant-calendar-ok-btn.active,
.ant-calendar .ant-calendar-ok-btn:active {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9
}

.ant-calendar .ant-calendar-ok-btn.active>a:only-child,
.ant-calendar .ant-calendar-ok-btn:active>a:only-child {
    color: currentColor
}

.ant-calendar .ant-calendar-ok-btn.active>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn:active>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn[disabled].active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none
}

.ant-calendar .ant-calendar-ok-btn-disabled.active>a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active>a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus>a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover>a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled>a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active>a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active>a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus>a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover>a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled>a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active>a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active>a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus>a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover>a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]>a:only-child {
    color: currentColor
}

.ant-calendar .ant-calendar-ok-btn-disabled.active>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn-disabled:active>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn-disabled>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled.active>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled:active>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled].active>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover>a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled]>a:only-child:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: ""
}

.ant-calendar-range-picker-input {
    width: 44%;
    height: 99%;
    text-align: center;
    background-color: transparent;
    border: 0;
    outline: 0
}

.ant-calendar-range-picker-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-calendar-range-picker-input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-calendar-range-picker-input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-calendar-range-picker-input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-calendar-range-picker-input[disabled] {
    cursor: not-allowed
}

.ant-calendar-range-picker-separator {
    display: inline-block;
    min-width: 10px;
    height: 100%;
    color: rgba(0, 0, 0, .45);
    white-space: nowrap;
    text-align: center;
    vertical-align: top;
    pointer-events: none
}

.ant-calendar-range {
    width: 552px;
    overflow: hidden
}

.ant-calendar-range .ant-calendar-date-panel:after {
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
    content: "."
}

.ant-calendar-range-part {
    position: relative;
    width: 50%
}

.ant-calendar-range-left {
    float: left
}

.ant-calendar-range-left .ant-calendar-time-picker-inner {
    border-right: 1px solid #e8e8e8
}

.ant-calendar-range-right {
    float: right
}

.ant-calendar-range-right .ant-calendar-time-picker-inner {
    border-left: 1px solid #e8e8e8
}

.ant-calendar-range-middle {
    position: absolute;
    left: 50%;
    z-index: 1;
    height: 34px;
    margin: 1px 0 0;
    padding: 0 200px 0 0;
    color: rgba(0, 0, 0, .45);
    line-height: 34px;
    text-align: center;
    transform: translateX(-50%);
    pointer-events: none
}

.ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: -90px
}

.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
    padding: 0 10px 0 0;
    transform: translateX(-50%)
}

.ant-calendar-range .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
    color: #1890ff;
    background: #bae7ff;
    border-color: #1890ff
}

.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date {
    color: #fff;
    background: #1890ff;
    border: 1px solid transparent
}

.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover {
    background: #1890ff
}

.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: 0
}

.ant-calendar-range .ant-calendar-input-wrap {
    position: relative;
    height: 34px
}

.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    transition: all .3s;
    height: 24px;
    padding: 4px 0;
    line-height: 24px;
    border: 0;
    box-shadow: none
}

.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-calendar-range .ant-calendar-input:placeholder-shown,
.ant-calendar-range .ant-calendar-time-picker-input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5;
    vertical-align: bottom;
    transition: all .3s, height 0s
}

.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px
}

.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
    height: 24px;
    padding: 1px 7px
}

.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
    box-shadow: none
}

.ant-calendar-range .ant-calendar-time-picker-icon {
    display: none
}

.ant-calendar-range.ant-calendar-week-number {
    width: 574px
}

.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
    width: 286px
}

.ant-calendar-range .ant-calendar-decade-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-year-panel {
    top: 34px
}

.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
    top: 0
}

.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table {
    height: 208px
}

.ant-calendar-range .ant-calendar-in-range-cell {
    position: relative;
    border-radius: 0
}

.ant-calendar-range .ant-calendar-in-range-cell>div {
    position: relative;
    z-index: 1
}

.ant-calendar-range .ant-calendar-in-range-cell:before {
    position: absolute;
    top: 4px;
    right: 0;
    bottom: 4px;
    left: 0;
    display: block;
    background: #e6f7ff;
    border: 0;
    border-radius: 0;
    content: ""
}

.ant-calendar-range .ant-calendar-footer-extra {
    float: left
}

div.ant-calendar-range-quick-selector {
    text-align: left
}

div.ant-calendar-range-quick-selector>a {
    margin-right: 8px
}

.ant-calendar-range .ant-calendar-decade-panel-header,
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header {
    border-bottom: 0
}

.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-decade-panel-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body {
    border-top: 1px solid #e8e8e8
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
    top: 68px;
    z-index: 2;
    width: 100%;
    height: 207px
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
    height: 267px;
    margin-top: -34px
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
    height: 100%;
    padding-top: 40px;
    background: 0 0
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
    display: inline-block;
    height: 100%;
    background-color: #fff;
    border-top: 1px solid #e8e8e8
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
    height: 100%
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
    max-height: 100%
}

.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    margin-right: 8px
}

.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
    height: 22px;
    margin: 8px 12px;
    line-height: 22px
}

.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
    height: 233px
}

.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
    border-top-color: transparent
}

.ant-calendar-time-picker {
    position: absolute;
    top: 40px;
    width: 100%;
    background-color: #fff
}

.ant-calendar-time-picker-panel {
    position: absolute;
    z-index: 1050;
    width: 100%
}

.ant-calendar-time-picker-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0
}

.ant-calendar-time-picker-combobox {
    width: 100%
}

.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
    width: 100%
}

.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
    width: 50%
}

.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
    width: 33.33%
}

.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
    width: 25%
}

.ant-calendar-time-picker-input-wrap {
    display: none
}

.ant-calendar-time-picker-select {
    position: relative;
    float: left;
    height: 226px;
    overflow: hidden;
    font-size: 14px;
    border-right: 1px solid #e8e8e8
}

.ant-calendar-time-picker-select:hover {
    overflow-y: auto
}

.ant-calendar-time-picker-select:first-child {
    margin-left: 0;
    border-left: 0
}

.ant-calendar-time-picker-select:last-child {
    border-right: 0
}

.ant-calendar-time-picker-select ul {
    width: 100%;
    max-height: 206px;
    margin: 0;
    padding: 0;
    list-style: none
}

.ant-calendar-time-picker-select li {
    width: 100%;
    height: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    list-style: none;
    cursor: pointer;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-calendar-time-picker-select li:last-child:after {
    display: block;
    height: 202px;
    content: ""
}

.ant-calendar-time-picker-select li:hover {
    background: #e6f7ff
}

.ant-calendar-time-picker-select li:focus {
    color: #1890ff;
    font-weight: 600;
    outline: 0
}

li.ant-calendar-time-picker-select-option-selected {
    font-weight: 600;
    background: #f5f5f5
}

li.ant-calendar-time-picker-select-option-disabled {
    color: rgba(0, 0, 0, .25)
}

li.ant-calendar-time-picker-select-option-disabled:hover {
    background: 0 0;
    cursor: not-allowed
}

.ant-calendar-time .ant-calendar-day-select {
    display: inline-block;
    padding: 0 2px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    line-height: 34px
}

.ant-calendar-time .ant-calendar-footer {
    position: relative;
    height: auto
}

.ant-calendar-time .ant-calendar-footer-btn {
    text-align: right
}

.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
    float: left;
    margin: 0
}

.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    display: inline-block;
    margin-right: 8px
}

.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
    color: rgba(0, 0, 0, .25)
}

.ant-calendar-month-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: 0
}

.ant-calendar-month-panel>div {
    display: flex;
    flex-direction: column;
    height: 100%
}

.ant-calendar-month-panel-hidden {
    display: none
}

.ant-calendar-month-panel-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative
}

.ant-calendar-month-panel-header a:hover {
    color: #40a9ff
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select {
    display: inline-block;
    padding: 0 2px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    line-height: 40px
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow {
    display: none
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 0 5px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    left: 7px;
    height: 100%
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
    display: none
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
    position: relative;
    left: -3px;
    display: inline-block
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    right: 7px;
    height: 100%
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:after {
    display: none
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:before {
    position: relative;
    left: 3px
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:after {
    display: inline-block
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
    left: 29px;
    height: 100%
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
    display: none
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
    right: 29px;
    height: 100%
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:after {
    display: none
}

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-month-panel-body {
    flex: 1 1
}

.ant-calendar-month-panel-footer {
    border-top: 1px solid #e8e8e8
}

.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
    padding: 0 12px
}

.ant-calendar-month-panel-table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    border-collapse: separate
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    color: #fff;
    background: #1890ff
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
    color: #fff;
    background: #1890ff
}

.ant-calendar-month-panel-cell {
    text-align: center
}

.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
    color: rgba(0, 0, 0, .25);
    background: #f5f5f5;
    cursor: not-allowed
}

.ant-calendar-month-panel-month {
    display: inline-block;
    height: 24px;
    margin: 0 auto;
    padding: 0 8px;
    color: rgba(0, 0, 0, .65);
    line-height: 24px;
    text-align: center;
    background: 0 0;
    border-radius: 2px;
    transition: background .3s ease
}

.ant-calendar-month-panel-month:hover {
    background: #e6f7ff;
    cursor: pointer
}

.ant-calendar-year-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: 0
}

.ant-calendar-year-panel>div {
    display: flex;
    flex-direction: column;
    height: 100%
}

.ant-calendar-year-panel-hidden {
    display: none
}

.ant-calendar-year-panel-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative
}

.ant-calendar-year-panel-header a:hover {
    color: #40a9ff
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select {
    display: inline-block;
    padding: 0 2px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    line-height: 40px
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow {
    display: none
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 0 5px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    left: 7px;
    height: 100%
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:after {
    display: none
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:after {
    position: relative;
    left: -3px;
    display: inline-block
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    right: 7px;
    height: 100%
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:after {
    display: none
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:before {
    position: relative;
    left: 3px
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:after {
    display: inline-block
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
    left: 29px;
    height: 100%
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
    display: none
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
    right: 29px;
    height: 100%
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
    display: none
}

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-year-panel-body {
    flex: 1 1
}

.ant-calendar-year-panel-footer {
    border-top: 1px solid #e8e8e8
}

.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
    padding: 0 12px
}

.ant-calendar-year-panel-table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    border-collapse: separate
}

.ant-calendar-year-panel-cell {
    text-align: center
}

.ant-calendar-year-panel-year {
    display: inline-block;
    height: 24px;
    margin: 0 auto;
    padding: 0 8px;
    color: rgba(0, 0, 0, .65);
    line-height: 24px;
    text-align: center;
    background: 0 0;
    border-radius: 2px;
    transition: background .3s ease
}

.ant-calendar-year-panel-year:hover {
    background: #e6f7ff;
    cursor: pointer
}

.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
    color: #fff;
    background: #1890ff
}

.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
    color: #fff;
    background: #1890ff
}

.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
    color: rgba(0, 0, 0, .25);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-calendar-decade-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;
    outline: 0
}

.ant-calendar-decade-panel-hidden {
    display: none
}

.ant-calendar-decade-panel-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative
}

.ant-calendar-decade-panel-header a:hover {
    color: #40a9ff
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select {
    display: inline-block;
    padding: 0 2px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    line-height: 40px
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow {
    display: none
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 0 5px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    left: 7px;
    height: 100%
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
    display: none
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
    position: relative;
    left: -3px;
    display: inline-block
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    right: 7px;
    height: 100%
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:after {
    display: none
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:before {
    position: relative;
    left: 3px
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:after {
    display: inline-block
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
    left: 29px;
    height: 100%
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:after {
    display: none
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
    right: 29px;
    height: 100%
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(.8);
    transition: all .3s;
    content: ""
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover:before {
    border-color: rgba(0, 0, 0, .65)
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:after {
    display: none
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:before {
    transform: rotate(135deg) scale(.8)
}

.ant-calendar-decade-panel-body {
    flex: 1 1
}

.ant-calendar-decade-panel-footer {
    border-top: 1px solid #e8e8e8
}

.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
    padding: 0 12px
}

.ant-calendar-decade-panel-table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    border-collapse: separate
}

.ant-calendar-decade-panel-cell {
    white-space: nowrap;
    text-align: center
}

.ant-calendar-decade-panel-decade {
    display: inline-block;
    height: 24px;
    margin: 0 auto;
    padding: 0 6px;
    color: rgba(0, 0, 0, .65);
    line-height: 24px;
    text-align: center;
    background: 0 0;
    border-radius: 2px;
    transition: background .3s ease
}

.ant-calendar-decade-panel-decade:hover {
    background: #e6f7ff;
    cursor: pointer
}

.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
    color: #fff;
    background: #1890ff
}

.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
    color: #fff;
    background: #1890ff
}

.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
    color: rgba(0, 0, 0, .25);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-calendar-month .ant-calendar-month-header-wrap {
    position: relative;
    height: 288px
}

.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
    top: 0;
    height: 100%
}

.ant-calendar-week-number-cell {
    opacity: .5
}

.ant-calendar-week-number .ant-calendar-body tr {
    cursor: pointer;
    transition: all .3s
}

.ant-calendar-week-number .ant-calendar-body tr:hover {
    background: #e6f7ff
}

.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
    font-weight: 700;
    background: #bae7ff
}

.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
    color: rgba(0, 0, 0, .65);
    background: 0 0
}

.ant-time-picker-panel {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1050;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
}

.ant-time-picker-panel-inner {
    position: relative;
    left: -2px;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-time-picker-panel-input {
    width: 100%;
    max-width: 154px;
    margin: 0;
    padding: 0;
    line-height: normal;
    border: 0;
    outline: 0;
    cursor: auto
}

.ant-time-picker-panel-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-time-picker-panel-input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-time-picker-panel-input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-time-picker-panel-input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-time-picker-panel-input-wrap {
    position: relative;
    padding: 7px 2px 7px 12px;
    border-bottom: 1px solid #e8e8e8
}

.ant-time-picker-panel-input-invalid {
    border-color: #f5222d
}

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
    max-width: 112px
}

.ant-time-picker-panel-select {
    position: relative;
    float: left;
    width: 56px;
    max-height: 192px;
    overflow: hidden;
    font-size: 14px;
    border-left: 1px solid #e8e8e8
}

.ant-time-picker-panel-select:hover {
    overflow-y: auto
}

.ant-time-picker-panel-select:first-child {
    margin-left: 0;
    border-left: 0
}

.ant-time-picker-panel-select:last-child {
    border-right: 0
}

.ant-time-picker-panel-select:only-child {
    width: 100%
}

.ant-time-picker-panel-select ul {
    width: 56px;
    margin: 0;
    padding: 0 0 160px;
    list-style: none
}

.ant-time-picker-panel-select li {
    width: 100%;
    height: 32px;
    margin: 0;
    padding: 0 0 0 12px;
    line-height: 32px;
    text-align: left;
    list-style: none;
    cursor: pointer;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-time-picker-panel-select li:focus {
    color: #1890ff;
    font-weight: 600;
    outline: 0
}

.ant-time-picker-panel-select li:hover {
    background: #e6f7ff
}

li.ant-time-picker-panel-select-option-selected {
    font-weight: 600;
    background: #f5f5f5
}

li.ant-time-picker-panel-select-option-selected:hover {
    background: #f5f5f5
}

li.ant-time-picker-panel-select-option-disabled {
    color: rgba(0, 0, 0, .25)
}

li.ant-time-picker-panel-select-option-disabled:hover {
    background: 0 0;
    cursor: not-allowed
}

.ant-time-picker-panel-combobox {
    zoom: 1
}

.ant-time-picker-panel-combobox:after,
.ant-time-picker-panel-combobox:before {
    display: table;
    content: ""
}

.ant-time-picker-panel-combobox:after {
    clear: both
}

.ant-time-picker-panel-addon {
    padding: 8px;
    border-top: 1px solid #e8e8e8
}

.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight {
    animation-name: antSlideDownIn
}

.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight {
    animation-name: antSlideUpIn
}

.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
    animation-name: antSlideDownOut
}

.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
    animation-name: antSlideUpOut
}

.ant-time-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 128px;
    outline: 0;
    cursor: text;
    transition: opacity .3s
}

.ant-time-picker-input {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all .3s
}

.ant-time-picker-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-time-picker-input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-time-picker-input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-time-picker-input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-time-picker-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-time-picker-input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-time-picker-input-disabled {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-time-picker-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

textarea.ant-time-picker-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5;
    vertical-align: bottom;
    transition: all .3s, height 0s
}

.ant-time-picker-input-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px
}

.ant-time-picker-input-sm {
    height: 24px;
    padding: 1px 7px
}

.ant-time-picker-input[disabled] {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-time-picker-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

.ant-time-picker-open {
    opacity: 0
}

.ant-time-picker-clear,
.ant-time-picker-icon {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    color: rgba(0, 0, 0, .25);
    line-height: 14px;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-time-picker-clear .ant-time-picker-clock-icon,
.ant-time-picker-icon .ant-time-picker-clock-icon {
    display: block;
    color: rgba(0, 0, 0, .25);
    line-height: 1
}

.ant-time-picker-clear {
    z-index: 2;
    background: #fff;
    opacity: 0;
    pointer-events: none
}

.ant-time-picker-clear:hover {
    color: rgba(0, 0, 0, .45)
}

.ant-time-picker:hover .ant-time-picker-clear {
    opacity: 1;
    pointer-events: auto
}

.ant-time-picker-large .ant-time-picker-input {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px
}

.ant-time-picker-small .ant-time-picker-input {
    height: 24px;
    padding: 1px 7px
}

.ant-time-picker-small .ant-time-picker-clear,
.ant-time-picker-small .ant-time-picker-icon {
    right: 7px
}

@media not all and (min-resolution:0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color:transparent) {
        .ant-input {
            line-height: 1.5
        }
    }
}

.ant-table-wrapper {
    zoom: 1
}

.ant-table-wrapper:after,
.ant-table-wrapper:before {
    display: table;
    content: ""
}

.ant-table-wrapper:after {
    clear: both
}

.ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    clear: both
}

.ant-table-body {
    transition: opacity .3s
}

.ant-table-empty .ant-table-body {
    overflow-x: auto !important;
    overflow-y: hidden !important
}

.ant-table table {
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border-collapse: separate;
    border-spacing: 0
}

.ant-table-layout-fixed table {
    table-layout: fixed
}

.ant-table-thead>tr>th {
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    transition: background .3s ease
}

.ant-table-thead>tr>th[colspan] {
    text-align: center
}

.ant-table-thead>tr>th .ant-table-filter-icon,
.ant-table-thead>tr>th .anticon-filter {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 100%;
    color: #bfbfbf;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    transition: all .3s
}

.ant-table-thead>tr>th .ant-table-filter-icon>svg,
.ant-table-thead>tr>th .anticon-filter>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -6px
}

.ant-table-thead>tr>th .ant-table-filter-selected.anticon-filter {
    color: #1890ff
}

.ant-table-thead>tr>th .ant-table-column-sorter {
    display: table-cell;
    vertical-align: middle
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner {
    height: 1em;
    margin-top: .35em;
    margin-left: .57142857em;
    color: #bfbfbf;
    line-height: 1em;
    text-align: center;
    transition: all .3s
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up {
    display: inline-block;
    font-size: 12px;
    transform: scale(.91666667) rotate(0);
    display: block;
    height: 1em;
    line-height: 1em;
    transition: all .3s
}

:root .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down,
:root .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up {
    font-size: 12px
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on {
    color: #1890ff
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
    margin-top: -.15em
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up {
    height: .5em;
    line-height: .5em
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
    margin-top: .125em
}

.ant-table-thead>tr>th.ant-table-column-has-actions {
    position: relative;
    background-clip: padding-box;
    -webkit-background-clip: border-box
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: 30px !important
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open {
    color: rgba(0, 0, 0, .45);
    background: #e5e5e5
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover {
    color: rgba(0, 0, 0, .45);
    background: #e5e5e5
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active {
    color: rgba(0, 0, 0, .65)
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters {
    cursor: pointer
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: #f2f2f2
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter {
    background: #f2f2f2
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on),
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on) {
    color: rgba(0, 0, 0, .45)
}

.ant-table-thead>tr>th .ant-table-header-column {
    display: inline-block;
    max-width: 100%;
    vertical-align: top
}

.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters {
    display: table
}

.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters>.ant-table-column-title {
    display: table-cell;
    vertical-align: middle
}

.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters>:not(.ant-table-column-sorter) {
    position: relative
}

.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    transition: all .3s;
    content: ""
}

.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters:hover:before {
    background: rgba(0, 0, 0, .04)
}

.ant-table-thead>tr>th.ant-table-column-has-sorters {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-table-thead>tr:first-child>th:first-child {
    border-top-left-radius: 4px
}

.ant-table-thead>tr:first-child>th:last-child {
    border-top-right-radius: 4px
}

.ant-table-thead>tr:not(:last-child)>th[colspan] {
    border-bottom: 0
}

.ant-table-tbody>tr>td {
    border-bottom: 1px solid #e8e8e8;
    transition: all .3s, border 0s
}

.ant-table-tbody>tr,
.ant-table-thead>tr {
    transition: all .3s, height 0s
}

.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: #e6f7ff
}

.ant-table-tbody>tr.ant-table-row-selected>td.ant-table-column-sort,
.ant-table-thead>tr.ant-table-row-selected>td.ant-table-column-sort {
    background: #fafafa
}

.ant-table-tbody>tr:hover.ant-table-row-selected>td,
.ant-table-thead>tr:hover.ant-table-row-selected>td {
    background: #fafafa
}

.ant-table-tbody>tr:hover.ant-table-row-selected>td.ant-table-column-sort,
.ant-table-thead>tr:hover.ant-table-row-selected>td.ant-table-column-sort {
    background: #fafafa
}

.ant-table-thead>tr:hover {
    background: 0 0
}

.ant-table-footer {
    position: relative;
    padding: 8px;
    color: rgba(0, 0, 0, .85);
    background: #fafafa;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px
}

.ant-table-footer:before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fafafa;
    content: ""
}

.ant-table.ant-table-bordered .ant-table-footer {
    border: 1px solid #e8e8e8
}

.ant-table-title {
    position: relative;
    top: 1px;
    padding: 16px 0;
    border-radius: 4px 4px 0 0
}

.ant-table.ant-table-bordered .ant-table-title {
    padding-right: 16px;
    padding-left: 16px;
    border: 1px solid #e8e8e8
}

.ant-table-title+.ant-table-content {
    position: relative;
    border-radius: 4px 4px 0 0
}

.ant-table-bordered .ant-table-title+.ant-table-content,
.ant-table-bordered .ant-table-title+.ant-table-content .ant-table-thead>tr:first-child>th,
.ant-table-bordered .ant-table-title+.ant-table-content table {
    border-radius: 0
}

.ant-table-without-column-header .ant-table-title+.ant-table-content,
.ant-table-without-column-header table {
    border-radius: 0
}

.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border-top: 1px solid #e8e8e8;
    border-radius: 4px
}

.ant-table-tbody>tr.ant-table-row-selected td {
    color: inherit;
    background: #fafafa
}

.ant-table-thead>tr>th.ant-table-column-sort {
    background: #f5f5f5
}

.ant-table-tbody>tr>td.ant-table-column-sort {
    background: rgba(0, 0, 0, .01)
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
    padding: 16px;
    overflow-wrap: break-word
}

.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
    width: 50px;
    min-width: 50px;
    text-align: center
}

.ant-table-header {
    overflow: hidden;
    background: #fafafa
}

.ant-table-header table {
    border-radius: 4px 4px 0 0
}

.ant-table-loading {
    position: relative
}

.ant-table-loading .ant-table-body {
    background: #fff;
    opacity: .5
}

.ant-table-loading .ant-table-spin-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    margin-left: -30px;
    line-height: 20px
}

.ant-table-loading .ant-table-with-pagination {
    margin-top: -20px
}

.ant-table-loading .ant-table-without-pagination {
    margin-top: 10px
}

.ant-table-bordered .ant-table-body>table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table,
.ant-table-bordered .ant-table-header>table {
    border: 1px solid #e8e8e8;
    border-right: 0;
    border-bottom: 0
}

.ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8
}

.ant-table-bordered.ant-table-fixed-header .ant-table-header>table {
    border-bottom: 0
}

.ant-table-bordered.ant-table-fixed-header .ant-table-body>table {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner>table,
.ant-table-bordered.ant-table-fixed-header .ant-table-header+.ant-table-body>table {
    border-top: 0
}

.ant-table-bordered .ant-table-thead>tr:not(:last-child)>th {
    border-bottom: 1px solid #e8e8e8
}

.ant-table-bordered .ant-table-tbody>tr>td,
.ant-table-bordered .ant-table-thead>tr>th {
    border-right: 1px solid #e8e8e8
}

.ant-table-placeholder {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    padding: 16px;
    color: rgba(0, 0, 0, .25);
    font-size: 14px;
    text-align: center;
    background: #fff;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px
}

.ant-table-pagination.ant-pagination {
    float: right;
    margin: 16px 0
}

.ant-table-filter-dropdown {
    position: relative;
    min-width: 96px;
    margin-left: -8px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-table-filter-dropdown .ant-dropdown-menu {
    border: 0;
    border-radius: 4px 4px 0 0;
    box-shadow: none
}

.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
    max-height: 400px;
    overflow-x: hidden
}

.ant-table-filter-dropdown .ant-dropdown-menu-item>label+span {
    padding-right: 0
}

.ant-table-filter-dropdown .ant-dropdown-menu-sub {
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title:after {
    color: #1890ff;
    font-weight: 700;
    text-shadow: 0 0 2px #bae7ff
}

.ant-table-filter-dropdown .ant-dropdown-menu-item {
    overflow: hidden
}

.ant-table-filter-dropdown>.ant-dropdown-menu>.ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown>.ant-dropdown-menu>.ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
    border-radius: 0
}

.ant-table-filter-dropdown-btns {
    padding: 7px 8px;
    overflow: hidden;
    border-top: 1px solid #e8e8e8
}

.ant-table-filter-dropdown-link {
    color: #1890ff
}

.ant-table-filter-dropdown-link:hover {
    color: #40a9ff
}

.ant-table-filter-dropdown-link:active {
    color: #096dd9
}

.ant-table-filter-dropdown-link.confirm {
    float: left
}

.ant-table-filter-dropdown-link.clear {
    float: right
}

.ant-table-selection {
    white-space: nowrap
}

.ant-table-selection-select-all-custom {
    margin-right: 4px !important
}

.ant-table-selection .anticon-down {
    color: #bfbfbf;
    transition: all .3s
}

.ant-table-selection-menu {
    min-width: 96px;
    margin-top: 5px;
    margin-left: -30px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.ant-table-selection-menu .ant-action-down {
    color: #bfbfbf
}

.ant-table-selection-down {
    display: inline-block;
    padding: 0;
    line-height: 1;
    cursor: pointer
}

.ant-table-selection-down:hover .anticon-down {
    color: rgba(0, 0, 0, .6)
}

.ant-table-row-expand-icon {
    color: #1890ff;
    text-decoration: none;
    cursor: pointer;
    transition: color .3s;
    display: inline-block;
    width: 17px;
    height: 17px;
    color: inherit;
    line-height: 13px;
    text-align: center;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    outline: 0;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
    color: #40a9ff
}

.ant-table-row-expand-icon:active {
    color: #096dd9
}

.ant-table-row-expand-icon:active,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
    border-color: currentColor
}

.ant-table-row-expanded:after {
    content: "-"
}

.ant-table-row-collapsed:after {
    content: "+"
}

.ant-table-row-spaced {
    visibility: hidden
}

.ant-table-row-spaced:after {
    content: "."
}

.ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-table-row-cell-ellipsis .ant-table-column-title {
    display: block
}

.ant-table-row-cell-break-word {
    word-wrap: break-word;
    word-break: break-word
}

tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
    background: #fbfbfb
}

tr.ant-table-expanded-row td>.ant-table-wrapper {
    margin: -16px -16px -17px
}

.ant-table .ant-table-row-indent+.ant-table-row-expand-icon {
    margin-right: 8px
}

.ant-table-scroll {
    overflow: auto;
    overflow-x: hidden
}

.ant-table-scroll table {
    min-width: 100%
}

.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) {
    visibility: hidden
}

.ant-table-body-inner {
    height: 100%
}

.ant-table-fixed-header>.ant-table-content>.ant-table-scroll>.ant-table-body {
    position: relative;
    background: #fff
}

.ant-table-fixed-header .ant-table-body-inner {
    overflow: scroll
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
    margin-bottom: -20px;
    padding-bottom: 20px;
    overflow: scroll;
    opacity: .9999
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
    border: solid #e8e8e8;
    border-width: 0 0 1px
}

.ant-table-hide-scrollbar {
    scrollbar-color: transparent transparent
}

.ant-table-hide-scrollbar::-webkit-scrollbar {
    background-color: transparent
}

.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
    border: 1px solid #e8e8e8;
    border-left-width: 0
}

.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead>tr:only-child>th:last-child {
    border-right-color: transparent
}

.ant-table-fixed-left,
.ant-table-fixed-right {
    position: absolute;
    top: 0;
    z-index: auto;
    overflow: hidden;
    border-radius: 0;
    transition: box-shadow .3s ease
}

.ant-table-fixed-left table,
.ant-table-fixed-right table {
    width: auto;
    background: #fff
}

.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
    border-radius: 0
}

.ant-table-fixed-left {
    left: 0;
    box-shadow: 6px 0 6px -4px rgba(0, 0, 0, .15)
}

.ant-table-fixed-left .ant-table-header {
    overflow-y: hidden
}

.ant-table-fixed-left .ant-table-body-inner {
    margin-right: -20px;
    padding-right: 20px
}

.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
    padding-right: 0
}

.ant-table-fixed-left,
.ant-table-fixed-left table {
    border-radius: 4px 0 0 0
}

.ant-table-fixed-left .ant-table-thead>tr>th:last-child {
    border-top-right-radius: 0
}

.ant-table-fixed-right {
    right: 0;
    box-shadow: -6px 0 6px -4px rgba(0, 0, 0, .15)
}

.ant-table-fixed-right,
.ant-table-fixed-right table {
    border-radius: 0 4px 0 0
}

.ant-table-fixed-right .ant-table-expanded-row {
    color: transparent;
    pointer-events: none
}

.ant-table-fixed-right .ant-table-thead>tr>th:first-child {
    border-top-left-radius: 0
}

.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
    box-shadow: none
}

.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
    box-shadow: none
}

.ant-table colgroup>col.ant-table-selection-col {
    width: 60px
}

.ant-table-thead>tr>th.ant-table-selection-column-custom .ant-table-selection {
    margin-right: -15px
}

.ant-table-tbody>tr>td.ant-table-selection-column,
.ant-table-thead>tr>th.ant-table-selection-column {
    text-align: center
}

.ant-table-tbody>tr>td.ant-table-selection-column .ant-radio-wrapper,
.ant-table-thead>tr>th.ant-table-selection-column .ant-radio-wrapper {
    margin-right: 0
}

.ant-table-row[class*=ant-table-row-level-0] .ant-table-selection-column>span {
    display: inline-block
}

.ant-table-filter-dropdown .ant-checkbox-wrapper+span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper+span {
    padding-left: 8px
}

@supports (-moz-appearance:meterbar) {
    .ant-table-thead>tr>th.ant-table-column-has-actions {
        background-clip: padding-box
    }
}

.ant-table-middle>.ant-table-footer,
.ant-table-middle>.ant-table-title {
    padding: 12px 8px
}

.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr>th {
    padding: 12px 8px
}

.ant-table-middle tr.ant-table-expanded-row td>.ant-table-wrapper {
    margin: -12px -8px -13px
}

.ant-table-small {
    border: 1px solid #e8e8e8;
    border-radius: 4px
}

.ant-table-small>.ant-table-footer,
.ant-table-small>.ant-table-title {
    padding: 8px
}

.ant-table-small>.ant-table-title {
    top: 0;
    border-bottom: 1px solid #e8e8e8
}

.ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0 8px
}

.ant-table-small>.ant-table-content>.ant-table-body>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table {
    border: 0
}

.ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-tbody>tr>td,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr>th {
    padding: 8px
}

.ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-thead>tr>th,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr>th {
    background-color: transparent
}

.ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-thead>tr,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-thead>tr,
.ant-table-small>.ant-table-content>.ant-table-header>table>.ant-table-thead>tr,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-thead>tr,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr {
    border-bottom: 1px solid #e8e8e8
}

.ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th.ant-table-column-sort,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th.ant-table-column-sort,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th.ant-table-column-sort,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort,
.ant-table-small>.ant-table-content>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-thead>tr>th.ant-table-column-sort,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort {
    background-color: rgba(0, 0, 0, .01)
}

.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table {
    padding: 0
}

.ant-table-small>.ant-table-content .ant-table-header {
    background-color: #fff;
    border-radius: 4px 4px 0 0
}

.ant-table-small>.ant-table-content .ant-table-placeholder,
.ant-table-small>.ant-table-content .ant-table-row:last-child td {
    border-bottom: 0
}

.ant-table-small.ant-table-bordered {
    border-right: 0
}

.ant-table-small.ant-table-bordered .ant-table-title {
    border: 0;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8
}

.ant-table-small.ant-table-bordered .ant-table-content {
    border-right: 1px solid #e8e8e8
}

.ant-table-small.ant-table-bordered .ant-table-footer {
    border: 0;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8
}

.ant-table-small.ant-table-bordered .ant-table-footer:before {
    display: none
}

.ant-table-small.ant-table-bordered .ant-table-placeholder {
    border-right: 0;
    border-bottom: 0;
    border-left: 0
}

.ant-table-small.ant-table-bordered .ant-table-tbody>tr>td:last-child,
.ant-table-small.ant-table-bordered .ant-table-thead>tr:only-child>th:last-child {
    border-right: none
}

.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody>tr>td:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead>tr>th:last-child {
    border-right: 1px solid #e8e8e8
}

.ant-table-small.ant-table-bordered .ant-table-fixed-right {
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8
}

.ant-table-small tr.ant-table-expanded-row td>.ant-table-wrapper {
    margin: -8px -8px -9px
}

.ant-table-small.ant-table-fixed-header>.ant-table-content>.ant-table-scroll>.ant-table-body {
    border-radius: 0 0 4px 4px
}

.ant-radio-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    line-height: unset
}

.ant-radio-wrapper {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    margin: 0 8px 0 0;
    white-space: nowrap;
    cursor: pointer
}

.ant-radio {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: sub;
    outline: 0;
    cursor: pointer
}

.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
    border-color: #1890ff
}

.ant-radio-input:focus+.ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .08)
}

.ant-radio-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 50%;
    visibility: hidden;
    animation: antRadioEffect .36s ease-in-out;
    animation-fill-mode: both;
    content: ""
}

.ant-radio-wrapper:hover .ant-radio:after,
.ant-radio:hover:after {
    visibility: visible
}

.ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 100px;
    transition: all .3s
}

.ant-radio-inner:after {
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    transform: scale(0);
    opacity: 0;
    transition: all .3s cubic-bezier(.78, .14, .15, .86);
    content: " "
}

.ant-radio-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0
}

.ant-radio-checked .ant-radio-inner {
    border-color: #1890ff
}

.ant-radio-checked .ant-radio-inner:after {
    transform: scale(1);
    opacity: 1;
    transition: all .3s cubic-bezier(.78, .14, .15, .86)
}

.ant-radio-disabled .ant-radio-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
    cursor: not-allowed
}

.ant-radio-disabled .ant-radio-inner:after {
    background-color: rgba(0, 0, 0, .2)
}

.ant-radio-disabled .ant-radio-input {
    cursor: not-allowed
}

.ant-radio-disabled+span {
    color: rgba(0, 0, 0, .25);
    cursor: not-allowed
}

span.ant-radio+* {
    padding-right: 8px;
    padding-left: 8px
}

.ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, .65);
    line-height: 30px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1.02px solid #d9d9d9;
    border-left: 0;
    cursor: pointer;
    transition: color .3s, background .3s, border-color .3s
}

.ant-radio-button-wrapper a {
    color: rgba(0, 0, 0, .65)
}

.ant-radio-button-wrapper>.ant-radio-button {
    display: block;
    width: 0;
    height: 0;
    margin-left: 0
}

.ant-radio-group-large .ant-radio-button-wrapper {
    height: 40px;
    font-size: 16px;
    line-height: 38px
}

.ant-radio-group-small .ant-radio-button-wrapper {
    height: 24px;
    padding: 0 7px;
    line-height: 22px
}

.ant-radio-button-wrapper:not(:first-child):before {
    position: absolute;
    top: 0;
    left: -1px;
    display: block;
    width: 1px;
    height: 100%;
    background-color: #d9d9d9;
    content: ""
}

.ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 4px 0 0 4px
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0
}

.ant-radio-button-wrapper:first-child:last-child {
    border-radius: 4px
}

.ant-radio-button-wrapper:hover {
    position: relative;
    color: #1890ff
}

.ant-radio-button-wrapper:focus-within {
    outline: 3px solid rgba(24, 144, 255, .06)
}

.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type=checkbox],
.ant-radio-button-wrapper input[type=radio] {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #fff;
    border-color: #1890ff;
    box-shadow: -1px 0 0 0 #1890ff
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: #1890ff !important;
    opacity: .1
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #1890ff;
    box-shadow: none !important
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #40a9ff;
    border-color: #40a9ff;
    box-shadow: -1px 0 0 0 #40a9ff
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #096dd9;
    border-color: #096dd9;
    box-shadow: -1px 0 0 0 #096dd9
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: 3px solid rgba(24, 144, 255, .06)
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #fff;
    background: #096dd9;
    border-color: #096dd9
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: 3px solid rgba(24, 144, 255, .06)
}

.ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed
}

.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9
}

.ant-radio-button-wrapper-disabled:first-child {
    border-left-color: #d9d9d9
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: #fff;
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    box-shadow: none
}

@keyframes antRadioEffect {
    0% {
        transform: scale(1);
        opacity: .5
    }

    to {
        transform: scale(1.6);
        opacity: 0
    }
}

@supports (-moz-appearance:meterbar) and (background-blend-mode:difference, normal) {
    .ant-radio {
        vertical-align: text-bottom
    }
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-suffix {
    margin-left: 4px;
    color: rgba(0, 0, 0, .65);
    font-size: 16px;
    font-style: normal
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoTitle {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, .65);
    font-size: 16px;
    transition: all .3s
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoSubTitle {
    height: 22px;
    overflow: hidden;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoValue {
    margin-top: 4px;
    overflow: hidden;
    font-size: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoValue>span {
    display: inline-block;
    height: 32px;
    margin-right: 32px;
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 32px
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoValue .antd-pro-pages-dashboard-analysis-components-number-info-index-subTotal {
    margin-right: 0;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    vertical-align: top
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoValue .antd-pro-pages-dashboard-analysis-components-number-info-index-subTotal i {
    margin-left: 4px;
    font-size: 12px;
    transform: scale(.82)
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoValue .antd-pro-pages-dashboard-analysis-components-number-info-index-subTotal .anticon-caret-up {
    color: #f5222d
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfo .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoValue .antd-pro-pages-dashboard-analysis-components-number-info-index-subTotal .anticon-caret-down {
    color: #52c41a
}

.antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfolight .antd-pro-pages-dashboard-analysis-components-number-info-index-numberInfoValue>span {
    color: rgba(0, 0, 0, .65)
}

.ant-statistic {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum"
}

.ant-statistic-title {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

.ant-statistic-content {
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
}

.ant-statistic-content-value-decimal {
    font-size: 16px
}

.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
    display: inline-block
}

.ant-statistic-content-prefix {
    margin-right: 4px
}

.ant-statistic-content-suffix {
    margin-left: 4px;
    font-size: 16px
}

.antd-pro-pages-dashboard-monitor-components-charts-index-miniChart {
    position: relative;
    width: 100%
}

.antd-pro-pages-dashboard-monitor-components-charts-index-miniChart .antd-pro-pages-dashboard-monitor-components-charts-index-chartContent {
    position: absolute;
    bottom: -28px;
    width: 100%
}

.antd-pro-pages-dashboard-monitor-components-charts-index-miniChart .antd-pro-pages-dashboard-monitor-components-charts-index-chartContent>div {
    margin: 0 -5px;
    overflow: hidden
}

.antd-pro-pages-dashboard-monitor-components-charts-index-miniChart .antd-pro-pages-dashboard-monitor-components-charts-index-chartLoading {
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -7px
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie {
    position: relative
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-chart {
    position: relative
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie.antd-pro-pages-dashboard-monitor-components-charts-pie-index-hasLegend .antd-pro-pages-dashboard-monitor-components-charts-pie-index-chart {
    width: calc(100% - 240px)
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-legend {
    position: absolute;
    top: 50%;
    right: 0;
    min-width: 200px;
    margin: 0 20px;
    padding: 0;
    list-style: none;
    transform: translateY(-50%)
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-legend li {
    height: 22px;
    margin-bottom: 16px;
    line-height: 22px;
    cursor: pointer
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-legend li:last-child {
    margin-bottom: 0
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 8px
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-line {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin-right: 8px;
    background-color: #e8e8e8
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-legendTitle {
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-percent {
    color: rgba(0, 0, 0, .45)
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-value {
    position: absolute;
    right: 0
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-title {
    margin-bottom: 8px
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-total {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 62px;
    text-align: center;
    transform: translate(-50%, -50%)
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-total>h4 {
    height: 22px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .45);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-pie .antd-pro-pages-dashboard-monitor-components-charts-pie-index-total>p {
    display: block;
    height: 32px;
    color: rgba(0, 0, 0, .85);
    font-size: 1.2em;
    line-height: 32px;
    white-space: nowrap
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-legendBlock.antd-pro-pages-dashboard-monitor-components-charts-pie-index-hasLegend .antd-pro-pages-dashboard-monitor-components-charts-pie-index-chart {
    width: 100%;
    margin: 0 0 32px
}

.antd-pro-pages-dashboard-monitor-components-charts-pie-index-legendBlock .antd-pro-pages-dashboard-monitor-components-charts-pie-index-legend {
    position: relative;
    transform: none
}

.antd-pro-pages-dashboard-monitor-components-charts-tag-cloud-index-tagCloud {
    overflow: hidden
}

.antd-pro-pages-dashboard-monitor-components-charts-tag-cloud-index-tagCloud canvas {
    transform-origin: 0 0
}

.antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-waterWave {
    position: relative;
    display: inline-block;
    transform-origin: left
}

.antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-text {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    text-align: center
}

.antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-text span {
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-text h4 {
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 32px
}

.antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-waterWave .antd-pro-pages-dashboard-monitor-components-charts-water-wave-index-waterWaveCanvasWrapper {
    transform: scale(.5);
    transform-origin: 0 0
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-activeChart {
    position: relative
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-activeChartGrid p {
    position: absolute;
    top: 80px
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-activeChartGrid p:last-child {
    top: 115px
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-activeChartLegend {
    position: relative;
    height: 20px;
    margin-top: 8px;
    font-size: 0;
    line-height: 20px
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-activeChartLegend span {
    display: inline-block;
    width: 33.33%;
    font-size: 12px;
    text-align: center
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-activeChartLegend span:first-child {
    text-align: left
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-activeChartLegend span:last-child {
    text-align: right
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-dashedLine {
    position: relative;
    top: -70px;
    left: -3px;
    height: 1px
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-dashedLine .antd-pro-pages-dashboard-monitor-components-active-chart-index-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, transparent 50%, #e9e9e9 0);
    background-size: 6px
}

.antd-pro-pages-dashboard-monitor-components-active-chart-index-dashedLine:last-child {
    top: -36px
}

.antd-pro-pages-dashboard-monitor-style-mapChart {
    height: 452px;
    padding-top: 24px;
    text-align: center
}

.antd-pro-pages-dashboard-monitor-style-mapChart img {
    display: inline-block;
    max-width: 100%;
    max-height: 437px
}

.antd-pro-pages-dashboard-monitor-style-pieCard .pie-stat {
    font-size: 24px !important
}

@media screen and (max-width:992px) {
    .antd-pro-pages-dashboard-monitor-style-mapChart {
        height: auto
    }
}

.ant-skeleton {
    display: table;
    width: 100%
}

.ant-skeleton-header {
    display: table-cell;
    padding-right: 16px;
    vertical-align: top
}

.ant-skeleton-header .ant-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: #f2f2f2;
    width: 32px;
    height: 32px;
    line-height: 32px
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%
}

.ant-skeleton-header .ant-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%
}

.ant-skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top
}

.ant-skeleton-content .ant-skeleton-title {
    width: 100%;
    height: 16px;
    margin-top: 16px;
    background: #f2f2f2
}

.ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
    margin-top: 24px
}

.ant-skeleton-content .ant-skeleton-paragraph {
    padding: 0
}

.ant-skeleton-content .ant-skeleton-paragraph>li {
    width: 100%;
    height: 16px;
    list-style: none;
    background: #f2f2f2
}

.ant-skeleton-content .ant-skeleton-paragraph>li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 61%
}

.ant-skeleton-content .ant-skeleton-paragraph>li+li {
    margin-top: 16px
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    margin-top: 12px
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
    margin-top: 28px
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite
}

@keyframes ant-skeleton-loading {
    0% {
        background-position: 100% 50%
    }

    to {
        background-position: 0 50%
    }
}

.antd-pro-pages-dashboard-workplace-components-radar-index-radar .antd-pro-pages-dashboard-workplace-components-radar-index-legend {
    margin-top: 16px
}

.antd-pro-pages-dashboard-workplace-components-radar-index-radar .antd-pro-pages-dashboard-workplace-components-radar-index-legend .antd-pro-pages-dashboard-workplace-components-radar-index-legendItem {
    position: relative;
    color: rgba(0, 0, 0, .45);
    line-height: 22px;
    text-align: center;
    cursor: pointer
}

.antd-pro-pages-dashboard-workplace-components-radar-index-radar .antd-pro-pages-dashboard-workplace-components-radar-index-legend .antd-pro-pages-dashboard-workplace-components-radar-index-legendItem p {
    margin: 0
}

.antd-pro-pages-dashboard-workplace-components-radar-index-radar .antd-pro-pages-dashboard-workplace-components-radar-index-legend .antd-pro-pages-dashboard-workplace-components-radar-index-legendItem h6 {
    margin-top: 4px;
    margin-bottom: 0;
    padding-left: 16px;
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 32px
}

.antd-pro-pages-dashboard-workplace-components-radar-index-radar .antd-pro-pages-dashboard-workplace-components-radar-index-legend .antd-pro-pages-dashboard-workplace-components-radar-index-legendItem:after {
    position: absolute;
    top: 8px;
    right: 0;
    width: 1px;
    height: 40px;
    background-color: #e8e8e8;
    content: ""
}

.antd-pro-pages-dashboard-workplace-components-radar-index-radar .antd-pro-pages-dashboard-workplace-components-radar-index-legend>:last-child .antd-pro-pages-dashboard-workplace-components-radar-index-legendItem:after {
    display: none
}

.antd-pro-pages-dashboard-workplace-components-radar-index-radar .antd-pro-pages-dashboard-workplace-components-radar-index-legend .antd-pro-pages-dashboard-workplace-components-radar-index-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 6px;
    border-radius: 6px
}

.antd-pro-pages-dashboard-workplace-components-editable-link-group-index-linkGroup {
    padding: 20px 0 8px 24px;
    font-size: 0
}

.antd-pro-pages-dashboard-workplace-components-editable-link-group-index-linkGroup>a {
    display: inline-block;
    width: 25%;
    margin-bottom: 13px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px
}

.antd-pro-pages-dashboard-workplace-components-editable-link-group-index-linkGroup>a:hover {
    color: #1890ff
}

.antd-pro-pages-dashboard-workplace-style-activitiesList {
    padding: 0 24px 8px
}

.antd-pro-pages-dashboard-workplace-style-activitiesList .antd-pro-pages-dashboard-workplace-style-username {
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-dashboard-workplace-style-activitiesList .antd-pro-pages-dashboard-workplace-style-event {
    font-weight: 400
}

.antd-pro-pages-dashboard-workplace-style-pageHeaderContent {
    display: flex
}

.antd-pro-pages-dashboard-workplace-style-pageHeaderContent .antd-pro-pages-dashboard-workplace-style-avatar {
    flex: 0 1 72px
}

.antd-pro-pages-dashboard-workplace-style-pageHeaderContent .antd-pro-pages-dashboard-workplace-style-avatar>span {
    display: block;
    width: 72px;
    height: 72px;
    border-radius: 72px
}

.antd-pro-pages-dashboard-workplace-style-pageHeaderContent .antd-pro-pages-dashboard-workplace-style-content {
    position: relative;
    top: 4px;
    flex: 1 1 auto;
    margin-left: 24px;
    color: rgba(0, 0, 0, .45);
    line-height: 22px
}

.antd-pro-pages-dashboard-workplace-style-pageHeaderContent .antd-pro-pages-dashboard-workplace-style-content .antd-pro-pages-dashboard-workplace-style-contentTitle {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px
}

.antd-pro-pages-dashboard-workplace-style-extraContent {
    zoom: 1;
    float: right;
    white-space: nowrap
}

.antd-pro-pages-dashboard-workplace-style-extraContent:after,
.antd-pro-pages-dashboard-workplace-style-extraContent:before {
    display: table;
    content: " "
}

.antd-pro-pages-dashboard-workplace-style-extraContent:after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden
}

.antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem {
    position: relative;
    display: inline-block;
    padding: 0 32px
}

.antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem>p:first-child {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem>p {
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    line-height: 38px
}

.antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem>p>span {
    color: rgba(0, 0, 0, .45);
    font-size: 20px
}

.antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem:after {
    position: absolute;
    top: 8px;
    right: 0;
    width: 1px;
    height: 40px;
    background-color: #e8e8e8;
    content: ""
}

.antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem:last-child {
    padding-right: 0
}

.antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem:last-child:after {
    display: none
}

.antd-pro-pages-dashboard-workplace-style-members a {
    display: block;
    height: 24px;
    margin: 12px 0;
    color: rgba(0, 0, 0, .65);
    transition: all .3s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all
}

.antd-pro-pages-dashboard-workplace-style-members a .antd-pro-pages-dashboard-workplace-style-member {
    margin-left: 12px;
    font-size: 14px;
    line-height: 24px;
    vertical-align: top
}

.antd-pro-pages-dashboard-workplace-style-members a:hover {
    color: #1890ff
}

.antd-pro-pages-dashboard-workplace-style-projectList .ant-card-meta-description {
    height: 44px;
    overflow: hidden;
    color: rgba(0, 0, 0, .45);
    line-height: 22px
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-cardTitle {
    font-size: 0
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-cardTitle a {
    display: inline-block;
    height: 24px;
    margin-left: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 24px;
    vertical-align: top
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-cardTitle a:hover {
    color: #1890ff
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-projectGrid {
    width: 33.33%
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-projectItemContent {
    display: flex;
    height: 20px;
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-projectItemContent a {
    display: inline-block;
    flex: 1 1;
    color: rgba(0, 0, 0, .45);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-projectItemContent a:hover {
    color: #1890ff
}

.antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-projectItemContent .antd-pro-pages-dashboard-workplace-style-datetime {
    flex: 0 0 auto;
    float: right;
    color: rgba(0, 0, 0, .25)
}

.antd-pro-pages-dashboard-workplace-style-datetime {
    color: rgba(0, 0, 0, .25)
}

@media screen and (max-width:1200px) and (min-width:992px) {
    .antd-pro-pages-dashboard-workplace-style-activeCard {
        margin-bottom: 24px
    }

    .antd-pro-pages-dashboard-workplace-style-members {
        margin-bottom: 0
    }

    .antd-pro-pages-dashboard-workplace-style-extraContent {
        margin-left: -44px
    }

    .antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem {
        padding: 0 16px
    }
}

@media screen and (max-width:992px) {
    .antd-pro-pages-dashboard-workplace-style-activeCard {
        margin-bottom: 24px
    }

    .antd-pro-pages-dashboard-workplace-style-members {
        margin-bottom: 0
    }

    .antd-pro-pages-dashboard-workplace-style-extraContent {
        float: none;
        margin-right: 0
    }

    .antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem {
        padding: 0 16px;
        text-align: left
    }

    .antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem:after {
        display: none
    }
}

@media screen and (max-width:768px) {
    .antd-pro-pages-dashboard-workplace-style-extraContent {
        margin-left: -16px
    }

    .antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-projectGrid {
        width: 50%
    }
}

@media screen and (max-width:576px) {
    .antd-pro-pages-dashboard-workplace-style-pageHeaderContent {
        display: block
    }

    .antd-pro-pages-dashboard-workplace-style-pageHeaderContent .antd-pro-pages-dashboard-workplace-style-content {
        margin-left: 0
    }

    .antd-pro-pages-dashboard-workplace-style-extraContent .antd-pro-pages-dashboard-workplace-style-statItem {
        float: none
    }
}

@media screen and (max-width:480px) {
    .antd-pro-pages-dashboard-workplace-style-projectList .antd-pro-pages-dashboard-workplace-style-projectGrid {
        width: 100%
    }
}

.ant-input-number {
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    transition: all .3s;
    display: inline-block;
    width: 90px;
    margin: 0;
    padding: 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px
}

.ant-input-number::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-input-number:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-input-number::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-input-number:placeholder-shown {
    text-overflow: ellipsis
}

.ant-input-number:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-input-number[disabled] {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-input-number[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

textarea.ant-input-number {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5;
    vertical-align: bottom;
    transition: all .3s, height 0s
}

.ant-input-number-lg {
    height: 40px;
    padding: 6px 11px
}

.ant-input-number-sm {
    height: 24px;
    padding: 1px 7px
}

.ant-input-number-handler {
    position: relative;
    display: block;
    width: 100%;
    height: 50%;
    overflow: hidden;
    color: rgba(0, 0, 0, .45);
    font-weight: 700;
    line-height: 0;
    text-align: center;
    transition: all .1s linear
}

.ant-input-number-handler:active {
    background: #f4f4f4
}

.ant-input-number-handler:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler:hover .ant-input-number-handler-up-inner {
    color: #40a9ff
}

.ant-input-number-handler-down-inner,
.ant-input-number-handler-up-inner {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    width: 12px;
    height: 12px;
    color: rgba(0, 0, 0, .45);
    line-height: 12px;
    transition: all .1s linear;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-input-number-handler-down-inner>*,
.ant-input-number-handler-up-inner>* {
    line-height: 1
}

.ant-input-number-handler-down-inner svg,
.ant-input-number-handler-up-inner svg {
    display: inline-block
}

.ant-input-number-handler-down-inner:before,
.ant-input-number-handler-up-inner:before {
    display: none
}

.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon {
    display: block
}

.ant-input-number:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important
}

.ant-input-number-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2)
}

.ant-input-number-disabled {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important
}

.ant-input-number-disabled .ant-input-number-input {
    cursor: not-allowed
}

.ant-input-number-disabled .ant-input-number-handler-wrap {
    display: none
}

.ant-input-number-input {
    width: 100%;
    height: 30px;
    padding: 0 11px;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    outline: 0;
    transition: all .3s linear;
    -moz-appearance: textfield !important
}

.ant-input-number-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1
}

.ant-input-number-input:-ms-input-placeholder {
    color: #bfbfbf
}

.ant-input-number-input::-webkit-input-placeholder {
    color: #bfbfbf
}

.ant-input-number-input:placeholder-shown {
    text-overflow: ellipsis
}

.ant-input-number-input[type=number]::-webkit-inner-spin-button,
.ant-input-number-input[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none
}

.ant-input-number-lg {
    padding: 0;
    font-size: 16px
}

.ant-input-number-lg input {
    height: 38px
}

.ant-input-number-sm {
    padding: 0
}

.ant-input-number-sm input {
    height: 22px;
    padding: 0 7px
}

.ant-input-number-handler-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 100%;
    background: #fff;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 4px 4px 0;
    opacity: 0;
    transition: opacity .24s linear .1s
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
    display: inline-block;
    font-size: 12px;
    transform: scale(.58333333) rotate(0);
    min-width: auto;
    margin-right: 0
}

:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
    font-size: 12px
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 40%
}

.ant-input-number:hover .ant-input-number-handler-wrap {
    opacity: 1
}

.ant-input-number-handler-up {
    border-top-right-radius: 4px;
    cursor: pointer
}

.ant-input-number-handler-up-inner {
    top: 50%;
    margin-top: -5px;
    text-align: center
}

.ant-input-number-handler-up:hover {
    height: 60% !important
}

.ant-input-number-handler-down {
    top: 0;
    border-top: 1px solid #d9d9d9;
    border-bottom-right-radius: 4px;
    cursor: pointer
}

.ant-input-number-handler-down-inner {
    top: 50%;
    margin-top: -6px;
    text-align: center
}

.ant-input-number-handler-down:hover {
    height: 60% !important
}

.ant-input-number-handler-down-disabled,
.ant-input-number-handler-up-disabled {
    cursor: not-allowed
}

.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
    color: rgba(0, 0, 0, .25)
}

.antd-pro-pages-form-basic-form-style-card {
    margin-bottom: 24px
}

.antd-pro-pages-form-basic-form-style-heading {
    margin: 0 0 16px;
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-form-basic-form-style-steps.ant-steps {
    max-width: 750px;
    margin: 16px auto
}

.antd-pro-pages-form-basic-form-style-errorIcon {
    margin-right: 24px;
    color: #f5222d;
    cursor: pointer
}

.antd-pro-pages-form-basic-form-style-errorIcon i {
    margin-right: 4px
}

.antd-pro-pages-form-basic-form-style-errorPopover .ant-popover-inner-content {
    min-width: 256px;
    max-height: 290px;
    padding: 0;
    overflow: auto
}

.antd-pro-pages-form-basic-form-style-errorListItem {
    padding: 8px 16px;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all .3s
}

.antd-pro-pages-form-basic-form-style-errorListItem:hover {
    background: #e6f7ff
}

.antd-pro-pages-form-basic-form-style-errorListItem:last-child {
    border: 0
}

.antd-pro-pages-form-basic-form-style-errorListItem .antd-pro-pages-form-basic-form-style-errorIcon {
    float: left;
    margin-top: 4px;
    margin-right: 12px;
    padding-bottom: 22px;
    color: #f5222d
}

.antd-pro-pages-form-basic-form-style-errorListItem .antd-pro-pages-form-basic-form-style-errorField {
    margin-top: 2px;
    color: rgba(0, 0, 0, .45);
    font-size: 12px
}

.antd-pro-pages-form-basic-form-style-editable td {
    padding-top: 13px !important;
    padding-bottom: 12.5px !important
}

.antd-pro-pages-form-basic-form-style-advancedForm+div {
    padding-bottom: 64px
}

.antd-pro-pages-form-basic-form-style-advancedForm .ant-form .ant-row:last-child .ant-form-item {
    margin-bottom: 24px
}

.antd-pro-pages-form-basic-form-style-advancedForm .ant-table td {
    transition: none !important
}

.antd-pro-pages-form-basic-form-style-optional {
    color: rgba(0, 0, 0, .45);
    font-style: normal
}

.ant-steps {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    display: flex;
    width: 100%;
    font-size: 0
}

.ant-steps-item {
    position: relative;
    display: inline-block;
    flex: 1 1;
    overflow: hidden;
    vertical-align: top
}

.ant-steps-item-container {
    outline: 0
}

.ant-steps-item:last-child {
    flex: none
}

.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after,
.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-tail {
    display: none
}

.ant-steps-item-content,
.ant-steps-item-icon {
    display: inline-block;
    vertical-align: top
}

.ant-steps-item-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    line-height: 32px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: 32px;
    transition: background-color .3s, border-color .3s
}

.ant-steps-item-icon>.ant-steps-icon {
    position: relative;
    top: -1px;
    color: #1890ff;
    line-height: 1
}

.ant-steps-item-tail {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    padding: 0 10px
}

.ant-steps-item-tail:after {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: #e8e8e8;
    border-radius: 1px;
    transition: background .3s;
    content: ""
}

.ant-steps-item-title {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    color: rgba(0, 0, 0, .65);
    font-size: 16px;
    line-height: 32px
}

.ant-steps-item-title:after {
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #e8e8e8;
    content: ""
}

.ant-steps-item-subtitle {
    display: inline;
    margin-left: 8px;
    color: rgba(0, 0, 0, .45);
    font-weight: 400;
    font-size: 14px
}

.ant-steps-item-description {
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff;
    border-color: rgba(0, 0, 0, .25)
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    color: rgba(0, 0, 0, .25)
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: rgba(0, 0, 0, .25)
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, .45)
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    background-color: #e8e8e8
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: rgba(0, 0, 0, .45)
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #e8e8e8
}

.ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: #1890ff
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: #1890ff
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #1890ff
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, .85)
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    background-color: #e8e8e8
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: rgba(0, 0, 0, .65)
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #e8e8e8
}

.ant-steps-item-process .ant-steps-item-icon {
    background: #1890ff
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: #fff
}

.ant-steps-item-process .ant-steps-item-title {
    font-weight: 500
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #1890ff
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #1890ff
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #1890ff
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, .65)
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    background-color: #1890ff
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: rgba(0, 0, 0, .45)
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #1890ff
}

.ant-steps-item-error .ant-steps-item-icon {
    background-color: #fff;
    border-color: #f5222d
}

.ant-steps-item-error .ant-steps-item-icon>.ant-steps-icon {
    color: #f5222d
}

.ant-steps-item-error .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #f5222d
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #f5222d
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    background-color: #e8e8e8
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: #f5222d
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #e8e8e8
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
    background: #f5222d
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role=button] {
    cursor: pointer
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role=button] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role=button] .ant-steps-item-icon .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role=button] .ant-steps-item-title {
    transition: color .3s
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role=button]:hover .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role=button]:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role=button]:hover .ant-steps-item-title {
    color: #1890ff
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role=button]:hover .ant-steps-item-icon {
    border-color: #1890ff
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role=button]:hover .ant-steps-item-icon .ant-steps-icon {
    color: #1890ff
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 16px;
    white-space: nowrap
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
    margin-right: 0
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-right: 0
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
    display: none
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 140px;
    white-space: normal
}

.ant-steps-item-custom .ant-steps-item-icon {
    height: auto;
    background: 0 0;
    border: 0
}

.ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
    top: 0;
    left: .5px;
    width: 32px;
    height: 32px;
    font-size: 24px;
    line-height: 32px
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: #1890ff
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    width: auto
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 12px
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
    margin-right: 0
}

.ant-steps-small .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border-radius: 24px
}

.ant-steps-small .ant-steps-item-title {
    padding-right: 12px;
    font-size: 14px;
    line-height: 24px
}

.ant-steps-small .ant-steps-item-title:after {
    top: 12px
}

.ant-steps-small .ant-steps-item-description {
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

.ant-steps-small .ant-steps-item-tail {
    top: 8px
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: 0 0;
    border: 0;
    border-radius: 0
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
    font-size: 24px;
    line-height: 24px;
    transform: none
}

.ant-steps-vertical {
    display: block
}

.ant-steps-vertical .ant-steps-item {
    display: block;
    overflow: visible
}

.ant-steps-vertical .ant-steps-item-icon {
    float: left;
    margin-right: 16px
}

.ant-steps-vertical .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden
}

.ant-steps-vertical .ant-steps-item-title {
    line-height: 32px
}

.ant-steps-vertical .ant-steps-item-description {
    padding-bottom: 12px
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
    width: 1px;
    height: 100%
}

.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
    display: block
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    display: none
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px
}

@media (max-width:480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
        display: block
    }

    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
        display: block;
        overflow: visible
    }

    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
        float: left;
        margin-right: 16px
    }

    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
        display: block;
        min-height: 48px;
        overflow: hidden
    }

    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
        line-height: 32px
    }

    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
        padding-bottom: 12px
    }

    .ant-steps-horizontal.ant-steps-label-horizontal>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
        position: absolute;
        top: 0;
        left: 16px;
        width: 1px;
        height: 100%;
        padding: 38px 0 6px
    }

    .ant-steps-horizontal.ant-steps-label-horizontal>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
        width: 1px;
        height: 100%
    }

    .ant-steps-horizontal.ant-steps-label-horizontal>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
        display: block
    }

    .ant-steps-horizontal.ant-steps-label-horizontal>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
        display: none
    }

    .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
        position: absolute;
        top: 0;
        left: 12px;
        padding: 30px 0 6px
    }

    .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
        line-height: 24px
    }
}

.ant-steps-label-vertical .ant-steps-item {
    overflow: visible
}

.ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 51px;
    padding: 3.5px 24px
}

.ant-steps-label-vertical .ant-steps-item-content {
    display: block;
    width: 104px;
    margin-top: 8px;
    text-align: center
}

.ant-steps-label-vertical .ant-steps-item-icon {
    display: inline-block;
    margin-left: 36px
}

.ant-steps-label-vertical .ant-steps-item-title {
    padding-right: 0
}

.ant-steps-label-vertical .ant-steps-item-title:after {
    display: none
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
    margin-left: 40px
}

.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
    line-height: 1.5
}

.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 2px;
    width: 100%;
    margin: 0 0 0 70px;
    padding: 0
}

.ant-steps-dot .ant-steps-item-tail:after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
    width: calc(100% - 20px);
    height: 3px;
    margin-left: 12px
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 2px
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 67px;
    padding-right: 0;
    line-height: 8px;
    background: 0 0;
    border: 0
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    transition: all .3s
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
    position: absolute;
    top: -12px;
    left: -26px;
    width: 60px;
    height: 32px;
    background: rgba(0, 0, 0, .001);
    content: ""
}

.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 140px
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    width: 10px;
    height: 10px;
    line-height: 10px
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
    top: -1px
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-top: 8px;
    margin-left: 0
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    top: 2px;
    left: -9px;
    margin: 0;
    padding: 22px 0 4px
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 0
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
    left: -2px
}

.ant-steps-navigation {
    padding-top: 12px
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-left: -12px
}

.ant-steps-navigation .ant-steps-item {
    overflow: visible;
    text-align: center
}

.ant-steps-navigation .ant-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    text-align: left;
    transition: opacity .3s
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
    max-width: 140px
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    max-width: 100%;
    padding-right: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title:after {
    display: none
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role=button] {
    cursor: pointer
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role=button]:hover {
    opacity: .85
}

.ant-steps-navigation .ant-steps-item:last-child {
    flex: 1 1
}

.ant-steps-navigation .ant-steps-item:last-child:after {
    display: none
}

.ant-steps-navigation .ant-steps-item:after {
    position: absolute;
    top: 50%;
    left: 100%;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -14px;
    margin-left: -2px;
    border: 1px solid rgba(0, 0, 0, .25);
    border-bottom: none;
    border-left: none;
    transform: rotate(45deg);
    content: ""
}

.ant-steps-navigation .ant-steps-item:before {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 3px;
    background-color: #1890ff;
    transition: width .3s, left .3s;
    transition-timing-function: ease-out;
    content: ""
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active:before {
    left: 0;
    width: 100%
}

.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    margin-left: -16px;
    padding-left: 16px;
    background: #fff
}

.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
    margin-left: -12px;
    padding-left: 12px
}

.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
    overflow: hidden
}

.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot:after {
    right: -200px;
    width: 200px
}

.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot:after,
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot:before {
    position: absolute;
    top: 0;
    left: -10px;
    width: 10px;
    height: 8px;
    background: #fff;
    content: ""
}

.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot:after {
    right: -10px;
    left: auto
}

.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #ccc
}

.antd-pro-pages-form-step-form-components-step1-index-stepForm {
    max-width: 500px;
    margin: 40px auto 0
}

.antd-pro-pages-form-step-form-components-step1-index-stepFormText {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-components-step1-index-stepFormText .ant-form-item-control,
.antd-pro-pages-form-step-form-components-step1-index-stepFormText .ant-form-item-label {
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step1-index-result {
    max-width: 560px;
    margin: 0 auto;
    padding: 24px 0 8px
}

.antd-pro-pages-form-step-form-components-step1-index-desc {
    padding: 0 56px;
    color: rgba(0, 0, 0, .45)
}

.antd-pro-pages-form-step-form-components-step1-index-desc h3 {
    margin: 0 0 12px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    line-height: 32px
}

.antd-pro-pages-form-step-form-components-step1-index-desc h4 {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step1-index-desc p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px
}

@media screen and (max-width:768px) {
    .antd-pro-pages-form-step-form-components-step1-index-desc {
        padding: 0
    }
}

.antd-pro-pages-form-step-form-components-step1-index-information {
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step1-index-information .ant-row:not(:last-child) {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-components-step1-index-information .antd-pro-pages-form-step-form-components-step1-index-label {
    padding-right: 8px;
    color: rgba(0, 0, 0, .85);
    text-align: right
}

@media screen and (max-width:576px) {
    .antd-pro-pages-form-step-form-components-step1-index-information .antd-pro-pages-form-step-form-components-step1-index-label {
        text-align: left
    }
}

.antd-pro-pages-form-step-form-components-step1-index-money {
    font-weight: 500;
    font-size: 20px;
    font-family: Helvetica Neue, sans-serif;
    line-height: 14px
}

.antd-pro-pages-form-step-form-components-step1-index-uppercase {
    font-size: 12px
}

.ant-descriptions-title {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, .85);
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5
}

.ant-descriptions-view {
    width: 100%;
    overflow: hidden;
    border-radius: 4px
}

.ant-descriptions-view table {
    width: 100%;
    table-layout: fixed
}

.ant-descriptions-row>td,
.ant-descriptions-row>th {
    padding-bottom: 16px
}

.ant-descriptions-row:last-child {
    border-bottom: none
}

.ant-descriptions-item-label {
    color: rgba(0, 0, 0, .85);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap
}

.ant-descriptions-item-label:after {
    position: relative;
    top: -.5px;
    margin: 0 8px 0 2px;
    content: " "
}

.ant-descriptions-item-colon:after {
    content: ":"
}

.ant-descriptions-item-no-label:after {
    margin: 0;
    content: ""
}

.ant-descriptions-item-content {
    display: table-cell;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5
}

.ant-descriptions-item {
    padding-bottom: 0
}

.ant-descriptions-item>span {
    display: inline-block
}

.ant-descriptions-middle .ant-descriptions-row>td,
.ant-descriptions-middle .ant-descriptions-row>th {
    padding-bottom: 12px
}

.ant-descriptions-small .ant-descriptions-row>td,
.ant-descriptions-small .ant-descriptions-row>th {
    padding-bottom: 8px
}

.ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid #e8e8e8
}

.ant-descriptions-bordered .ant-descriptions-view>table {
    table-layout: auto
}

.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 16px 24px;
    border-right: 1px solid #e8e8e8
}

.ant-descriptions-bordered .ant-descriptions-item-content:last-child,
.ant-descriptions-bordered .ant-descriptions-item-label:last-child {
    border-right: none
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: #fafafa
}

.ant-descriptions-bordered .ant-descriptions-item-label:after {
    display: none
}

.ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #e8e8e8
}

.ant-descriptions-bordered .ant-descriptions-row:last-child {
    border-bottom: none
}

.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label {
    padding: 12px 24px
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
    padding: 8px 16px
}

.antd-pro-pages-form-step-form-components-step2-index-stepForm {
    max-width: 500px;
    margin: 40px auto 0
}

.antd-pro-pages-form-step-form-components-step2-index-stepFormText {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-components-step2-index-stepFormText .ant-form-item-control,
.antd-pro-pages-form-step-form-components-step2-index-stepFormText .ant-form-item-label {
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step2-index-result {
    max-width: 560px;
    margin: 0 auto;
    padding: 24px 0 8px
}

.antd-pro-pages-form-step-form-components-step2-index-desc {
    padding: 0 56px;
    color: rgba(0, 0, 0, .45)
}

.antd-pro-pages-form-step-form-components-step2-index-desc h3 {
    margin: 0 0 12px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    line-height: 32px
}

.antd-pro-pages-form-step-form-components-step2-index-desc h4 {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step2-index-desc p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px
}

@media screen and (max-width:768px) {
    .antd-pro-pages-form-step-form-components-step2-index-desc {
        padding: 0
    }
}

.antd-pro-pages-form-step-form-components-step2-index-information {
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step2-index-information .ant-row:not(:last-child) {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-components-step2-index-information .antd-pro-pages-form-step-form-components-step2-index-label {
    padding-right: 8px;
    color: rgba(0, 0, 0, .85);
    text-align: right
}

@media screen and (max-width:576px) {
    .antd-pro-pages-form-step-form-components-step2-index-information .antd-pro-pages-form-step-form-components-step2-index-label {
        text-align: left
    }
}

.antd-pro-pages-form-step-form-components-step2-index-money {
    font-weight: 500;
    font-size: 20px;
    font-family: Helvetica Neue, sans-serif;
    line-height: 14px
}

.antd-pro-pages-form-step-form-components-step2-index-uppercase {
    font-size: 12px
}

.antd-pro-pages-form-step-form-components-step3-index-stepForm {
    max-width: 500px;
    margin: 40px auto 0
}

.antd-pro-pages-form-step-form-components-step3-index-stepFormText {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-components-step3-index-stepFormText .ant-form-item-control,
.antd-pro-pages-form-step-form-components-step3-index-stepFormText .ant-form-item-label {
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step3-index-result {
    max-width: 560px;
    margin: 0 auto;
    padding: 24px 0 8px
}

.antd-pro-pages-form-step-form-components-step3-index-desc {
    padding: 0 56px;
    color: rgba(0, 0, 0, .45)
}

.antd-pro-pages-form-step-form-components-step3-index-desc h3 {
    margin: 0 0 12px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    line-height: 32px
}

.antd-pro-pages-form-step-form-components-step3-index-desc h4 {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step3-index-desc p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px
}

@media screen and (max-width:768px) {
    .antd-pro-pages-form-step-form-components-step3-index-desc {
        padding: 0
    }
}

.antd-pro-pages-form-step-form-components-step3-index-information {
    line-height: 22px
}

.antd-pro-pages-form-step-form-components-step3-index-information .ant-row:not(:last-child) {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-components-step3-index-information .antd-pro-pages-form-step-form-components-step3-index-label {
    padding-right: 8px;
    color: rgba(0, 0, 0, .85);
    text-align: right
}

@media screen and (max-width:576px) {
    .antd-pro-pages-form-step-form-components-step3-index-information .antd-pro-pages-form-step-form-components-step3-index-label {
        text-align: left
    }
}

.antd-pro-pages-form-step-form-components-step3-index-uppercase {
    font-size: 12px
}

.antd-pro-pages-form-step-form-style-card {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-style-heading {
    margin: 0 0 16px;
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-form-step-form-style-steps.ant-steps {
    max-width: 750px;
    margin: 16px auto
}

.antd-pro-pages-form-step-form-style-errorIcon {
    margin-right: 24px;
    color: #f5222d;
    cursor: pointer
}

.antd-pro-pages-form-step-form-style-errorIcon i {
    margin-right: 4px
}

.antd-pro-pages-form-step-form-style-errorPopover .ant-popover-inner-content {
    min-width: 256px;
    max-height: 290px;
    padding: 0;
    overflow: auto
}

.antd-pro-pages-form-step-form-style-errorListItem {
    padding: 8px 16px;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all .3s
}

.antd-pro-pages-form-step-form-style-errorListItem:hover {
    background: #e6f7ff
}

.antd-pro-pages-form-step-form-style-errorListItem:last-child {
    border: 0
}

.antd-pro-pages-form-step-form-style-errorListItem .antd-pro-pages-form-step-form-style-errorIcon {
    float: left;
    margin-top: 4px;
    margin-right: 12px;
    padding-bottom: 22px;
    color: #f5222d
}

.antd-pro-pages-form-step-form-style-errorListItem .antd-pro-pages-form-step-form-style-errorField {
    margin-top: 2px;
    color: rgba(0, 0, 0, .45);
    font-size: 12px
}

.antd-pro-pages-form-step-form-style-editable td {
    padding-top: 13px !important;
    padding-bottom: 12.5px !important
}

.antd-pro-pages-form-step-form-style-advancedForm+div {
    padding-bottom: 64px
}

.antd-pro-pages-form-step-form-style-advancedForm .ant-form .ant-row:last-child .ant-form-item {
    margin-bottom: 24px
}

.antd-pro-pages-form-step-form-style-advancedForm .ant-table td {
    transition: none !important
}

.antd-pro-pages-form-step-form-style-optional {
    color: rgba(0, 0, 0, .45);
    font-style: normal
}

.antd-pro-pages-form-advanced-form-style-card {
    margin-bottom: 24px
}

.antd-pro-pages-form-advanced-form-style-heading {
    margin: 0 0 16px;
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-form-advanced-form-style-steps.ant-steps {
    max-width: 750px;
    margin: 16px auto
}

.antd-pro-pages-form-advanced-form-style-errorIcon {
    margin-right: 24px;
    color: #f5222d;
    cursor: pointer
}

.antd-pro-pages-form-advanced-form-style-errorIcon i {
    margin-right: 4px
}

.antd-pro-pages-form-advanced-form-style-errorPopover .ant-popover-inner-content {
    min-width: 256px;
    max-height: 290px;
    padding: 0;
    overflow: auto
}

.antd-pro-pages-form-advanced-form-style-errorListItem {
    padding: 8px 16px;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all .3s
}

.antd-pro-pages-form-advanced-form-style-errorListItem:hover {
    background: #e6f7ff
}

.antd-pro-pages-form-advanced-form-style-errorListItem:last-child {
    border: 0
}

.antd-pro-pages-form-advanced-form-style-errorListItem .antd-pro-pages-form-advanced-form-style-errorIcon {
    float: left;
    margin-top: 4px;
    margin-right: 12px;
    padding-bottom: 22px;
    color: #f5222d
}

.antd-pro-pages-form-advanced-form-style-errorListItem .antd-pro-pages-form-advanced-form-style-errorField {
    margin-top: 2px;
    color: rgba(0, 0, 0, .45);
    font-size: 12px
}

.antd-pro-pages-form-advanced-form-style-editable td {
    padding-top: 13px !important;
    padding-bottom: 12.5px !important
}

.antd-pro-pages-form-advanced-form-style-advancedForm+div {
    padding-bottom: 64px
}

.antd-pro-pages-form-advanced-form-style-advancedForm .ant-form .ant-row:last-child .ant-form-item {
    margin-bottom: 24px
}

.antd-pro-pages-form-advanced-form-style-advancedForm .ant-table td {
    transition: none !important
}

.antd-pro-pages-form-advanced-form-style-optional {
    color: rgba(0, 0, 0, .45);
    font-style: normal
}

.antd-pro-pages-form-advanced-form-components-footer-toolbar-index-toolbar {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: 56px;
    padding: 0 24px;
    line-height: 56px;
    background: #fff;
    border-top: 1px solid #e8e8e8;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, .03)
}

.antd-pro-pages-form-advanced-form-components-footer-toolbar-index-toolbar:after {
    display: block;
    clear: both;
    content: ""
}

.antd-pro-pages-form-advanced-form-components-footer-toolbar-index-toolbar .antd-pro-pages-form-advanced-form-components-footer-toolbar-index-left {
    float: left
}

.antd-pro-pages-form-advanced-form-components-footer-toolbar-index-toolbar .antd-pro-pages-form-advanced-form-components-footer-toolbar-index-right {
    float: right
}

.antd-pro-pages-form-advanced-form-components-footer-toolbar-index-toolbar button+button {
    margin-left: 8px
}

.antd-pro-pages-list-search-articles-components-article-list-content-index-listContent .antd-pro-pages-list-search-articles-components-article-list-content-index-description {
    max-width: 720px;
    line-height: 22px
}

.antd-pro-pages-list-search-articles-components-article-list-content-index-listContent .antd-pro-pages-list-search-articles-components-article-list-content-index-extra {
    margin-top: 16px;
    color: rgba(0, 0, 0, .45);
    line-height: 22px
}

.antd-pro-pages-list-search-articles-components-article-list-content-index-listContent .antd-pro-pages-list-search-articles-components-article-list-content-index-extra>.ant-avatar {
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: top
}

.antd-pro-pages-list-search-articles-components-article-list-content-index-listContent .antd-pro-pages-list-search-articles-components-article-list-content-index-extra>em {
    margin-left: 16px;
    color: rgba(0, 0, 0, .25);
    font-style: normal
}

@media screen and (max-width:480px) {
    .antd-pro-pages-list-search-articles-components-article-list-content-index-listContent .antd-pro-pages-list-search-articles-components-article-list-content-index-extra>em {
        display: block;
        margin-top: 8px;
        margin-left: 0
    }
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow {
    display: flex;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed #e8e8e8
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .ant-form-item {
    margin-right: 24px
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .ant-form-item-label label {
    margin-right: 0;
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .ant-form-item-control,
.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .ant-form-item-label {
    padding: 0;
    line-height: 32px
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-articles-components-standard-form-row-index-label {
    flex: 0 0 auto;
    margin-right: 24px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    text-align: right
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-articles-components-standard-form-row-index-label>span {
    display: inline-block;
    height: 32px;
    line-height: 32px
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-articles-components-standard-form-row-index-label>span:after {
    content: "\FF1A"
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-articles-components-standard-form-row-index-content {
    flex: 1 1
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-articles-components-standard-form-row-index-content .ant-form-item:last-child {
    margin-right: 0
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRowLast {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRowBlock .ant-form-item,
.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRowBlock div.ant-form-item-control-wrapper {
    display: block
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRowGrid .ant-form-item,
.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRowGrid div.ant-form-item-control-wrapper {
    display: block
}

.antd-pro-pages-list-search-articles-components-standard-form-row-index-standardFormRowGrid .ant-form-item-label {
    float: left
}

.antd-pro-pages-list-search-articles-components-tag-select-index-tagSelect {
    position: relative;
    max-height: 32px;
    margin-left: -8px;
    overflow: hidden;
    line-height: 32px;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.antd-pro-pages-list-search-articles-components-tag-select-index-tagSelect .ant-tag {
    margin-right: 24px;
    padding: 0 8px;
    font-size: 14px
}

.antd-pro-pages-list-search-articles-components-tag-select-index-tagSelect.antd-pro-pages-list-search-articles-components-tag-select-index-expanded {
    max-height: 200px;
    transition: all .3s
}

.antd-pro-pages-list-search-articles-components-tag-select-index-tagSelect .antd-pro-pages-list-search-articles-components-tag-select-index-trigger {
    position: absolute;
    top: 0;
    right: 0
}

.antd-pro-pages-list-search-articles-components-tag-select-index-tagSelect .antd-pro-pages-list-search-articles-components-tag-select-index-trigger i {
    font-size: 12px
}

.antd-pro-pages-list-search-articles-components-tag-select-index-tagSelect.antd-pro-pages-list-search-articles-components-tag-select-index-hasExpandTag {
    padding-right: 50px
}

a.antd-pro-pages-list-search-articles-style-listItemMetaTitle {
    color: rgba(0, 0, 0, .85)
}

.antd-pro-pages-list-search-articles-style-listItemExtra {
    width: 272px;
    height: 1px
}

.antd-pro-pages-list-search-articles-style-selfTrigger {
    margin-left: 12px
}

@media screen and (max-width:480px) {
    .antd-pro-pages-list-search-articles-style-selfTrigger {
        display: block;
        margin-left: 0
    }
}

@media screen and (max-width:768px) {
    .antd-pro-pages-list-search-articles-style-selfTrigger {
        display: block;
        margin-left: 0
    }
}

@media screen and (max-width:992px) {
    .antd-pro-pages-list-search-articles-style-listItemExtra {
        width: 0;
        height: 1px
    }
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarList {
    display: inline-block
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarList ul {
    display: inline-block;
    margin-left: 8px;
    font-size: 0
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarItem {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-left: -8px;
    font-size: 14px
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarItem .ant-avatar {
    border: 1px solid #fff
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarItemLarge {
    width: 40px;
    height: 40px
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarItemSmall {
    width: 24px;
    height: 24px
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarItemMini {
    width: 20px;
    height: 20px
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarItemMini .ant-avatar {
    width: 20px;
    height: 20px;
    line-height: 20px
}

.antd-pro-pages-list-search-projects-components-avatar-list-index-avatarItemMini .ant-avatar .ant-avatar-string {
    font-size: 12px;
    line-height: 18px
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow {
    display: flex;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed #e8e8e8
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .ant-form-item {
    margin-right: 24px
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .ant-form-item-label label {
    margin-right: 0;
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .ant-form-item-control,
.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .ant-form-item-label {
    padding: 0;
    line-height: 32px
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-projects-components-standard-form-row-index-label {
    flex: 0 0 auto;
    margin-right: 24px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    text-align: right
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-projects-components-standard-form-row-index-label>span {
    display: inline-block;
    height: 32px;
    line-height: 32px
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-projects-components-standard-form-row-index-label>span:after {
    content: "\FF1A"
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-projects-components-standard-form-row-index-content {
    flex: 1 1
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-projects-components-standard-form-row-index-content .ant-form-item:last-child {
    margin-right: 0
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRowLast {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRowBlock .ant-form-item,
.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRowBlock div.ant-form-item-control-wrapper {
    display: block
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRowGrid .ant-form-item,
.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRowGrid div.ant-form-item-control-wrapper {
    display: block
}

.antd-pro-pages-list-search-projects-components-standard-form-row-index-standardFormRowGrid .ant-form-item-label {
    float: left
}

.antd-pro-pages-list-search-projects-components-tag-select-index-tagSelect {
    position: relative;
    max-height: 32px;
    margin-left: -8px;
    overflow: hidden;
    line-height: 32px;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.antd-pro-pages-list-search-projects-components-tag-select-index-tagSelect .ant-tag {
    margin-right: 24px;
    padding: 0 8px;
    font-size: 14px
}

.antd-pro-pages-list-search-projects-components-tag-select-index-tagSelect.antd-pro-pages-list-search-projects-components-tag-select-index-expanded {
    max-height: 200px;
    transition: all .3s
}

.antd-pro-pages-list-search-projects-components-tag-select-index-tagSelect .antd-pro-pages-list-search-projects-components-tag-select-index-trigger {
    position: absolute;
    top: 0;
    right: 0
}

.antd-pro-pages-list-search-projects-components-tag-select-index-tagSelect .antd-pro-pages-list-search-projects-components-tag-select-index-trigger i {
    font-size: 12px
}

.antd-pro-pages-list-search-projects-components-tag-select-index-tagSelect.antd-pro-pages-list-search-projects-components-tag-select-index-hasExpandTag {
    padding-right: 50px
}

.antd-pro-pages-list-search-projects-style-coverCardList {
    margin-bottom: -24px
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-card .ant-card-meta-title {
    margin-bottom: 4px
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-card .ant-card-meta-title>a {
    display: inline-block;
    max-width: 100%;
    color: rgba(0, 0, 0, .85)
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-card .ant-card-meta-description {
    height: 44px;
    overflow: hidden;
    line-height: 22px
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-card:hover .ant-card-meta-title>a {
    color: #1890ff
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-cardItemContent {
    display: flex;
    height: 20px;
    margin-top: 16px;
    margin-bottom: -4px;
    line-height: 20px
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-cardItemContent>span {
    flex: 1 1;
    color: rgba(0, 0, 0, .45);
    font-size: 12px
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-cardItemContent .antd-pro-pages-list-search-projects-style-avatarList {
    flex: 0 1 auto
}

.antd-pro-pages-list-search-projects-style-coverCardList .antd-pro-pages-list-search-projects-style-cardList {
    margin-top: 24px
}

.antd-pro-pages-list-search-projects-style-coverCardList .ant-list .ant-list-item-content-single {
    max-width: 100%
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow {
    display: flex;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed #e8e8e8
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .ant-form-item {
    margin-right: 24px
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .ant-form-item-label label {
    margin-right: 0;
    color: rgba(0, 0, 0, .65)
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .ant-form-item-control,
.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .ant-form-item-label {
    padding: 0;
    line-height: 32px
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-applications-components-standard-form-row-index-label {
    flex: 0 0 auto;
    margin-right: 24px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    text-align: right
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-applications-components-standard-form-row-index-label>span {
    display: inline-block;
    height: 32px;
    line-height: 32px
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-applications-components-standard-form-row-index-label>span:after {
    content: "\FF1A"
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-applications-components-standard-form-row-index-content {
    flex: 1 1
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRow .antd-pro-pages-list-search-applications-components-standard-form-row-index-content .ant-form-item:last-child {
    margin-right: 0
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRowLast {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRowBlock .ant-form-item,
.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRowBlock div.ant-form-item-control-wrapper {
    display: block
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRowGrid .ant-form-item,
.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRowGrid div.ant-form-item-control-wrapper {
    display: block
}

.antd-pro-pages-list-search-applications-components-standard-form-row-index-standardFormRowGrid .ant-form-item-label {
    float: left
}

.antd-pro-pages-list-search-applications-components-tag-select-index-tagSelect {
    position: relative;
    max-height: 32px;
    margin-left: -8px;
    overflow: hidden;
    line-height: 32px;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.antd-pro-pages-list-search-applications-components-tag-select-index-tagSelect .ant-tag {
    margin-right: 24px;
    padding: 0 8px;
    font-size: 14px
}

.antd-pro-pages-list-search-applications-components-tag-select-index-tagSelect.antd-pro-pages-list-search-applications-components-tag-select-index-expanded {
    max-height: 200px;
    transition: all .3s
}

.antd-pro-pages-list-search-applications-components-tag-select-index-tagSelect .antd-pro-pages-list-search-applications-components-tag-select-index-trigger {
    position: absolute;
    top: 0;
    right: 0
}

.antd-pro-pages-list-search-applications-components-tag-select-index-tagSelect .antd-pro-pages-list-search-applications-components-tag-select-index-trigger i {
    font-size: 12px
}

.antd-pro-pages-list-search-applications-components-tag-select-index-tagSelect.antd-pro-pages-list-search-applications-components-tag-select-index-hasExpandTag {
    padding-right: 50px
}

.antd-pro-pages-list-search-applications-style-filterCardList {
    margin-bottom: -24px
}

.antd-pro-pages-list-search-applications-style-filterCardList .ant-card-meta-content {
    margin-top: 0
}

.antd-pro-pages-list-search-applications-style-filterCardList .ant-card-meta-avatar {
    font-size: 0
}

.antd-pro-pages-list-search-applications-style-filterCardList .ant-list .ant-list-item-content-single {
    max-width: 100%
}

.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo {
    margin-top: 16px;
    margin-left: 40px;
    zoom: 1
}

.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo:after,
.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo:before {
    display: table;
    content: " "
}

.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo:after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden
}

.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo>div {
    position: relative;
    float: left;
    width: 50%;
    text-align: left
}

.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo>div p {
    margin: 0;
    font-size: 24px;
    line-height: 32px
}

.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo>div p:first-child {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
    line-height: 20px
}

.ant-modal {
    box-sizing: border-box;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: 100px;
    width: auto;
    margin: 0 auto;
    padding: 0 0 24px;
    pointer-events: none
}

.ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch
}

.ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    pointer-events: auto
}

.ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, .45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color .3s
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto
}

.ant-modal-close:focus,
.ant-modal-close:hover {
    color: rgba(0, 0, 0, .75);
    text-decoration: none
}

.ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, .65);
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0
}

.ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word
}

.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: 0 0;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px
}

.ant-modal-footer button+button {
    margin-bottom: 0;
    margin-left: 8px
}

.ant-modal.zoom-appear,
.ant-modal.zoom-enter {
    transform: none;
    opacity: 0;
    animation-duration: .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, .45)
}

.ant-modal-mask-hidden {
    display: none
}

.ant-modal-open {
    overflow: hidden
}

.ant-modal-centered {
    text-align: center
}

.ant-modal-centered:before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: ""
}

.ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle
}

@media (max-width:767px) {
    .ant-modal {
        max-width: calc(100vw - 16px);
        margin: 8px auto
    }

    .ant-modal-centered .ant-modal {
        flex: 1 1
    }
}

.ant-modal-confirm .ant-modal-header {
    display: none
}

.ant-modal-confirm .ant-modal-close {
    display: none
}

.ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px
}

.ant-modal-confirm-body-wrapper {
    zoom: 1
}

.ant-modal-confirm-body-wrapper:after,
.ant-modal-confirm-body-wrapper:before {
    display: table;
    content: ""
}

.ant-modal-confirm-body-wrapper:after {
    clear: both
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 8px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px
}

.ant-modal-confirm-body>.anticon {
    float: left;
    margin-right: 16px;
    font-size: 22px
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
    margin-left: 38px
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px
}

.ant-modal-confirm .ant-modal-confirm-btns button+button {
    margin-bottom: 0;
    margin-left: 8px
}

.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
    color: #f5222d
}

.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon,
.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon {
    color: #faad14
}

.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
    color: #1890ff
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
    color: #52c41a
}

.antd-pro-pages-list-table-list-components-standard-table-index-standardTable .ant-table-pagination {
    margin-top: 24px
}

.antd-pro-pages-list-table-list-components-standard-table-index-standardTable .antd-pro-pages-list-table-list-components-standard-table-index-tableAlert {
    margin-bottom: 16px
}

.antd-pro-pages-list-table-list-style-tableList .antd-pro-pages-list-table-list-style-tableListOperator {
    margin-bottom: 16px
}

.antd-pro-pages-list-table-list-style-tableList .antd-pro-pages-list-table-list-style-tableListOperator button {
    margin-right: 8px
}

.antd-pro-pages-list-table-list-style-tableListForm .ant-form-item {
    display: flex;
    margin-right: 0;
    margin-bottom: 24px
}

.antd-pro-pages-list-table-list-style-tableListForm .ant-form-item>.ant-form-item-label {
    width: auto;
    padding-right: 8px;
    line-height: 32px
}

.antd-pro-pages-list-table-list-style-tableListForm .ant-form-item .ant-form-item-control {
    line-height: 32px
}

.antd-pro-pages-list-table-list-style-tableListForm .ant-form-item-control-wrapper {
    flex: 1 1
}

.antd-pro-pages-list-table-list-style-tableListForm .antd-pro-pages-list-table-list-style-submitButtons {
    display: block;
    margin-bottom: 24px;
    white-space: nowrap
}

@media screen and (max-width:992px) {
    .antd-pro-pages-list-table-list-style-tableListForm .ant-form-item {
        margin-right: 24px
    }
}

@media screen and (max-width:768px) {
    .antd-pro-pages-list-table-list-style-tableListForm .ant-form-item {
        margin-right: 8px
    }
}

.antd-pro-pages-list-basic-list-style-standardList .ant-card-head {
    border-bottom: none
}

.antd-pro-pages-list-basic-list-style-standardList .ant-card-head-title {
    padding: 24px 0;
    line-height: 32px
}

.antd-pro-pages-list-basic-list-style-standardList .ant-card-extra {
    padding: 24px 0
}

.antd-pro-pages-list-basic-list-style-standardList .ant-list-pagination {
    margin-top: 24px;
    text-align: right
}

.antd-pro-pages-list-basic-list-style-standardList .ant-avatar-lg {
    width: 48px;
    height: 48px;
    line-height: 48px
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-headerInfo {
    position: relative;
    text-align: center
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-headerInfo>span {
    display: inline-block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-headerInfo>p {
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 32px
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-headerInfo>em {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 56px;
    background-color: #e8e8e8
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent {
    font-size: 0
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent .antd-pro-pages-list-basic-list-style-listContentItem {
    display: inline-block;
    margin-left: 40px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    vertical-align: middle
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent .antd-pro-pages-list-basic-list-style-listContentItem>span {
    line-height: 20px
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent .antd-pro-pages-list-basic-list-style-listContentItem>p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px
}

.antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-extraContentSearch {
    width: 272px;
    margin-left: 16px
}

@media screen and (max-width:480px) {
    .antd-pro-pages-list-basic-list-style-standardList .ant-list-item-content {
        display: block;
        flex: none;
        width: 100%
    }

    .antd-pro-pages-list-basic-list-style-standardList .ant-list-item-action {
        margin-left: 0
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent {
        margin-left: 0
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div {
        margin-left: 0
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listCard .ant-card-head-title {
        overflow: visible
    }
}

@media screen and (max-width:576px) {
    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-extraContentSearch {
        width: 100%;
        margin-left: 0
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-headerInfo {
        margin-bottom: 16px
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-headerInfo>em {
        display: none
    }
}

@media screen and (max-width:768px) {
    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div {
        display: block
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div:last-child {
        top: 0;
        width: 100%
    }

    .antd-pro-pages-list-basic-list-style-listCard .ant-radio-group {
        display: block;
        margin-bottom: 8px
    }
}

@media screen and (max-width:992px) and (min-width:768px) {
    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div {
        display: block
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div:last-child {
        top: 0;
        width: 100%
    }
}

@media screen and (max-width:1200px) {
    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div {
        margin-left: 24px
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div:last-child {
        top: 0
    }
}

@media screen and (max-width:1400px) {
    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent {
        text-align: right
    }

    .antd-pro-pages-list-basic-list-style-standardList .antd-pro-pages-list-basic-list-style-listContent>div:last-child {
        top: 0
    }
}

.antd-pro-pages-list-basic-list-style-standardListForm .ant-form-item {
    margin-bottom: 12px
}

.antd-pro-pages-list-basic-list-style-standardListForm .ant-form-item:last-child {
    margin-bottom: 32px;
    padding-top: 4px
}

.antd-pro-pages-list-basic-list-style-formResult {
    width: 100%
}

.antd-pro-pages-list-basic-list-style-formResult [class^=title] {
    margin-bottom: 8px
}

.antd-pro-pages-list-card-list-style-cardList {
    margin-bottom: -24px
}

.antd-pro-pages-list-card-list-style-cardList .antd-pro-pages-list-card-list-style-card .ant-card-meta-title {
    margin-bottom: 12px
}

.antd-pro-pages-list-card-list-style-cardList .antd-pro-pages-list-card-list-style-card .ant-card-meta-title>a {
    display: inline-block;
    max-width: 100%;
    color: rgba(0, 0, 0, .85)
}

.antd-pro-pages-list-card-list-style-cardList .antd-pro-pages-list-card-list-style-card .ant-card-body:hover .ant-card-meta-title>a {
    color: #1890ff
}

.antd-pro-pages-list-card-list-style-cardList .antd-pro-pages-list-card-list-style-item {
    height: 64px
}

.antd-pro-pages-list-card-list-style-cardList .ant-list .ant-list-item-content-single {
    max-width: 100%
}

.antd-pro-pages-list-card-list-style-extraImg {
    width: 155px;
    margin-top: -20px;
    text-align: center
}

.antd-pro-pages-list-card-list-style-extraImg img {
    width: 100%
}

.antd-pro-pages-list-card-list-style-newButton {
    width: 100%;
    height: 188px;
    color: rgba(0, 0, 0, .45);
    background-color: #fff;
    border-color: #d9d9d9;
    border-radius: 2px
}

.antd-pro-pages-list-card-list-style-cardAvatar {
    width: 48px;
    height: 48px;
    border-radius: 48px
}

.antd-pro-pages-list-card-list-style-cardDescription {
    position: relative;
    max-height: 4.5em;
    margin-right: -1em;
    padding-right: 1em;
    overflow: hidden;
    line-height: 1.5em;
    text-align: justify
}

.antd-pro-pages-list-card-list-style-cardDescription:before {
    position: absolute;
    right: 14px;
    bottom: 0;
    padding: 0 1px;
    background: #fff;
    content: "..."
}

.antd-pro-pages-list-card-list-style-cardDescription:after {
    position: absolute;
    right: 14px;
    width: 1em;
    height: 1em;
    margin-top: .2em;
    background: #fff;
    content: ""
}

.antd-pro-pages-list-card-list-style-pageHeaderContent {
    position: relative
}

.antd-pro-pages-list-card-list-style-contentLink {
    margin-top: 16px
}

.antd-pro-pages-list-card-list-style-contentLink a {
    margin-right: 32px
}

.antd-pro-pages-list-card-list-style-contentLink a img {
    width: 24px
}

.antd-pro-pages-list-card-list-style-contentLink img {
    margin-right: 8px;
    vertical-align: middle
}

@media screen and (max-width:992px) {
    .antd-pro-pages-list-card-list-style-contentLink a {
        margin-right: 16px
    }
}

@media screen and (max-width:768px) {
    .antd-pro-pages-list-card-list-style-extraImg {
        display: none
    }
}

@media screen and (max-width:576px) {
    .antd-pro-pages-list-card-list-style-pageHeaderContent {
        padding-bottom: 30px
    }

    .antd-pro-pages-list-card-list-style-contentLink {
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 1000px
    }

    .antd-pro-pages-list-card-list-style-contentLink a {
        margin-right: 16px
    }

    .antd-pro-pages-list-card-list-style-contentLink img {
        margin-right: 4px
    }
}

.antd-pro-pages-profile-basic-style-title {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px
}

.antd-pro-pages-profile-advanced-style-main .ant-descriptions-row>td {
    padding-bottom: 8px
}

.antd-pro-pages-profile-advanced-style-main .ant-page-header-heading-extra {
    flex-direction: column
}

.antd-pro-pages-profile-advanced-style-headerList {
    margin-bottom: 4px
}

.antd-pro-pages-profile-advanced-style-headerList .ant-descriptions-row>td {
    padding-bottom: 8px
}

.antd-pro-pages-profile-advanced-style-headerList .antd-pro-pages-profile-advanced-style-stepDescription {
    position: relative;
    left: 38px;
    padding-top: 8px;
    font-size: 14px;
    text-align: left
}

.antd-pro-pages-profile-advanced-style-headerList .antd-pro-pages-profile-advanced-style-stepDescription>div {
    margin-top: 8px;
    margin-bottom: 4px
}

.antd-pro-pages-profile-advanced-style-pageHeader .ant-page-header-heading-extra>*+* {
    margin-left: 8px
}

.antd-pro-pages-profile-advanced-style-pageHeader .antd-pro-pages-profile-advanced-style-moreInfo {
    display: flex;
    justify-content: space-between;
    width: 200px
}

@media screen and (max-width:576px) {
    .antd-pro-pages-profile-advanced-style-stepDescription {
        left: 8px
    }

    .antd-pro-pages-profile-advanced-style-pageHeader .ant-pro-page-header-wrap-row {
        flex-direction: column
    }
}

.antd-pro-pages-result-success-index-title {
    position: relative;
    color: rgba(0, 0, 0, .65);
    font-size: 12px;
    text-align: center
}

.antd-pro-pages-result-success-index-head-title {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500px;
    font-size: 16px
}

.antd-pro-pages-result-fail-index-error_icon {
    color: #f5222d
}

.antd-pro-pages-result-fail-index-title {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarList {
    display: inline-block
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarList ul {
    display: inline-block;
    margin-left: 8px;
    font-size: 0
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarItem {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-left: -8px;
    font-size: 14px
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarItem .ant-avatar {
    border: 1px solid #fff
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarItemLarge {
    width: 40px;
    height: 40px
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarItemSmall {
    width: 24px;
    height: 24px
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarItemMini {
    width: 20px;
    height: 20px
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarItemMini .ant-avatar {
    width: 20px;
    height: 20px;
    line-height: 20px
}

.antd-pro-pages-account-center-components-avatar-list-index-avatarItemMini .ant-avatar .ant-avatar-string {
    font-size: 12px;
    line-height: 18px
}

.antd-pro-pages-account-center-components-projects-index-coverCardList {
    margin-bottom: -24px
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-card .ant-card-meta-title {
    margin-bottom: 4px
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-card .ant-card-meta-title>a {
    display: inline-block;
    max-width: 100%;
    color: rgba(0, 0, 0, .85)
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-card .ant-card-meta-description {
    height: 44px;
    overflow: hidden;
    line-height: 22px
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-card:hover .ant-card-meta-title>a {
    color: #1890ff
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-cardItemContent {
    display: flex;
    height: 20px;
    margin-top: 16px;
    margin-bottom: -4px;
    line-height: 20px
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-cardItemContent>span {
    flex: 1 1;
    color: rgba(0, 0, 0, .45);
    font-size: 12px
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-cardItemContent .antd-pro-pages-account-center-components-projects-index-avatarList {
    flex: 0 1 auto
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .antd-pro-pages-account-center-components-projects-index-cardList {
    margin-top: 24px
}

.antd-pro-pages-account-center-components-projects-index-coverCardList .ant-list .ant-list-item-content-single {
    max-width: 100%
}

.antd-pro-pages-account-center-components-article-list-content-index-listContent .antd-pro-pages-account-center-components-article-list-content-index-description {
    max-width: 720px;
    line-height: 22px
}

.antd-pro-pages-account-center-components-article-list-content-index-listContent .antd-pro-pages-account-center-components-article-list-content-index-extra {
    margin-top: 16px;
    color: rgba(0, 0, 0, .45);
    line-height: 22px
}

.antd-pro-pages-account-center-components-article-list-content-index-listContent .antd-pro-pages-account-center-components-article-list-content-index-extra>.ant-avatar {
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: top
}

.antd-pro-pages-account-center-components-article-list-content-index-listContent .antd-pro-pages-account-center-components-article-list-content-index-extra>em {
    margin-left: 16px;
    color: rgba(0, 0, 0, .25);
    font-style: normal
}

@media screen and (max-width:480px) {
    .antd-pro-pages-account-center-components-article-list-content-index-listContent .antd-pro-pages-account-center-components-article-list-content-index-extra>em {
        display: block;
        margin-top: 8px;
        margin-left: 0
    }
}

.antd-pro-pages-account-center-components-articles-index-articleList .ant-list-item:first-child {
    padding-top: 0
}

a.antd-pro-pages-account-center-components-articles-index-listItemMetaTitle {
    color: rgba(0, 0, 0, .85)
}

.antd-pro-pages-account-center-components-applications-index-filterCardList {
    margin-bottom: -24px
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .ant-card-meta-content {
    margin-top: 0
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .ant-card-meta-avatar {
    font-size: 0
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .ant-list .ant-list-item-content-single {
    max-width: 100%
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .antd-pro-pages-account-center-components-applications-index-cardInfo {
    margin-top: 16px;
    margin-left: 40px;
    zoom: 1
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .antd-pro-pages-account-center-components-applications-index-cardInfo:after,
.antd-pro-pages-account-center-components-applications-index-filterCardList .antd-pro-pages-account-center-components-applications-index-cardInfo:before {
    display: table;
    content: " "
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .antd-pro-pages-account-center-components-applications-index-cardInfo:after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .antd-pro-pages-account-center-components-applications-index-cardInfo>div {
    position: relative;
    float: left;
    width: 50%;
    text-align: left
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .antd-pro-pages-account-center-components-applications-index-cardInfo>div p {
    margin: 0;
    font-size: 24px;
    line-height: 32px
}

.antd-pro-pages-account-center-components-applications-index-filterCardList .antd-pro-pages-account-center-components-applications-index-cardInfo>div p:first-child {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
    line-height: 20px
}

.antd-pro-pages-account-center-center-avatarHolder {
    margin-bottom: 24px;
    text-align: center
}

.antd-pro-pages-account-center-center-avatarHolder>img {
    width: 104px;
    height: 104px;
    margin-bottom: 20px
}

.antd-pro-pages-account-center-center-avatarHolder .antd-pro-pages-account-center-center-name {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px
}

.antd-pro-pages-account-center-center-detail p {
    position: relative;
    margin-bottom: 8px;
    padding-left: 26px
}

.antd-pro-pages-account-center-center-detail p:last-child {
    margin-bottom: 0
}

.antd-pro-pages-account-center-center-detail i {
    position: absolute;
    top: 4px;
    left: 0;
    width: 14px;
    height: 14px;
    background: url(https://gw.alipayobjects.com/zos/rmsportal/pBjWzVAHnOOtAUvZmZfy.svg)
}

.antd-pro-pages-account-center-center-detail i.antd-pro-pages-account-center-center-title {
    background-position: 0 0
}

.antd-pro-pages-account-center-center-detail i.antd-pro-pages-account-center-center-group {
    background-position: 0 -22px
}

.antd-pro-pages-account-center-center-detail i.antd-pro-pages-account-center-center-address {
    background-position: 0 -44px
}

.antd-pro-pages-account-center-center-tagsTitle,
.antd-pro-pages-account-center-center-teamTitle {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500
}

.antd-pro-pages-account-center-center-tags .ant-tag {
    margin-bottom: 8px
}

.antd-pro-pages-account-center-center-team .ant-avatar {
    margin-right: 12px
}

.antd-pro-pages-account-center-center-team a {
    display: block;
    margin-bottom: 24px;
    overflow: hidden;
    color: rgba(0, 0, 0, .65);
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    transition: color .3s
}

.antd-pro-pages-account-center-center-team a:hover {
    color: #1890ff
}

.antd-pro-pages-account-center-center-tabsCard .ant-card-head {
    padding: 0 16px
}

.ant-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    outline: 0
}

.ant-upload p {
    margin: 0
}

.ant-upload-btn {
    display: block;
    width: 100%;
    outline: 0
}

.ant-upload input[type=file] {
    cursor: pointer
}

.ant-upload.ant-upload-select {
    display: inline-block
}

.ant-upload.ant-upload-disabled {
    cursor: not-allowed
}

.ant-upload.ant-upload-select-picture-card {
    display: table;
    float: left;
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color .3s ease
}

.ant-upload.ant-upload-select-picture-card>.ant-upload {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle
}

.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #1890ff
}

.ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color .3s
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 16px 0
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: #096dd9
}

.ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed
}

.ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #40a9ff
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #40a9ff;
    font-size: 48px
}

.ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, .85);
    font-size: 16px
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

.ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(0, 0, 0, .25);
    font-size: 30px;
    transition: all .3s
}

.ant-upload.ant-upload-drag .anticon-plus:hover {
    color: rgba(0, 0, 0, .45)
}

.ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, .45)
}

.ant-upload-picture-card-wrapper {
    zoom: 1;
    display: inline-block;
    width: 100%
}

.ant-upload-picture-card-wrapper:after,
.ant-upload-picture-card-wrapper:before {
    display: table;
    content: ""
}

.ant-upload-picture-card-wrapper:after {
    clear: both
}

.ant-upload-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    zoom: 1
}

.ant-upload-list:after,
.ant-upload-list:before {
    display: table;
    content: ""
}

.ant-upload-list:after {
    clear: both
}

.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px
}

.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
    padding-right: 28px
}

.ant-upload-list-item {
    position: relative;
    height: 22px;
    margin-top: 8px;
    font-size: 14px
}

.ant-upload-list-item-name {
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px
}

.ant-upload-list-item-card-actions {
    position: absolute;
    right: 0;
    opacity: 0
}

.ant-upload-list-item-card-actions.picture {
    top: 25px;
    line-height: 1;
    opacity: 1
}

.ant-upload-list-item-card-actions .anticon {
    padding-right: 5px;
    color: rgba(0, 0, 0, .45)
}

.ant-upload-list-item-info {
    height: 100%;
    padding: 0 12px 0 4px;
    transition: background-color .3s
}

.ant-upload-list-item-info>span {
    display: block;
    width: 100%;
    height: 100%
}

.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
    position: absolute;
    top: 5px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px
}

.ant-upload-list-item .anticon-close {
    display: inline-block;
    font-size: 12px;
    transform: scale(.83333333) rotate(0);
    position: absolute;
    top: 6px;
    right: 4px;
    color: rgba(0, 0, 0, .45);
    line-height: 0;
    cursor: pointer;
    opacity: 0;
    transition: all .3s
}

:root .ant-upload-list-item .anticon-close {
    font-size: 12px
}

.ant-upload-list-item .anticon-close:hover {
    color: rgba(0, 0, 0, .65)
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #e6f7ff
}

.ant-upload-list-item:hover .anticon-close {
    opacity: 1
}

.ant-upload-list-item:hover .ant-upload-list-item-card-actions {
    opacity: 1
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-list-item-error .anticon-paper-clip {
    color: #f5222d
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions {
    opacity: 1
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    padding-right: 5px;
    color: #f5222d
}

.ant-upload-list-item-progress {
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding-left: 26px;
    font-size: 14px;
    line-height: 0
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px
}

.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
    background: 0 0
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #f5222d
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0
}

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: 0 0
}

.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
    border-style: dashed
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 48px;
    height: 48px;
    font-size: 26px;
    line-height: 54px;
    text-align: center;
    opacity: .8
}

.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    transform: translate(-50%, -50%)
}

.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
    max-width: 100%
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    overflow: hidden;
    line-height: 44px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .3s
}

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 18px
}

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
    padding-right: 36px
}

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
    line-height: 28px
}

.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 14px;
    width: calc(100% - 24px);
    margin-top: 0;
    padding-left: 56px
}

.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    opacity: 1
}

.ant-upload-list-picture-card.ant-upload-list:after {
    display: none
}

.ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: all .3s;
    content: " "
}

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
    opacity: 1
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: hsla(0, 0%, 100%, .85);
    font-size: 16px;
    cursor: pointer;
    transition: all .3s
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover {
    color: #fff
}

.ant-upload-list-picture-card .ant-upload-list-item-actions:hover,
.ant-upload-list-picture-card .ant-upload-list-item-info:hover+.ant-upload-list-item-actions {
    opacity: 1
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
    display: none;
    margin: 8px 0 0;
    padding: 0;
    line-height: 1.5;
    text-align: center
}

.ant-upload-list-picture-card .anticon-picture+.ant-upload-list-item-name {
    display: block
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: #fafafa
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: auto
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before {
    display: none
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
    margin-top: 18px;
    color: rgba(0, 0, 0, .45)
}

.ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 32px;
    padding-left: 0
}

.ant-upload-list .ant-upload-success-icon {
    color: #52c41a;
    font-weight: 700
}

.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave,
.ant-upload-list .ant-upload-animate-leave {
    animation-duration: .3s;
    animation-fill-mode: cubic-bezier(.78, .14, .15, .86)
}

.ant-upload-list .ant-upload-animate-enter {
    animation-name: uploadAnimateIn
}

.ant-upload-list .ant-upload-animate-leave {
    animation-name: uploadAnimateOut
}

.ant-upload-list .ant-upload-animate-inline-enter {
    animation-name: uploadAnimateInlineIn
}

.ant-upload-list .ant-upload-animate-inline-leave {
    animation-name: uploadAnimateInlineOut
}

@keyframes uploadAnimateIn {
    0% {
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0
    }
}

@keyframes uploadAnimateOut {
    to {
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0
    }
}

@keyframes uploadAnimateInlineIn {
    0% {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0
    }
}

@keyframes uploadAnimateInlineOut {
    to {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0
    }
}

.antd-pro-pages-account-settings-components-geographic-view-row .antd-pro-pages-account-settings-components-geographic-view-item {
    width: 50%;
    max-width: 220px
}

.antd-pro-pages-account-settings-components-geographic-view-row .antd-pro-pages-account-settings-components-geographic-view-item:first-child {
    width: calc(50% - 8px);
    margin-right: 8px
}

@media screen and (max-width:576px) {
    .antd-pro-pages-account-settings-components-geographic-view-item:first-child {
        margin: 0 0 8px
    }
}

.antd-pro-pages-account-settings-components-phone-view-area_code {
    width: 30%;
    max-width: 128px;
    margin-right: 8px
}

.antd-pro-pages-account-settings-components-phone-view-phone_number {
    width: calc(70% - 8px);
    max-width: 312px
}

.antd-pro-pages-account-settings-components-base-view-baseView {
    display: flex;
    padding-top: 12px
}

.antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-left {
    min-width: 224px;
    max-width: 448px
}

.antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-right {
    flex: 1 1;
    padding-left: 104px
}

.antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-right .antd-pro-pages-account-settings-components-base-view-avatar_title {
    height: 22px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px
}

.antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-right .antd-pro-pages-account-settings-components-base-view-avatar {
    width: 144px;
    height: 144px;
    margin-bottom: 12px;
    overflow: hidden
}

.antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-right .antd-pro-pages-account-settings-components-base-view-avatar img {
    width: 100%
}

.antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-right .antd-pro-pages-account-settings-components-base-view-button_view {
    width: 144px;
    text-align: center
}

@media screen and (max-width:1200px) {
    .antd-pro-pages-account-settings-components-base-view-baseView {
        flex-direction: column-reverse
    }

    .antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 448px;
        padding: 20px
    }

    .antd-pro-pages-account-settings-components-base-view-baseView .antd-pro-pages-account-settings-components-base-view-right .antd-pro-pages-account-settings-components-base-view-avatar_title {
        display: none
    }
}

.antd-pro-pages-account-settings-style-main {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
    background-color: #fff
}

.antd-pro-pages-account-settings-style-main .antd-pro-pages-account-settings-style-leftMenu {
    width: 224px;
    border-right: 1px solid #e8e8e8
}

.antd-pro-pages-account-settings-style-main .antd-pro-pages-account-settings-style-leftMenu .ant-menu-inline {
    border: none
}

.antd-pro-pages-account-settings-style-main .antd-pro-pages-account-settings-style-leftMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    font-weight: 700
}

.antd-pro-pages-account-settings-style-main .antd-pro-pages-account-settings-style-right {
    flex: 1 1;
    padding: 8px 40px
}

.antd-pro-pages-account-settings-style-main .antd-pro-pages-account-settings-style-right .antd-pro-pages-account-settings-style-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px
}

.antd-pro-pages-account-settings-style-main .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #e8e8e8
}

.antd-pro-pages-account-settings-style-main .ant-list-item {
    padding-top: 14px;
    padding-bottom: 14px
}

.ant-list-item-meta .taobao {
    display: block;
    color: #ff4000;
    font-size: 48px;
    line-height: 48px;
    border-radius: 4px
}

.ant-list-item-meta .dingding {
    margin: 2px;
    padding: 6px;
    color: #fff;
    font-size: 32px;
    line-height: 32px;
    background-color: #2eabff;
    border-radius: 4px
}

.ant-list-item-meta .alipay {
    color: #2eabff;
    font-size: 48px;
    line-height: 48px;
    border-radius: 4px
}

font.strong {
    color: #52c41a
}

font.medium {
    color: #faad14
}

font.weak {
    color: #f5222d
}

@media screen and (max-width:768px) {
    .antd-pro-pages-account-settings-style-main {
        flex-direction: column
    }

    .antd-pro-pages-account-settings-style-main .antd-pro-pages-account-settings-style-leftMenu {
        width: 100%;
        border: none
    }

    .antd-pro-pages-account-settings-style-main .antd-pro-pages-account-settings-style-right {
        padding: 40px
    }
}

.antd-pro-pages-editor-flow-components-editor-context-menu-index-contextMenu {
    display: none;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.antd-pro-pages-editor-flow-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-flow-components-editor-context-menu-index-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    cursor: pointer;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.antd-pro-pages-editor-flow-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-flow-components-editor-context-menu-index-item:hover {
    background: #e6f7ff
}

.antd-pro-pages-editor-flow-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-flow-components-editor-context-menu-index-item i {
    margin-right: 8px
}

.antd-pro-pages-editor-flow-components-editor-context-menu-index-contextMenu .disable .antd-pro-pages-editor-flow-components-editor-context-menu-index-item {
    color: rgba(0, 0, 0, .25);
    cursor: auto
}

.antd-pro-pages-editor-flow-components-editor-context-menu-index-contextMenu .disable .antd-pro-pages-editor-flow-components-editor-context-menu-index-item:hover {
    background: #fff
}

.antd-pro-pages-editor-flow-components-editor-detail-panel-index-detailPanel {
    flex: 1 1;
    background: #fafafa
}

.antd-pro-pages-editor-flow-components-editor-detail-panel-index-detailPanel .ant-card {
    background: #fafafa
}

.antd-pro-pages-editor-flow-components-editor-item-panel-index-itemPanel {
    flex: 1 1;
    background: #fafafa
}

.antd-pro-pages-editor-flow-components-editor-item-panel-index-itemPanel .ant-card {
    background: #fafafa
}

.antd-pro-pages-editor-flow-components-editor-item-panel-index-itemPanel .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center
}

.antd-pro-pages-editor-flow-components-editor-item-panel-index-itemPanel .ant-card-body>div {
    margin-bottom: 16px
}

.antd-pro-pages-editor-flow-components-editor-toolbar-index-toolbar {
    display: flex;
    align-items: center
}

.antd-pro-pages-editor-flow-components-editor-toolbar-index-toolbar .command i {
    display: inline-block;
    width: 27px;
    height: 27px;
    margin: 0 6px;
    padding-top: 6px;
    text-align: center;
    border: 1px solid #fff;
    cursor: pointer
}

.antd-pro-pages-editor-flow-components-editor-toolbar-index-toolbar .command i:hover {
    border: 1px solid #e6e9ed
}

.antd-pro-pages-editor-flow-components-editor-toolbar-index-toolbar .disable i {
    color: rgba(0, 0, 0, .25);
    cursor: auto
}

.antd-pro-pages-editor-flow-components-editor-toolbar-index-toolbar .disable i:hover {
    border: 1px solid #fff
}

.antd-pro-pages-editor-flow-components-editor-toolbar-index-tooltip .ant-tooltip-inner {
    font-size: 12px;
    border-radius: 0
}

.antd-pro-pages-editor-flow-index-editor {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 250px);
    background: #fff
}

.antd-pro-pages-editor-flow-index-editorHd {
    padding: 8px;
    border: 1px solid #e6e9ed
}

.antd-pro-pages-editor-flow-index-editorBd {
    flex: 1 1
}

.antd-pro-pages-editor-flow-index-editorContent,
.antd-pro-pages-editor-flow-index-editorSidebar {
    display: flex;
    flex-direction: column
}

.antd-pro-pages-editor-flow-index-editorSidebar {
    background: #fafafa
}

.antd-pro-pages-editor-flow-index-editorSidebar:first-child {
    border-right: 1px solid #e6e9ed
}

.antd-pro-pages-editor-flow-index-editorSidebar:last-child {
    border-left: 1px solid #e6e9ed
}

.antd-pro-pages-editor-flow-index-flow,
.antd-pro-pages-editor-flow-index-koni,
.antd-pro-pages-editor-flow-index-mind {
    flex: 1 1
}

.antd-pro-pages-editor-mind-components-editor-context-menu-index-contextMenu {
    display: none;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.antd-pro-pages-editor-mind-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-mind-components-editor-context-menu-index-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    cursor: pointer;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.antd-pro-pages-editor-mind-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-mind-components-editor-context-menu-index-item:hover {
    background: #e6f7ff
}

.antd-pro-pages-editor-mind-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-mind-components-editor-context-menu-index-item i {
    margin-right: 8px
}

.antd-pro-pages-editor-mind-components-editor-context-menu-index-contextMenu .disable .antd-pro-pages-editor-mind-components-editor-context-menu-index-item {
    color: rgba(0, 0, 0, .25);
    cursor: auto
}

.antd-pro-pages-editor-mind-components-editor-context-menu-index-contextMenu .disable .antd-pro-pages-editor-mind-components-editor-context-menu-index-item:hover {
    background: #fff
}

.antd-pro-pages-editor-mind-components-editor-detail-panel-index-detailPanel {
    flex: 1 1;
    background: #fafafa
}

.antd-pro-pages-editor-mind-components-editor-detail-panel-index-detailPanel .ant-card {
    background: #fafafa
}

.antd-pro-pages-editor-mind-components-editor-toolbar-index-toolbar {
    display: flex;
    align-items: center
}

.antd-pro-pages-editor-mind-components-editor-toolbar-index-toolbar .command i {
    display: inline-block;
    width: 27px;
    height: 27px;
    margin: 0 6px;
    padding-top: 6px;
    text-align: center;
    border: 1px solid #fff;
    cursor: pointer
}

.antd-pro-pages-editor-mind-components-editor-toolbar-index-toolbar .command i:hover {
    border: 1px solid #e6e9ed
}

.antd-pro-pages-editor-mind-components-editor-toolbar-index-toolbar .disable i {
    color: rgba(0, 0, 0, .25);
    cursor: auto
}

.antd-pro-pages-editor-mind-components-editor-toolbar-index-toolbar .disable i:hover {
    border: 1px solid #fff
}

.antd-pro-pages-editor-mind-components-editor-toolbar-index-tooltip .ant-tooltip-inner {
    font-size: 12px;
    border-radius: 0
}

.antd-pro-pages-editor-mind-index-editor {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 250px);
    background: #fff
}

.antd-pro-pages-editor-mind-index-editorHd {
    padding: 8px;
    border: 1px solid #e6e9ed
}

.antd-pro-pages-editor-mind-index-editorBd {
    flex: 1 1
}

.antd-pro-pages-editor-mind-index-editorContent,
.antd-pro-pages-editor-mind-index-editorSidebar {
    display: flex;
    flex-direction: column
}

.antd-pro-pages-editor-mind-index-editorSidebar {
    background: #fafafa
}

.antd-pro-pages-editor-mind-index-editorSidebar:first-child {
    border-right: 1px solid #e6e9ed
}

.antd-pro-pages-editor-mind-index-editorSidebar:last-child {
    border-left: 1px solid #e6e9ed
}

.antd-pro-pages-editor-mind-index-flow,
.antd-pro-pages-editor-mind-index-koni,
.antd-pro-pages-editor-mind-index-mind {
    flex: 1 1
}

.antd-pro-pages-editor-koni-components-editor-context-menu-index-contextMenu {
    display: none;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15)
}

.antd-pro-pages-editor-koni-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-koni-components-editor-context-menu-index-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    cursor: pointer;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.antd-pro-pages-editor-koni-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-koni-components-editor-context-menu-index-item:hover {
    background: #e6f7ff
}

.antd-pro-pages-editor-koni-components-editor-context-menu-index-contextMenu .antd-pro-pages-editor-koni-components-editor-context-menu-index-item i {
    margin-right: 8px
}

.antd-pro-pages-editor-koni-components-editor-context-menu-index-contextMenu .disable .antd-pro-pages-editor-koni-components-editor-context-menu-index-item {
    color: rgba(0, 0, 0, .25);
    cursor: auto
}

.antd-pro-pages-editor-koni-components-editor-context-menu-index-contextMenu .disable .antd-pro-pages-editor-koni-components-editor-context-menu-index-item:hover {
    background: #fff
}

.antd-pro-pages-editor-koni-components-editor-detail-panel-index-detailPanel {
    flex: 1 1;
    background: #fafafa
}

.antd-pro-pages-editor-koni-components-editor-detail-panel-index-detailPanel .ant-card {
    background: #fafafa
}

.antd-pro-pages-editor-koni-components-editor-item-panel-index-itemPanel {
    flex: 1 1;
    background: #fafafa
}

.antd-pro-pages-editor-koni-components-editor-item-panel-index-itemPanel .ant-card {
    background: #fafafa
}

.antd-pro-pages-editor-koni-components-editor-item-panel-index-itemPanel .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center
}

.antd-pro-pages-editor-koni-components-editor-item-panel-index-itemPanel .ant-card-body>div {
    margin-bottom: 16px
}

.antd-pro-pages-editor-koni-components-editor-toolbar-index-toolbar {
    display: flex;
    align-items: center
}

.antd-pro-pages-editor-koni-components-editor-toolbar-index-toolbar .command i {
    display: inline-block;
    width: 27px;
    height: 27px;
    margin: 0 6px;
    padding-top: 6px;
    text-align: center;
    border: 1px solid #fff;
    cursor: pointer
}

.antd-pro-pages-editor-koni-components-editor-toolbar-index-toolbar .command i:hover {
    border: 1px solid #e6e9ed
}

.antd-pro-pages-editor-koni-components-editor-toolbar-index-toolbar .disable i {
    color: rgba(0, 0, 0, .25);
    cursor: auto
}

.antd-pro-pages-editor-koni-components-editor-toolbar-index-toolbar .disable i:hover {
    border: 1px solid #fff
}

.antd-pro-pages-editor-koni-components-editor-toolbar-index-tooltip .ant-tooltip-inner {
    font-size: 12px;
    border-radius: 0
}

.antd-pro-pages-editor-koni-index-editor {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 250px);
    background: #fff
}

.antd-pro-pages-editor-koni-index-editorHd {
    padding: 8px;
    border: 1px solid #e6e9ed
}

.antd-pro-pages-editor-koni-index-editorBd {
    flex: 1 1
}

.antd-pro-pages-editor-koni-index-editorContent,
.antd-pro-pages-editor-koni-index-editorSidebar {
    display: flex;
    flex-direction: column
}

.antd-pro-pages-editor-koni-index-editorSidebar {
    background: #fafafa
}

.antd-pro-pages-editor-koni-index-editorSidebar:first-child {
    border-right: 1px solid #e6e9ed
}

.antd-pro-pages-editor-koni-index-editorSidebar:last-child {
    border-left: 1px solid #e6e9ed
}

.antd-pro-pages-editor-koni-index-flow,
.antd-pro-pages-editor-koni-index-koni,
.antd-pro-pages-editor-koni-index-mind {
    flex: 1 1
}

#root,
body,
html {
    height: 100%
}

.colorWeak {
    -webkit-filter: invert(80%);
    filter: invert(80%)
}

.ant-layout {
    min-height: 100vh
}

canvas {
    display: block
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

ol,
ul {
    list-style: none
}

@media (max-width:480px) {
    .ant-table {
        width: 100%;
        overflow-x: auto
    }

    .ant-table-tbody>tr>td,
    .ant-table-tbody>tr>th,
    .ant-table-thead>tr>td,
    .ant-table-thead>tr>th {
        white-space: pre
    }

    .ant-table-tbody>tr>td>span,
    .ant-table-tbody>tr>th>span,
    .ant-table-thead>tr>td>span,
    .ant-table-thead>tr>th>span {
        display: block
    }
}



.ui-avatar__content--smaller{
    width: 25px;
    height: 25px;
    font-size: 11px;
    overflow: hidden;
    border-radius: 50%;
}

*/

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu :global(.anticon) {
  margin-right: 8px;
}
.menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.right {
  float: right;
  height: 100%;
  margin-left: auto;
  overflow: hidden;
}
.right .action {
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.right .action > i {
  color: rgba(0, 0, 0, 0.65);
  vertical-align: middle;
}
.right .action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right .action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}
.right .search {
  padding: 0 12px;
}
.right .search:hover {
  background: transparent;
}
.right .account .avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #1890FF;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.85);
}
.dark {
  height: 64px;
}
.dark .action {
  color: rgba(255, 255, 255, 0.85);
}
.dark .action > i {
  color: rgba(255, 255, 255, 0.85);
}
.dark .action:hover,
.dark .action:global(.opened) {
  background: #1890FF;
}
:global(.ant-pro-global-header) .dark .action {
  color: rgba(0, 0, 0, 0.65);
}
:global(.ant-pro-global-header) .dark .action > i {
  color: rgba(0, 0, 0, 0.65);
}
:global(.ant-pro-global-header) .dark .action:hover {
  color: rgba(255, 255, 255, 0.85);
}
:global(.ant-pro-global-header) .dark .action:hover > i {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 768px) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
  }
  .right .account .avatar {
    margin-right: 0;
  }
}
.noticeButton {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.icon {
  padding: 4px;
  vertical-align: middle;
}
.badge {
  font-size: 14px;
  /*color:#fff;*/
}
.badge i {
  margin-right: 6px;
}
.badge p {
  color: #fff;
}

html#iubenda_policy,
#iubenda_policy body {
  margin: 0;
  padding: 0;
}

html#iubenda_policy {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

#iubenda_policy h1,
#iubenda_policy h2,
#iubenda_policy h3,
#iubenda_policy h4,
#iubenda_policy h5,
#iubenda_policy h6,
#iubenda_policy p,
#iubenda_policy blockquote,
#iubenda_policy pre,
#iubenda_policy a,
#iubenda_policy abbr,
#iubenda_policy acronym,
#iubenda_policy address,
#iubenda_policy cite,
#iubenda_policy code,
#iubenda_policy del,
#iubenda_policy dfn,
#iubenda_policy em,
#iubenda_policy img,
#iubenda_policy q,
#iubenda_policy s,
#iubenda_policy samp,
#iubenda_policy small,
#iubenda_policy strike,
#iubenda_policy strong,
#iubenda_policy sub,
#iubenda_policy sup,
#iubenda_policy tt,
#iubenda_policy var,
#iubenda_policy dd,
#iubenda_policy dl,
#iubenda_policy dt,
#iubenda_policy li,
#iubenda_policy ol,
#iubenda_policy ul,
#iubenda_policy fieldset,
#iubenda_policy form,
#iubenda_policy label,
#iubenda_policy legend,
#iubenda_policy button,
#iubenda_policy table,
#iubenda_policy caption,
#iubenda_policy tbody,
#iubenda_policy tfoot,
#iubenda_policy thead,
#iubenda_policy tr,
#iubenda_policy th,
#iubenda_policy td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}

#iubenda_policy table {
  border-collapse: collapse;
  border-spacing: 0;
}

#iubenda_policy ol,
#iubenda_policy ul {
  list-style: none;
}

#iubenda_policy q:before,
#iubenda_policy q:after,
#iubenda_policy blockquote:before,
#iubenda_policy blockquote:after {
  content: '';
}

#iubenda_policy a:focus {
  outline: thin dotted;
}

#iubenda_policy a:hover,
#iubenda_policy a:active {
  outline: 0;
}

#iubenda_policy article,
#iubenda_policy aside,
#iubenda_policy details,
#iubenda_policy figcaption,
#iubenda_policy figure,
#iubenda_policy footer,
#iubenda_policy header,
#iubenda_policy hgroup,
#iubenda_policy nav,
#iubenda_policy section {
  display: block;
}

#iubenda_policy audio,
#iubenda_policy canvas,
#iubenda_policy video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

#iubenda_policy audio:not([controls]) {
  display: none;
}

#iubenda_policy sub,
#iubenda_policy sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

#iubenda_policy sup {
  top: -0.5em;
}

#iubenda_policy sub {
  bottom: -0.25em;
}

#iubenda_policy img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

#iubenda_policy button,
#iubenda_policy input,
#iubenda_policy select,
#iubenda_policy textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

#iubenda_policy button,
#iubenda_policy input {
  line-height: normal;
  *overflow: visible;
}

#iubenda_policy button::-moz-focus-inner,
#iubenda_policy input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

#iubenda_policy button,
#iubenda_policy input[type='button'],
#iubenda_policy input[type='reset'],
#iubenda_policy input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}

#iubenda_policy input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

#iubenda_policy input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

#iubenda_policy textarea {
  overflow: auto;
  vertical-align: top;
}

html#iubenda_policy {
  -webkit-font-smoothing: antialiased;
}

#iubenda_policy p {
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 9px;
}

#iubenda_policy p small {
  font-size: 11px;
  color: #bfbfbf;
}

#iubenda_policy h1,
#iubenda_policy h2,
#iubenda_policy h3,
#iubenda_policy h4,
#iubenda_policy h5,
#iubenda_policy h6 {
  font-weight: bold;
  color: #59636d;
}

#iubenda_policy h1 {
  margin-bottom: 18px;
  font-size: 30px;
  line-height: 2;
}

#iubenda_policy h1 small {
  font-size: 18px;
}

#iubenda_policy h2 {
  font-size: 24px;
  margin-bottom: 18px;
  line-height: 1.5;
}

#iubenda_policy h2 small {
  font-size: 14px;
}

#iubenda_policy h3,
#iubenda_policy h4,
#iubenda_policy h5,
#iubenda_policy h6 {
  margin-bottom: 9px;
}

#iubenda_policy h3 {
  font-size: 18px;
}

#iubenda_policy h3 small {
  font-size: 14px;
}

#iubenda_policy h4 {
  font-size: 16px;
}

#iubenda_policy h4 small {
  font-weight: bold;
  font-size: 13px;
}

#iubenda_policy h5 {
  font-size: 13px;
  padding-top: 19px;
}

#iubenda_policy h6 {
  font-size: 13px;
  color: #bfbfbf;
  text-transform: uppercase;
}

#iubenda_policy ul ul,
#iubenda_policy ul ol,
#iubenda_policy ol ol,
#iubenda_policy ol ul {
  margin: 0;
}

#iubenda_policy ul.styled,
#iubenda_policy ul {
  list-style: disc;
  padding-top: 5px;
}

#iubenda_policy ul.styled li,
#iubenda_policy ul li {
  list-style: disc;
  line-height: 19px;
  font-size: 13px;
  margin-left: 30px;
  margin-top: 2px;
}

#iubenda_policy ol {
  list-style: decimal;
}

#iubenda_policy ul.unstyled {
  list-style: none;
  margin-left: 0;
}

#iubenda_policy dl {
  margin-bottom: 18px;
}

#iubenda_policy dl dt,
#iubenda_policy dl dd {
  line-height: 18px;
}

#iubenda_policy dl dt {
  font-weight: bold;
}

#iubenda_policy dl dd {
  margin-left: 9px;
}

#iubenda_policy hr {
  margin: 0 0 19px;
  border: 0;
  border-bottom: 1px solid #eee;
}

#iubenda_policy strong {
  font-style: inherit;
  font-weight: bold;
}

#iubenda_policy em {
  font-style: italic;
  font-weight: inherit;
  line-height: inherit;
}

#iubenda_policy .muted {
  color: #bfbfbf;
}

#iubenda_policy blockquote {
  margin-bottom: 18px;
  border-left: 5px solid #eee;
  padding-left: 15px;
}

#iubenda_policy blockquote p {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 0;
}

#iubenda_policy blockquote small {
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #bfbfbf;
}

#iubenda_policy blockquote small:before {
  content: '\2014 \00A0';
}

#iubenda_policy address {
  display: block;
  line-height: 18px;
  margin-bottom: 18px;
}

#iubenda_policy code,
#iubenda_policy pre {
  padding: 0 3px 2px;
  font-family: Monaco, Andale Mono, Courier New, monospace;
  font-size: 12px;
  border-radius: 3px;
}

#iubenda_policy code {
  background-color: #fee9cc;
  color: rgba(0, 0, 0, 0.75);
  padding: 1px 3px;
}

#iubenda_policy pre {
  background-color: #f5f5f5;
  display: block;
  padding: 17px;
  margin: 0 0 18px;
  line-height: 18px;
  font-size: 12px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#iubenda_policy .breadcrumbs {
  padding: 0 0 10px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
}

#iubenda_policy .breadcrumbs > li {
  float: left;
  filter: alpha(opacity=50);
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
}

#iubenda_policy .breadcrumbs > li:not(:last-child):after {
  color: #333b43;
  padding: 0 10px;
  content: '\203a';
}

#iubenda_policy .breadcrumbs + .pills,
#iubenda_policy .breadcrumbs + .sec_tabs {
  margin-top: -15px;
}

#iubenda_policy .table {
  display: table;
  border-collapse: collapse;
  padding: 0 !important;
  margin: 0;
}

#iubenda_policy .cust_row {
  display: table-row;
  margin: 0;
}

#iubenda_policy .column {
  display: table-cell;
  vertical-align: top;
  padding: 30px;
}

#iubenda_policy .box_primary {
  border: 1px solid #c0c1c1;
  border-bottom-color: #a8aaab;
  box-shadow: 0 1px 0 #ebebec;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

#iubenda_policy .box_content {
  border-radius: 4px;
  padding: 30px;
}

#iubenda_policy .box_content .iub_content {
  padding: 30px;
}

#iubenda_policy .box_content .iub_content > hr {
  width: 686px;
  margin-left: -30px;
  margin-right: -30px;
}

#iubenda_policy .box_content .aside {
  width: 191px;
  padding: 30px;
}

#iubenda_policy .box_content .aside.aside-right {
  border-left: 1px solid #dfdfdf;
}

#iubenda_policy .table > .box_content {
  padding: 0;
}

#iubenda_policy .box_10 {
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}

#iubenda_policy .box_10 > h4 {
  margin-bottom: 0;
  font-size: 13px;
}

#iubenda_policy .box_10 > .w_icon,
#iubenda_policy .box_10.expand > .w_icon,
#iubenda_policy .box_10 > .w_icon.expand-click,
#iubenda_policy .box_10.expand > .w_icon.expand-click {
  padding-left: 45px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 10px;
  background-position-y: 7px;
  background-position: 10px 7px;
}

#iubenda_policy .box_10 > .w_icon_16,
#iubenda_policy .box_10.expand > .w_icon_16,
#iubenda_policy .box_10 > .w_icon_16.expand-click,
#iubenda_policy .box_10.expand > .w_icon_16.expand-click {
  padding-left: 40px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 11px;
  background-position-y: 11px;
  background-position: 11px 11px;
}

#iubenda_policy .box_10 > .w_icon_24,
#iubenda_policy .box_10.expand > .w_icon_24,
#iubenda_policy .box_10 > .w_icon_24.expand-click,
#iubenda_policy .box_10.expand > .w_icon_24.expand-click {
  padding-left: 45px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 10px;
  background-position-y: 10px;
  background-position: 10px 10px;
}

#iubenda_policy .box_5 {
  padding: 5px;
  border-radius: 3px;
  font-size: 11px;
  margin-bottom: 15px;
}

#iubenda_policy .box_5 hr {
  padding-top: 5px;
  margin: 0 -5px 5px -5px;
  border: 0;
  border-bottom: 1px solid #ac3737;
}

#iubenda_policy .box_5.w_icon_16 {
  padding-left: 30px;
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: 6px;
  background-position: 8px 6px;
}

#iubenda_policy .box_5.w_icon_16 hr {
  width: 100%;
  padding-left: 30px;
  padding-right: 5px;
  margin-left: -30px;
  margin-right: -5px;
}

#iubenda_policy .box_5.w_icon_16.red {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAATlBMVEUAAAD%2F%2F%2F8AAAD%2F%2F%2F8AAAAAAAD%2F%2F%2F%2F%2F%2F%2F%2FT09P%2F%2F%2F%2F9%2Ff3Y2Nj9%2Ff39%2Ff3d3d3%2F%2F%2F%2F8%2FPz39%2Ff19fX%2B%2Fv79%2Ff34%2BPj5%2Bfn8%2FPz9%2Ff3%2F%2F%2F8ZO4GEAAAAGXRSTlMAEB0gMDNAUHSAgYSRoaWwsra3weLl5fLyUJhrdwAAAF1JREFUeF6NzUcWhCAAwFAQsIPOWCD3v6gPxLYjy7%2BJKE1Ok%2FxAD%2BMbFIB6wYIxLA%2FUbEJAc8PKHmG9oAOkArq8DICdgXCuLUA7EDkBsd%2BfWALnyXmXoNImpytR0AEwdQcUE5t8VQAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .box_thumb {
  background: #fff;
  box-shadow: 0 0 1px #a3a3a3, 0 1px 1px #a3a3a3;
  padding: 6px;
}

#iubenda_policy footer {
  margin-top: 17px;
  padding-top: 17px;
  border-top: 1px solid #eee;
}

#iubenda_policy hr {
  padding-top: 15px;
  margin: 0 0 15px 0;
}

#iubenda_policy hr.primary {
  border: 0;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0 1px 0 #f7f7f7;
}

#iubenda_policy .btn {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 0 20px;
  line-height: 38px;
  color: #54616b;
  font-size: 13px;
  border: 0;
  border-radius: 4rem;
  border-collapse: separate;
  transition: 0.1s linear all;
}

#iubenda_policy .btn:hover {
  background-position: 0 -15px;
  text-decoration: none;
}

#iubenda_policy .btn:focus {
  outline: 1px dotted #666;
}

#iubenda_policy .btn.primary {
  color: #fff;
  background-color: #0073ce;
}

#iubenda_policy .btn:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
}

#iubenda_policy .btn.disabled {
  cursor: default;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  filter: alpha(opacity=65);
  -khtml-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  box-shadow: none;
}

#iubenda_policy .btn[disabled] {
  cursor: default;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  filter: alpha(opacity=65);
  -khtml-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  box-shadow: none;
}

#iubenda_policy .btn.large {
  font-size: 16px;
  line-height: normal;
  padding: 0 14px;
  border-radius: 6px;
}

#iubenda_policy .btn.mid_large {
  padding: 0 10px;
  line-height: 32px;
  font-size: 13px;
}

#iubenda_policy .btn.mid {
  padding: 0 10px;
  line-height: 28px;
  font-size: 11px;
}

#iubenda_policy .btn.small {
  padding: 0 8px;
  line-height: 18px;
  font-size: 11px;
}

#iubenda_policy :root .alert-message,
#iubenda_policy :root .btn {
  border-radius: 0 \0;
}

#iubenda_policy button.btn::-moz-focus-inner,
#iubenda_policy input.btn[type='submit']::-moz-focus-inner {
  padding: 0;
  border: 0;
}

#iubenda_policy .circle {
  font-size: 11px;
  line-height: 18px;
  width: 18px;
  padding: 0;
  text-align: center;
  border-radius: 11px;
}

#iubenda_policy .circle.small {
  width: 14px;
  line-height: 14px;
  border-radius: 9px;
  padding: 0;
}

#iubenda_policy .blue {
  color: #fff;
  background-color: #0073ce;
}

#iubenda_policy .yellow {
  color: #6d693d;
  background-color: #ffd24d;
}

#iubenda_policy .red {
  color: #fff;
  background-color: #ff5d4d;
}

#iubenda_policy .red a,
#iubenda_policy .red a:hover:not(.btn) {
  color: #fff;
}

#iubenda_policy .red a {
  border-bottom-color: rgba(247, 247, 247, 0.3);
}

#iubenda_policy .red a:hover {
  border-bottom-color: rgba(247, 247, 247, 0.6);
}

#iubenda_policy .green {
  color: #4d6c47;
  background-color: #f1ffd5;
}

#iubenda_policy .iubgreen {
  color: #ffffff;
  background-color: #1cc691;
}

#iubenda_policy .azure {
  color: #364048;
  background-color: #d2ecfe;
}

#iubenda_policy .white {
  color: #54616b;
  background-color: #f8f8f8;
}

#iubenda_policy .black {
  color: #fff;
  background-color: #333333;
}

#iubenda_policy .trasp {
  color: #333b43;
  background-color: #ffffff;
}

#iubenda_policy .alert-message {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 18px;
  color: #404040;
  background-color: #eedc94;
  background-repeat: repeat-x;
  background-image: -khtml-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
  background-image: linear-gradient(to bottom, #fceec1, #eedc94);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fceec1', endColorstr='#eedc94', GradientType=0);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-color: #eedc94 #eedc94 #e4c652;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
}

#iubenda_policy .alert-message .close {
  *margin-top: 3px;
}

#iubenda_policy .alert-message h5 {
  line-height: 18px;
}

#iubenda_policy .alert-message p {
  margin-bottom: 0;
}

#iubenda_policy .alert-message div {
  margin-top: 5px;
  margin-bottom: 2px;
  line-height: 28px;
}

#iubenda_policy .alert-message .btn {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}

#iubenda_policy .alert-message.block-message {
  background-image: none;
  background-color: #fdf5d9;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  padding: 14px;
  border-color: #fceec1;
  box-shadow: none;
}

#iubenda_policy .alert-message.block-message ul,
#iubenda_policy .alert-message.block-message p {
  margin-right: 30px;
}

#iubenda_policy .alert-message.block-message ul {
  margin-bottom: 0;
}

#iubenda_policy .alert-message.block-message li {
  color: #404040;
}

#iubenda_policy .alert-message.block-message .alert-actions {
  margin-top: 5px;
}

#iubenda_policy .alert-message.block-message.error,
#iubenda_policy .alert-message.block-message.success,
#iubenda_policy .alert-message.block-message.info {
  color: #404040;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

#iubenda_policy .alert-message.block-message.error {
  background-color: #fddfde;
  border-color: #fbc7c6;
}

#iubenda_policy .alert-message.block-message.success {
  background-color: #d1eed1;
  border-color: #bfe7bf;
}

#iubenda_policy .alert-message.block-message.info {
  background-color: #ddf4fb;
  border-color: #c6edf9;
}

#iubenda_policy .fade {
  transition: opacity 0.15s linear;
  opacity: 0;
}

#iubenda_policy .fade.in {
  opacity: 1;
}

#iubenda_policy .expand-click {
  cursor: pointer;
  position: relative;
}

#iubenda_policy .box_10.expand .expand-click {
  margin: -10px;
  padding: 12px 25px 13px 10px;
}

#iubenda_policy .box_10.expand .expand-content {
  margin-top: 10px;
}

#iubenda_policy .box_10.expand .expand-content > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

#iubenda_policy .expand.expanded .expand-click:after,
#iubenda_policy .box_10.expand.expanded .expand-click:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 19px;
  border: 5px;
  border-color: transparent;
  border-style: solid;
  border-top-color: #333b43;
}

#iubenda_policy .expand .expand-click,
#iubenda_policy .box_10.expand .expand-click,
#iubenda_policy .expand.expanded .expand-click,
#iubenda_policy .box_10.expand.expanded .expand-click {
  border-bottom: 1px dotted #ddd;
  margin-bottom: 10px;
  transition: 0.2s linear all;
}

#iubenda_policy .expand.collapsed .expand-click {
  border-bottom: 0;
  margin-bottom: -10px;
}

#iubenda_policy .expand.collapsed .expand-click:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 17px;
  border: 5px;
  border-color: transparent;
  border-style: solid;
  border-right-color: #333b43;
}

#iubenda_policy .all-collapsed .expand .expand-click:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 17px;
  border: 5px;
  border-color: transparent;
  border-style: solid;
  border-right-color: #333b43;
}

#iubenda_policy .all-collapsed .expand .expand-click {
  border-bottom: 0;
  margin-bottom: -10px;
}

#iubenda_policy .all-collapsed .expand-content {
  display: none;
}

html#iubenda_policy,
#iubenda_policy body {
  background-color: #fff;
}

#iubenda_policy {
  font-family: 'Helvetica Neue', Helvetica, Arial, FreeSans, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  color: #59636d;
}

#iubenda_policy body {
  margin: 0;
}

#iubenda_policy .iub_container-fluid {
  position: relative;
  min-width: 940px;
  padding-left: 20px;
  padding-right: 20px;
  zoom: 1;
}

#iubenda_policy .iub_container-fluid:before,
#iubenda_policy .iub_container-fluid:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}

#iubenda_policy .iub_container-fluid:after {
  clear: both;
}

#iubenda_policy .iub_container-fluid > .sidebar {
  float: left;
  width: 220px;
}

#iubenda_policy .iub_container-fluid > .iub_content {
  margin-left: 240px;
}

#iubenda_policy a {
  text-decoration: none;
  font-weight: bold;
  border-bottom: 1px solid #f6f6f6;
  color: #333b43;
}

#iubenda_policy a.unstyled {
  border-bottom: 0;
}

#iubenda_policy a:hover:not(.btn) {
  color: #121518;
  border-bottom-color: #d6d6d6;
  transition: 0.1s linear all;
}

#iubenda_policy a.no_border,
#iubenda_policy a.no_border:hover {
  border-bottom-width: 0;
}

#iubenda_policy .pull-right {
  float: right;
}

#iubenda_policy .pull-left {
  float: left;
}

#iubenda_policy .hide {
  display: none;
}

#iubenda_policy .show {
  display: block;
}

#iubenda_policy .link_on_dark a {
  border-bottom-color: rgba(247, 247, 247, 0.3);
}

#iubenda_policy .link_on_dark a:hover {
  border-bottom-color: rgba(247, 247, 247, 0.6);
}

#iubenda_policy [class*='policyicon_'] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
}

#iubenda_policy .policyicon_pdt_68 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .policyicon_purpose_5 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAElBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2BtTDCxAAAABXRSTlMAECBAgLf%2B2%2BsAAABGSURBVBhXY2AAA5ZQBwY4YA0NIJfjCjYHygkNDUTmBGPhgOyFc1iB6pE4wSAOUAGCIxoaiOCYhgYjOKqhQThkyODAAR4OAI98N9LK6tL3AAAAAElFTkSuQmCC);
}

#iubenda_policy .policyicon_purpose_7 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAINJREFUeF6V0UsOxCAIBmB8tVoZ4f6nnUqaoFUW%2FVeEj0hUMOKM9kE7CBcxr93SuGcCf%2FRZniCmXGVUwZV2M78DgYRXQDaAP0OzIJIB4C%2FaQo%2BTCyK9ISFizimAPyuNACjlKXW6SMF30B9I9YFndRieuZCCHKU0QIU1LDEhrvDrQG6EP%2FDZElAL0vLHAAAAAElFTkSuQmCC);
}

#iubenda_policy .policyicon_purpose_9 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAACC0lEQVQ4y7XSO2gWVhQH8BPxDRG%2BEhurMcSI4GsoPqjkZ6BLwcFFHUQJKqbEwRciDqZDF90cpIsILtZHh0KTIdQMgkTRiIshBoWgTRpbsVaxgqRf4uM4JCHfRzpIwXun8%2Bf%2BuHDOifj%2FxwoD2qek7Qat%2FG9Qr1%2FblLRNv%2FqyqKHCjIgIqw3oGE9mmtlQERGhw4DVERFmNFREhG91uq6gxUspnVdlky5dNqlyXkovtSi4rtPe8JeUaq1yWLN9tkVoklJThK1a7HXISrVSehpSGrXb5woWqFZljZNSOmmtBRapUe0Lu4xKOQZSr0633dejS7chKQ25p0%2BvHn3u6Bt7OQFSeuWG3pI6DbvpZ5dc8WwimwTPbYswx49Sei89sDNCpaoI6%2FyqWA5OmxUR4StF6Z0hX5puvyH%2FOmeeudrLwXfjg1prUCo6FuGyty444W89CpYZKQU%2FmF3ywwvVthtxwpwImz1yzjSdpWBYq2nWuzbWoQgX%2FaPOAd%2Br1O55hDOl4LHdDRXqnPVWehLhlPSNgiURFlof4adJMGC7eRERarRKr32t2qBn9lhlg%2BVq7fDbJDhasp%2BfueW9brOscdULv7vntlselnZpadlKH5fSRYvN16ytdJgT4KBGGzVqtNFmv4yndzWrt8WjqSCNGFZUNOxN2Xq8K6%2FD47Et%2FKg7ajAc9edHgz8ciU9%2BPgBKt4%2FTzlslzAAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .policyicon_purpose_10,
#iubenda_policy .policyicon_purpose_15 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .policyicon_purpose_13 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAJ1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9PhkGkAAAADHRSTlMAECBAUHCQoLDA4PB7ua%2BoAAAAa0lEQVR42p3QQQ6AIAxE0aEIFdr7n1eMxIAOMfEt%2B9sF4IOkYt5YSTKO1Qd6p%2BQP6Zqrvyjd7zdiLJggO5VReajwhR%2FBnDIoDwrhQcAfkhd%2FtQO0KDqf1A0kmEZgDjk2AZPzPoJo6wFEYOsHFFISOn%2BKxfoAAAAASUVORK5CYII%3D);
}

#iubenda_policy .policyicon_purpose_14 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwUGBwgJCgsMDQ4PASl6hyAAAAfklEQVR42pXRUQ6EMAgE0MEWW21l7n9btanJWnE3%2Bz4hhCHgq5jKooKD6FJS7OVQebIIROOphlY3dqrsLABidJgg0ZWw0bWBL%2F5vvO%2FIdGVM%2Fh0TMNMx47DwYcVJKgdV0MgwUwSXfA%2F0QY2dKW7CxutHA1lbHMFTavE9qsBvOztlFTRVyS%2BYAAAAAElFTkSuQmCC);
}

#iubenda_policy .policyicon_purpose_16 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAJFJREFUeF6V0NsOAyEIRVE6I4rFwvn%2Fr63N3CR10nQnPK2IUdpbpKmsorJQqOKTl2xeRhDsycMgA7QDGkmfq9cI%2FvNEhGcAO8CowAbAGTEwX1XDKvYNnJM7f78clVqfydOlgwRIG6S1TwDdQEnD3cv1iWw4f54VQ1qfUO5QDDGYVLNCmOQ5O2Ea8R2kP8FWobvefhoT%2FSVCMbAAAAAASUVORK5CYII%3D);
}

#iubenda_policy .icon_ribbon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAW0lEQVR42u3OwQkAIQxE0XSWVrazlJpdQdGDC0pQEf7A3ELmibsPV1V9pDM%2FAQAAAAAAAAAAAAAAEAXY1%2BcUwCQnITYD6niL2ASo4z3EaoDKf8qNBQHxArgK8ALKMXCw%2Bim7vwAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .icon_owner {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwQGBwgJCgsMDQ4PC8YWy5AAAAiElEQVR42o2QwRKFIAhFIcwyRP7%2Fb3uNlkBv0dkw3jODd4AbPHhNC7xAafqjYBRZOzUa0cHmc9IbiZsefIFtiuQ68RS7FUkNnwTWmRewLE9ewSPh73dfCgJbzxkiRxcrDGJhWVxa5MqYr1HzcLSPRo2ojcoZAcyV2F1MzaPoxIqcP4gGkP5BcAIxQBCQ7o5t3AAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .icon_general {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
}

#iubenda_policy .icon_temple_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABwklEQVR42s3Wu0vDUBjGYS%2BLsbZSSaWgguAFHFztYEmFbp0E%2FwOrgotQFyetOKiTLqKCWCenbl20S8FFERydBLt1KV7QwUGR4094hQymjYpi4SGH9zvf%2BUgCoQ3GmF%2F1dwNq%2FRzHaUwkEn24lP73rEaL%2FwEcZmEcJexiDyfKrG8P0OG9OIDBrCvPKMuh98sDaApiAmWYj8fiqg%2FjSrWy9gbrDlDzEHIwLi9YRieiWMOrakIPvZ4DKHYhjTsYD%2Be48Kqrdwpdnw1I4RAFbCKHHWxhX%2BtjHGFb2ZbynHoKOiNV7x3YrnWLrmFFWqvm6vH7DmK4ho0l5NGGCialoiyvPbZ6Yn4GOHhCFBsoIQSDOTHKStoTVY%2FjZ0Act7CxiiICqGJaqsqK2mOrJ%2F6VARGs6ZA2ZTNyq6yoPZF%2FNWC0xiOaEq9HNOpnQBIG3djFGcLKFsQoO8UOepQl%2FQyIIKtP9BjSylcwghhWlKWR0N4sIp4D1NCKDgR0DSIEGxbCYikLibvH8voWNWMdD6jiEXe4waOye1GdGntc9Qcso8nrDtoxgEFdIcp81INed7CIBWQw%2F00ZnbH42YAXef4RnfNn%2FyreALybXwSLU3v7AAAAAElFTkSuQmCC);
}

#iubenda_policy .icon_box_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABv0lEQVR42t3Vz0uTcRzAcUMyWhmWdAgqkKDMiBHt0A%2FYsploddwpO1gk0l%2BQEEQU0iUPgd0LIpCOCh2KkG4GTaI6mqAmWhG0CQ5jfXsdntN4bJPRxS%2B84IFnz%2Ff97Nl4Pg0hhP9qkwT%2BtTKZTCN7uM0qeTrZuc4ltQVs0EqK%2B3xmigc8Z5E39HOI7TUFfHAbJxngNQtM0kdzg5VOp7c4TvKYeeZ4wmXaYgNONHGcW7zjA6Mkqzy%2BZq4zzgxj5DhcGUgxwzSZ9TasEtvFCL%2F4WBm4SKDAPXrZV%2BPGWznFVSYIhMpAliILhEieO5ynMWbjDgZ4xjxlAn%2FiAt0skeMG44TIT8boo51zDPOeEqFSXKAnCrRHd5fgNMN8IrDCFLOUCVB74CsdZBlkL03s5xG%2FCcAGA73M0cIViswyQisn%2BFZPoIdljkWP6AIvKDFIiuV6Al2s8JIciSh0hqNk%2BVHvNwiRNd5ylwNRqKvewNnYv1z0O%2FCQ7xsIFCsDCS4xyhKhwiprhCqmGaIz%2Fm0qxBGu8YoCoYpFntLNwbS3ba3zYDdJhshTohwpMEE%2FbeyoMg%2BqTrQWbvKFSSxDJn5tsqH%2FF0QW2NxzTlYFAAAAAElFTkSuQmCC);
}

#iubenda_policy .icon_tools_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABdklEQVR42rXVzyuDcRzA8WU3ctOag0RiO5mEg8MKcaJc%2FAlcdxppKPlZdpeTE0dqxZWaaEnh7DShlYuJbG0f78Nz%2BPj2zfM89Wz1qmff5%2FPdu%2B27tpCINNTfJzySyeQGhkPGg7UljBtrEbxDMOkaYCiKJ8QtgR0cGWt9EMe8l8AIBAOWwCGKxtqMCux5CSRQxyW61QtNo4yCEVhWgRzCboFm5CF4xTVuUYUgZQTOVeAZHV4OeRSfEMMFWoxAVt2%2FQtQ14Gzsx43anEWr5Vx61Eza9Qz%2B%2BXw71S09M6hm1v0GVtXmWCMCa2pz3BpgXc1kAg2w1oW0mjnGRJCBOYjhLshAO96MQCroM3hQMzUMBfkOZvEDUU78BFbUxl5LYBdiqPgJbKmNCUtg2xL48PpTMYVvFFDEPdqCDDwi51xHIVi0%2FAGJoew18IJ957oJFWx6CHx5DSygjgPkUULM8i2qGoFTP4ecgaCEMcshh5FFDYIzRGyBhvoF3n%2ByMxzF1ykAAAAASUVORK5CYII%3D);
}

#iubenda_policy .icon_paper_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABYklEQVR42t3VP0vDQBjH8Tq4SKXiWFwcRHARVyspgq9AOjkoLoKTcymk%2BjKKYDcnO6iLKPgHRJ11aVGkgt2M2IiWlobzKzyB4zBwBGvBwIdc7rnjR8ITLqGU6qm%2FC8hms%2Bo39TWgg0fUUJX7PV7RlVo1Qtsm4Ckhl%2BM4A%2BGY%2BQJ8TCYiLmp1m4AHWbyGa%2BxiFEtQMneIY80RTtCyCahJwDpOsYc0FtHGDS5wbjjDp03AM6aRRhIpTGAbH5jBEJIm208UwMcbmuIFHam34KEZ0tYGNgEeXFEUW9jAKvLYRFHjCs8moIEMZpHRxnNwzJrx3Oj%2Fj2a%2BQbT4b%2BDBjcmz6iK9M6LF76I6UnHY%2Fgc%2BythB2YK%2B1tcC%2Ful54COPMeT0tsMBxsW%2B0dY52VPAe1RAgAoGw7OA8QoUbvVzgPEU7qS2HJ4b33tRQfBTQBcl4wBZgMIlhrX5EVxJbd7YU0JXD%2BipLw8sG8DBrCLzAAAAAElFTkSuQmCC);
}

#iubenda_policy .icon_man_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABLUlEQVR42t3VsUrDUBSAYaWtSwSp6NAH6CbOVSHZ9QEEBx9AEPc6OAh9Bp0KDg6igqs4tktXFQQnKQiKiNRAp%2BT4C2cImqbXc3Fp4VsazvlpuUmmRORfTUig6BNFURnbeISoB2yhlDPiHvhegF18Qn74wA5KPoE13EFGuEXDFGBwBoeQMQ5QsQSqOHUInGDOEljElUPgEguWwDzOHQJnqFoCszh2CBwhsAQq2EdSsDxFE2XrMV3HS0HgFRs%2B90ENNwWBa9TMgTAMp%2FWR8J6z%2FA2bXo8K%2FRXLuB9xFy%2BZAwwHWMUF4pxArNdWnE%2BRLm2ghS76SMecoj66aOls8CvAl3XsoYNnDCF%2FNNTZju6qZwO93L%2FBLkYvGxggVeIpVYNsoI0nJBBPie5qT8ZL%2FwsLLryq%2FnnTDAAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .icon_keyhole_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABIUlEQVR42rXUP0vCQRjA8bIoiAppLmwQEmehSX5RLTb2Huw9RGODLvUCXG0Kp16ALhENNRjRXLRlIeafJOyevkNu14M89%2FPgMx133%2BF4bkZEpkrdHK8oipIoowuHK2QxGxzgkk1cYgD3R9BEAQlzgMOruMA3xOMZuZDAEToQxRkWrYEHOIiiibQ1IBMEOtixBn4mCLSRtwbqIKIGbrFhDRzgA6I4xpw1kMA5ep6LHa6RCh20ddx4Aq%2FYj2OSF1D3BJ6QjCOQwosn8IbtOAK7%2BPQEvlAcf3ghj1zE4J9HrmApJDCPkvLZ3WMtJLCCmjLNLWRCAmk8KkPWxyHMgQLaSmCIk5BJPkVfCYxQxbI10MBQCTjcYcsaeMcIomhhTwtM1S9g12NLa1YPRQAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .iub_base_container {
  border-radius: 3px;
  background: #ffffff;
  color: #6b6b6b;
  position: relative;
}

#iubenda_policy .iub_base_container > .close {
  background: transparent
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAw1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4eHh1dXUAAAAAAAAAAAAAAAAAAABfX18AAAAAAAAAAAAAAAAAAAAAAAA2NjYAAAAAAAArKyvJycne3t7X19eFhYWxsbGVlZWsrKzr6%2BvLy8vJycnv7%2B%2Fp6enS0tLi4uL09PTv7%2B%2F8%2FPz7%2B%2Fv4%2BPj39%2FcAAABPT09fX19vb2%2F%2F%2F%2F9S%2BfXQAAAAPHRSTlMAAgMEBQYHCgsMDQ4PEhMWGRobHB8gIiMkJScoKSs0NT1DRUpMWF5gjpOYmaGjpr%2FIys3S1dnZ7vP09vfFQC13AAAA9ElEQVR42oXQZ6%2BCMBiG4aeCAoqiuPceuPes8P7%2FX6Xn5RgBTbw%2BNO3dpG2KH0RcM5JJQ4uLUE2UnSM9HZ1y4r0TM50z%2FTs7ZuyVSysKWJX8DZHeUsg2zUfpU4qY6gBE8xLtl6YAtAnP79Ij8uSdFxMNsHY8lVK67nPgxc4CisQ8yTxiRaBGPvcvu%2BSrAY1vvQHUv51TByqHz3sPFcCef75zbgOpwZUiroMUoFSX0b6sKgDMzjqc1x2Tvznb2wTzppf1P1q1u7PTq55mXVuFT7Va48X%2BRnTbL8YtizMTRqHdH45Gw367YAgEKHoml8%2FnMroC9gCKfVabzD1q%2BwAAAABJRU5ErkJggg%3D%3D)
    no-repeat;
  border: none;
  display: block;
  position: absolute;
  z-index: 110;
  top: -10px;
  right: -9px;
  height: 24px;
  width: 23px;
}

#iubenda_policy p {
  line-height: 19px;
  margin: 0;
  padding-top: 11px;
}

#iubenda_policy address {
  margin: 0;
  line-height: inherit;
  display: inline;
}

#iubenda_policy a {
  font-weight: normal;
  border-bottom: 1px solid #f0f0f0;
}

#iubenda_policy .iub_content {
  position: relative;
  padding: 25px 30px;
  margin: 0 auto;
  border-radius: 3px 3px 0 0;
}

#iubenda_policy #wbars {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

#iubenda_policy #wbars .horizontal {
  display: none;
}

#iubenda_policy .iub_header {
  border-bottom: 1px dotted #dfdfdf;
  padding-bottom: 25px;
  position: relative;
}

#iubenda_policy .iub_header p {
  margin: 0;
  padding: 0;
}

#iubenda_policy .iub_header img {
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
}

#iubenda_policy h1,
#iubenda_policy h2,
#iubenda_policy h3 {
  color: #3f3f3f;
  margin: 0;
}

#iubenda_policy h1 + p,
#iubenda_policy h2 + p,
#iubenda_policy h3 + p {
  padding-top: 5px;
}

#iubenda_policy h1 {
  font-size: 19px;
  font-weight: normal;
  line-height: 23px;
  margin-bottom: 5px;
}

#iubenda_policy h2 {
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
  padding-top: 21px;
}

#iubenda_policy h3 {
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  padding-top: 24px;
}

#iubenda_policy h3 + p {
  padding-top: 0;
}

#iubenda_policy .iconed ul li h3 {
  padding-top: 10px;
  color: #615e5e;
}

#iubenda_policy h4 {
  font-size: 13px;
  font-weight: bold;
  padding-top: 19px;
  margin-bottom: 0;
}

#iubenda_policy h4:first-child {
  padding-top: 0;
}

#iubenda_policy ul.for_boxes,
#iubenda_policy ul.for_boxes > li,
#iubenda_policy ul.unstyled,
#iubenda_policy ul.unstyled > li {
  list-style: none;
  padding: 0;
  margin: 0;
}

#iubenda_policy ul.for_boxes {
  zoom: 1;
}

#iubenda_policy ul.for_boxes:before,
#iubenda_policy ul.for_boxes:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}

#iubenda_policy ul.for_boxes:after {
  clear: both;
}

#iubenda_policy .half_col {
  float: left;
  width: 50%;
  zoom: 1;
}

#iubenda_policy .half_col:before,
#iubenda_policy .half_col:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}

#iubenda_policy .half_col:after {
  clear: both;
}

#iubenda_policy .half_col:nth-child(2n + 1) > * {
  margin-right: 15px;
}

#iubenda_policy .half_col:nth-child(2n) > * {
  margin-left: 15px;
}

#iubenda_policy .half_col + .one_line_col,
#iubenda_policy .half_col + .iub_footer {
  border-top: 1px dotted #dfdfdf;
}

#iubenda_policy .one_line_col {
  zoom: 1;
  float: left;
  width: 100%;
  border-bottom: 1px dotted #dfdfdf;
}

#iubenda_policy .one_line_col:before,
#iubenda_policy .one_line_col:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}

#iubenda_policy .one_line_col:after {
  clear: both;
}

#iubenda_policy .one_line_col > ul.for_boxes > li {
  float: left;
  width: 50%;
}

#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) {
  clear: left;
}

#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 15px;
}

#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n) {
  clear: right;
}

#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 15px;
}

#iubenda_policy .one_line_col.wide {
  width: 100%;
}

#iubenda_policy .one_line_col.wide > ul.for_boxes > li {
  clear: both;
  width: 100%;
}

#iubenda_policy .one_line_col.wide > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 0;
}

#iubenda_policy .one_line_col.wide > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 0;
}

#iubenda_policy ul.normal_list {
  list-style: disc;
  display: block;
  padding-top: 11px;
}

#iubenda_policy ul.normal_list li {
  list-style: disc;
  float: none;
  line-height: 19px;
  margin: 5px 25px;
}

#iubenda_policy .simple_pp > ul > li {
  padding-bottom: 21px;
}

#iubenda_policy .simple_pp > ul > li > ul .iconed {
  padding-left: 40px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 2px;
  background-position-y: 26px;
  background-position: 2px 26px;
}

#iubenda_policy .simple_pp .for_boxes > .one_line_col > ul.for_boxes {
  margin-top: 0;
}

#iubenda_policy .legal_pp .one_line_col {
  float: none;
  border-top: 0;
  padding-bottom: 21px;
}

#iubenda_policy .legal_pp .one_line_col > ul.for_boxes {
  margin-top: 21px;
}

#iubenda_policy .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n + 1) {
  clear: left;
  float: left;
}

#iubenda_policy .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n) {
  float: right;
  clear: right;
}

#iubenda_policy .legal_pp .definitions {
  margin-top: 21px;
}

#iubenda_policy .legal_pp .definitions .expand-click.w_icon_24 {
  margin-top: -11px;
  padding: 14px 10px 12px 45px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 5px;
  background-position-y: 0;
  background-position: 5px 0;
}

#iubenda_policy .legal_pp .definitions .expand-content {
  padding-left: 5px;
  padding-right: 5px;
}

#iubenda_policy .wrap p {
  display: inline-block;
}

#iubenda_policy .iub_footer {
  clear: both;
  position: relative;
  font-size: 11px;
}

#iubenda_policy .iub_footer p {
  font-size: 11px;
  padding: 0;
}

#iubenda_policy .iub_content .iub_footer {
  padding: 24px 0;
}

#iubenda_policy .iub_content .iub_footer p {
  margin: 10px 0;
  clear: both;
}

#iubenda_policy .iub_content .iub_footer .show_comp_link {
  display: block;
  float: right;
}

#iubenda_policy .iub_container > .iub_footer {
  min-height: 21px;
  background-color: #f6f6f6;
  color: #717171;
  padding: 30px;
  box-shadow: 0 -1px 6px #cfcfcf;
  border-radius: 0 0 3px 3px;
}

#iubenda_policy .iub_container > .iub_footer > .btn {
  position: absolute;
  top: 25px;
  right: 30px;
}

#iubenda_policy .iub_container > .iub_footer .btn {
  padding: 0px 24px;
  line-height: 29px;
}

#iubenda_policy .iub_container > .iub_footer .button-stack {
  margin: -4px 0;
}

#iubenda_policy .iub_container > .iub_footer .button-stack .btn + .btn {
  margin-left: 5px;
}

#iubenda_policy .iub_container > .iub_footer img {
  margin: -4px 3px 0;
  vertical-align: middle;
  width: 70px;
  height: 25px;
}

#iubenda_policy .wide {
  width: 150px;
}

@media (max-width: 767px) {
  #iubenda_policy .legal_pp .one_line_col,
  #iubenda_policy .legal_pp .half_col {
    width: 100%;
  }

  #iubenda_policy .legal_pp .one_line_col > ul.for_boxes > li,
  #iubenda_policy .legal_pp .half_col > ul.for_boxes > li {
    clear: both;
    width: 100%;
  }

  #iubenda_policy .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
  #iubenda_policy .legal_pp .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
    margin-right: 0;
  }

  #iubenda_policy .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
  #iubenda_policy .legal_pp .half_col > ul.for_boxes > li:nth-child(2n) > div {
    margin-left: 0;
  }

  #iubenda_policy .iub_header img {
    position: static;
    margin-bottom: 12.5px;
  }

  #iubenda_policy .iub_content > .iub_footer .show_comp_link {
    position: static;
    display: inline;
  }

  #iubenda_policy .iub_container > .iub_footer {
    padding: 20px;
  }

  #iubenda_policy .iub_container > .iub_footer .btn {
    top: 15px;
    right: 15px;
  }

  #iubenda_policy .iub_base_container > .close {
    content: 'X';
    color: #000;
    font-size: 11px;
    line-height: 18px;
    padding: 0;
    text-align: center;
    border-radius: 30px;
    display: block;
    /* background: #fff url(../design/images/close_big.png) no-repeat; */
    background-position-x: 18px;
    background-position-y: 18px;
    background-position: 18px 18px;
    position: absolute;
    z-index: 110;
    top: -10px;
    right: -10px;
    margin: 5px 5px 0 0;
    height: 57px;
    width: 60px;
    box-shadow: 0 1px 1px #000000;
  }

  #iubenda_policy .iub_base_container > .close.small {
    width: 14px;
    line-height: 14px;
    border-radius: 9px;
    padding: 0;
  }
}

@media (max-width: 480px) {
  html#iubenda_policy {
    padding: 0;
  }

  #iubenda_policy body {
    padding: 0;
  }

  #iubenda_policy .iub_base_container,
  #iubenda_policy .iub_container {
    margin: 0;
  }

  #iubenda_policy .half_col:nth-child(2n + 1) > * {
    margin-right: 0;
  }

  #iubenda_policy .half_col:nth-child(2n) > * {
    margin-left: 0;
  }

  #iubenda_policy .one_line_col,
  #iubenda_policy .half_col {
    width: 100%;
  }

  #iubenda_policy .one_line_col > ul.for_boxes > li,
  #iubenda_policy .half_col > ul.for_boxes > li {
    clear: both;
    width: 100%;
  }

  #iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
  #iubenda_policy .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
    margin-right: 0;
  }

  #iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
  #iubenda_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
    margin-left: 0;
  }

  #iubenda_policy .iub_header img {
    position: static;
    margin-bottom: 12.5px;
  }

  #iubenda_policy .iub_content > .iub_footer .show_comp_link {
    position: static;
    display: inline;
  }

  #iubenda_policy .iub_container > .iub_footer {
    padding: 10px;
    text-align: center;
  }

  #iubenda_policy .iub_container > .iub_footer .btn {
    position: static;
    width: auto;
    display: block;
    margin: 10px auto 0 auto;
    max-width: 200px;
  }

  #iubenda_policy .iub_container > .iub_footer.in_preview {
    padding: 30px 10px;
  }

  #iubenda_policy .iub_content {
    padding-left: 20px;
    padding-right: 20px;
  }

  #iubenda_policy .iub_base_container > .close {
    content: 'X';
    color: #000;
    font-size: 11px;
    line-height: 18px;
    padding: 0;
    text-align: center;
    border-radius: 30px;
    display: block;
    /* background: #fff url(../design/images/close_big.png) no-repeat; */
    background-position-x: 18px;
    background-position-y: 18px;
    background-position: 18px 18px;
    position: absolute;
    z-index: 110;
    top: -10px;
    right: -10px;
    margin: 5px 5px 0 0;
    height: 57px;
    width: 60px;
    box-shadow: 0 1px 1px #000000;
  }

  #iubenda_policy .iub_base_container > .close.small {
    width: 14px;
    line-height: 14px;
    border-radius: 9px;
    padding: 0;
  }
}

#iubenda_policy.iubenda_fixed_policy .iub_base_container {
  max-width: 800px;
}

#iubenda_policy.iubenda_fixed_policy .iub_container {
  margin-left: auto;
  margin-right: auto;
  zoom: 1;
}

#iubenda_policy.iubenda_fixed_policy .iub_container:before,
#iubenda_policy.iubenda_fixed_policy .iub_container:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}

#iubenda_policy.iubenda_fixed_policy .iub_container:after {
  clear: both;
}

#iubenda_policy.iubenda_fluid_policy #wbars {
  overflow-y: auto;
  box-shadow: none;
  height: auto;
}

#iubenda_policy.iubenda_fluid_policy .iub_container {
  margin-top: 30px;
  margin-bottom: 30px;
}

#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n + 1) > * {
  margin-right: 0;
}

#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n) > * {
  margin-left: 0;
}

#iubenda_policy.iubenda_fluid_policy .one_line_col,
#iubenda_policy.iubenda_fluid_policy .half_col {
  width: 100%;
}

#iubenda_policy.iubenda_fluid_policy .one_line_col > ul.for_boxes > li,
#iubenda_policy.iubenda_fluid_policy .half_col > ul.for_boxes > li {
  clear: both;
  width: 100%;
}

#iubenda_policy.iubenda_fluid_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
#iubenda_policy.iubenda_fluid_policy .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 0;
}

#iubenda_policy.iubenda_fluid_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
#iubenda_policy.iubenda_fluid_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 0;
}

#iubenda_policy.iubenda_embed_policy .iub_base_container {
  background: none;
}

#iubenda_policy.iubenda_embed_policy .iub_container > .iub_footer {
  box-shadow: none;
  border-radius: none;
}

#iubenda_policy.iubenda_embed_policy .expand-click {
  cursor: default;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .iub_base_container {
  color: #666;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h2 {
  font-size: 24px;
  padding-top: 50px;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h3 {
  color: #444;
  font-size: 20px;
  padding-top: 45px;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h4 {
  font-size: 16px;
  padding-top: 40px;
  color: #555;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h5 {
  font-size: 14px;
  padding-top: 35px;
  margin-bottom: 0;
  color: #666;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h6 {
  font-size: 12px;
  color: #505050;
  text-transform: uppercase;
  padding-top: 32px;
  margin-bottom: 0;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions {
  margin-top: 60px !important;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content {
  padding: 25px 15px !important;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content h4 {
  font-size: 15px !important;
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions:before {
  content: '';
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 -10px;
  position: relative;
  top: -45px;
}

#iubenda_policy.iubenda_vip_policy.iubenda_fixed_policy .iub_container {
  max-width: 660px;
  padding-top: 80px;
}

#iubenda_policy.iubenda_vip_policy .iub_base_container {
  color: #6b6b6b;
}

#iubenda_policy.iubenda_vip_policy p {
  font-size: 14px;
  line-height: 1.6;
}

#iubenda_policy.iubenda_vip_policy .allcaps,
#iubenda_policy.iubenda_vip_policy p.allcaps,
#iubenda_policy.iubenda_vip_policy ul.allcaps li {
  -webkit-font-feature-settings: "smcp" !important;
          font-feature-settings: "smcp" !important;
  font-variant: small-caps !important;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans,
    Helvetica Neue, sans-serif !important;
}

#iubenda_policy.iubenda_vip_policy ul li {
  font-size: 14px;
  line-height: 1.6;
}

#iubenda_policy.iubenda_vip_policy h1 {
  font-size: 30px;
  color: #141414;
  line-height: 1.6;
  margin-bottom: 60px;
}

#iubenda_policy.iubenda_vip_policy h2 {
  font-size: 18px;
  color: #141414;
  line-height: 1.6;
  padding-top: 50px;
  padding-bottom: 15px;
}

#iubenda_policy.iubenda_vip_policy h3 {
  color: #141414;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

#iubenda_policy.iubenda_vip_policy .legal_pp .one_line_col {
  padding-bottom: 50px;
}

#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n + 1) > * {
  margin-right: 0;
}

#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n) > * {
  margin-left: 0;
}

#iubenda_policy.iubenda_vip_policy .one_line_col,
#iubenda_policy.iubenda_vip_policy .half_col {
  width: 100%;
}

#iubenda_policy.iubenda_vip_policy .one_line_col > ul.for_boxes > li,
#iubenda_policy.iubenda_vip_policy .half_col > ul.for_boxes > li {
  clear: both;
  width: 100%;
}

#iubenda_policy.iubenda_vip_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
#iubenda_policy.iubenda_vip_policy .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 0;
}

#iubenda_policy.iubenda_vip_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
#iubenda_policy.iubenda_vip_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 0;
}

#iubenda_policy.iubenda_vip_policy .definitions,
#iubenda_policy.iubenda_vip_policy .iub_footer,
#iubenda_policy.iubenda_vip_policy .for_boxes {
  color: #59636d;
}

#iubenda_policy.iubenda_vip_policy .definitions h3,
#iubenda_policy.iubenda_vip_policy .iub_footer h3,
#iubenda_policy.iubenda_vip_policy .for_boxes h3,
#iubenda_policy.iubenda_vip_policy .definitions p,
#iubenda_policy.iubenda_vip_policy .iub_footer p,
#iubenda_policy.iubenda_vip_policy .for_boxes p,
#iubenda_policy.iubenda_vip_policy .definitions li,
#iubenda_policy.iubenda_vip_policy .iub_footer li,
#iubenda_policy.iubenda_vip_policy .for_boxes li {
  font-size: 13px;
}

#iubenda_policy.iubenda_vip_policy .w_icon_24 {
  background-image: none;
}

#iubenda_policy.iubenda_vip_policy .box_10.expand .expand-click.w_icon_24 {
  padding-left: 10px;
}

#iubenda_policy.iubenda_vip_policy .box_primary {
  border-color: #e0e0e0;
  border-bottom-color: #d3d3d3;
  box-shadow: none;
}

#iubenda_policy.iubenda_vip_policy .box_primary h3 {
  color: #333;
}

#iubenda_policy.iubenda_vip_policy .tc-deactivated h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

#iubenda_policy.iubenda_vip_policy .legal_pp .one_line_col {
  padding-bottom: 21px;
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  font-style: normal;
  src: url(/fonts/proxima-nova/proximanova-light.woff2) format('woff2'), url(/fonts/proxima-nova/proximanova-light.woff) format('woff'), url(/fonts/proxima-nova/proximanova-light.ttf) format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-style: normal;
  src: url(/fonts/proxima-nova/proximanova-regular.woff2) format('woff2'), url(/fonts/proxima-nova/proximanova-regular.woff) format('woff'), url(/fonts/proxima-nova/proximanova-regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-style: normal;
  src: url(/fonts/proxima-nova/proximanova-semibold.woff2) format('woff2'), url(/fonts/proxima-nova/proximanova-semibold.woff) format('woff'), url(/fonts/proxima-nova/proximanova-semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'Omnes';
  font-weight: 400;
  font-style: normal;
  src: url(/fonts/omnes-font/07dc45de3c2d37fcd503045055b2253b.woff2) format('woff2'), url(/fonts/omnes-font/07dc45de3c2d37fcd503045055b2253b.woff) format('woff'), url(/fonts/omnes-font/07dc45de3c2d37fcd503045055b2253b.ttf) format('truetype');
}
@font-face {
  font-family: 'Bariol-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/bariol/Bariol.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/montserrat-v15-latin/montserrat-v15-latin-200.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url('/fonts/montserrat-v15-latin/montserrat-v15-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-200.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-200.woff') format('woff'), /* Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-200.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/montserrat-v15-latin/montserrat-v15-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'), url('/fonts/montserrat-v15-latin/montserrat-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-300.woff') format('woff'), /* Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-300.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/montserrat-v15-latin/montserrat-v15-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('/fonts/montserrat-v15-latin/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/montserrat-v15-latin/montserrat-v15-latin-600.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('/fonts/montserrat-v15-latin/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-600.woff') format('woff'), /* Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-600.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/montserrat-v15-latin/montserrat-v15-latin-800.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url('/fonts/montserrat-v15-latin/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-800.woff') format('woff'), /* Modern Browsers */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/montserrat-v15-latin/montserrat-v15-latin-800.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

.ant-page-header {
  background-color: #ffffff;
}

/*.ant-page-header.has-footer{
  padding-bottom:12px;
}
*/
.dashboard_page_content
, .dashboard_page_header_wrap{
	margin: 0px auto;
	width:500px;
 	padding: 24;
	background: '#fff';
}

/*.ant-menu-sub.ant-menu-inline > .ant-menu-item
, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title */
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title
{
  text-transform:uppercase;
}

.dashboard_page_header_wrap .ant-statistic-content{
	text-align: center!important;
}

/* HACK FOR PAGEHEADER */

span.view_requests{
  line-height: 26px;
  color: rgba(0, 0, 0, 0.85)
}
.ant-page-header-ghost {background-color: #fff!important;}
/*HACK FOR FILTERS*/
.filter_form{
  background-color: #fff;
  padding:24px 12px !important; 
  margin-bottom: 24px !important; 
}

.filter_form.ant-form-inline{
  display: flex!important;
  flex-direction: row!important;
  justify-content: flex-start!important;
  align-items: flex-start;
}
.filter_form.ant-form-inline .ant-form-item{
  display: flex!important;
  flex-direction: column;
  /*justify-content: flex-end*/;
  margin-right: 8px;

}

.ant-form-item{
  margin-bottom: 0px;
}

.filter_form .ant-form-item-label{
  text-align: left!important;
  height:20px!important;
  line-height:20px!important;
}

/*.ant-form-item-label{
  height:20px!important;
  line-height:20px!important;
}*/

.filter_form .ant-form-item-label > label{
  color: rgba(0, 0, 0, 0.65)!important;
}

.filter_form .ant-select-dropdown, /* << HACK: Should be like this */
.ant-select-dropdown{
  min-width: 250px!important;
}

.filter_wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin: 0 0px;
  padding-bottom: 24px;
  background-color: #fff;
}


/* form styling */
h3.fileds_header{
  padding-left: 50px;
  margin-top:20px;
}


/* *********************** */

/*HACK FOR TABLES*/

.ant-table-title{
  min-height: 56px;
}

.react-resizable
, .vertical_align_top .react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle
, .vertical_align_top .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.vertical_align_top tr{
  vertical-align: top;
}
.vertical_align_top th{
  vertical-align: middle;
  border-right: 1px solid #e8e8e8;
}
.ant-table-row.odd{
  background-color:#F9F9F9;
}
.ant-table-row.even{
  background-color:#FFF;
}

.ant-table-thead > tr > th
, .ant-table-tbody > tr > td:not(.amount_col) {
    padding: 8px 8px;
    /*overflow-wrap: break-word;*/
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > th span{
  font-weight: bold;
}

.ant-table-tbody > tr > td:first-child, .ant-table-tbody > tr > th:first-child{
  padding-left:16px;
}

.ant-table-row.unconfirmed{
  background-color:rgba(255, 0, 0, 0.2);
}

.amount_col{
  padding-right: 0px!important;
  padding-left: 0px!important;
  width:150px!important;
}

.ant-divider-horizontal {
    margin: 6px 0 !important;
}

.row_tx_title{
  font-size: 16px;
  font-weight: 600;
}

.ant-table-wrapper .ant-pagination {display: none!important;}
.ant-table-footer {
    display: flex;
    justify-content: flex-end;
}

.ant-table-body {
    transition: opacity .3s;
    color: rgba(0, 0, 0, .9);
    font-size: 13px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;

}
/* *********************** */


/*
* HACK for SELECT CONTROLS
*/

/*.money-transfer__select .ant-select-selection, .money-transfer__row .ant-select-selection
,.money-transfer__select .ant-input:not(.bordered_input):hover, .money-transfer__row .ant-input:not(.bordered_input):hover{
  border:none!important;
}

.money-transfer__select .ant-select-focused .ant-select-selection, .money-transfer__select .ant-select-selection:focus, .money-transfer__select .ant-select-selection:active
, .money-transfer__row .ant-select-focused ,.money-transfer__row .ant-select-selection:focus, .money-transfer__row .ant-select-selection:active
, .money-transfer__select .ant-input:hover, .money-transfer__row .ant-input:hover
{
  box-shadow: none!important;
}


.money-transfer__select .ant-select, .money-transfer__row .ant-select{
  font-size: 15px!important;
}
.money-transfer__select .ant-select-selection__rendered , .money-transfer__row .ant-select-selection__rendered{
    margin-left: 0px!important;
}
*/
.ant-upload.ant-upload-disabled  .anticon{
  color:gray!important;
}
.ant-upload.ant-upload-disabled .ant-upload-text{
  color:gray!important;
}


.select-price__currency .ant-select-selection-selected-value
{
  font-size:40px ;  
}

.request_details_title
{
  font-weight: 600!important;
  font-size: 1.6em;
  line-height: 1.6em;
}

/* FONTAWESOME Icons for transactions */
/*.icon_color_default { color:gray; }*/
.knoi-drag-icon .anticon{font-size:22px!important;}
.icon_color_default 
  , .icon_color_default .ant-upload-drag-icon 
  , .icon_color_default .knoi-drag-icon .anticon
  { color:rgba(0, 0, 0, 0.99)!important; }
.icon_color_green   
  , .icon_color_green .ant-upload-drag-icon
  , .icon_color_green .knoi-drag-icon .anticon
  { color:#3db389!important; }

.display_block{display:block!important;}
.display_block.addresse-avatar{top:0px;}
.addresse-avatar{top:0px!important;}

/* FORMS -> LABELS are shown if text is not empty ... NOT WORKING! */
.with_labels .money-transfer__row:has(>input:placeholder-shown )  label
{
  background-color: #00ff00!important;
  /*display:none!important;*/
}
.with_labels .money-transfer__row:has(>input:placeholder-shown ) label
{
  display:none!important;
}
.with_labels .money-transfer__row label{
  display:block!important;
  /*color:rgb(153, 153, 153);*/
  color:rgba(0, 0, 0, 0.85);
}
.with_labels .money-transfer__row label::before,
.with_labels .money-transfer__row label::after{
  display:none!important;
}

/* AMOUNT DISPLAYS */
.c-ticket__row .c-ticket__title_small
, .c-ticket__row .price-tag_small
, .c-ticket__row .price-tag_small .price-tag.price-tag-symbol
, .c-ticket__row .price-tag_small .price-tag.price-tag-fraction{
  font-weight: 400!important;
  font-size: 1.2em!important;
  line-height: 1.6em!important; 
}


/* PDV */
.circled_action_type{
  line-height: 2.2;
  text-align: center;
}
.flex_center, .flex_row, .flex_column{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}
.flex_row{
    flex-direction: row!important;
}
.flex_row_start{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow:hidden;
}
.flex_column{
    flex-direction: column!important;
}

.mp-box__shadow__full {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}


/*EDITABLE TABLES*/
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 4px 11px;
}

.ant-list-item .right{
  /*display: flex!important;
  flex-direction: row!important;
  justify-content: flex-end!important;*/
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

}
.ant-list-item .right .last-1{
  margin-right:8px;
}
.ant-list-item .right .last{
  min-width:130px;
  display: flex!important;
  flex-direction: column!important;
  justify-content: flex-end!important;
  align-items: flex-end;
}
.ant-list-item .right .last .money{
  font-weight: bold;
}

.column_editable{
  background-color:#e6f7ff;
}
.column_action{
  background-color:#f2f4f5;
}

.no_label .ant-form-item-label {display:none;}

.c-detail_small {
    width: 600px !important;
    margin: 0 auto;
    padding: 0;
    /*font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;*/
    font-weight: 400;
  }

.c-detail_small .ui-list
, .c-detail_small .ui-row{
  background-color: #f0f0f0!important;
}

.c-detail .ui-list.shorter .ui-row{
  height:40px;
  margin-top:4px;
  margin-bottom:4px;
}
.c-detail .ui-list.shorter .ui-row a
, .c-detail .ui-list.shorter .ui-row a:link
, .c-detail .ui-list.shorter .ui-row a:visited
, .c-detail .ui-list.shorter .ui-row a:active{
  text-decoration: none;
}

.money-transfer__select .ant-select-search--inline .ant-select-search__field{
  line-height: inherit!important;
}

.certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}

.ant-menu-item{
  /*padding-left: 36px!important;*/
}

.ant-menu-item svg.svg-inline--fa{
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
}
.ant-table-footer{
  padding: 8px!important;
}


/* pull to refresh :) */

.code-box-actions {
    padding-top: 12px;
    text-align: center;
    border-top: 1px dashed #ebedf0;
    opacity: .7;
    transition: opacity .3s;
}

.code-box-actions:hover
{
  opacity:1
}

.redoButton{
  color: rgba(0,0,0,0.85)!important;
}
.redoButton:hover{
  color: #40a9ff!important;
}

select .anticon {
  color:rgba(0,0,0,1)!important;
}













/*.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title*/
/*.account_menu_item li, ul#accounts_menu_key li{
  height:auto;
  min-height: 40px;
  background-color: #ff6699;
}*/

.account_menu_item .menu_account_container, .menu_account_container{
  display:         flex;
  flex-direction:  column;
  justify-content: flex-start;
  align-items:     flex-start;
  padding-left:    28px; 
  margin-top:      -8px; 
}
.account_menu_item .menu_account_name, .menu_account_name{
  font-weight:     600;
  text-transform:  lowercase!important;
}

.menu_account_permission{
  font-size:       0.85em;
  line-height:     1em;
}
.menu_account_permission span{
  font-weight:     bold;
}
.menu_account_type{
  font-size:       0.85em;
  line-height:     1em;
}
.menu_account_type span{
  font-weight:     bold;
}



.menu_account_item{
  padding:  16px 8px 16px 24px;
  color:    rgba(0, 0, 0, 0.65);
}

.menu_account_item .anticon{
  min-width: 14px;
  margin-right: 10px;
  color: #000;
}
.menu_account_item .menu_account_name{
  font-size:   18px;
  line-height: 18px;
}
.menu_account_item .menu_account_container{
  padding-left:    28px; 
  margin-top:      4px;
}
.menu_account_item .menu_account_permission{
  font-size:       10px;
  line-height:     10px;
}
.menu_account_item .menu_account_permission span{
  font-weight:     bold;
}
.menu_account_item .menu_account_type{
  font-size:       10px;
  line-height:     10px;
}
.menu_account_item .menu_account_type span{
  font-weight:     bold;
}

.ant-menu-submenu-title svg.svg-inline--fa{
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
}

.menu_balance_wrapper{
  display:         flex;
  flex-direction:  row;
  justify-content: flex-start;
  align-items:     flex-start;
  padding:16px 8px;
}
.menu_balance_container{
  display:         flex;
  flex-direction:  column;
  justify-content: flex-start;
  align-items:     flex-end;
  flex-grow:       1;
}
.menu_balance_container button{
  font-size: 24px;
}
.menu_balance_currency{
  /*line-height:  40px;*/
  font-size:      12px;
  /*flex-grow:    3;
  text-align:   right; */
}
.menu_balance_container .small{
  font-size:10px;
  line-height:10px;
  padding-right: 5px;
}


.omnes_isologo{
  /*font-family: Omnes;*/
  font-family: 'Bariol-Regular';
  font-size:   20px!important;
  line-height: 20px!important;
  font-weight: 400;
  line-height: 1.1;
  color:       #444;
/*  color:        rgb(0, 160, 221);*/
}

.omnes_isologo small {
  font-weight: 400;
  line-height: 1;
  color: #bbb;
}



.load_more_txs{
  color: rgb(105, 123, 140);
  width: 100%;
  background-color: #f0f0f0;
  margin: 4px 0px;
  font-size: 12px;
  line-height: 10px;
  height: 24px;
}

span.uppercase {text-transform: uppercase;}
span.bold {font-weight: bold;}

div.headerList tr{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
}
div.headerList td{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  flex:1 1;
}
div.headerList td .ant-descriptions-item-label{ flex:1 1; }
div.headerList td .ant-descriptions-item-content{flex:3 1; align-self: flex-start;}


.col_icon_widget {
  display:          flex;
  flex-direction:   row;
  justify-content:  center;
}
.col_icon{flex: 0 0 49px;}
.col_widget{flex:1 1;}






/* ******************************************************* */
/* SIDE MENU items */
.ant-layout-sider-children ul.ant-menu li.ant-menu-item {
  height: auto;
  min-height: 40px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin:0px!important;
  /*line-height: 40px;*/
}


.ant-layout-sider-children ul.ant-menu li.ant-menu-item a{
  overflow: hidden;
  overflow-wrap: normal;
  height: auto;
  max-height: 40px;
  line-height: 14px;
}

.ant-layout-sider-children ul.ant-menu li.ant-menu-item a span{
  /*overflow-wrap: normal;*/
  width:100%;
  white-space:normal; 
  line-height: 14px;
}

.ant-menu-item.is_root_menu a:first-child {
    flex-basis: auto!important;
}
/* ******************************************************* */

.margin_top_20{
  margin-top: 20px;
}

.info_page .desc .ant-typography {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.info_page .desc .name{font-size:16px; flex:3 1; padding-right:16px; text-align: right; align-self: flex-end;}
.info_page .desc .value{font-size:16px; font-weight:strong;flex:6 1;}

.powered_by{padding-left:0px;}
/* HACK FOR COLORS AND BUTTONS */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.danger_color {
  color: #ff4d4f;
}
.danger_color:hover {
  color: #ff7875;
}
/* ROBADO DE MP */
.u-button-link {
  color: #009ee3;
  cursor: pointer;
  font-size: 16px;
  border: 0;
  padding: 0;
  background-color: transparent;
  line-height: inherit;
}
/*
* Copied from https://www.mercadopago.com.ar/activities
* List Styles
*/
.l-container {
  max-width: 65.25em;
  padding: 1.5em 2em;
  margin: 0 auto;
}
/*
* Copied from https://www.mercadopago.com.ar/activities
* List Styles
*/
.u-clearfix:after,
.u-clearfix:before {
  display: table;
  content: "";
}
.c-detail {
  /*width: 40.5em;*/
  width: 700px !important;
  margin: 0 auto;
  padding: 24px 0 0 0;
  /*font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;*/
  font-weight: 400;
}
.c-detail .ui-row {
  position: relative;
  border-bottom: 0;
}
.c-detail .ui-row:last-child {
  border: 0;
}
.c-detail .ui-info-row--background-gray {
  background-color: #f8f8f8;
}
.c-detail.bottom {
  padding: 8px;
  background-color: #fff;
  text-align: center;
}
/*
.ui-row, .ui-row__link {
    display: flex;
    align-items: center;
    padding: 24px;
    background: #fff;
}
*/
.ui-row__col--heading {
  padding-right: 12px;
}
.ui-row__col--actions,
.ui-row__col--heading {
  flex-shrink: 0;
}
.rectangle-avatar {
  padding-right: 0.25em;
}
.ui-avatar {
  position: relative;
}
.rectangle-avatar .ui-avatar__content--border {
  border: none;
  max-width: 8.75em;
}
.rectangle-avatar .ui-avatar__content {
  border-radius: 0;
  width: inherit;
}
.ui-avatar__content--border {
  border: 0.0625em solid #ccc;
}
.ui-avatar__content {
  width: 3.125em;
  height: 3.125em;
  font-size: 16px;
  overflow: hidden;
  border-radius: 50%;
}
.ui-avatar__content--small {
  width: 2.5em;
  height: 2.5em;
  font-size: 14px;
  overflow: hidden;
  border-radius: 50%;
}
.rectangle-avatar .ui-avatar__content--border img {
  object-fit: contain;
}
.ui-avatar__content--img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ui-row__col--content {
  flex-grow: 1;
  overflow: hidden;
}
.ui-info-row--medium .ui-info-row__title {
  font-size: 18px;
}
.ui-info-row__details li {
  display: inline;
}
.ui-info-row__details li {
  /*color: #999;*/
}
/*.ui-info-row__details li:before {
    content: "-";
    padding: 0 .3125em;
}*/
/*
* Header with no style
*/
.c-header-detail {
  line-height: 16px ;
}
.c-header-detail.padding-top-1em {
  padding-top: 0px;
  padding-bottom: 0px;
}
.c-header-detail__head {
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  padding: 0 0 8px 0;
  /*padding-bottom: 2em;*/
}
.c-header-detail__head.u-clearfix {
  height: auto;
}
.c-header-detail__title {
  float: left;
  max-width: 550px;
}
.c-header-detail__actions {
  float: right;
  text-align: right;
  min-width: 145px;
  width: auto;
}
/*
* List
*/
.c-ticket-payment .c-ticket__title,
.c-ticket-payment .price-tag,
.c-ticket-subtotal .c-ticket__title,
.c-ticket-subtotal .price-tag {
  font-weight: 400;
}
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.ui-list {
  padding: 0;
  /*background: #fff;*/
  overflow: hidden;
  border-radius: 0.25em;
  box-shadow: 0 0.07em 0.125em 0 rgba(0, 0, 0, 0.15);
  overflow: inherit;
  margin-bottom: 12px;
  /*
    * ListItem (card)
    */
}
.ui-list li .c-ticket {
  border-radius: 0.25em 0.25em 0 0;
}
.ui-list li .c-ticket .c-ticket__section:last-child {
  border-bottom: none;
}
.ui-list li .c-ticket .c-ticket__section {
  margin: 0 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item {
  padding-top: 1.5em;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__title {
  font-weight: 300;
  padding-right: 0.3125em;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount {
  font-weight: 300;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .c-ticket-payment .price-tag,
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .c-ticket-subtotal .price-tag {
  font-size: 1.375em;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .price-tag {
  overflow: visible;
  display: inline-block;
  vertical-align: text-bottom;
  line-height: 1em;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .price-tag .price-tag-symbol {
  margin-right: 0.1em;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .price-tag .price-tag-cents-text,
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .price-tag .price-tag-decimal-separator,
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .price-tag .price-tag-symbol-text {
  position: absolute;
  font-size: 0;
}
.ui-list li .c-ticket .c-ticket__section .c-ticket__item .c-ticket__row .c-ticket__amount .price-tag .price-tag * {
  float: left;
}
.ui-list li .ui-list .ui-row:only-child,
.ui-list li .ui-list .ui-row:only-child .ui-row__link {
  border-radius: 0.25em;
}
.hidden {
  display: none!important;
}
.name_value_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}
.name_value_row.vertical_centered {
  align-items: center;
}
.name_value_row .row_name {
  flex: 1 1;
}
.name_value_row .row_name.centered {
  align-self: center;
  justify-self: center;
}
.name_value_row .row_name.flex_fixed_width_5em {
  flex: 0 0 5em;
}
.name_value_row .row_value {
  flex: 2 1;
  font-weight: normal;
}
.name_value_row .row_value.wider {
  flex: 6 1;
}
.name_value_row .row_value.bold {
  font-weight: bold;
}
.ui-list .ui-row:first-child,
.ui-list .ui-row:first-child .ui-row__link {
  border-radius: 0.25em 0.25em 0 0;
}
.c-detail .ui-row:before {
  content: "";
  position: absolute;
  left: 1.5em;
  bottom: -0.0625em;
  right: 1.5em;
  border-bottom: 0.0625em solid #eee;
  z-index: 1;
}
.ui-avatar__content {
  width: 3.125em;
  height: 3.125em;
  font-size: 16px;
  overflow: hidden;
  border-radius: 50%;
}
ui-avatar__icon {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.ui-avatar__content {
  width: 3.125em;
  height: 3.125em;
  font-size: 16px;
  overflow: hidden;
  border-radius: 50%;
}
.ui-avatar__content--icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ui-avatar__content--initials {
  border: 0.0625em solid #1890FF;
  background: #e6f7ff;
  line-height: 2.2;
  text-align: center;
}
.ui-avatar__content--initials span {
  display: block;
  margin: 0 auto;
  font-size: 1.375em;
  color: #1890FF;
  text-transform: uppercase;
  line-height: 2.2;
}
.c-notes {
  box-shadow: none;
  margin-bottom: 0;
}
.c-notes__add-notes {
  line-height: 30px;
}
.c-notes__add-notes {
  width: 100%;
  border: 0;
}
/*
* ############################################################
*  POSTS!!!!!!!!!!!!!!!!!!
* NO LOGGED
*/
.logo_container {
  width: 120px;
  height: 31px;
}
.home_logo {
  width: 200px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: 12px;
  flex: 0 0 200px;
  align-self: flex-start;
}
.home_logo a {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.home_logo a img {
  width: 175px;
  height: auto;
}
.mobile_device .hide_if_mobile {
  display: none;
}
.mobile_device .home_logo {
  width: 200px;
  height: 40px;
}
.mobile_device .home_logo a {
  height: 40px;
}
.mobile_device .home_logo a img {
  height: 40px;
  width: auto;
}
.ant-layout {
  /*background-color:#fff;*/
}
.home .ant-layout-header {
  background: #fff;
  text-align: right;
  display: flex;
  flex-direction: row;
  /*padding:4px;*/
  /*justify-content:;*/
  align-items: center;
  /*.home_logo{
    margin-left: 12px;
    flex:0 0 200px;
    align-self: flex-start;
    a{
      display:flex;
      flex-direction:row;
      align-items: center;
      justify-content:flex-start;
      img{
        width: 175px;
        height: auto;
      }
    }
  }*/
}
.home .ant-layout-header .ant-menu-horizontal {
  border: none;
  align-self: flex-end;
  flex: 1 1;
}
.mobile_device .home .ant-layout-header .ant-menu-horizontal {
  /*padding-top:40px!important;  */
}
.mobile_device .ant-layout-header {
  height: 64px;
  padding: 8px;
  line-height: 64px;
}
.mobile_device.authenticated .ant-layout-header {
  height: 48px;
  padding: 0px;
  line-height: 48px;
}
.home .ant-layout-content {
  display: flex!important;
  flex-direction: column!important;
  justify-content: flex-start !important;
  padding: 0 24px;
  margin-top: 24px;
}
.home.post .ant-layout-content {
  margin-top: 0px;
}
.home .content {
  /*background: #fff;
  padding: 24px;*/
  flex: 1 1;
  flex-basis: calc(100% - 53px);
  display: flex!important;
  flex-direction: column!important;
  justify-content: flex-start !important;
  align-items: stretch;
}
.home .content .content_wrapper {
  flex: 1 1;
  display: flex!important;
  flex-direction: column!important;
  justify-content: flex-start !important;
}
.home .content .content_wrapper.post_view {
  background-color: #fff;
}
.home .content .title {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}
.home .content .title h1 {
  padding: 20px 0px 12px;
  font-size: 32px;
  color: #1890FF;
}
.home .content .title h5 {
  font-size: 24px;
}
.home .content .content_wrapper .action_wrapper {
  background-color: #fff;
  margin-bottom: 22px;
  flex: 6 1;
  padding: 20px 50px;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.home .content .content_wrapper .action_wrapper .drag-icon .anticon {
  color: #1890FF;
  font-size: 22px;
}
.home .content .content_wrapper .action_wrapper .ant-upload.ant-upload-drag {
  padding: 20px 0px;
  background-color: #fff;
}
.home .content .content_wrapper .proofs_list {
  background-color: #fff;
  flex: 4 1;
  padding: 0px 50px;
}
.home .content .content_wrapper .proofs_list .title {
  text-align: center;
  font-size: 24px;
  padding: 24px 8px;
}
.home .content .content_wrapper .proofs_list .title span.number {
  color: #1890FF;
}
.home .content .content_wrapper .proofs_list .post,
.post_list_container .post {
  display: flex;
  flex-direction: row!important;
  justify-content: flex-start !important;
  overflow: hidden!important;
  line-height: 22px;
}
.home .content .content_wrapper .proofs_list .post .post_content {
  flex: 5 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.home .content .content_wrapper .proofs_list .post .post_content .bottom {
  flex: 1 1;
  font-size: 12px;
}
.home .content .content_wrapper .proofs_list .post .post_content .top {
  flex: 3 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.home .content .content_wrapper .proofs_list .post .post_content .top .file {
  flex: 3 1;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
}
.home .content .content_wrapper .proofs_list .post .post_content .top .action {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.home .content .content_wrapper .proofs_list .ant-list-header {
  background-color: #fafafa;
}
.home .content .content_wrapper .proofs_list .post.table_view .file {
  /*flex-wrap: wrap; */
  overflow-wrap: break-word;
  overflow: hidden;
  padding-left: 8px;
  flex: 3 1;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
}
.home .content .content_wrapper .proofs_list .post.table_view .timestamp {
  flex: 0 0 150px;
  align-self: center;
  color: rgba(0, 0, 0, 0.85);
}
.home .content .content_wrapper .proofs_list .post.table_view .hash {
  /*flex-wrap: wrap; */
  overflow: hidden;
  overflow-wrap: break-word;
  flex: 5 1;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
}
.home .content .content_wrapper .proofs_list .post.table_view .action {
  padding-right: 8px;
  flex: 1.5 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.home .content .content_wrapper .proofs_list .post .timestamp,
.post_list_container .post .timestamp {
  flex: 0 0 150px;
  align-self: center;
}
.home .content .content_wrapper .proofs_list .post .timestamp_avatar,
.post_list_container .post .timestamp_avatar {
  flex: 0 0 150px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home .content .content_wrapper .proofs_list .post .timestamp_avatar .anticon,
.post_list_container .post .timestamp_avatar .anticon {
  font-size: 20px;
}
.home .content .content_wrapper .proofs_list .post .timestamp_avatar span,
.post_list_container .post .timestamp_avatar span {
  margin-top: 4px;
  color: purple;
}
.home .content .content_wrapper .proofs_list .post .file,
.post_list_container .post .file {
  flex: 1 1;
  align-self: flex-start;
}
.home .content .content_wrapper .proofs_list .post .hash,
.post_list_container .post .hash {
  flex: 3 1;
  align-self: flex-start;
}
.hash_content {
  flex-wrap: wrap;
  word-wrap: break-word;
}
.home .content .content_wrapper .proofs_list .post .created_at,
.post_list_container .post .created_at {
  color: #87cb16;
}
.home .content .content_wrapper .proofs_list .post .action,
.post_list_container .post .action {
  flex: 1 1;
  align-self: flex-end;
}
.ant-list-header .post div {
  font-weight: bold;
}
.home .ant-list-item post {
  overflow: hidden!important;
}
.name_value_container {
  display: flex!important;
  flex-direction: row!important;
  justify-content: flex-start !important;
  flex-wrap: wrap;
}
.name_value_container .name {
  flex: 1 1;
}
.name_value_container .value {
  flex: 2 1;
  overflow: hidden;
  word-wrap: break-word;
  flex-wrap: wrap;
}
.link_withMargin_left_8 {
  margin-left: 8px;
}
.home .content .content_wrapper .post_wrapper {
  flex: 1 1;
  padding: 20px 50px;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.ant-layout-header a {
  text-decoration: none!important;
}
@media screen and (max-width: 576px) {
  .home .ant-layout-content {
    padding: 0 0px;
    margin-top: 0px;
  }
  .post .post_content_wrapper {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .post .post_actions {
    width: 100%;
    padding-top: 10px;
    min-height: 22px;
    display: block;
    text-align: right;
  }
  .home .content .content_wrapper .post_wrapper {
    padding: 0px 0px;
  }
  .ant-result {
    padding: 16px 8px;
  }
}
/*
* ############################################################
*  POSTS LOGGED!!!!!!!!!!!!!!!!!!
*  
*/
.post_list_container {
  background-color: #fff;
  margin-top: 24px;
  padding: 8px;
}
.post_content_wrapper {
  display: block;
  width: 100%;
  overflow: hidden;
}
.post_content_wrapper .post_ex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.post_content_wrapper .post_ex .first_box {
  flex: 0 0 150px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*.anticon{
        font-size:20px;
      }*/
}
.post_content_wrapper .post_ex .first_box .icon_numFiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.post_content_wrapper .post_ex .first_box .icon_numFiles .anticon {
  font-size: 20px;
  flex: 1 1;
}
.post_content_wrapper .post_ex .first_box .icon_numFiles .numFiles {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  flex: 3 1;
  margin-left: 8px;
}
.post_content_wrapper .post_ex .first_box span {
  margin-top: 4px;
  color: purple;
}
.post_content_wrapper .post_ex .second_box {
  flex: 5 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.post_content_wrapper .post_ex .second_box .header {
  flex: 0 0 20px;
  font-size: 16px;
}
.post_content_wrapper .post_ex .second_box .content {
  flex: 0 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 4px;
}
.post_content_wrapper .post_ex .second_box .content .main {
  font-size: 19px;
  color: rgba(0, 0, 0, 0.85);
  padding: 4px 0px;
}
.post_content_wrapper .post_ex .second_box .content .secondary {
  font-size: 14px;
}
.post_content_wrapper .post_ex .second_box .footer {
  flex: 0 0 15px;
  font-size: 10px;
}
.post_content_wrapper .post_ex .third_box {
  padding-left: 6px;
  flex: 3 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.post_content_wrapper .post_ex .third_box .dms {
  font-size: 14px;
}
.post_content_wrapper .post_ex .third_box .lat_lon {
  font-size: 10px;
}
.post_content_wrapper .post_ex .third_box .address {
  font-size: 12px;
}
.post_content_wrapper .post_ex .action {
  padding-left: 6px;
  flex: 2 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.post_content_wrapper .post_ex .action .author {
  font-size: 12px;
}
.buckets .bucket,
.users .bucket,
.buckets .user,
.users .user {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;
}
.buckets .bucket .top,
.users .bucket .top,
.buckets .user .top,
.users .user .top {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 8px;
}
.buckets .bucket .top .left,
.users .bucket .top .left,
.buckets .user .top .left,
.users .user .top .left {
  flex: 5 1;
}
.buckets .bucket .top .left .title,
.users .bucket .top .left .title,
.buckets .user .top .left .title,
.users .user .top .left .title {
  padding-bottom: 4px;
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
}
.buckets .bucket .top .left .description,
.users .bucket .top .left .description,
.buckets .user .top .left .description,
.users .user .top .left .description {
  line-height: 16px;
  font-size: 14px;
  font-weight: 100;
}
.buckets .bucket .top .right,
.users .bucket .top .right,
.buckets .user .top .right,
.users .user .top .right {
  flex: 2 1;
}
.buckets .bucket .bottom,
.users .bucket .bottom,
.buckets .user .bottom,
.users .user .bottom {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.buckets .bucket .bottom .left,
.users .bucket .bottom .left,
.buckets .user .bottom .left,
.users .user .bottom .left {
  align-self: flex-end;
  flex: 5 1;
}
.buckets .bucket .bottom .left .created_at,
.users .bucket .bottom .left .created_at,
.buckets .user .bottom .left .created_at,
.users .user .bottom .left .created_at {
  font-size: 14px;
  font-weight: 100;
  color: purple;
}
.buckets .bucket .bottom .right,
.users .bucket .bottom .right,
.buckets .user .bottom .right,
.users .user .bottom .right {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex: 5 1;
}
.buckets .bucket .bottom .right .nums,
.users .bucket .bottom .right .nums,
.buckets .user .bottom .right .nums,
.users .user .bottom .right .nums {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.buckets .bucket .bottom .right .numCats,
.users .bucket .bottom .right .numCats,
.buckets .user .bottom .right .numCats,
.users .user .bottom .right .numCats {
  margin-left: 8px;
}
.buckets .bucket .bottom .right .action,
.users .bucket .bottom .right .action,
.buckets .user .bottom .right .action,
.users .user .bottom .right .action {
  flex: 1 1;
  align-self: flex-end;
  text-align: right;
  padding-right: 7px;
}
.buckets.user_bucket .bottom .right,
.users.user_bucket .bottom .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.buckets.user_bucket .bottom .right .action,
.users.user_bucket .bottom .right .action {
  align-self: flex-end;
  padding-right: 0px!important;
}
.tagsContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.tagsContainer.gray_bg {
  width: 100%;
  background-color: #f6f6f6;
  padding: 4px;
  border-radius: 4px;
}
.tagsContainer .ant-tag {
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 8px;
}
.tagsContainer .bordered_input,
.tagsContainer .bordered_input:hover {
  width: 78px !important;
  border: 1px solid gray !important;
  border-radius: 4px!important;
  height: 30px!important;
  line-height: 30px!important;
  font-size: 12px;
  display: inline-block;
}
.bottom_bordered {
  border-bottom: 1px solid #ddd;
}
.ui-info-row__title span.small {
  font-size: 16px;
}
.ui-info-row__title span.big {
  font-size: 20px;
}
/*.is_root_menu{
  padding-left: 24px!important;
}
*/
.ant-menu-item.is_root_menu a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: 28px;
}
.ant-menu-item.is_root_menu a:hover {
  text-decoration: none;
}
.ant-menu-item.is_root_menu a:first-child {
  flex-basis: 24px;
}
.ant-menu-item.is_root_menu a span {
  line-height: 14px;
  overflow: hidden;
}
.post_wrapper {
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
}
.post_wrapper .header {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: flex-start;
}
.post_wrapper .header .upper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-start;
  padding: 4px 8px 0px 8px;
}
.post_wrapper .header .upper .bucket {
  font-size: 14px;
}
.post_wrapper .header .upper .category {
  padding-left: 6px;
  font-size: 14px;
}
.post_wrapper .header .title {
  font-size: 19px;
  font-weight: 400;
  padding: 8px;
}
.post_wrapper .content {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-start;
}
.post_wrapper .content .files {
  flex: 8 1;
}
.post_wrapper .content .info {
  flex: 8 1;
}
.post_wrapper .content .info .rows .row,
.post_wrapper .content .info .map .row {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
  padding: 4px 0px;
}
.post_wrapper .content .info .rows .row .label,
.post_wrapper .content .info .map .row .label {
  padding-right: 12px;
  text-align: right;
  line-height: 18px;
  font-size: 16px;
  flex: 2 1;
  align-self: center;
}
.post_wrapper .content .info .rows .row .value,
.post_wrapper .content .info .map .row .value {
  align-self: center;
  line-height: 18px;
  font-size: 16px;
  flex: 8 1;
}
.post_wrapper .content .info .rows .row .value.stronger,
.post_wrapper .content .info .map .row .value.stronger {
  font-weight: bold;
}
.post_wrapper .footer {
  width: 100%;
}
.post_wrapper .footer .row {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
  padding: 4px 0px;
}
.post_wrapper .footer .row .label {
  padding-right: 12px;
  text-align: right;
  line-height: 18px;
  font-size: 16px;
  flex: 5 1;
  align-self: center;
}
.post_wrapper .footer .row .value {
  align-self: center;
  line-height: 18px;
  font-size: 16px;
  flex: 8 1;
}
.map_container {
  margin-top: 24px;
  /*height: calc( 100% - 64px - 24px - 61px - 12px - 16px - 24px - 24px - 38px - 24px);*/
  /*height: calc( 100% - 287px);
  width: calc( 100% - 232px );*/
  height: calc(100% - 110px);
  width: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}
.map_container .map {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.loading_overlay {
  background-color: white;
  border: 1px solid #ccc;
  padding: 15px;
}
.infoWindow {
  background-color: white;
  border: 1px solid #ccc;
  padding: 15px;
  width: 400px;
  height: 225px;
}
.infoWindow .info .rows .row {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
}
.infoWindow .info .rows .row.button_container {
  flex-direction: row;
  justify-content: flex-end;
}
.infoWindow .info .rows .row .label {
  padding-right: 4px;
  line-height: 14px;
  font-size: 12px;
  flex: 0 0 100px;
  align-self: center;
}
.infoWindow .info .rows .row .value {
  align-self: center;
  line-height: 14px;
  font-size: 12px;
  flex: 2 1;
}
.infoWindow .info .rows .row .value.stronger {
  font-weight: bold;
}
.post.at_header.logged {
  display: none!important;
}
.post_carousel .slick-dots {
  /*background-color:#009ee3;*/
}
.post_carousel .slick-dots button {
  height: 6px!important;
}
.post_carousel .slick-dots li button {
  background-color: #009ee3 !important;
}
.post_carousel .slick-dots li.slick-active button {
  background-color: #364d79 !important;
}
.bucket_form {
  min-height: 400px;
}
.post_list_container {
  height: calc(100% - 110px);
  min-height: 500px;
}
.post_list_container .ant-carousel {
  overflow: hidden;
  display: block;
  height: 100%;
}
.post_list_container .ant-carousel .slick-slider {
  display: block;
  height: 100%;
}
.post_list_container .ant-carousel .slick-slider .slick-list {
  display: block;
  height: 100%;
}
.post_list_container .ant-carousel .slick-slider .slick-list .slick-track {
  display: block;
  height: 100%;
}
.post_list_container .ant-carousel .slick-slider .slick-list .slick-track .slick-slide {
  display: block;
  height: 100%;
}
.bucket_selector {
  width: 100%;
}
.bucket_selector .ant-form-item-children div.ant-select {
  width: calc(100% - 32px);
}
.bucket_selector button.redoButton {
  width: 32px;
  position: absolute;
  right: -32px;
  top: 0px;
}
.transactions_list_container {
  height: calc(100% - 110px);
  min-height: 500px;
  margin-top: 24px;
}
.buckets_list_container {
  height: calc(100% - 110px);
  min-height: 500px;
  margin-top: 24px;
  /*background-color:#fff;
  padding: 8px; */
}
.create_user .buckets_list_container {
  padding-left: 16px;
  padding-right: 16px;
}
.filter_container {
  padding: 8px;
  margin-bottom: 24px;
  display: flex!important;
  flex-direction: column!important;
  justify-content: flex-start !important;
  background-color: #fff;
}
.filter_container form {
  margin-bottom: 0px;
}
.filter_container.primary {
  border: 1px solid #1890FF;
}
.filter_container .search_file {
  align-self: flex-end;
}
.filter_buttons {
  padding-bottom: 4px;
  align-self: flex-end;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1 1;
  display: flex;
  flex-direction: row;
}
.filter_buttons.inline {
  align-self: unset;
  margin-top: 24px;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-btn > .svg-inline--fa + span,
.ant-btn > span + .svg-inline--fa {
  margin-left: 8px;
}
.post_wrapper {
  height: calc(100% - 10px);
  min-height: 400px;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
.post_wrapper .row {
  height: 100%;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
}
.post_wrapper .row .row_card {
  /* height:100%;*/
}
.post_wrapper .row .row_card.bottom_margin {
  margin-bottom: 16px;
}
.post_wrapper .row .row_card .signer_wrapper .request .requested_by {
  font-weight: bold;
}
.post_wrapper .row .row_card .signer_wrapper .request_actions {
  margin-top: 12px;
}
.post_wrapper .row .row_card .signer_wrapper .request_actions > button:not(:first-child) {
  margin-left: 24px;
}
.post_wrapper .row .row_card .signer_wrapper .request_actions button.approve {
  background-color: #52c41a;
  border-color: #52c41a;
}
.post_wrapper .row .row_card .signer_wrapper .request_actions button.approve:hover {
  background-color: #87d068;
  border-color: #87d068;
}
.post_wrapper .row .row_card .signer_wrapper .request_actions button.reject {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}
.post_wrapper .row .row_card .signer_wrapper .request_actions button.reject:hover {
  background-color: #f50;
  border-color: #f50;
}
.post_wrapper .row .details {
  margin-right: 16px;
  flex: 5 1;
}
.post_wrapper .row .details.dont_has_signature_request .row_card {
  height: 100%;
}
.post_wrapper .row .details,
.post_wrapper .row .tx {
  flex: 5 1;
}
.post_wrapper .row .details .details_wrapper,
.post_wrapper .row .tx .details_wrapper,
.post_wrapper .row .details .tx_wrapper,
.post_wrapper .row .tx .tx_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.post_wrapper .row .details .details_wrapper .details_row,
.post_wrapper .row .tx .details_wrapper .details_row,
.post_wrapper .row .details .tx_wrapper .details_row,
.post_wrapper .row .tx .tx_wrapper .details_row {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.post_wrapper .row .details .details_wrapper .details_row .label_value_mode,
.post_wrapper .row .tx .details_wrapper .details_row .label_value_mode,
.post_wrapper .row .details .tx_wrapper .details_row .label_value_mode,
.post_wrapper .row .tx .tx_wrapper .details_row .label_value_mode,
.post_wrapper .row .details .details_wrapper .details_row .label_value,
.post_wrapper .row .tx .details_wrapper .details_row .label_value,
.post_wrapper .row .details .tx_wrapper .details_row .label_value,
.post_wrapper .row .tx .tx_wrapper .details_row .label_value {
  padding: 0 1rem;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.post_wrapper .row .details .details_wrapper .details_row .label_value_mode .value,
.post_wrapper .row .tx .details_wrapper .details_row .label_value_mode .value,
.post_wrapper .row .details .tx_wrapper .details_row .label_value_mode .value,
.post_wrapper .row .tx .tx_wrapper .details_row .label_value_mode .value,
.post_wrapper .row .details .details_wrapper .details_row .label_value .value,
.post_wrapper .row .tx .details_wrapper .details_row .label_value .value,
.post_wrapper .row .details .tx_wrapper .details_row .label_value .value,
.post_wrapper .row .tx .tx_wrapper .details_row .label_value .value {
  font-weight: bold;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-all;
  margin-bottom: 16px;
}
.post_wrapper .row .details .details_wrapper .details_row .col1,
.post_wrapper .row .tx .details_wrapper .details_row .col1,
.post_wrapper .row .details .tx_wrapper .details_row .col1,
.post_wrapper .row .tx .tx_wrapper .details_row .col1 {
  flex: 1 1;
}
.post_wrapper .row .details .details_wrapper .details_row .col2,
.post_wrapper .row .tx .details_wrapper .details_row .col2,
.post_wrapper .row .details .tx_wrapper .details_row .col2,
.post_wrapper .row .tx .tx_wrapper .details_row .col2 {
  flex: 1 1;
}
.post_wrapper .row .details .details_wrapper .details_row .col3,
.post_wrapper .row .tx .details_wrapper .details_row .col3,
.post_wrapper .row .details .tx_wrapper .details_row .col3,
.post_wrapper .row .tx .tx_wrapper .details_row .col3 {
  flex: 1 1;
}
.post_wrapper .row .map_signature_wrapper {
  flex-grow: 0;
  height: auto;
  flex: 2 1;
  display: flex;
  flex-direction: column;
}
.post_wrapper .row .map_signature_wrapper .row_card_half {
  flex: 1 1;
}
.post_wrapper .row .map_signature_wrapper .row_card_half:first-child {
  margin-bottom: 16px;
}
.post_wrapper .row .map_signature_wrapper .signatures_status {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer {
  display: flex;
  flex-direction: column;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top {
  display: flex;
  flex-direction: row;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon {
  flex: 0 0 30px;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon .icon_circle {
  height: 24px;
  width: 24px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon .icon_circle.pending {
  background-color: #d9d9d9;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon .icon_circle.sent {
  border-radius: 50%;
  background-color: #1890FF;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon .icon_circle.read {
  border-radius: 50%;
  background-color: #FA8C16;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon .icon_circle.signed {
  border-radius: 50%;
  background-color: #1890FF;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon .icon_circle.approved {
  border-radius: 50%;
  background-color: #52C41A;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .icon .icon_circle.rejected {
  border-radius: 50%;
  background-color: #F5222D;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .top .name {
  flex: 1 1;
  font-size: 16px;
  font-weight: bold;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .bottom {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .bottom .hint {
  flex: 1 1;
  font-size: 14px;
  color: #8C8C8C;
}
.post_wrapper .row .map_signature_wrapper .signatures_status .signer .bottom .link {
  flex: 1 1;
  white-space: nowrap;
  overflow-wrap: break-word;
}
.post_wrapper .row .map_signature_wrapper .map_wrapper {
  width: 100%;
}
.post_wrapper .row .map_signature_wrapper img {
  width: 100%;
  height: auto;
}
.post_wrapper .row .files {
  flex: 5 1;
  margin-right: 16px;
}
.post_wrapper .row .tx {
  flex: 2 1;
}
.tag_coloured {
  margin-bottom: 4px!important;
  height: 16px;
  line-height: 14px;
  padding: 0 8px;
  margin: 0 4px 4px 0;
  max-width: 198px;
  font-size: 12px;
  font-weight: 600;
}
.mobile_device .mobile_pager {
  height: 48px;
  width: 100%;
  display: flex;
  background-color: #fff;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  padding: 0px;
  overflow: hidden;
}
.mobile_device .mobile_pager .left_buttons {
  background-color: #1890FF;
  flex: 0 0 40px;
  /*align-self: center;*/
  justify-self: flex-start;
}
.mobile_device .mobile_pager .left_buttons .backButton {
  color: #fff;
  height: 48px;
}
.mobile_device .mobile_pager .right_buttons {
  background-color: transparent;
  flex: 0 0 40px;
  /*align-self: center;*/
  justify-self: flex-start;
}
.mobile_device .mobile_pager .right_buttons .anticon {
  font-weight: bold;
  color: #314659;
}
.mobile_device .mobile_pager .title {
  white-space: nowrap;
  flex: 4 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  font-size: 18px;
  font-weight: bold;
}
.mobile_device .posts_mobile_header {
  white-space: nowrap;
  overflow: hidden;
  min-height: 40px;
  width: 100%;
  display: flex;
  background-color: #fff;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  padding: 8px;
}
.mobile_device .posts_mobile_header .left_buttons {
  flex: 3 1;
  /*align-self: center;*/
  justify-self: flex-start;
}
.mobile_device .posts_mobile_header .right_buttons {
  flex: 4 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mobile_device .posts_mobile_header .right_buttons > button:not(:first-child) {
  margin-left: 8px;
}
.mobile_device .buckets_list_container .bucket.ant-list-item {
  border-bottom: none!important;
  padding: 12px 0px 0px 0px;
}
.mobile_device .user .user_content_wrapper,
.mobile_device .bucket .bucket_content_wrapper,
.mobile_device .post .post_content_wrapper,
.mobile_device .post_details {
  padding: 8px;
  background-color: #fff;
  width: 100%;
}
.mobile_device .user .user_content_wrapper .ant-card-head,
.mobile_device .bucket .bucket_content_wrapper .ant-card-head,
.mobile_device .post .post_content_wrapper .ant-card-head,
.mobile_device .post_details .ant-card-head {
  padding: 0 8px;
}
.mobile_device .user .user_content_wrapper .ant-card-head .ant-card-head-title,
.mobile_device .bucket .bucket_content_wrapper .ant-card-head .ant-card-head-title,
.mobile_device .post .post_content_wrapper .ant-card-head .ant-card-head-title,
.mobile_device .post_details .ant-card-head .ant-card-head-title {
  padding: 8px 0;
}
.mobile_device .user .user_content_wrapper .ant-card-body,
.mobile_device .bucket .bucket_content_wrapper .ant-card-body,
.mobile_device .post .post_content_wrapper .ant-card-body,
.mobile_device .post_details .ant-card-body {
  padding: 8px;
}
.mobile_device .user .user_content_wrapper .key_value,
.mobile_device .bucket .bucket_content_wrapper .key_value,
.mobile_device .post .post_content_wrapper .key_value,
.mobile_device .post_details .key_value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.mobile_device .user .user_content_wrapper .key_value .key,
.mobile_device .bucket .bucket_content_wrapper .key_value .key,
.mobile_device .post .post_content_wrapper .key_value .key,
.mobile_device .post_details .key_value .key {
  flex: 1 1;
  font-weight: bold;
}
.mobile_device .user .user_content_wrapper .key_value .value,
.mobile_device .bucket .bucket_content_wrapper .key_value .value,
.mobile_device .post .post_content_wrapper .key_value .value,
.mobile_device .post_details .key_value .value {
  flex: 2 1;
}
.mobile_device .user .user_content_wrapper .key_value .value.buttons,
.mobile_device .bucket .bucket_content_wrapper .key_value .value.buttons,
.mobile_device .post .post_content_wrapper .key_value .value.buttons,
.mobile_device .post_details .key_value .value.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mobile_device .user .user_content_wrapper .key_value .value.wraped,
.mobile_device .bucket .bucket_content_wrapper .key_value .value.wraped,
.mobile_device .post .post_content_wrapper .key_value .value.wraped,
.mobile_device .post_details .key_value .value.wraped {
  overflow-wrap: break-word;
  overflow: hidden;
}
.mobile_device .transactions_list_container {
  height: calc(100% - 122px);
  min-height: 500px;
  margin-top: 12px;
}
.mobile_device .users .user.ant-list-item {
  border-bottom: none!important;
  padding: 12px 0px 0px 0px;
}
.mobile_device .posts_container .post.ant-list-item {
  border-bottom: none!important;
  padding: 12px 0px 0px 0px;
}
.mobile_device .post_details {
  margin-bottom: 8px;
}
.mobile_device .post_details.map_container {
  padding: 0px;
}
.mobile_device .post_details.map_container .ant-card-head {
  padding: 8px 16px;
}
.mobile_device .post_details.map_container .ant-card-body {
  padding: 0px;
}
.mobile_device .post_details.map_container a {
  float: right;
}
.mobile_device .post_details .ant-card-body > .key_value_ex:not(:first-child) {
  margin-top: 8px;
}
.mobile_device .post_details .key_value_ex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mobile_device .post_details .key_value_ex .key {
  flex: 1 1;
  font-weight: bold;
}
.mobile_device .post_details .key_value_ex .value {
  overflow-wrap: break-word;
  overflow: hidden;
  flex: 1 1;
}
.mobile_device .post_details .key_value_ex .value.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ant-layout-header {
  flex: 0 0 auto;
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
.ant-pro-global-header {
  line-height: 64px;
  height: 64px;
  overflow: hidden;
}
.mobile_device .ant-pro-global-header {
  line-height: 48px;
  height: 48px;
  overflow: hidden;
}
.dashboard {
  margin-top: 24px;
}
.dashboard h3 {
  font-weight: 600;
}
.mobile_device .dashboard {
  margin-top: 0px;
  padding: 8px;
}
.users_list_container {
  height: calc(100% - 110px);
  min-height: 500px;
  margin-top: 24px;
  /*background-color:#fff;
  padding: 8px; */
}
.mobile_device .users_list_container {
  height: calc(100% - 110px);
  min-height: 500px;
  margin-top: 12px;
  /*background-color:#fff;
  padding: 0px; */
}
.create_bucket {
  margin: 0 0px;
  padding: 24px;
  margin-top: 24px;
}
.mobile_device .create_bucket {
  margin: 0 0px;
  padding: 0px;
  margin-top: 12px;
}
.mobile_device .create_bucket .info-personal {
  padding: 12px;
}
.create_bucket_result {
  background-color: #fff;
  margin-top: 24px;
  padding: 8px;
}
.mobile_device .create_bucket_result {
  background-color: #fff;
  margin-top: 12px;
  padding: 0px;
}
.create_post {
  margin: 0 0px;
  padding: 24px;
  margin-top: 24px;
}
.mobile_device .create_post {
  margin: 0 0px;
  padding: 0px;
  margin-top: 12px;
}
.mobile_device .create_post .my-data {
  padding: 0px 12px 12px 12px;
}
.create_post_result {
  background-color: #fff;
  margin-top: 24px;
  padding: 8px;
}
.mobile_device .create_post_result {
  background-color: #fff;
  margin-top: 12px;
  padding: 0px;
}
.create_user {
  margin: 0 0px;
  padding: 24px;
  margin-top: 24px;
}
.mobile_device .create_user {
  margin: 0 0px;
  padding: 0px;
  margin-top: 12px;
}
.mobile_device .create_user .info-personal {
  padding: 12px;
}
.create_user_result {
  background-color: #fff;
  margin-top: 24px;
  padding: 8px;
}
.mobile_device .create_user_result {
  background-color: #fff;
  margin-top: 12px;
  padding: 0px;
}
.logo_mobile {
  padding-top: 10px;
  height: 130px;
  line-height: 130px;
}
.mobile_device .logo_mobile {
  padding-top: 10px;
  height: 84px;
  line-height: 84px;
}
.black_button {
  color: #000;
  /*  background: transparent !important;*/
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 2px;
}
/*
input.roberto:not(:placeholder-shown) + label ,
  input:focus:not(:placeholder-shown) + label {

    visibility: visible;
    border-bottom: 1px green solid;
  }

input.roberto:placeholder-shown + label {
  visibility: hidden;
  border-bottom: 1px red solid;
}

*/
.knoi_form input.ant-input,
.knoi_form input.ant-input:hover,
.knoi_form textarea.ant-input,
.knoi_form textarea.ant-input:hover,
.knoi_form select {
  padding: 5px 12px;
  display: inline-block;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
}
.knoi_form label {
  color: rgba(0, 0, 0, 0.65);
}
.money-transfer__row.knoi {
  padding: 0px;
  min-height: 1px;
}
.file_selector.knoi {
  padding: 14px 0px;
}
/*form.no_labels_form{
  .ant-form-item-label{
    display:none;
  }
}*/
.mp-box__actions.knoi {
  background-color: transparent;
}
.knoi .ant-upload.ant-upload-drag {
  background-color: transparent;
}
.dashboard .knoi_account_stats_container .ant-card-body {
  padding: 0px;
}
.dashboard .knoi_account_stat {
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 22px 16px;
  overflow: hidden;
}
.dashboard .knoi_account_stat .wrapper {
  display: flex;
  flex-direction: column;
}
.dashboard .knoi_account_stat .wrapper .top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.dashboard .knoi_account_stat .wrapper .top .left {
  flex: 1 1;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}
.dashboard .knoi_account_stat .wrapper .top .rightX {
  flex: 0 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  line-height: 24px;
}
.dashboard .knoi_account_stat .wrapper .top .rightX .svg-inline--fa {
  color: #1890FF;
  font-size: 20px;
  line-height: 24px;
}
.dashboard .knoi_account_stat .wrapper .top .rightX .total {
  font-weight: 600;
  margin-left: 6px;
  font-size: 24px;
  line-height: 24px;
}
.dashboard .knoi_account_stat .wrapper .bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.dashboard .knoi_account_stat .wrapper .bottom .right {
  flex: 2 1;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}
.dashboard .knoi_account_stat .wrapper .bottom .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 3 1;
  line-height: 24px;
}
.dashboard .knoi_account_stat .wrapper .bottom .left .anticon {
  color: #1890FF;
  font-size: 24px;
  line-height: 24px;
}
.dashboard .knoi_account_stat .wrapper .bottom .left .total {
  font-weight: 600;
  margin-left: 6px;
  font-size: 24px;
  line-height: 24px;
}
form.no_labels_form .ant-form-item-label {
  display: none;
}
form.no_labels_form .knoi.show_label .ant-form-item-label {
  display: inline-block !important;
}
.signature_widget {
  width: 100%;
  text-align: left;
}
.signature_widget_item_icon {
  margin-right: 4px;
}
.signature_widget_item .svg-inline--fa {
  color: #1890FF;
  margin-right: 4px;
}
.signature_widget_item .signature_widget_item_content {
  display: flex;
  /*height: 44px;*/
  flex-direction: column;
  justify-content: flex-start;
}
.signature_widget_item .signature_widget_item_content .hint {
  color: rgba(0, 0, 0, 0.45);
}
.signature_widget_item .signature_widget_item_content .hint .hint {
  display: none;
}
.ant-select-selection-selected-value .hint {
  display: none;
}
.signature_widget_dropdown .hint {
  display: block;
}
.signer_selector {
  display: flex;
  flex-direction: row;
}
.signer_selector_option {
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.signer_selector_option .left {
  flex: 2 1;
}
.signer_selector_option .right {
  flex: 0 0 90px;
  text-align: right;
}
.signer_selector_option .account_name {
  font-weight: bold;
}
.signer_selector_option .account_type {
  color: rgba(0, 0, 0, 0.7);
}
.signer_selector_option.me {
  background-color: #F6FFED;
}
.signer_selector .ant-select-selection--multiple .ant-select-selection__rendered > ul > li.ant-select-selection__choice {
  background-color: #FFF7E6;
  border: 1px solid #FA8C16;
  color: #FA8C16;
}
.signer_selector .ant-select-selection--multiple .ant-select-selection__rendered > ul > li .ant-select-selection__choice__remove {
  color: #FA8C16;
}
.signer_selector .signer_selector_select {
  flex: 1 1;
}
.signer_selector button {
  width: 24px;
  flex: 0 0 36px;
  margin-left: 12px;
}
.ant-form.knoi_form .tagsContainer .ant-tag.added_item {
  background-color: #E6FFFB;
  border: 1px solid #13C2C2;
  color: #13C2C2;
}
.ant-form.knoi_form .tagsContainer .ant-tag.added_item .anticon.anticon-close {
  color: #13C2C2;
}
.create_post .knoi_form .with_border_top,
.create_bucket .knoi_form .with_border_top {
  border-top: 1px solid #ddd;
  padding: 14px 0px;
}
.mobile_device .create_post .knoi_form .with_border_top,
.mobile_device .create_bucket .knoi_form .with_border_top {
  border-top: 1px solid #ddd;
  padding: 6px 0px;
}
.knoi_form .knoi .ant-row.ant-form-item {
  margin-bottom: 12px!important;
}
.td_flex_row {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
label.ant-checkbox-wrapper {
  display: block!important;
}
@media screen and (max-width: 576px) {
  .myNotification {
    width: 300px;
    margin-left: calc(100vw - 324px);
  }
}
@media screen and (min-width: 576px) {
  .myNotification {
    width: 600px;
    margin-left: -200px;
  }
}
.certificate_extra {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.certificate_extra .certificate_category {
  flex: 1 1;
  font-size: 11px;
}
.certificate_extra .certificate_category .certificate_category_describer {
  font-size: 10px;
  color: #8C8C8C;
}
.certificate_extra .certificate_signature_icon {
  flex: 1 1;
  color: #1890FF;
  text-align: right;
  align-self: flex-end;
}
.info-personal .form_header {
  padding: 34px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.info-personal .form_header .form_header_icon {
  text-align: center;
  flex: 2 1;
}
.info-personal .form_header .form_header_icon .anticon {
  font-size: 40px;
}
.info-personal .form_header .form_header_text {
  text-align: center;
  flex: 1 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
@media screen and (max-width: 576px) {
  .info-personal .form_header {
    padding: 14px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .info-personal .form_header .form_header_icon {
    text-align: center;
    flex: 0 0 58px;
    /* 17 24 17 */
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 24px;
  }
  .info-personal .form_header .form_header_text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    flex: 1 1;
  }
}
a.no_padding {
  padding: 0px;
}
.temp_alert_action {
  background-color: #ff4d4f;
}
.temp_alert_action:hover {
  border: 1px solid #ff4d4f;
}
.temp_alert_action .fa_icon {
  color: white;
}
.temp_alert_action:hover .fa_icon {
  color: #ff4d4f;
}

