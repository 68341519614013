@import url("/css/mp.css"); 
@import url("/css/mp_2.css"); 
@import url("/css/mp_3.css"); 
@import url("/css/mp_4.css"); 

.ant-page-header {
  background-color: #ffffff;
}

/*.ant-page-header.has-footer{
  padding-bottom:12px;
}
*/
.dashboard_page_content
, .dashboard_page_header_wrap{
	margin: 0px auto;
	width:500px;
 	padding: 24;
	background: '#fff';
}

/*.ant-menu-sub.ant-menu-inline > .ant-menu-item
, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title */
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title
{
  text-transform:uppercase;
}

.dashboard_page_header_wrap .ant-statistic-content{
	text-align: center!important;
}

/* HACK FOR PAGEHEADER */

span.view_requests{
  line-height: 26px;
  color: rgba(0, 0, 0, 0.85)
}
.ant-page-header-ghost {background-color: #fff!important;}
/*HACK FOR FILTERS*/
.filter_form{
  background-color: #fff;
  padding:24px 12px !important; 
  margin-bottom: 24px !important; 
}

.filter_form.ant-form-inline{
  display: flex!important;
  flex-direction: row!important;
  justify-content: flex-start!important;
  align-items: flex-start;
}
.filter_form.ant-form-inline .ant-form-item{
  display: flex!important;
  flex-direction: column;
  /*justify-content: flex-end*/;
  margin-right: 8px;

}

.ant-form-item{
  margin-bottom: 0px;
}

.filter_form .ant-form-item-label{
  text-align: left!important;
  height:20px!important;
  line-height:20px!important;
}

/*.ant-form-item-label{
  height:20px!important;
  line-height:20px!important;
}*/

.filter_form .ant-form-item-label > label{
  color: rgba(0, 0, 0, 0.65)!important;
}

.filter_form .ant-select-dropdown, /* << HACK: Should be like this */
.ant-select-dropdown{
  min-width: 250px!important;
}

.filter_wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin: 0 0px;
  padding-bottom: 24px;
  background-color: #fff;
}


/* form styling */
h3.fileds_header{
  padding-left: 50px;
  margin-top:20px;
}


/* *********************** */

/*HACK FOR TABLES*/

.ant-table-title{
  min-height: 56px;
}

.react-resizable
, .vertical_align_top .react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle
, .vertical_align_top .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.vertical_align_top tr{
  vertical-align: top;
}
.vertical_align_top th{
  vertical-align: middle;
  border-right: 1px solid #e8e8e8;
}
.ant-table-row.odd{
  background-color:#F9F9F9;
}
.ant-table-row.even{
  background-color:#FFF;
}

.ant-table-thead > tr > th
, .ant-table-tbody > tr > td:not(.amount_col) {
    padding: 8px 8px;
    /*overflow-wrap: break-word;*/
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > th span{
  font-weight: bold;
}

.ant-table-tbody > tr > td:first-child, .ant-table-tbody > tr > th:first-child{
  padding-left:16px;
}

.ant-table-row.unconfirmed{
  background-color:rgba(255, 0, 0, 0.2);
}

.amount_col{
  padding-right: 0px!important;
  padding-left: 0px!important;
  width:150px!important;
}

.ant-divider-horizontal {
    margin: 6px 0 !important;
}

.row_tx_title{
  font-size: 16px;
  font-weight: 600;
}

.ant-table-wrapper .ant-pagination {display: none!important;}
.ant-table-footer {
    display: flex;
    justify-content: flex-end;
}

.ant-table-body {
    transition: opacity .3s;
    color: rgba(0, 0, 0, .9);
    font-size: 13px;
    font-variant: tabular-nums;
    line-height: 1.5;

}
/* *********************** */


/*
* HACK for SELECT CONTROLS
*/

/*.money-transfer__select .ant-select-selection, .money-transfer__row .ant-select-selection
,.money-transfer__select .ant-input:not(.bordered_input):hover, .money-transfer__row .ant-input:not(.bordered_input):hover{
  border:none!important;
}

.money-transfer__select .ant-select-focused .ant-select-selection, .money-transfer__select .ant-select-selection:focus, .money-transfer__select .ant-select-selection:active
, .money-transfer__row .ant-select-focused ,.money-transfer__row .ant-select-selection:focus, .money-transfer__row .ant-select-selection:active
, .money-transfer__select .ant-input:hover, .money-transfer__row .ant-input:hover
{
  box-shadow: none!important;
}


.money-transfer__select .ant-select, .money-transfer__row .ant-select{
  font-size: 15px!important;
}
.money-transfer__select .ant-select-selection__rendered , .money-transfer__row .ant-select-selection__rendered{
    margin-left: 0px!important;
}
*/
.ant-upload.ant-upload-disabled  .anticon{
  color:gray!important;
}
.ant-upload.ant-upload-disabled .ant-upload-text{
  color:gray!important;
}


.select-price__currency .ant-select-selection-selected-value
{
  font-size:40px ;  
}

.request_details_title
{
  font-weight: 600!important;
  font-size: 1.6em;
  line-height: 1.6em;
}

/* FONTAWESOME Icons for transactions */
/*.icon_color_default { color:gray; }*/
.knoi-drag-icon .anticon{font-size:22px!important;}
.icon_color_default 
  , .icon_color_default .ant-upload-drag-icon 
  , .icon_color_default .knoi-drag-icon .anticon
  { color:rgba(0, 0, 0, 0.99)!important; }
.icon_color_green   
  , .icon_color_green .ant-upload-drag-icon
  , .icon_color_green .knoi-drag-icon .anticon
  { color:#3db389!important; }

.display_block{display:block!important;}
.display_block.addresse-avatar{top:0px;}
.addresse-avatar{top:0px!important;}

/* FORMS -> LABELS are shown if text is not empty ... NOT WORKING! */
.with_labels .money-transfer__row:has(>input:placeholder-shown )  label
{
  background-color: #00ff00!important;
  /*display:none!important;*/
}
.with_labels .money-transfer__row:has(>input:placeholder-shown ) label
{
  display:none!important;
}
.with_labels .money-transfer__row label{
  display:block!important;
  /*color:rgb(153, 153, 153);*/
  color:rgba(0, 0, 0, 0.85);
}
.with_labels .money-transfer__row label::before,
.with_labels .money-transfer__row label::after{
  display:none!important;
}

/* AMOUNT DISPLAYS */
.c-ticket__row .c-ticket__title_small
, .c-ticket__row .price-tag_small
, .c-ticket__row .price-tag_small .price-tag.price-tag-symbol
, .c-ticket__row .price-tag_small .price-tag.price-tag-fraction{
  font-weight: 400!important;
  font-size: 1.2em!important;
  line-height: 1.6em!important; 
}


/* PDV */
.circled_action_type{
  line-height: 2.2;
  text-align: center;
}
.flex_center, .flex_row, .flex_column{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}
.flex_row{
    flex-direction: row!important;
}
.flex_row_start{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow:hidden;
}
.flex_column{
    flex-direction: column!important;
}

.mp-box__shadow__full {
    -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}


/*EDITABLE TABLES*/
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 4px 11px;
}

.ant-list-item .right{
  /*display: flex!important;
  flex-direction: row!important;
  justify-content: flex-end!important;*/
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

}
.ant-list-item .right .last-1{
  margin-right:8px;
}
.ant-list-item .right .last{
  min-width:130px;
  display: flex!important;
  flex-direction: column!important;
  justify-content: flex-end!important;
  align-items: flex-end;
}
.ant-list-item .right .last .money{
  font-weight: bold;
}

.column_editable{
  background-color:#e6f7ff;
}
.column_action{
  background-color:#f2f4f5;
}

.no_label .ant-form-item-label {display:none;}

.c-detail_small {
    width: 600px !important;
    margin: 0 auto;
    padding: 0;
    /*font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;*/
    font-weight: 400;
  }

.c-detail_small .ui-list
, .c-detail_small .ui-row{
  background-color: #f0f0f0!important;
}

.c-detail .ui-list.shorter .ui-row{
  height:40px;
  margin-top:4px;
  margin-bottom:4px;
}
.c-detail .ui-list.shorter .ui-row a
, .c-detail .ui-list.shorter .ui-row a:link
, .c-detail .ui-list.shorter .ui-row a:visited
, .c-detail .ui-list.shorter .ui-row a:active{
  text-decoration: none;
}

.money-transfer__select .ant-select-search--inline .ant-select-search__field{
  line-height: inherit!important;
}

.certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}

.ant-menu-item{
  /*padding-left: 36px!important;*/
}

.ant-menu-item svg.svg-inline--fa{
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
}
.ant-table-footer{
  padding: 8px!important;
}


/* pull to refresh :) */

.code-box-actions {
    padding-top: 12px;
    text-align: center;
    border-top: 1px dashed #ebedf0;
    opacity: .7;
    transition: opacity .3s;
}

.code-box-actions:hover
{
  opacity:1
}

.redoButton{
  color: rgba(0,0,0,0.85)!important;
}
.redoButton:hover{
  color: #40a9ff!important;
}

select .anticon {
  color:rgba(0,0,0,1)!important;
}













/*.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title*/
/*.account_menu_item li, ul#accounts_menu_key li{
  height:auto;
  min-height: 40px;
  background-color: #ff6699;
}*/

.account_menu_item .menu_account_container, .menu_account_container{
  display:         flex;
  flex-direction:  column;
  justify-content: flex-start;
  align-items:     flex-start;
  padding-left:    28px; 
  margin-top:      -8px; 
}
.account_menu_item .menu_account_name, .menu_account_name{
  font-weight:     600;
  text-transform:  lowercase!important;
}

.menu_account_permission{
  font-size:       0.85em;
  line-height:     1em;
}
.menu_account_permission span{
  font-weight:     bold;
}
.menu_account_type{
  font-size:       0.85em;
  line-height:     1em;
}
.menu_account_type span{
  font-weight:     bold;
}



.menu_account_item{
  padding:  16px 8px 16px 24px;
  color:    rgba(0, 0, 0, 0.65);
}

.menu_account_item .anticon{
  min-width: 14px;
  margin-right: 10px;
  color: #000;
}
.menu_account_item .menu_account_name{
  font-size:   18px;
  line-height: 18px;
}
.menu_account_item .menu_account_container{
  padding-left:    28px; 
  margin-top:      4px;
}
.menu_account_item .menu_account_permission{
  font-size:       10px;
  line-height:     10px;
}
.menu_account_item .menu_account_permission span{
  font-weight:     bold;
}
.menu_account_item .menu_account_type{
  font-size:       10px;
  line-height:     10px;
}
.menu_account_item .menu_account_type span{
  font-weight:     bold;
}

.ant-menu-submenu-title svg.svg-inline--fa{
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
}

.menu_balance_wrapper{
  display:         flex;
  flex-direction:  row;
  justify-content: flex-start;
  align-items:     flex-start;
  padding:16px 8px;
}
.menu_balance_container{
  display:         flex;
  flex-direction:  column;
  justify-content: flex-start;
  align-items:     flex-end;
  flex-grow:       1;
}
.menu_balance_container button{
  font-size: 24px;
}
.menu_balance_currency{
  /*line-height:  40px;*/
  font-size:      12px;
  /*flex-grow:    3;
  text-align:   right; */
}
.menu_balance_container .small{
  font-size:10px;
  line-height:10px;
  padding-right: 5px;
}


.omnes_isologo{
  /*font-family: Omnes;*/
  font-family: 'Bariol-Regular';
  font-size:   20px!important;
  line-height: 20px!important;
  font-weight: 400;
  line-height: 1.1;
  color:       #444;
/*  color:        rgb(0, 160, 221);*/
}

.omnes_isologo small {
  font-weight: 400;
  line-height: 1;
  color: #bbb;
}



.load_more_txs{
  color: rgb(105, 123, 140);
  width: 100%;
  background-color: #f0f0f0;
  margin: 4px 0px;
  font-size: 12px;
  line-height: 10px;
  height: 24px;
}

span.uppercase {text-transform: uppercase;}
span.bold {font-weight: bold;}

div.headerList tr{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
}
div.headerList td{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  flex:1;
}
div.headerList td .ant-descriptions-item-label{ flex:1; }
div.headerList td .ant-descriptions-item-content{flex:3; align-self: flex-start;}


.col_icon_widget {
  display:          flex;
  flex-direction:   row;
  justify-content:  center;
}
.col_icon{flex: 0 0 49px;}
.col_widget{flex:1;}






/* ******************************************************* */
/* SIDE MENU items */
.ant-layout-sider-children ul.ant-menu li.ant-menu-item {
  height: auto;
  min-height: 40px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin:0px!important;
  /*line-height: 40px;*/
}


.ant-layout-sider-children ul.ant-menu li.ant-menu-item a{
  overflow: hidden;
  overflow-wrap: normal;
  height: auto;
  max-height: 40px;
  line-height: 14px;
}

.ant-layout-sider-children ul.ant-menu li.ant-menu-item a span{
  /*overflow-wrap: normal;*/
  width:100%;
  white-space:normal; 
  line-height: 14px;
}

.ant-menu-item.is_root_menu a:first-child {
    flex-basis: auto!important;
}
/* ******************************************************* */

.margin_top_20{
  margin-top: 20px;
}

.info_page .desc .ant-typography {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.info_page .desc .name{font-size:16px; flex:3; padding-right:16px; text-align: right; align-self: flex-end;}
.info_page .desc .value{font-size:16px; font-weight:strong;flex:6;}

.powered_by{padding-left:0px;}